import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';
import { stateOptions, integerKeyDown /* , floatKeyDown */ } from '../../../Services/inputMasks';
import PercentageInput from '../../Shared/PercentageInput';

const WCRatesModal = () => {
	// context
	const {
		operation,
		createRate,
		updateRate,
		// unavailableStates,
		wcRatesModal,
		hideWCRatesModal,
		rateType,
		year
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ validator, setValidator ] = useState( {
		state: 'string',
		code: '1111',
		rate: 0
	} );

	// set formData to operation data
	useEffect( () => {
		if ( operation ) {
			const rateData = {
				state: operation.state,
				code: operation.code,
				rate: operation.rate
			};

			setData( rateData );
		}

		return () => {
			setData( {} );
		};
	}, [ operation, setData ] );

	// prepare data for updateLead api call
	const handleUpdate = () => {
		updateRate( {
			...operation,
			state: formData.state,
			code: formData.code,
			rate: formData.rate
		} );
	};

	// prepare data for updateLead api call
	const handleCreateNew = () => {
		createRate( {
			type: { id: rateType },
			year,
			state: formData.state,
			code: formData.code,
			rate: formData.rate
		} );
	};

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {
			state: 'string',
			code: '1111',
			rate: 0
		} );
	};

	return (
		<Modal
			open={ wcRatesModal }
			size="mini"
		>
			<Modal.Header>
        Administration Rate
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Select
						required
						name="state"
						value={ formData.state || '' }
						label="State"
						options={ stateOptions }
						width={ 3 }
						fluid
						search
						onChange={ ( e, { name, value } ) => updateField( { target: { name, value } } ) }
						error={ validator.state.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, state: formData.state || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, state: 'XX' } );
						}}
					/>
					<Message
						hidden={ validator.state.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please select a state.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						required
						name="code"
						label="Code"
						maxLength={ 4 }
						value={ formData.code || '' }
						onChange={ updateField }
						error={ validator.code.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, code: formData.code || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, code: '0' } );
						}}
						onKeyDown={ integerKeyDown }
					>
					</Form.Input>
					<Message
						hidden={ validator.code.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a code
							</Message.Item>
						</Message.List>
					</Message>
					<PercentageInput
						required
						name="rate"
						label="Rate"
						value={ formData.rate || '' }
						onChange={ updateField }
						error={ !( parseInt( validator.rate ) >= 0 ) }
						onBlur={ () => setValidator( { ...validator, rate: parseInt( formData.rate ) >= 0 ? formData.rate : '' } ) }
						onFocus={ () => setValidator( { ...validator, rate: 0 } ) }
					/>
					<Message
						hidden={
							parseInt( validator.rate ) >= 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a rate.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ operation
						? () => {
							handleUpdate();
							clearData();
							hideWCRatesModal();
						}
						: () => {
							handleCreateNew();
							clearData();
							hideWCRatesModal();
						}
					}
					disabled={
						( formData.state ? formData.state.length === 0 : true )
            || ( formData.code ? formData.code.length === 0 : true )
            || !( parseInt( formData.rate ) >= 0 )
					}
				>
				</Button>
				<Button
					negative
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideWCRatesModal();
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default WCRatesModal;
