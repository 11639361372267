import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import CustomersProvider from '../Customers/CustomersContext';
import OperationsProvider from '../Operations/OperationsContext';
import ReportsProvider from '../Reports/ReportsContext';
import Sales from './Sales';
import Clients from './Clients';
import Operations from './Operations';
import Reports from './Reports';
import Admin from './Admin';

const PageIndex = () => {
	// context
	const { authedUser } = useContext( AuthContext );

	return (
		<div className="dashboardContainer">
			<CustomersProvider authedUser={ authedUser }>
				{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 5 || authedUser.role.id === 7
					? <Route path="/main/sales" component={ Sales } />
					: null
				}
				{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 5 || authedUser.role.id === 7 || authedUser.role.id === 8
					? <Route path="/main/clients" component={ Clients } />
					: null
				}
			</CustomersProvider>
			{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 7
				? <OperationsProvider>
					<Route path="/main/operations" component={ Operations } />
				</OperationsProvider>
				: null
			}
			<ReportsProvider>
				{ authedUser.role.id === 1
					? <Route path="/main/reports" component={ Reports } />
					: null
				}
			</ReportsProvider>
			{ authedUser.role.id === 1 /* || authedUser.role.id === 4 || authedUser.role.id === 7 */
				? <Route path="/main/administration" component={ Admin } />
				: null
			}
		</div>
	);
};

export default PageIndex;
