import React from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';
import { Settings } from '../Global/config';
import { unmaskPhone } from '../../Services/inputMasks';

export const AdminContext = React.createContext();

class AdminProvider extends React.PureComponent {
  constructor( props ) {
    super( props );
    const { cookies } = props;
    this.state = {
      accessToken: cookies.get( 'token' ),
      // loaders
      spinner: false,
      uiLoader: false,
      loadingMessage: '',
      tableLoader: false,
      users: [],
      user: null,
      adminModal: false,
      deleteConfirmModal: false,
    };
  }

  // api calls

  getUsers = async () => {
    this.setState({
      tableLoader: true,
      uiLoader: true,
      loadingMessage: 'Retrieving users...'
    });
    const response = await axios({
      method: 'get',
      baseURL: Settings.apiUrl,
      url: `/users`,
      headers: {
        api_key: Settings.apiKey,
        Accept: 'application/json',
        AccessToken: this.state.accessToken,
      }
    });
    await this.setState({
      users: response.data,
      tableLoader: false,
      uiLoader: false,
      loadingMessage: ''
    });
  }

  getUser = async userid => {
    const response = await axios({
      method: 'get',
      baseURL: Settings.apiUrl,
      url: `/users/${ userid }`,
      headers: {
        api_key: Settings.apiKey,
        Accept: 'application/json',
        AccessToken: this.state.accessToken,
      },
    });
    await this.setState({
      user: response.data,
    });
  }

  createUser = async formData => {
    this.setState({
      uiLoader: true,
      loadingMessage: 'Creating a new user...'
    });
    const password = btoa( formData.password );
    const data = {
      id: 0,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: unmaskPhone( formData.phone ),
      isSalesAgent: formData.isSalesAgent,
      role: {
        id: parseInt( formData.roleId ),
      },
    };
    await axios({
      method: 'post',
      baseURL: Settings.apiUrl,
      url: '/User',
      params: {
        password
      },
      data,
      headers: {
        api_key: Settings.apiKey,
        Accept: 'application/json',
        AccessToken: this.state.accessToken,
      }
    });
    await this.setState({
      uiLoader: false,
      loadingMessage: ''
    });
    await this.getUsers();
  }

  updateUser = async formData => {
    this.setState({
      uiLoader: true,
      loadingMessage: 'Updating user...'
    });
    const password = btoa( formData.password );
    let params = {};
    if ( password ) {
      params = { password: password };
    }
    const data = {
      id: formData.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: unmaskPhone( formData.phone ),
      isSalesAgent: formData.isSalesAgent,
      role: {
        id: parseInt( formData.roleId ),
      },
    };
    await axios({
      method: 'post',
      baseURL: Settings.apiUrl,
      url: '/user',
      params,
      data,
      headers: {
        api_key: Settings.apiKey,
        Access: 'application/json',
        AccessToken: this.state.accessToken,
      },
    });
    await this.setState({
      uiLoader: false,
      loadingMessage: ''
    });
    await this.getUsers();
  }

  deleteUser = async id => {
    this.setState({
      uiLoader: true,
      loadingMessage: 'Removing user...'
    });
    await axios({
      method: 'delete',
      baseURL: Settings.apiUrl,
      url: `/user/${ id }`,
      headers: {
        api_key: Settings.apiKey,
        Accept: 'application/json',
        AccessToken: this.state.accessToken,
      },
    });
    await this.setState({
      uiLoader: false,
      loadingMessage: ''
    });
    await this.getUsers();
  }

  // modal handlers

  showAdminModal = async id => {
    this.setState({ spinner: true });
    if ( id ) {
      const user = await this.state.users.find( user => user.id === id );
      await this.setState({ user });
    }
    this.setState({
      adminModal: true,
    });
    this.setState({ spinner: false });
  }

  hideAdminModal = () => {
    this.setState({
      adminModal: false,
      user: null,
      userId: ''
    });
  }

  toggleDeleteConfirmModal = id => {
    this.setState( prevState => ({
      deleteConfirmModal: !prevState.deleteConfirmModal,
    }));
  }

  render() {
    return (
      <AdminContext.Provider
        value={{
          ...this.state,
          getUsers: this.getUsers,
          createUser: this.createUser,
          updateUser: this.updateUser,
          deleteUser: this.deleteUser,
          showAdminModal: this.showAdminModal,
          hideAdminModal: this.hideAdminModal,
          toggleDeleteConfirmModal: this.toggleDeleteConfirmModal,
        }}
      >
        { this.props.children }
      </AdminContext.Provider>
    );
  }
}

export default withCookies( AdminProvider );
