import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Settings } from './Components/Global/config';
import history from './history';
import { Router, Route, Redirect } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { AuthContext } from './Components/Auth/AuthContext';
import LoginForm from './Components/Auth/LoginForm';
import Main from './Components/Main/Main';
import ConfirmPasswordChange from './Components/Auth/ConfirmPasswordChange';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import TwoAuthForm from './Components/Auth/TwoAuthForm';

const App = () => {
	// context
	const { isAuthed } = useContext( AuthContext );
	const [ appLoading, setAppLoading ] = useState( true );
	const [ apiError, setApiError ] = useState( false );

	useEffect( () => {
		( async () => {
			try {
				const { data: { app_Version } } = await axios( {
					method: 'get',
					baseURL: Settings.apiUrl,
					url: '/info',
					headers: {
						api_key: Settings.apiKey,
						Accept: 'application/json'
					}
				} );
				const currentVersion = localStorage.getItem( 'appVersion' );

				if ( !currentVersion || JSON.parse( currentVersion ) !== app_Version ) {
					localStorage.setItem( 'appVersion', JSON.stringify( app_Version ) );
					window.location.reload( true );
				}

				setAppLoading( false );
			} catch ( e ) {
				setApiError( true );
			}
		} )();
	}, [] );

	useEffect( () => {
		let currentPathname = null;
		let currentSearch = null;

		history.listen( ( newLocation, action ) => {
			if ( action === 'PUSH' ) {
				if (
					newLocation.pathname !== currentPathname
          || newLocation.search !== currentSearch
				) {
					// Save new location
					currentPathname = newLocation.pathname;
					currentSearch = newLocation.search;
					// Clone location object and push it to history
					history.push( {
						pathname: newLocation.pathname,
						search: newLocation.search
					} );
				}
			} else {
				// Send user back if they try to navigate back
				history.go( 1 );
			}
		} );
	}, [] );

	return (
		<>
			<Modal
				open={ apiError }
				size="mini"
			>
				<Modal.Content style={{ textAlign: 'center' }}>
					<img src="assets/images/logo.png" alt="Worksmart Logo" />
					<p>The app is currently down for maintenance!</p>
				</Modal.Content>
			</Modal>
			{ !appLoading
				? <>
					<Router history={ history }>
						<Route
							path="/login"
							render={ () => !isAuthed
								? <LoginForm />
								: <Redirect to={{ pathname: '/main' }} />
							}
						/>
						<Route
							path="/twoAuth"
							render={ () => !isAuthed
								? <TwoAuthForm />
								: <Redirect to={{ pathname: '/main' }} />
							}
						/>
						<Route
							path="/main"
							render={ () => isAuthed
								? <Main />
								: <Redirect to={{ pathname: '/login' }} />
							}
						/>
						<Route
							path="/confirm"
							component={ ConfirmPasswordChange }
						/>
					</Router>
				</>
				: null
			}
		</>
	);
};

export default App;
