import React, { useContext } from 'react';
import { Modal } from 'semantic-ui-react';
import { AuthContext } from './AuthContext';

const WarningModal = () => {
	const { warningModal } = useContext( AuthContext );

	return (
		<Modal
			open={ warningModal }
			size="mini"
		>
			<Modal.Content style={{ textAlign: 'center' }}>
        No activity has been detected for 10 minutes.
				<br />You will be logged out in 5 minutes if no further activity is detected.
			</Modal.Content>
		</Modal>
	);
};

export default WarningModal;
