import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { OperationsContext } from '../OperationsContext';
import RatesFilter from './FilesFilter';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import FilesModal from './FilesModal';
import AnthemClaimsModal from './AnthemClaimsModal';

const Files = () => {
	// context
	const {
		files,
		fileType,
		getFiles,
		deleteFile,
		deleteConfirmModal,
		toggleDeleteConfirmModal,
		tableLoader
	} = useContext( OperationsContext );

	// state
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ fileId, setFileId ] = useState( '' );

	useEffect( () => {
		getFiles();
	}, [ getFiles, fileType ] );

	const columns = [
		{
			id: 'label',
			Header: <div>Label</div>,
			accessor: row => `${ row.label }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.label.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.label }</div>,
			style: { textAlign: 'left', whiteSpace: 'unset' },
			width: 200
		},
		{
			id: 'filename',
			Header: <div>Filename</div>,
			accessor: row => `${ row.filename }`,
			filterMethod: ( filter, row ) => _.includes( row._original.filename.toUpperCase(), filter.value.toUpperCase() ),
			filterable: true,
			sortable: true,
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.filename }</div>,
			style: { textAlign: 'left', whiteSpace: 'unset' }
		},
		{
			id: 'comment',
			Header: <div>Comment</div>,
			accessor: row => `${ row.comment }`,
			sortable: true,
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.comment }</div>,
			style: { textAlign: 'left', whiteSpace: 'unset' }
		},
		{
			accessor: 'id',
			Header: 'Remove',
			sortable: false,
			filterable: false,
			Cell: row => (
				<div>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.state );
							setFileId( row.original.id );
							toggleDeleteConfirmModal();
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<div>
			<Header as="h2" style={{ fontWeight: 'normal' }}>File Administration</Header>
			<Segment>
				<RatesFilter />
				<Table
					data={ files }
					columns={ columns }
					loading={ tableLoader }
				/>
				<br />
				<p>Records: { files.length }</p>
			</Segment>
			<FilesModal />
			<AnthemClaimsModal />
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="File"
				open={ deleteConfirmModal }
				message={ 'All data associated with this import will be removed from CMS.' }
				closeModal={ () => toggleDeleteConfirmModal() }
				onClick={ () => deleteFile( fileId ) }
			/>
		</div>
	);
};

export default Files;
