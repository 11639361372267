import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider, Dropdown, Form, Label, Segment, Table } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { AuthContext } from '../../Auth/AuthContext';
import { maskCurrency, unmaskCurrency, floatKeyDown } from '../../../Services/inputMasks';
import AdminRatesNotesModal from './AdminRatesNotesModal';

const AdminRatesTables = ( { formData, updateField } ) => {
	const { adminRates, saveAndCalculate } = useContext( CustomersContext );
	const { authedUser } = useContext( AuthContext );

	// form handler
	const [ perEmployee, setPerEmployee ] = useState( null );
	const [ payCycle, setPayCycle ] = useState( 'all' );

	useEffect( () => {
		if ( payCycle !== 'all' ) {
			const pc = payCycle === 'wS_WK' ? 52
				: payCycle === 'wS_BW' ? 26
					: payCycle === 'wS_SM' ? 24
						: payCycle === 'wS_MO' ? 12
							: null;
			const employeeCount = adminRates[ `${ payCycle }_Count` ] || 1;
			const prospectMonthly = unmaskCurrency( formData.rate );
			const prospectAnnual = prospectMonthly * 12;
			const prospectPerPayroll = prospectAnnual / pc;
			const wsAnnual = unmaskCurrency( formData[ payCycle ] ) * pc;
			const wsMonthly = wsAnnual / 12;

			const calculatedPerEmployee = {
				employeeCount,
				prospect: {
					perPayroll: prospectPerPayroll,
					monthly: prospectMonthly,
					annual: prospectAnnual
				},
				ws: {
					perPayroll: unmaskCurrency( formData[ payCycle ] ),
					monthly: wsMonthly,
					annual: wsAnnual
				},
				profit: {
					perPayroll: prospectPerPayroll - unmaskCurrency( formData[ payCycle ] ),
					monthly: prospectMonthly - wsMonthly,
					annual: prospectAnnual - wsAnnual
				}
			};

			setPerEmployee( calculatedPerEmployee );
		} else {
			const employeeCount = adminRates.wS_WK_Count + adminRates.wS_BW_Count + adminRates.wS_SM_Count + adminRates.wS_MO_Count || 1;
			const prospectMonthly = unmaskCurrency( formData.rate );
			const wsAnnual = unmaskCurrency( formData.wS_WK ) * adminRates.wS_WK_Count * 52
          + unmaskCurrency( formData.wS_BW ) * adminRates.wS_BW_Count * 26
          + unmaskCurrency( formData.wS_SM ) * adminRates.wS_SM_Count * 24
          + unmaskCurrency( formData.wS_MO ) * adminRates.wS_MO_Count * 12;
			const wsMonthly = wsAnnual / 12;

			const calculatedPerEmployee = {
				employeeCount,
				prospect: {
					monthly: prospectMonthly,
					annual: prospectMonthly * 12
				},
				ws: {
					monthly: wsMonthly / employeeCount,
					annual: wsAnnual / employeeCount // an average
				},
				profit: {
					monthly: prospectMonthly - wsMonthly / employeeCount,
					annual: prospectMonthly * 12 - wsAnnual / employeeCount
				}
			};

			setPerEmployee( calculatedPerEmployee );
		}
	}, [ formData, adminRates, payCycle ] );

	return (
		<>
			<Form>
				<Segment>
					<Form.Group style={{ alignItems: 'flex-end', marginLeft: 0, marginRight: 0 }}>
						<Form.Input
							name="rate"
							value={ formData.rate || '' }
							label="Current Monthly Rate"
							onChange={ updateField }
							onBlur={ e => {
								updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
							} }
							onKeyDown={ floatKeyDown }
							readOnly={ authedUser.role.id !== 1 }
						/>
						<Form.Group style={{ alignItems: 'flex-end', marginBottom: 0, paddingLeft: '0.5rem' }}>
							<Form.Input
								readOnly={ authedUser.role.id !== 1 }
								name="wsFee"
								value={ formData.wsFee || '' }
								label="Implementaton Fee"
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
								} }
								onKeyDown={ floatKeyDown }
								action={ authedUser.role.id === 1
									? <Label
										basic
										style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
									>
										<Checkbox
											toggle
											name="wsFee_Override"
											checked={ formData.wsFee_Override || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Label>
									: null
								}
								actionPosition={ authedUser.role.id === 1 ? 'left' : null }
							/>
							{ authedUser.role.id === 1
								? <AdminRatesNotesModal
									title="Implementation Fee Notes"
									name="note_2"
									value={ formData.note_2 }
									onChange={ updateField }
									onSave={ () => saveAndCalculate( {
										...adminRates,
										...formData,
										rate: unmaskCurrency( formData.rate ),
										wsRate: unmaskCurrency( formData.wsRate ),
										wsFee: unmaskCurrency( formData.wsFee )
									} )}
								/>
								: null
							}
						</Form.Group>
					</Form.Group>

					<Form.Group style={{ alignItems: 'flex-end', marginLeft: 0, marginRight: 0 }}>
						{ adminRates.wS_WK_Count > 0
							? <Form.Input
								readOnly={ authedUser.role.id !== 1 }

								name="wS_WK"
								value={ formData.wS_WK || '' }
								label="Weekly WS Rate"
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
								} }
								onKeyDown={ floatKeyDown }
								action={ authedUser.role.id === 1
									? <Label
										basic
										style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
									>
										<Checkbox
											toggle
											name="wS_WK_Override"
											checked={ formData.wS_WK_Override || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Label>
									: null
								}
								actionPosition={ authedUser.role.id === 1 ? 'left' : null }
							/>
							: null
						}
						{ adminRates.wS_BW_Count > 0
							? <Form.Input
								readOnly={ authedUser.role.id !== 1 }

								name="wS_BW"
								value={ formData.wS_BW || '' }
								label="Bi-Weekly WS Rate"
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
								} }
								onKeyDown={ floatKeyDown }
								action={ authedUser.role.id === 1
									? <Label
										basic
										style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
									>
										<Checkbox
											toggle
											name="wS_BW_Override"
											checked={ formData.wS_BW_Override || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Label>
									: null
								}
								actionPosition={ authedUser.role.id === 1 ? 'left' : null }
							/>
							: null
						}
						{ adminRates.wS_SM_Count > 0
							? <Form.Input
								readOnly={ authedUser.role.id !== 1 }

								name="wS_SM"
								value={ formData.wS_SM || '' }
								label="Semi-Monthly WS Rate"
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
								} }
								onKeyDown={ floatKeyDown }
								action={ authedUser.role.id === 1
									? <Label
										basic
										style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
									>
										<Checkbox
											toggle
											name="wS_SM_Override"
											checked={ formData.wS_SM_Override || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Label>
									: null
								}
								actionPosition={ authedUser.role.id === 1 ? 'left' : null }
							/>
							: null
						}
						{ adminRates.wS_MO_Count > 0
							? <Form.Input
								readOnly={ authedUser.role.id !== 1 }

								name="wS_MO"
								value={ formData.wS_MO || '' }
								label="Monthly WS Rate"
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? maskCurrency( e.target.value ) : '' } } );
								} }
								onKeyDown={ floatKeyDown }
								action={ authedUser.role.id === 1
									? <Label
										basic
										style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
									>
										<Checkbox
											toggle
											name="wS_MO_Override"
											checked={ formData.wS_MO_Override || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Label>
									: null
								}
								actionPosition={ authedUser.role.id === 1 ? 'left' : null }
							/>
							: null
						}
						{ authedUser.role.id === 1
							? <AdminRatesNotesModal
								title="WS Rate Notes"
								name="note_1"
								value={ formData.note_1 }
								onChange={ updateField }
								onSave={ () => saveAndCalculate( {
									...adminRates,
									...formData,
									rate: unmaskCurrency( formData.rate ),
									wS_WK: unmaskCurrency( formData.wS_WK ),
									wS_BW: unmaskCurrency( formData.wS_BW ),
									wS_SM: unmaskCurrency( formData.wS_SM ),
									wS_MO: unmaskCurrency( formData.wS_MO ),
									wsFee: unmaskCurrency( formData.wsFee )
								} )}
							/>
							: null
						}
					</Form.Group>

					{/* <Form.Button
              style={{ minWidth: '174px' }}
              content="Save and Calculate"
              onClick={ () => saveAndCalculate({
                ...adminRates,
                ...formData,
                rate: unmaskCurrency( formData.rate ),
                wS_WK: unmaskCurrency( formData.wS_WK ),
                wS_BW: unmaskCurrency( formData.wS_BW ),
                wS_SM: unmaskCurrency( formData.wS_SM ),
                wS_MO: unmaskCurrency( formData.wS_MO ),
                wsFee: unmaskCurrency( formData.wsFee )
              })}
            /> */}
				</Segment>
			</Form>
			<Divider hidden />
			<Table
				celled
				columns={ 4 }
				textAlign="center"
			>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							<span style={{ fontWeight: 'normal' }}>Pay Cycles:&nbsp;</span>
							<Dropdown
								inline
								defaultValue="all"
								options={[
									{ text: 'All', value: 'all' },
									...adminRates.wS_MO ? [ { text: 'Monthly', value: 'wS_MO' } ] : [],
									...adminRates.wS_SM ? [ { text: 'Semi-Monthly', value: 'wS_SM' } ] : [],
									...adminRates.wS_BW ? [ { text: 'Bi-Weekly', value: 'wS_BW' } ] : [],
									...adminRates.wS_WK ? [ { text: 'Weekly', value: 'wS_WK' } ] : []
								]}
								label="PayCycles"
								onChange={ ( e, { value } ) => {
									setPerEmployee( null ); setPayCycle( value );
								} }
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
              Prospect Admin Costs
						</Table.HeaderCell>
						<Table.HeaderCell>
              WS Admin Costs
						</Table.HeaderCell>
						<Table.HeaderCell>
              Profit
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row>
						<Table.Cell colSpan={ 4 }>
              Per Employee
							{/* { `Per Employee (one time implementation fee of ${ maskCurrency( adminRates.wsFee ) })` } */}
						</Table.Cell>
					</Table.Row>
					{ payCycle !== 'all'
						? <Table.Row>
							<Table.Cell>
                  Per Payroll
							</Table.Cell>
							<Table.Cell>
								{ perEmployee ? maskCurrency( perEmployee.prospect.perPayroll ) : null }
							</Table.Cell>
							<Table.Cell>
								{ perEmployee ? maskCurrency( perEmployee.ws.perPayroll ) : null }
							</Table.Cell>
							<Table.Cell style={ perEmployee && { color: perEmployee.profit.perPayroll < 0 ? 'red' : null }}>
								{ perEmployee ? maskCurrency( perEmployee.profit.perPayroll ) : null }
							</Table.Cell>
						</Table.Row>
						: null
					}
					<Table.Row>
						<Table.Cell>
              Monthly
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.prospect.monthly ) : null }
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.ws.monthly ) : null }
						</Table.Cell>
						<Table.Cell style={ perEmployee && { color: perEmployee.profit.monthly < 0 ? 'red' : null }}>
							{ perEmployee ? maskCurrency( perEmployee.profit.monthly ) : null }
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
              Annual
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.prospect.annual ) : null }
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.ws.annual ) : null }
						</Table.Cell>
						<Table.Cell style={ perEmployee && { color: perEmployee.profit.annual < 0 ? 'red' : null }}>
							{ perEmployee ? maskCurrency( perEmployee.profit.annual ) : null }
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell colSpan={ 4 }>
              All Employees
							{/* { `All Employees (${ employeeCount }) (one time implementation fee of ${ maskCurrency( adminRates.wsFee * employeeCount ) })` } */}
						</Table.Cell>
					</Table.Row>
					{ payCycle !== 'all'
						? <Table.Row>
							<Table.Cell>
                  Per Payroll
							</Table.Cell>
							<Table.Cell>
								{ perEmployee ? maskCurrency( perEmployee.prospect.perPayroll * perEmployee.employeeCount ) : null }
							</Table.Cell>
							<Table.Cell>
								{ perEmployee ? maskCurrency( perEmployee.ws.perPayroll * perEmployee.employeeCount ) : null }
							</Table.Cell>
							<Table.Cell style={ perEmployee && { color: perEmployee.profit.perPayroll * perEmployee.employeeCount < 0 ? 'red' : null }}>
								{ perEmployee ? maskCurrency( perEmployee.profit.perPayroll * perEmployee.employeeCount ) : null }
							</Table.Cell>
						</Table.Row>
						: null
					}
					<Table.Row>
						<Table.Cell>
              Monthly
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.prospect.monthly * perEmployee.employeeCount ) : null }
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.ws.monthly * perEmployee.employeeCount ) : null }
						</Table.Cell>
						<Table.Cell style={ perEmployee && { color: perEmployee.profit.monthly * perEmployee.employeeCount < 0 ? 'red' : null }}>
							{ perEmployee ? maskCurrency( perEmployee.profit.monthly * perEmployee.employeeCount ) : null }
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
              Annual
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.prospect.annual * perEmployee.employeeCount ) : null }
						</Table.Cell>
						<Table.Cell>
							{ perEmployee ? maskCurrency( perEmployee.ws.annual * perEmployee.employeeCount ) : null }
						</Table.Cell>
						<Table.Cell style={ perEmployee && { color: perEmployee.profit.annual * perEmployee.employeeCount < 0 ? 'red' : null }}>
							{ perEmployee ? maskCurrency( perEmployee.profit.annual * perEmployee.employeeCount ) : null }
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</>
	);
};

export default AdminRatesTables;
