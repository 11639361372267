const roundToHundredth = num => {
	const whole = Math.floor( num );
	const decimal = num - whole;
	const roundedDecimal = Math.round( Math.round( decimal * 1000 ) / 10 ) / 100;
	const roundedNum = whole + roundedDecimal;

	return roundedNum;
};

export default roundToHundredth;
