import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';
import CustomersFilter from '../../CustomersFilter';
import Table from '../../../Shared/Table';
import ArchiveConfirmModal from '../../../Shared/ArchiveConfirmModal';
import DeleteConfirmModal from '../../../Shared/DeleteConfirmModal';
import LeadsModal from './LeadsModal';

function transformDate( str ) {
	return new Date( str ).toLocaleDateString();
}

const Leads = () => {
	// context
	const {
		leads,
		tableLoader,
		getLeads,
		deleteLead,
		showLeadsModal,
		archiveReasons,
		archiveLead,
		archiveConfirmModal,
		deleteConfirmModal,
		toggleArchiveConfirmModal,
		toggleDeleteConfirmModal,
		resetFilter,
		setFilteredCustomers
	} = useContext( CustomersContext );

	const leadsTable = useRef( null );

	// state
	const [ archiveId, setArchiveId ] = useState( '' );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );

	useEffect( () => {
		resetFilter( getLeads );
	}, [ resetFilter, getLeads ] );

	useEffect( () => {
		setFilteredCustomers( leads.map( ( { id } ) => id ) );
	}, [ leads, setFilteredCustomers ] );

	const columns = [
		{
			id: 'nameAddress',
			Header: <div>Name & Address</div>,
			accessor: row => `${ row.companyName } ${ row.address } ${ row.address2 } ${ row.city } ${ row.state } ${ row.zip }`,
			filterMethod: ( filter, row ) => _.includes( row._original.companyName.toUpperCase(), filter.value.toUpperCase() )
        || _.includes( row._original.address.toUpperCase(), filter.value.toUpperCase() )
        || _.includes( row._original.city.toUpperCase(), filter.value.toUpperCase() )
        || _.includes( row._original.state.toUpperCase(), filter.value.toUpperCase() )
        || _.includes( row._original.zip.toUpperCase(), filter.value.toUpperCase() ),
			filterable: true,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div style={{ whiteSpace: 'normal', textAlign: 'left' }}>
					<span style={{ fontWeight: 'bold' }}>{ row.original.companyName }</span>
					<br/>
					<span style={{ fontSize: '0.9rem' }}>
						{ row.original.address }
						{ row.original.address2
							? <>
								<br />
								{ row.original.address2 }
							</>
							: null
						}
						<br/> { row.original.city }, { row.original.state } { row.original.zip }
					</span>
				</div>
			),
			style: { whiteSpace: 'unset' },
			width: 200
		},
		{
			id: 'assignmentDate',
			Header: <div style={{ marginLeft: '4px' }}>Assignment Date</div>,
			accessor: d => d.assignmentDate,
			Cell: d => transformDate( d.original.assignmentDate ),
			filterable: true,
			sortable: true
		},
		{
			id: 'contactName',
			Header: <div>Contact Name</div>,
			accessor: row => `${ row.contactName }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.contactName.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.contactName }</div>,
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'email',
			Header: <div style={{ marginLeft: '4px' }}>Email Address</div>,
			accessor: row => row.contactEmail,
			filterMethod: ( filter, row ) => _.includes( row._original.contactEmail.toUpperCase(), filter.value.toUpperCase() ),
			filterable: true,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <a href={ `mailto:${ row.original.contactEmail }` }>{ row.original.contactEmail }</a>,
			style: { whiteSpace: 'unset' },
			width: 250
		},
		{
			id: 'source',
			Header: <div>Source</div>,
			accessor: row => `${ row.source }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.source.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.source }</div>,
			style: { whiteSpace: 'unset' },
			width: 90
		},
		{
			id: 'status',
			Header: <div>Status</div>,
			accessor: row => `${ row.status }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.status.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			Cell:
        // eslint-disable-next-line react/display-name
        row => <div style={{ whiteSpace: 'normal' }}>{ row.original.status }</div>,
			style: { whiteSpace: 'unset' },
			width: 90
		},
		{
			id: 'statusDate',
			Header: <div style={{ marginLeft: '4px' }}>Status Date</div>,
			accessor: d => d.statusDate,
			Cell: d => transformDate( d.original.statusDate ),
			filterable: true,
			sortable: true,
			width: 90
		},
		{
			accessor: 'id',
			Header: 'Edit / Remove',
			sortable: false,
			filterable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						onClick={ () => showLeadsModal( row.original.id ) }
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						secondary
						size="tiny"
						onClick={ () => {
							setArchiveId( row.original.id );
							toggleArchiveConfirmModal();
						}}
					>
						<Icon name="archive" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.companyName );
							setDeleteId( row.original.id );
							toggleDeleteConfirmModal();
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<>
			<Header as="h2" style={{ fontWeight: 'normal' }}>Leads List</Header>
			<Segment>
				<CustomersFilter callback={ getLeads } showModal={ showLeadsModal } showSalesForceSync />
				<Table
					data={ leads }
					columns={ columns }
					loading={ tableLoader }
					ref={ leadsTable }
					onFilteredChange={ () => {
						const customerIds = leadsTable?.current.getResolvedState().sortedData.map( ( { id } ) => id );

						setFilteredCustomers( customerIds );
					}}
				/>
				<br />
				<p>Records: { leads ? leads.length : 0 }</p>
			</Segment>
			<LeadsModal />
			<ArchiveConfirmModal
				type="Lead"
				open={ archiveConfirmModal }
				closeModal={ () => toggleArchiveConfirmModal() }
				onClick={ async reasonCodeID => {
					await archiveLead( archiveId, reasonCodeID );
					await getLeads();
				}}
				reasons={ archiveReasons }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Lead"
				open={ deleteConfirmModal }
				closeModal={ () => toggleDeleteConfirmModal() }
				onClick={ () => deleteLead( deleteId ) }
			/>
		</>
	);
};

export default Leads;
