import React from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';
import { Settings } from '../Global/config';

export const ReportsContext = React.createContext();

class ReportsProvider extends React.PureComponent {
	constructor( props ) {
		super( props );
		const { cookies } = props;

		this.state = {
			accessToken: cookies.get( 'token' ),
			// loaders
			spinner: false,
			uiLoader: false,
			loadingMessage: '',
			tableLoader: false,
			// lookups
			clients: [],
			// modals
			reportModal: false
		};
	}

	componentDidMount() {
		// lookups
		this.lookupClients();
	}

	// lookups
	mapLookup = ( data, text = 'name' ) => {
		return data.map( option => ( {
			key: option.id,
			text: option[ text ],
			value: option.id
		} ) );
	}

	lookupClients = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/clients',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const clients = await this.mapLookup( response.data.types, 'description' );

		this.setState( { clients } );
	}

	getClaimSummaryReport = async ( { start, end, customerIds } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading claim summary...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/report/claimsummary',
			params: {
				start,
				end,
				customerIds: customerIds.join( ',' )
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getHighClaimantExtract = async ( { start, end, customerIds, firstName = '', lastName = '' } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading high claimant extract...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/highclaimant',
			params: {
				start,
				end,
				customerIds: customerIds.join( ',' ),
				firstName,
				lastName
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getAnthemReinsuranceExtract = async ( { start, end, customerIds, firstName = '', lastName = '' } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading anthem reinsurance extract...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/anthemreinsurance',
			params: {
				start,
				end,
				customerIds: customerIds.join( ',' ),
				firstName,
				lastName,
				includeDetail: false
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getTierRateChart = async ( { year } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading high claimant extract...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/report/tierchart',
			params: { year },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getAnthemReinsuranceDetail = async ( { start, end, customerIds, firstName = '', lastName = '' } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading anthem reinsurance detail...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/anthemreinsurance',
			params: {
				start,
				end,
				customerIds: customerIds.join( ',' ),
				firstName,
				lastName,
				includeDetail: true
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getMedicalLossSummary = async ( { start, end } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading medical loss summary...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/claimantsummary',
			params: { start, end },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getEmployeeClaimsExtract = async ( { start, end, customerIds, firstName = '', lastName = '' } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading anthem reinsurance extract...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/employeeclaims',
			params: {
				start,
				end,
				customerIds: customerIds.join( ',' ),
				firstName,
				lastName
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	getAnnualDataExtract = async ( { year } ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'downloading anthem reinsurance extract...'
		} );
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/extract/data/all',
			params: { year },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	showReportModal = ( reportId = 0 ) => {
		switch ( reportId ) {
			default:
				this.setState( { reportModal: true } );
		}
	}

	hideReportModal = ( reportId = 0 ) => {
		switch ( reportId ) {
			default:
				this.setState( { reportModal: false } );
		}
	}

	render() {
		return (
			<ReportsContext.Provider
				value={{
					...this.state,

					// api calls
					getClaimSummaryReport: this.getClaimSummaryReport,
					getHighClaimantExtract: this.getHighClaimantExtract,
					getAnthemReinsuranceExtract: this.getAnthemReinsuranceExtract,
					getAnthemReinsuranceDetail: this.getAnthemReinsuranceDetail,
					getMedicalLossSummary: this.getMedicalLossSummary,
					getEmployeeClaimsExtract: this.getEmployeeClaimsExtract,
					getAnnualDataExtract: this.getAnnualDataExtract,
					getTierRateChart: this.getTierRateChart,
					// modal handlers
					showReportModal: this.showReportModal,
					hideReportModal: this.hideReportModal
				}}
			>
				{ this.props.children }
			</ReportsContext.Provider>
		);
	}
}

export default withCookies( ReportsProvider );
