import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { OperationsContext } from '../OperationsContext';
import TiersFilter from './TiersFilter';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import TiersModal from './TiersModal';

const Tiers = props => {

  // context
  const {
    tiers,
    tierType,
    year,
    getTiers,
    deleteTier,
    showTiersModal,
    deleteConfirmModal,
    toggleDeleteConfirmModal,
    tableLoader
  } = useContext( OperationsContext );

  // state
  const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
  const [ tierId, setTierId ] = useState( '' );

  useEffect( () => {
    getTiers();
  }, [ getTiers, tierType, year ] );

  const columns = [
    {
      id: 'description',
      Header: <div>Description</div>,
      accessor: row => `${ row.description }`,
      filterable: true,
      filterMethod: ( filter, row ) =>
        _.includes( row._original.description.toUpperCase(), filter.value.toUpperCase() ),
      sortable: true,
      Cell:
        row => {
          return (
            <div style={{ whiteSpace: 'normal' }}>{row.original.description }</div>
          );
        },
      style: {'whiteSpace': 'unset' },
    },
    {
      id: 'rate',
      Header: <div>Rate</div>,
      accessor: row => `${ row.rate }`,
      sortable: true,
      Cell:
        row => {
          return (
            <div style={{ whiteSpace: 'normal' }}>{ row.original.rate }</div>
          );
        },
      style: { 'whiteSpace': 'unset' },
    },
    {
      accessor: 'id',
      Header: 'Edit / Remove',
      sortable: false,
      filterable: false,
      Cell: row => (
        <div>
          <Button
            icon
            primary
            size="tiny"
            tierid={ row.original.id }
            onClick={ ( e, { tierid } ) => showTiersModal( tierid ) }
          >
            <Icon name="pencil" />
          </Button>
          <Button
            icon
            negative
            size="tiny"
            onClick={ () => {
              setDeleteConfirmModalNameProp( row.original.description);
              setTierId( row.original.id );
              toggleDeleteConfirmModal();
            }}
          >
            <Icon name="trash" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'normal' }}>Benefit Tier Administration</Header>
      <Segment>
        <TiersFilter />
        <Table
          data={ tiers }
          columns={ columns }
          loading={ tableLoader }
        />
        <br />
        <p>Records: { tiers.length }</p>
      </Segment>
      <TiersModal />
      <DeleteConfirmModal
        name={ deleteConfirmModalNameProp }
        type="Tier"
        open={ deleteConfirmModal }
        closeModal={ () => toggleDeleteConfirmModal() }
        onClick={ () => deleteTier( tierId ) }
      />
    </>
  );
};

export default Tiers;
