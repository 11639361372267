import React, { useCallback, useContext } from 'react';
import { Form, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';

const LifeTab = ( { formData, updateField, readonly } ) => {
	const { employerPaid } = useContext( CustomersContext );

	const findOption = useCallback( ( val, options ) => {
		const opt = options.find( option => option.value === val );

		return opt.text;
	}, [] );

	return (
		<Tab.Pane>
			<Form>
				<Form.Group inline>
					<Form.Field width={ 3 }>
						<label>Basic Life & ADD</label>
						<Form.Checkbox
							name="basicLife"
							checked={ formData.basicLife || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group inline>
					<Form.Field width={ 3 }>
						<label>Voluntary Life & ADD</label>
						<Form.Checkbox
							name="volunteerLife"
							checked={ formData.volunteerLife || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.employerPaid, employerPaid ) }
							label="Employer Paid Life & ADD"
							width={ 3 }
						/>
						: <Form.Select
							name="employerPaid"
							value={ formData.employerPaid || '' }
							label="Employer Paid Life & ADD"
							options={ employerPaid }
							width={ 3 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
			</Form>
		</Tab.Pane>
	);
};

export default LifeTab;
