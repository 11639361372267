import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalculator, faLayerGroup, faFileUpload /* faMoneyBillAlt */ } from '@fortawesome/free-solid-svg-icons';
import history from '../../history';

const OperationsMenu = () => {
	return (
		<div className="sidebar-menu-container">
			<Link to="/main/operations" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations' ? '#2654a3' : '#ccc' }}>
				<FontAwesomeIcon icon={ faLayerGroup } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Tiers
			</Link>
			<Link to="/main/operations/plans" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations/plans' ? '#2654a3' : '#ccc' }}>
				<FontAwesomeIcon icon={ faBook } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Plans
			</Link>
			<Link to="/main/operations/rates" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations/rates' ? '#2654a3' : '#ccc' }}>
				<FontAwesomeIcon icon={ faCalculator } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Rates
			</Link>
			{/* <Link to="/main/operations/fees" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations/fees' ? '#2654a3' : '#ccc' }}>
        <FontAwesomeIcon icon={ faMoneyBillAlt } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Fees
      </Link> */}
			<Link to="/main/operations/files" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations/files' ? '#2654a3' : '#ccc' }}>
				<FontAwesomeIcon icon={ faFileUpload } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Files
			</Link>
			<Link to="/main/operations/procedures" className="sidebar-menu-item" style={{ color: history.location.pathname === '/main/operations/procedures' ? '#2654a3' : '#ccc' }}>
				<FontAwesomeIcon icon={ faFileUpload } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Procedures
			</Link>
		</div>
	);
};

export default OperationsMenu;
