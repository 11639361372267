import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faHandshake, faFunnelDollar, faArchive } from '@fortawesome/free-solid-svg-icons';
import history from '../../../history';

const SalesMenu = () => {
	return (
		<div className="sidebar-menu-container">
			<Link
				to="/main/sales"
				className="sidebar-menu-item"
				style={{ color: history.location.pathname === '/main/sales' ? '#2654a3' : '#ccc' }}
			>
				<FontAwesomeIcon icon={ faFunnelDollar } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Leads
			</Link>
			<Link
				to="/main/sales/rfp"
				className="sidebar-menu-item"
				style={{ color: history.location.pathname === '/main/sales/rfp' ? '#2654a3' : '#ccc' }}
			>
				<FontAwesomeIcon icon={ faChalkboardTeacher } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        RFP List
			</Link>
			<Link
				to="/main/sales/prospects"
				className="sidebar-menu-item"
				style={{ color: history.location.pathname === '/main/sales/prospects' ? '#2654a3' : '#ccc' }}
			>
				<FontAwesomeIcon icon={ faHandshake } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Prospects
			</Link>
			<Link
				to="/main/sales/archived"
				className="sidebar-menu-item"
				style={{ color: history.location.pathname === '/main/sales/archived' ? '#2654a3' : '#ccc' }}
			>
				<FontAwesomeIcon icon={ faArchive } style={{ fontSize: '2.5rem', marginBottom: '10px' }} />
        Archived
			</Link>
		</div>
	);
};

export default SalesMenu;
