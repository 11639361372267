import React, { useContext, useRef, useState } from 'react';
import { Confirm, Divider, Form, Header, Ref, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';

const SessionInfoTab = () => {
	// context
	const { getCustomer, customer, unlockRfpSubmission } = useContext( CustomersContext );

	// state
	const [ unlockConfirm, setUnlockConfirm ] = useState( false );

	const primaryContact = customer.contacts.find( customer => customer.primary );

	const emailBody = `<div>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>${ primaryContact ? primaryContact.firstName : '' } ${ primaryContact ? primaryContact.lastName : '' },</p>
		<br/>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>I am excited to prepare a proposal and comparative analysis for your organization that will outline all WorkSmart services and fees. Please follow the steps below.</p>
		<br/>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>1. Complete the Request for Proposal. You can access this form here:<br><a href="${ customer.rfp.url }">${ customer.rfp.url }</a></p>
		<br/>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>2. I will send you a separate email from the HERO risk analysis system including:</p>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;text-indent:.5in;'>A link to complete the Group Health Questionnaire (GHQ). Please complete with as much detail as possible without asking questions of employees.</p>
		<ul style="margin-bottom:0in;" type="disc">
				<ul style="margin-bottom:0in;" type="circle">
						<li style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Attached to the GHQ, you will find a census form to complete. Please complete with every benefit-eligible employee (30 hours per week or greater). Please note the following regarding certain columns of the requested information.</li>
						<li style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Coverage Tier refers to Employee, Employee + Spouse, Employee + Child(ren), Family, or Waiver of Coverage (there is a drop-down list in the column).</li>
						<li style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Height and Weight - Only answer to the best of your ability if you have this information available</li>
						<li style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Tobacco Use - Only answer to the best of your ability if you have this information available.</li>
						<li style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Please include participants who have been extended coverage under COBRA.&nbsp;</li>
				</ul>
		</ul>
		<br/>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>After we receive this information, please allow approximately one week for the generation of a complete proposal.</p>
		<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>Best,</p>
	</div>`;

	// ref
	let urlRef = useRef( null );

	return (
		<Tab.Pane>
			<Header>Session Info</Header>
			<Divider />
			<Form>
				<Form.Input
					value={ customer.rfp.rfpKey }
					label="Session Key"
					transparent
					readOnly
					width={ 16 }
				/>
				<Form.Group>
					<Form.Field width={ 16 }>
						<label>
              RFP URL&nbsp;&nbsp;
							<span
								style={{ cursor: 'pointer' }}
								onClick={ () => {
									urlRef.current.childNodes[ 0 ].childNodes[ 0 ].select();
									document.execCommand( 'copy' );
								}}
							>
                [&nbsp;copy&nbsp;]
							</span>
              &nbsp;&nbsp;
							<a
								href={ `mailto:${ primaryContact.email }?subject=WorkSmart%20Next%20Steps` }
								onClick={ () => {
									function listener( e ) {
										e.preventDefault();
										e.clipboardData.setData( 'text/html', emailBody );
										e.clipboardData.setData( 'text/plain', emailBody );
									}

									document.addEventListener( 'copy', listener );
									document.execCommand( 'copy' );
									document.removeEventListener( 'copy', listener );
								} }
							>[ &nbsp;email&nbsp;link&nbsp; ]</a>
						</label>
						<Ref innerRef={ urlRef }>
							<Form.Input
								value={ customer.rfp.url }
								transparent
								readOnly
							/>
						</Ref>
					</Form.Field>
				</Form.Group>
				<Divider hidden />
				<Form.Group>
					<Form.Input
						value={ new Date( customer.rfp.createdOn ).toLocaleDateString() }
						label="Session Created On"
						transparent
						readOnly
						width={ 4 }
					/>
					<Form.Input
						value={ customer.rfp.createdBy }
						label="Created By"
						transparent
						readOnly
						width={ 4 }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						value={ customer.rfp.submittedOn ? new Date( customer.rfp.submittedOn ).toLocaleDateString() : 'N/A' }
						label="Submitted On"
						transparent
						readOnly
						width={ 4 }
					/>
					{ customer.customerStage.id === 2
						? <Form.Field width={ 4 }>
							<label>
                  Submission Status
                  &nbsp;&nbsp;
								{ customer.customerStatus.id === 15 || customer.customerStatus.id === 21
									? <span
										style={{ cursor: 'pointer' }}
										onClick={ () => setUnlockConfirm( true ) }
									>
                        [&nbsp;unlock&nbsp;]
									</span>
									: null
								}
							</label>
							<Form.Input
								value={ customer.customerStatus.id === 75 ? 'Submission Unlocked' : customer.customerStatus.id === 14 ? 'Pending Submission' : customer.customerStatus.id === 15 || customer.customerStatus.id === 21 ? 'Submission Recieved' : null }
								transparent
								readOnly
							/>
						</Form.Field>
						: null
					}
				</Form.Group>
				<Form.Group>
					<Form.Input
						value={ new Date( customer.rfp.expiration ).toLocaleDateString() }
						label="Expiration Date"
						transparent
						readOnly
						width={ 4 }
					/>
					<Form.Input
						value={ new Date().toJSON() > customer.rfp.expiration ? 'Yes' : 'No' }
						label="Expired"
						transparent
						readOnly
						width={ 4 }
					/>
				</Form.Group>
			</Form>
			<Confirm
				className="unlock-confirm-modal"
				open={ unlockConfirm }
				size="tiny"
				header={ 'Unlock RFP?' }
				content={
					<div className="content" style={{ fontSize: '16px' }}>
            Would you like to unlock the RFP portal for this customer?
					</div>
				}
				cancelButton="Cancel"
				confirmButton="Unlock"
				onCancel={ () => setUnlockConfirm( false ) }
				onConfirm={ async () => {
					await unlockRfpSubmission();
					await getCustomer( customer.id );
					setUnlockConfirm( false );
				}}
			/>
		</Tab.Pane>
	);
};

export default SessionInfoTab;
