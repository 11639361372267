import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { validateZip, stateOptions, integerKeyDown } from '../../../Services/inputMasks';

const LocationsModal = props => {

  // context
  const { postLocation } = useContext( CustomersContext );

  // form handler
  const { formData, updateField, setData } = useForm();

  // state
  const [ validator, setValidator ] = useState({
    locationLabel: 'string',
    locationAddress: 'string',
    locationCity: 'string',
    locationState: 'XX',
    locationZip: '12345',
  });

  // props
  const { location, setLocation, locationsModal, setLocationsModal } = props;

  // set formData to customer data
  useEffect( () => {
    if ( location ) {
      const locationData = {
        locationLabel: location.label,
        locationAddress: location.address,
        locationAddress2: location.address2,
        locationCity: location.city,
        locationState: location.state,
        locationZip: location.zip,
        locationPrimary: location.primary
      };
      setData( locationData );
    }
    // on unmount
    return () => {
      setData( {} );
    };
  }, [ location, setData ] );

  // prepare data for postLocations api call (update)
  const handleUpdate = () => {
    postLocation({
      ...location,
      label: formData.locationLabel,
      address: formData.locationAddress,
      address2: formData.locationAddress2,
      city: formData.locationCity,
      state: formData.locationState,
      zip: formData.locationZip,
      primary: formData.locationPrimary
    });
    setLocationsModal( false );
  };

  // prepare data for postLocations api call (create)
  const handleCreateNew = () => {
    postLocation({
      id: 0,
      label: formData.locationLabel,
      address: formData.locationAddress,
      address2: formData.locationAddress2,
      city: formData.locationCity,
      state: formData.locationState,
      zip: formData.locationZip,
      primary: formData.locationPrimary
    });
    setLocationsModal( false );
  };

  // clear out location, formData, and validator when modal closes
  const clearData = () => {
    setLocation( '' );
    setData( {} );
    setValidator({
      locationLabel: 'string',
      locationAddress: 'string',
      locationCity: 'string',
      locationState: 'XX',
      locationZip: '12345',
    });
  };

  return (
    <Modal
      id="locations-modal"
      open={ locationsModal }
      onClose={ () => {
        setLocation( '' );
        setLocationsModal( false );
      }}
      closeOnDimmerClick={ false }
      size="tiny"
    >
      <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        { location ? 'Edit Location' : 'Add New Location' }
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Input
              required
              name="locationLabel"
              value={ formData.locationLabel || '' }
              label="Location Label"
              width={ 13 }
              maxLength={ 225 }
              onChange={ updateField }
              error={ validator.locationLabel.length === 0 }
              onBlur={ () => {
                setValidator( { ...validator, locationLabel: formData.locationLabel || '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, locationLabel: 'string' } );
              }}
            />
            <Form.Field width={ 3 } style={{ textAlign: 'center' }}>
              <label>Primary</label>
              <Form.Checkbox
                name="locationPrimary"
                inline={ false }
                style={{ marginTop: '1rem' }}
                disabled={ formData.locationPrimary }
                checked={ formData.locationPrimary || false }
                onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }); } }
              />
            </Form.Field>
          </Form.Group>
          <Message
            hidden={ validator.locationLabel.length > 0 }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ validator.locationLabel.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a label for this location.
              </Message.Item>
            </Message.List>
          </Message>
          <Form.Input
            required
            name="locationAddress"
            value={ formData.locationAddress || '' }
            label="Address Line 1"
            width={ 16 }
            maxLength={ 125 }
            onChange={ updateField }
            error={ validator.locationAddress.length === 0 }
            onBlur={ () => {
              setValidator( { ...validator, locationAddress: formData.locationAddress || '' } );
            }}
            onFocus={ () => {
              setValidator( { ...validator, locationAddress: 'string' } );
            }}
          />
          <Message
            negative
            hidden={ validator.locationAddress.length > 0 }
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List>
              <Message.Item
                hidden={ validator.locationAddress.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter an address.
              </Message.Item>
            </Message.List>
          </Message>
          <Form.Input
            name="locationAddress2"
            value={ formData.locationAddress2 || '' }
            label="Address Line 2"
            width={ 16 }
            maxLength={ 125 }
            onChange={ updateField }
          />
          <Form.Group>
            <Form.Input
              required
              name="locationCity"
              value={ formData.locationCity || '' }
              label="City"
              width={ 8 }
              maxLength={ 125 }
              onChange={ updateField }
              error={ validator.locationCity.length === 0 }
              onBlur={ () => {
                setValidator( { ...validator, locationCity: formData.locationCity || '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, locationCity: 'string' } );
              }}
            />
            <Form.Select
              required
              name="locationState"
              value={ formData.locationState || '' }
              label="State"
              options={ stateOptions }
              width={ 3 }
              fluid
              search
              onChange={ ( e, { name, value } ) => { updateField({ target: { name: name, value: value } }); } }
              error={ validator.locationState.length === 0 }
              onBlur={ () => {
                setValidator( { ...validator, locationState: formData.locationState || '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, locationState: 'XX' } );
              }}
            />
            <Form.Input
              required
              name="locationZip"
              value={ formData.locationZip || '' }
              label="Zip"
              width={ 6 }
              maxLength={ 5 }
              onChange={ updateField }
              error={ !validateZip( validator.locationZip ) }
              onBlur={ () => {
                setValidator( { ...validator, locationZip: formData.locationZip || '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, locationZip: '12345' } );
              }}
              onKeyDown={ integerKeyDown }
            />
          </Form.Group>
          <Message
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
            hidden={
              validator.locationCity.length > 0
              && validator.state > 0
              && validateZip( validator.locationZip )
            }
          >
          <Message.List>
            <Message.Item
              hidden={ validator.locationCity.length > 0 }
            >
              <Icon name="exclamation circle" />
              Please enter a city.
            </Message.Item>
            <Message.Item
              hidden={ validator.locationState.length > 0 }
            >
              <Icon name="exclamation circle" />
              Please select a state.
            </Message.Item>
            <Message.Item
              hidden={ validateZip( validator.locationZip ) }
            >
              <Icon name="exclamation circle" />
              Please enter a valid 5-digit zip code.
            </Message.Item>
          </Message.List>
        </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Save"
          onClick={ () => {
            location ? handleUpdate() : handleCreateNew();
            clearData();
            setLocationsModal( false );
          }}
          disabled={
            ( formData.locationLabel ? formData.locationLabel.length === 0 : true )
            || ( formData.locationAddress ? formData.locationAddress.length === 0 : true )
            || ( formData.locationCity ? formData.locationCity.length === 0 : true )
            || ( formData.locationState ? formData.locationState.length === 0 : true )
            || ( formData.locationZip ? !validateZip( formData.locationZip ) : true )
          }
        >
        </Button>
        <Button
          style={{ backgroundColor: '#F58229', color: '#fff' }}
          content="Cancel"
          onClick={ () => {
            clearData();
            setLocationsModal( false );
          }}
        >
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LocationsModal;
