import React, { useContext, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import Datepicker from '../Shared/Datepicker';
import { ReportsContext } from './ReportsContext';
import { useForm } from '../../Hooks/useForm';

const ClaimsSummaryReportModal = () => {
	// context
	const {
		clients,
		getClaimSummaryReport,
		claimsSummaryReportModal,
		hideReportModal
	} = useContext( ReportsContext );

	// form handler
	const { formData, updateField, setData } = useForm( {
		start: null,
		end: null,
		customerids: []
	} );

	// state
	const [ reportLoading, setReportLoading ] = useState( false );
	const [ validator, setValidator ] = useState( {
		start: new Date(),
		end: new Date(),
		customerids: [ '' ]
	} );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setReportLoading( false );
		setData( {
			start: null,
			end: null,
			customerids: []
		} );
		setValidator( {
			start: new Date(),
			end: new Date(),
			customerids: [ '' ]
		} );
	};

	return (
		<Modal
			open={ claimsSummaryReportModal }
			size="mini"
		>
			<Modal.Header>
        Claims Summary Report
			</Modal.Header>
			<Modal.Content>
				{ reportLoading
					? <p><Icon name="spinner" loading />Generating report, please wait...</p>
					: <Form>
						<Form.Group>
							<Datepicker
								label="Start Date"
								name="start"
								selected={ formData.start }
								onChange={ e => {
									updateField( { target: { name: 'start', value: e } } );
								} }
							/>
							<Datepicker
								label="End Date"
								name="end"
								selected={ formData.end }
								onChange={ e => {
									updateField( { target: { name: 'end', value: e } } );
								} }
							/>
							<Message
								hidden={ Boolean( validator.start ) && Boolean( validator.end ) }
								negative
								style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							>
								<Message.List style={{ listStyle: 'none' }}>
									<Message.Item
										hidden={ validator.start }
									>
										<Icon name="exclamation circle" />
								Please enter an start date.
									</Message.Item>
									<Message.Item
										hidden={ validator.end }
									>
										<Icon name="exclamation circle" />
								Please enter an end date.
									</Message.Item>
									<Message.Item
										hidden={ validator.start.getFullYear() - validator.end.getFullYear() * 12 + ( validator.end.getMonth - validator.start.getMonth() ) <= 36 }
									>
										<Icon name="exclamation circle" />
								Please enter an end date.
									</Message.Item>
								</Message.List>
							</Message>
						</Form.Group>
						<Form.Select
							name="customerids"
							label="Clients"
							width={ 16 }
							value={ formData.customerids }
							options={ clients }
							multiple
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
						<Message
							hidden={ Boolean( validator.customerids.length ) }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
                Please select one or more clients.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
				}
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Generate"
					onClick={ async () => {
						setReportLoading( true );
						const { url } = await getClaimSummaryReport( formData );

						window.open( url );
						clearData();
						hideReportModal();
					} }
					disabled={ !formData.start
            || !formData.end
            || formData.start.getFullYear() - formData.end.getFullYear() * 12 + ( formData.end.getMonth - formData.start.getMonth() ) > 36
            || !formData.customerids.length
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideReportModal( 1 );
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default ClaimsSummaryReportModal;
