import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';

const ClientRenewalModal = () => {
	// context
	const {
		clients,
		clientRenewalModal,
		hideClientRenewalModal,
		renewClients,
		resetRenewalProgress
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm( { customerIds: [] } );

	const [ url, setUrl ] = useState( '' );

	// prepare data for updateLead api call
	const handleRenewal = useCallback( async () => {
		const response = await renewClients( formData.customerIds );
		// const response = { url: 'https://kiq-ws-cms-dev-api.azurewebsites.net/extract/download/32490170_Renewal_Report_24_8_2023[…]RpTFJpclpiTGFDMzQzcDJGMVNmOG1qZGFjZnBXUGJUME0yRTg9' };

		setUrl( response.url );
	}, [ formData.customerIds, renewClients ] );

	const handleDownload = useCallback( () => {
		// const { url } = await renewClients( formData.customerIds );
		window.open( url );
		hideClientRenewalModal();
	}, [ hideClientRenewalModal, url ] );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( { customerIds: [] } );
		resetRenewalProgress();
	};

	return (
		<Modal
			open={ clientRenewalModal }
			size="mini"
		>
			<Modal.Header>
        Renew Clients
			</Modal.Header>
			<Modal.Content>
				{ url
					? <p>Client Renewal has been successfully completed.</p>
					: <Form>
						<Form.Select
							name="customerIds"
							value={ formData.customerIds }
							label="Omitted Clients"
							width={ 16 }
							options={ clients }
							multiple
							placeholder="none"
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					</Form>
				}
			</Modal.Content>
			<Modal.Actions>
				{ url
					? <Button
						primary
						content="Download Renewal"
						onClick={ handleDownload }
					/>
					: <>
						<Button
							primary
							content="Renew"
							onClick={ handleRenewal }
						>
						</Button>
						<Button
							style={{ backgroundColor: '#F58229', color: '#fff' }}
							content="Cancel"
							onClick={ () => {
								clearData();
								hideClientRenewalModal();
							}}
						>
						</Button>
					</>
				}
			</Modal.Actions>
		</Modal>
	);
};

export default ClientRenewalModal;
