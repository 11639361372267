import React, { useContext } from 'react';
import { Button, Divider, Form, Grid, Header, List, Tab, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import DocumentUploadModal from './DocumentUploadModal';
import { maskCurrency, integerKeyDown, floatKeyDown } from '../../../Services/inputMasks';
import Datepicker from '../../Shared/Datepicker';

const BenefitsTab = props => {
	// context
	const {
		documents,
		mapDocuments,
		showDocumentUploadModal,
		retirementPlans
	} = useContext( CustomersContext );

	// props
	const { formData, updateField } = props;

	const panes = [
		{
			menuItem: 'Medical',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 3 }>
								<label>Sponsors Medical Plan</label>
								<Form.Checkbox
									name="medicalSponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.medicalSponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.medicalSponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } } >
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="medicalPlanVendor"
										value={ formData.medicalPlanVendor || '' }
										label="Plan Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Plan Renewal Date"
										name="medicalRenewalDate"
										selected={ formData.medicalRenewalDate ? new Date( formData.medicalRenewalDate ) : '' }
										onChange={ e => {
											updateField( { target: { name: 'medicalRenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="medicalRenewalDate"
                    label="Plan Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.medicalRenewalDate  ? new Date( formData.medicalRenewalDate ) : '' }
                    onChange={ e => { updateField({ target: { name: "medicalRenewalDate", value: e } }); } }
                  /> */}
									<Form.Input
										type="number"
										name="plansOffered"
										value={ formData.plansOffered || '' }
										min={ 0 }
										label="# of Plans Offered"
										width={ 3 }
										onChange={ updateField }
										onKeyDown={ integerKeyDown }
									/>
								</Form.Group>
								<Form.Group>
									<Form.Input
										name="hsaVendor"
										value={ formData.hsaVendor || '' }
										label="Health Savings Account Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Form.Field
										style={{ display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
										width={ 6 }
									>
										<label style={{ flexBasis: '100%' }}>Types of Plans Offered</label>
										<Form.Checkbox
											name="poS_Offered"
											label="POS"
											style={{ marginTop: '0.5rem' }}
											checked={ formData.poS_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
										<Form.Checkbox
											name="hmO_Offered"
											label="HMO"
											inline
											style={{ marginTop: '0.5rem' }}
											checked={ formData.hmO_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
										<Form.Checkbox
											name="ppO_Offered"
											label="PPO"
											inline
											style={{ marginTop: '0.5rem' }}
											checked={ formData.ppO_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
										<Form.Checkbox
											name="hdhP_Offered"
											label="HDHP"
											inline
											style={{ marginTop: '0.5rem' }}
											checked={ formData.hdhP_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
										<Form.Checkbox
											name="hrA_Offered"
											label="HRA"
											inline
											style={{ marginTop: '0.5rem' }}
											checked={ formData.hrA_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Form.Field>
								</Form.Group>
								<Form.Group inline>
									<Form.Field width={ 3 }>
										<label>Includes Basic Life</label>
										<Form.Checkbox
											name="basicLife_Offered"
											style={{ marginTop: '0.5rem' }}
											checked={ formData.basicLife_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Form.Field>
									<Form.Field width={ 3 }>
										<label>Extends Cobra</label>
										<Form.Checkbox
											name="extendsCobra"
											style={{ marginTop: '0.5rem' }}
											checked={ formData.extendsCobra || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Form.Field>
									<Form.Field width={ 4 }>
										<label>Sponsors Section 125 Cafeteria Plan</label>
										<Form.Checkbox
											name="section_125_Offered"
											style={{ marginTop: '0.5rem' }}
											checked={ formData.section_125_Offered || false }
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Form.Field>
									<Form.Field width={ 4 }>
										<label>Includes Flexible Spending Account</label>
										<Form.Checkbox
											name="flexibleSpendingAccount"
											style={{ marginTop: '0.5rem' }}
											checked={ formData.flexibleSpendingAccount || false }
											// eslint-disable-next-line object-shorthand
											onChange={ ( e, { name, checked } ) => {
												updateField( { target: { name, value: checked } } );
											} }
										/>
									</Form.Field>
								</Form.Group>
							</Segment>
							<Grid style={{ margin: 0 }}>
								{ formData.basicLife_Offered
									? <Grid.Column
										width={ 6 }
										style={{ padding: '0 0.5rem 0 0' }}
									>
										<Segment style={{ margin: 0 }}>
											<Form.Group>
												<Form.Input
													name="basicLifeAmount"
													value={ formData.basicLifeAmount || '' }
													label="Basic Life Amount"
													width={ 16 }
													onChange={ updateField }
													onBlur={ e => {
														updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) } } );
													} }
													onKeyDown={ floatKeyDown }
												/>
											</Form.Group>
										</Segment>
									</Grid.Column>
									: null
								}
								{ formData.extendsCobra
									? <Grid.Column
										width={ 10 }
										style={formData.basicLife_Offered ? { padding: '0 0 0 0.5rem' } : { padding: '0 0 0 0' } }
									>
										<Segment style={{ margin: 0 }}>
											<Form.Group>
												<Form.Input
													name="cobraVendor"
													value={ formData.cobraVendor || '' }
													label="Cobra Vendor"
													width={ 8 }
													maxLength={ 255 }
													onChange={ updateField }
												/>
												<Form.Input
													type="number"
													name="cobraCount"
													value={ formData.cobraCount || '' }
													label="Number of Cobra Participants"
													width={ 8 }
													min={ 0 }
													onChange={ updateField }
													onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189 ) && e.preventDefault() }
												/>
											</Form.Group>
										</Segment>
									</Grid.Column>
									: null
								}
							</Grid>
						</Form>
						<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
						<Header as="h4">Medical Summary Plans</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.medical.filter( doc => doc.typeID === 38 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 38 ) }
									size="large"
								/>
							</List.Item>
						</List>
						<Header as="h4">Medical Insurance Invoices</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.medical.filter( doc => doc.typeID === 39 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 39 ) }
									size="large"
								/>
							</List.Item>
						</List>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Dental',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 4 }>
								<label>Sponsors Dental Plan</label>
								<Form.Checkbox
									name="dentalSponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.dentalSponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.dentalSponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } } >
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="dentalPlanVendor"
										value={ formData.dentalPlanVendor || '' }
										label="Plan Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Plan Renewal Date"
										name="dentalRenewalDate"
										selected={ formData.dentalRenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'dentalRenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="dentalRenewalDate"
                    label="Plan Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.dentalRenewalDate }
                    onChange={ e => { updateField({ target: { name: "dentalRenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
						</Form>
						<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
						<Header as="h4">Dental Summary Plans</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.dental.filter( doc => doc.typeID === 40 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 40 ) }
									size="large"
								/>
							</List.Item>
						</List>
						<Header as="h4">Dental Insurance Invoices</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.dental.filter( doc => doc.typeID === 41 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 41 ) }
									size="large"
								/>
							</List.Item>
						</List>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Vision',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 4 }>
								<label>Sponsors Vision Plan</label>
								<Form.Checkbox
									name="visionSponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.visionSponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.visionSponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="visionPlanVendor"
										value={ formData.visionPlanVendor || '' }
										label="Plan Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Plan Renewal Date"
										name="visionRenewalDate"
										selected={ formData.visionRenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'visionRenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="visionRenewalDate"
                    label="Plan Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.visionRenewalDate }
                    onChange={ e => { updateField({ target: { name: "visionRenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
						</Form>
						<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
						<Header as="h4">Vision Summary Plans</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.vision.filter( doc => doc.typeID === 46 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 46 ) }
									size="large"
								/>
							</List.Item>
						</List>
						<Header as="h4">Vision Insurance Invoices</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.vision.filter( doc => doc.typeID === 47 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 47 ) }
									size="large"
								/>
							</List.Item>
						</List>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Life',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 4 }>
								<label>Sponsors Life Plan</label>
								<Form.Checkbox
									name="lifeSponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.lifeSponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.lifeSponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="lifePlanVendor"
										value={ formData.lifePlanVendor || '' }
										label="Plan Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Plan Renewal Date"
										name="lifeRenewalDate"
										selected={ formData.lifeRenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'lifeRenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="lifeRenewalDate"
                    label="Plan Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.lifeRenewalDate }
                    onChange={ e => { updateField({ target: { name: "lifeRenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
						</Form>
						<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.life.filter( doc => doc.typeID === 42 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 42 ) }
									size="large"
								/>
							</List.Item>
						</List>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Disability',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 6 }>
								<label>Sponsors Short Term Disability Plan</label>
								<Form.Checkbox
									name="stD_SponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.stD_SponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.stD_SponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="stD_PlanVendor"
										value={ formData.stD_PlanVendor || '' }
										label="Short Term Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Short Term Renewal Date"
										name="stD_RenewalDate"
										selected={ formData.stD_RenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'stD_RenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="stD_RenewalDate"
                    label="Short Term Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.stD_RenewalDate }
                    onChange={ e => { updateField({ target: { name: "stD_RenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
							<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
							<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
								{ mapDocuments( documents.disability.filter( doc => doc.typeID === 43 ) ) }
								<List.Item>
									<Button
										basic
										circular
										icon="add"
										onClick={ () => showDocumentUploadModal( null, 43 ) }
										size="large"
									/>
								</List.Item>
							</List>
						</Form>
					</Segment>
					<Divider />
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 6 }>
								<label>Sponsors Long Term Disability Plan</label>
								<Form.Checkbox
									name="ltD_SponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.ltD_SponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.ltD_SponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="ltD_PlanVendor"
										value={ formData.ltD_PlanVendor || '' }
										label="Long Term Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Datepicker
										width={ 3 }
										label="Long Term Renewal Date"
										name="ltD_RenewalDate"
										selected={ formData.ltD_RenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'ltD_RenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="ltD_RenewalDate"
                    label="Long Term Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.ltD_RenewalDate }
                    onChange={ e => { updateField({ target: { name: "ltD_RenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
						</Form>
						<Header style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Documents</Header>
						<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
							{ mapDocuments( documents.disability.filter( doc => doc.typeID === 44 ) ) }
							<List.Item>
								<Button
									basic
									circular
									icon="add"
									onClick={ () => showDocumentUploadModal( null, 44 ) }
									size="large"
								/>
							</List.Item>
						</List>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Retirement',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 4 }>
								<label>Sponsors Retirement Plan</label>
								<Form.Checkbox
									name="retirementSponsorsPlan"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.retirementSponsorsPlan || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.retirementSponsorsPlan ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.Input
										name="retirementPlanVendor"
										value={ formData.retirementPlanVendor || '' }
										label="Plan Vendor"
										width={ 6 }
										maxLength={ 255 }
										onChange={ updateField }
									/>
									<Form.Select
										name="retirementPlanTypeID"
										label="Plan Type"
										width={ 5 }
										value={ parseInt( formData.retirementPlanTypeID ) >= 0 ? formData.retirementPlanTypeID : 0 }
										options={ [ { key: 0, text: 'Select an option', value: 0 }, ...retirementPlans ] }
										onChange={ ( e, { name, value } ) => {
											updateField( { target: { name, value } } );
										} }
									/>
									<Datepicker
										width={ 3 }
										label="Plan Renewal Date"
										name="retirementRenewalDate"
										selected={ formData.retirementRenewalDate }
										onChange={ e => {
											updateField( { target: { name: 'retirementRenewalDate', value: e } } );
										} }
									/>
									{/* <Form.Input
                    control={ DatePicker }
                    width={ 3 }
                    name="retirementRenewalDate"
                    label="Plan Renewal Date"
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    isClearable
                    selected={ formData.retirementRenewalDate }
                    onChange={ e => { updateField({ target: { name: "retirementRenewalDate", value: e } }); } }
                  /> */}
								</Form.Group>
							</Segment>
						</Form>
					</Segment>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Ancillary',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group inline style={{ margin: 0 }}>
							<Form.Field width={ 4 }>
								<label>Sponsors Ancillary Benefits</label>
								<Form.Checkbox
									name="hasBenefits"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.hasBenefits || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Segment basic style={ formData.hasBenefits ? { padding: 0 } : { display: 'none', padding: 0 } }>
						<Form>
							<Segment style={{ marginLeft: 0, marginRight: 0 }}>
								<Form.Group>
									<Form.TextArea
										name="ancillaryDescription"
										label="Description of Benefits"
										width={ 14 }
										rows={ 10 }
										value={ formData.ancillaryDescription || '' }
										onChange={ updateField }
									/>
								</Form.Group>
							</Segment>
						</Form>
					</Segment>
				</Tab.Pane>
			)
		}
	];

	return (
		<Tab.Pane className="benefits-tab">
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Header>Benefits</Header>
			</div>
			<Divider />
			<Tab
				panes={ panes }
			/>
			<DocumentUploadModal />
		</Tab.Pane>
	);
};

export default BenefitsTab;
