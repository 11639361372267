import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal, Tab } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { CustomersContext } from '../../CustomersContext';
import { useForm } from '../../../../Hooks/useForm';
import { maskPhone, unmaskPhone, validateZip, validatePhone, validateEmail, stateOptions, integerKeyDown } from '../../../../Services/inputMasks';

const LeadsModal = () => {
	// context
	const {
		years,
		customer,
		updateLead,
		createLead,
		promoteLead,
		leadsModal,
		hideLeadsModal,
		leadStatuses,
		salesAgents
	} = useContext( CustomersContext );

	// state
	const [ validator, setValidator ] = useState( {
		name: 'string',
		address: 'string',
		city: 'string',
		state: 'XX',
		zip: '12345',
		contactFirstName: 'string',
		contactLastName: 'string',
		contactTitle: 'string',
		contactEmail: 'string@string.com',
		contactPhone: '1111111111',
		contactZip: '12345',
		source: 1,
		salesAgent: 1,
		status: 1,
		operatingYear: new Date().getFullYear()
	} );

	// form handler
	const { formData, updateField, setData } = useForm();

	// set formData to customer data
	useEffect( () => {
		if ( customer ) {
			const leadData = {
				name: customer.name,
				address: customer.address,
				address2: customer.address2,
				city: customer.city,
				state: customer.state,
				zip: customer.zip,
				operatingYear: customer.operatingYear || new Date().getFullYear(),
				contactFirstName: customer.contacts[ 0 ].firstName ? customer.contacts[ 0 ].firstName : '',
				contactLastName: customer.contacts[ 0 ].lastName ? customer.contacts[ 0 ].lastName : '',
				contactTitle: customer.contacts[ 0 ].title ? customer.contacts[ 0 ].title : '',
				contactEmail: customer.contacts[ 0 ].email ? customer.contacts[ 0 ].email : '',
				contactPhone: customer.contacts[ 0 ].phone ? customer.contacts[ 0 ].phone : '',
				contactAddress: customer.contacts[ 0 ].address ? customer.contacts[ 0 ].address : '',
				contactAddress2: customer.contacts[ 0 ].address2 ? customer.contacts[ 0 ].address2 : '',
				contactCity: customer.contacts[ 0 ].city ? customer.contacts[ 0 ].city : '',
				contactState: customer.contacts[ 0 ].state ? customer.contacts[ 0 ].state : '',
				contactZip: customer.contacts[ 0 ].zip ? customer.contacts[ 0 ].zip : '',
				source: customer.source.id,
				salesAgent: customer.salesAgent.id,
				status: customer.customerStatus.id
			};

			setData( leadData );
			setValidator( leadData );
		}

		// on unmount
		return () => {
			setData( {} );
			setValidator( {
				name: 'string',
				address: 'string',
				city: 'string',
				state: 'XX',
				zip: '12345',
				contactFirstName: 'string',
				contactLastName: 'string',
				contactTitle: 'string',
				contactEmail: 'string@string.com',
				contactPhone: '1111111111',
				contactZip: '12345',
				source: 1,
				salesAgent: 1,
				status: 1
			} );
		};
	}, [ customer, setData ] );

	// prepare data for updateLead api call
	const handleUpdate = () => {
		updateLead( {
			...customer,
			id: customer.id,
			name: formData.name,
			address: formData.address,
			address2: formData.address2,
			city: formData.city,
			state: formData.state,
			zip: formData.zip,
			operatingYear: formData.operatingYear || new Date().getFullYear(),
			customerStatus: { id: formData.status },
			salesAgent: { id: formData.salesAgent },
			source: { id: formData.source },
			contacts: [
				{
					id: customer.contacts[ 0 ].id,
					firstName: formData.contactFirstName,
					lastName: formData.contactLastName,
					title: formData.contactTitle,
					email: formData.contactEmail,
					phone: formData.contactPhone,
					address: formData.contactAddress,
					address2: formData.contactAddress2,
					city: formData.contactCity,
					state: formData.contactState,
					zip: formData.contactZip,
					primary: true
				}
			]
		} );
	};

	// prepare data for updateLead api call
	const handleCreateNew = () => {
		createLead( {
			id: 0,
			name: formData.name,
			address: formData.address,
			address2: formData.address2 || '',
			city: formData.city,
			state: formData.state,
			zip: formData.zip,
			operatingYear: formData.operatingYear || new Date().getFullYear(),
			customerStatus: { id: formData.status },
			salesAgent: { id: formData.salesAgent },
			source: { id: 9 },
			profile: null,
			contacts: [
				{
					id: 0,
					firstName: formData.contactFirstName,
					lastName: formData.contactLastName,
					title: formData.contactTitle,
					email: formData.contactEmail,
					phone: formData.contactPhone,
					address: formData.contactAddress,
					address2: formData.contactAddress2,
					city: formData.contactCity,
					state: formData.contactState,
					zip: formData.contactZip,
					primary: true
				}
			],
			locations: [],
			rfp: null
		} );
	};

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {
			name: 'string',
			address: 'string',
			city: 'string',
			state: 'XX',
			zip: '12345',
			contactFirstName: 'string',
			contactLastName: 'string',
			contactTitle: 'string',
			contactEmail: 'string@string.com',
			contactPhone: '1111111111',
			contactZip: '12345',
			source: 1,
			salesAgent: 1,
			status: 1
		} );
	};

	// define tab panes
	const panes = [
		{
			menuItem: 'Company Info',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form autoComplete="off">
						<Form.Input
							required
							name="name"
							value={ formData.name || '' }
							label="Company Name"
							width={ 16 }
							maxLength={ 255 }
							onChange={ updateField }
							error={ validator.name.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, name: formData.name || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, name: 'string' } );
							}}
						/>
						<Message
							hidden={ validator.name.length > 0 }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.name.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter the company&apos;s name.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Input
							required
							name="address"
							value={ formData.address || '' }
							label="Address Line 1"
							width={ 16 }
							maxLength={ 125 }
							onChange={ updateField }
							error={ validator.address.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, address: formData.address || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, address: 'string' } );
							}}
						/>
						<Message
							negative
							hidden={ validator.address.length > 0 }
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List>
								<Message.Item
									hidden={ validator.address.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter an address.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Input
							name="address2"
							value={ formData.address2 || '' }
							label="Address Line 2"
							width={ 16 }
							maxLength={ 125 }
							onChange={ updateField }
						/>
						<Form.Group>
							<Form.Input
								required
								name="city"
								value={ formData.city || '' }
								label="City"
								width={ 8 }
								maxLength={ 125 }
								onChange={ updateField }
								error={ validator.city.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, city: formData.city || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, city: 'string' } );
								}}
							/>
							<Form.Select
								required
								name="state"
								value={ formData.state }
								label="State"
								options={ stateOptions }
								width={ 3 }
								fluid
								search
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value } } );
								} }
								error={ validator.state.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, state: formData.state || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, state: 'XX' } );
								}}
							/>
							<Form.Input
								required
								name="zip"
								value={ formData.zip || '' }
								label="Zip"
								width={ 6 }
								maxLength={ 5 }
								onChange={ updateField }
								error={ !validateZip( validator.zip ) }
								onBlur={ () => {
									setValidator( { ...validator, zip: formData.zip || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, zip: '12345' } );
								}}
								onKeyDown={ integerKeyDown }
							/>
						</Form.Group>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={
								validator.city.length > 0
                && validator.state.length > 0
                && validateZip( validator.zip )
							}
						>
							<Message.List>
								<Message.Item
									hidden={ validator.city.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a city.
								</Message.Item>
								<Message.Item
									hidden={ validator.state.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please select a state.
								</Message.Item>
								<Message.Item
									hidden={ validateZip( validator.zip ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid, 5-digit zip code.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Primary Contact Info',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group>
							<Form.Input
								required
								name="contactFirstName"
								value={ formData.contactFirstName || '' }
								label="First Name"
								width={ 8 }
								maxLength={ 125 }
								onChange={ updateField }
								error={ validator.contactFirstName.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, contactFirstName: formData.contactFirstName || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, contactFirstName: 'string' } );
								}}
							/>
							<Form.Input
								required
								name="contactLastName"
								value={ formData.contactLastName || '' }
								label="Last Name"
								width={ 8 }
								maxLength={ 125 }
								onChange={ updateField }
								error={ validator.contactLastName.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, contactLastName: formData.contactLastName || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, contactLastName: 'string' } );
								}}
							/>
						</Form.Group>
						<Message
							hidden={
								validator.contactFirstName.length > 0
                && validator.contactLastName.length > 0
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.contactFirstName.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a first name.
								</Message.Item>
								<Message.Item
									hidden={ validator.contactLastName.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a last name.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Input
							name="contactTitle"
							value={ formData.contactTitle || '' }
							label="Title"
							width={ 16 }
							maxLength={ 50 }
							onChange={ updateField }
						/>
						<Form.Group>
							<Form.Input
								name="contactEmail"
								value={ formData.contactEmail || '' }
								icon
								label="Email Address"
								width={ 10 }
								maxLength={ 255 }
								onChange={ updateField }
								error={ validator.contactEmail.length > 0 && !validateEmail( validator.contactEmail ) }
								onBlur={ () => {
									setValidator( { ...validator, contactEmail: formData.contactEmail || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, contactEmail: 'string@string.com' } );
								}}
							>
								<input />
								{ customer && formData.contactEmail
									? <Icon
										name="mail"
										link={ true }
										onClick={ () => {
											window.location = `mailto:${ formData.contactEmail }`;
										}}
									/>
									: null
								}
							</Form.Input>
							<Form.Input
								name="contactPhone"
								value={ maskPhone( formData.contactPhone ) || '' }
								label="Phone Number"
								width={ 6 }
								maxLength={ 14 }
								onChange={ ( e, { name, value } ) => updateField( { target: { name, value: unmaskPhone( value ) } } ) }
								error={ validator.contactPhone.length > 0 && !validatePhone( validator.contactPhone ) }
								onBlur={ () => {
									setValidator( { ...validator, contactPhone: unmaskPhone( formData.contactPhone ) || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, contactPhone: '1111111111' } );
								}}
								onKeyDown={ integerKeyDown }
							/>
						</Form.Group>
						<Message
							hidden={
								( validator.contactEmail.length === 0 || validateEmail( validator.contactEmail ) )
                && ( validator.contactPhone.length === 0 || validatePhone( validator.contactPhone ) )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.contactEmail.length === 0 || validateEmail( validator.contactEmail ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid email address.
								</Message.Item>
								<Message.Item
									hidden={ !validator.contactPhone === 0 || validatePhone( validator.contactPhone ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid phone number.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Input
							name="contactAddress"
							value={ formData.contactAddress || '' }
							label="Address Line 1"
							width={ 16 }
							maxLength={ 125 }
							onChange={ updateField }
						/>
						<Form.Input
							name="contactAddress2"
							value={ formData.contactAddress2 || '' }
							label="Address Line 2"
							width={ 16 }
							maxLength={ 125 }
							onChange={ updateField }
						/>
						<Form.Group>
							<Form.Input
								name="contactCity"
								value={ formData.contactCity || '' }
								label="City"
								width={ 8 }
								maxLength={ 125 }
								onChange={ updateField }
							/>
							<Form.Select
								name="contactState"
								value={ formData.contactState || '' }
								label="State"
								options={ stateOptions }
								width={ 3 }
								fluid
								search
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value } } );
								} }
							/>
							<Form.Input
								name="contactZip"
								value={ formData.contactZip || '' }
								label="Zip"
								width={ 6 }
								maxLength={ 5 }
								onChange={ updateField }
								error={ validator.contactZip.length > 0 && !validateZip( validator.contactZip ) }
								onBlur={ () => {
									setValidator( { ...validator, contactZip: formData.contactZip || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, contactZip: '12345' } );
								}}
								onKeyDown={ integerKeyDown }
							/>
						</Form.Group>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={ validator.contactZip.length === 0 || validateZip( validator.contactZip ) }
						>
							<Message.List>
								<Message.Item
									hidden={ validator.contactZip.length === 0 || validateZip( validator.contactZip ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid 5-digit zip code.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Lead Info',
			// eslint-disable-next-line react/display-name
			render: () => (
				<Tab.Pane>
					<Form>
						{ !customer
							? null
							: <Form.Input
								label="Source"
								readOnly
								value={ customer.source.description }
								width={ 16 }
							/>
						}
						<Form.Select
							required
							name="salesAgent"
							label="Sales Agent"
							width={ 16 }
							value={ formData.salesAgent || 0 }
							options={ [ { key: 0, text: 'Select an option', value: 0 }, ...salesAgents ] }
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							}}
							error={ validator.salesAgent === 0 }
							onBlur={ () => {
								setValidator( { ...validator, salesAgent: formData.salesAgent || 0 } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, salesAgent: 1 } );
							}}
						/>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={
								validator.salesAgent > 0
							}
						>
							<Message.List>
								<Message.Item>
									<Icon name="exclamation circle" />
                  Please select an option for Sales Agent.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Select
							required
							name="status"
							label="Status"
							width={ 16 }
							value={ formData.status || 0 }
							options={ [ { key: 0, text: 'Select an option', value: 0 }, ...leadStatuses ] }
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							}}
							error={ validator.status === 0 }
							onBlur={ () => {
								setValidator( { ...validator, status: formData.status || 0 } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, status: 1 } );
							}}
						/>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={
								validator.status > 0
							}
						>
							<Message.List>
								<Message.Item>
									<Icon name="exclamation circle" />
                  Please select an option for Status.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Select
							required
							name="operatingYear"
							label="Operating Year"
							width={ 16 }
							value={ formData.operatingYear || new Date().getFullYear() }
							options={ years }
							onChange={ ( e, { name, value } ) => updateField( { target: { name, value } } ) }
						/>
					</Form>
				</Tab.Pane>
			)
		}
	];

	return (
		<Modal
			id="leads-modal"
			open={ leadsModal }
			onClose={ hideLeadsModal }
			closeOnDimmerClick={ false }
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Lead Profile
				{ customer
					? <Button
						basic
						secondary
						animated="fade"
						style={{ width: '130px' }}
						onClick={ () => {
							handleUpdate();
							promoteLead( customer.id );
							hideLeadsModal();
						}}
					>
						<Button.Content visible>
							<FontAwesomeIcon icon={ faRunning } style={{ fontSize: '1.25em' }} />
						</Button.Content>
						<Button.Content hidden> Promote to RFP </Button.Content>
					</Button>
					: null
				}
			</Modal.Header>
			<Modal.Content style={{ paddingTop: 0 }}>
				<div style={{ backgroundColor: '#27BEBD88', height: '2rem', borderTop: '1px solid black', margin: '0 -1.5rem 2rem', padding: '0 14px', lineHeight: '2rem' }}>
					{ customer
						? <React.Fragment>
							<span style={{ marginRight: '3rem' }}><strong>Company:</strong> { customer.name }</span>
							<span style={{ marginRight: '3rem' }}><strong>Assignment Date:</strong> { new Date( customer.customerStage.date ).toLocaleDateString() }</span>
							<span style={{ marginRight: '3rem' }}><strong>Operating Year:</strong> { customer.operatingYear }</span>
						</React.Fragment>
						: null
					}
				</div>
				<Tab
					panes={ panes }
					menu={{ vertical: true }}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						customer ? handleUpdate() : handleCreateNew();
						clearData();
						hideLeadsModal();
					}}
					disabled={
						( formData.name ? formData.name.length === 0 : true )
            || ( formData.address ? formData.address.length === 0 : true )
            || ( formData.city ? formData.city.length === 0 : true )
            || ( formData.state ? formData.state.length === 0 : true )
            || ( formData.zip ? !validateZip( formData.zip ) : true )
            || ( formData.contactFirstName ? formData.contactFirstName.length === 0 : true )
            || ( formData.contactLastName ? formData.contactLastName.length === 0 : true )
            || ( formData.contactEmail ? formData.contactEmail.length > 0 && !validateEmail( formData.contactEmail ) : false )
            || ( formData.contactPhone ? formData.contactPhone.length > 0 && !validatePhone( formData.contactPhone ) : false )
            || ( formData.contactZip ? formData.contactZip.length > 0 && !validateZip( formData.contactZip ) : false )
            // || customer ? !formData.source : false
            || !formData.salesAgent
            || !formData.status
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Close"
					onClick={ () => {
						clearData();
						hideLeadsModal();
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default LeadsModal;
