import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';
import { maskCurrency, unmaskCurrency, floatKeyDown } from '../../../Services/inputMasks';

const PlansModal = () => {
	// context
	const {
		operation,
		createPlan,
		updatePlan,
		plansModal,
		hidePlansModal,
		planType,
		year
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ validator, setValidator ] = useState( {
		name: 'string',
		vendor: 'string',
		code: 'string',
		description: 'string',
		eE_Amount: '0',
		eS_Amount: '0',
		eC_Amount: '0',
		eF_Amount: '0'
	} );

	const fixedTimeZoneOffset = date => {
		if ( !date ) {
			return null;
		}

		return new Date( date.getTime() - date.getTimezoneOffset() * 60000 ).toJSON();
	};

	// Set formData to operation data
	useEffect( () => {
		if ( operation ) {
			const planData = {
				name: operation.name,
				vendor: operation.vendor,
				effectiveDate: new Date( operation.effectiveDate ),
				code: operation.code,
				hdhp: operation.modelType.id,
				description: operation.description,
				eE_Amount: maskCurrency( operation.eE_Amount ),
				eS_Amount: maskCurrency( operation.eS_Amount ),
				eC_Amount: maskCurrency( operation.eC_Amount ),
				eF_Amount: maskCurrency( operation.eF_Amount )
			};

			setData( planData );
		}

		return () => {
			setData( {} );
		};
	}, [ operation, setData ] );

	// prepare data for updateLead api call
	const handleUpdate = () => {
		updatePlan( {
			...operation,
			name: formData.name,
			vendor: formData.vendor,
			effectiveDate: fixedTimeZoneOffset( formData.effectiveDate ),
			code: formData.code,
			modelType: { id: formData.hdhp },
			description: formData.description,
			eE_Amount: unmaskCurrency( formData.eE_Amount ),
			eS_Amount: unmaskCurrency( formData.eS_Amount ),
			eC_Amount: unmaskCurrency( formData.eC_Amount ),
			eF_Amount: unmaskCurrency( formData.eF_Amount )
		} );
	};

	// prepare data for updateLead api call
	const handleCreateNew = () => {
		createPlan( {
			type: { id: planType },
			year,
			name: formData.name,
			vendor: formData.vendor,
			effectiveDate: fixedTimeZoneOffset( formData.effectiveDate ),
			code: formData.code,
			modelType: { id: formData.hdhp },
			description: formData.description,
			eE_Amount: unmaskCurrency( formData.eE_Amount ),
			eS_Amount: unmaskCurrency( formData.eS_Amount ),
			eC_Amount: unmaskCurrency( formData.eC_Amount ),
			eF_Amount: unmaskCurrency( formData.eF_Amount )
		} );
	};

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {
			name: 'string',
			vendor: 'string',
			code: 'string',
			description: 'string',
			eE_Amount: '0',
			eS_Amount: '0',
			eC_Amount: '0',
			eF_Amount: '0'
		} );
	};

	return (
		<Modal
			open={ plansModal }
			size="mini"
		>
			<Modal.Header>
        Medical Plan
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Input
						required
						name="name"
						label="Plan Name"
						value={ formData.name || '' }
						maxLength={ 125 }
						onChange={ updateField }
						error={ validator.name.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, name: formData.name || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, name: 'string' } );
						}}
					/>
					<Message
						hidden={ validator.name.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.name.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a plan name.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						required
						name="vendor"
						label="Plan Vendor"
						value={ formData.vendor || '' }
						maxLength={ 125 }
						onChange={ updateField }
						error={ validator.vendor.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, vendor: formData.vendor || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, vendor: 'string' } );
						}}
					/>
					<Message
						hidden={ validator.vendor.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.vendor.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a plan vendor.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Input
							required
							control={ DatePicker }
							name="effectiveDate"
							label="Effective Date"
							customInput={
								<MaskedInput
									mask={[ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/ ]}
									keepCharPositions= {true}
									guide = {true}
								/>
							}
							isClearable
							selected={ formData.effectiveDate ? new Date( formData.effectiveDate ) : '' }
							onChange={ e => {
								updateField( { target: { name: 'effectiveDate', value: e } } );
							} }
						/>
						<Form.Input
							required
							name="code"
							label="Code"
							width={ 10 }
							value={ formData.code || '' }
							maxLength={ 25 }
							onChange={ updateField }
							error={ validator.code.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, code: formData.code || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, code: 'string' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={ validator.code.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.code.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a code.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Field>
						<label>HDHP</label>
						<Form.Checkbox
							name="hdhp"
							style={{ marginTop: '0.5rem' }}
							checked={ formData.hdhp === 80 }
							onChange={ ( e, { name } ) => updateField( { target: { name, value: formData.hdhp !== 80 ? 80 : 79 } } ) }
						/>
					</Form.Field>
					<Form.TextArea
						required
						name="description"
						label="Description"
						value={ formData.description || '' }
						maxLength={ 255 }
						onChange={ updateField }
						error={ validator.description.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, description: formData.description || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, description: 'string' } );
						}}
					/>
					<Message
						hidden={ validator.description.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.description.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a description.
							</Message.Item>
						</Message.List>
					</Message>
					<Header>Premiums</Header>
					<Divider />
					<Form.Input
						required
						name="eE_Amount"
						label="EE:"
						inline
						value={ formData.eE_Amount || '' }
						onChange={ updateField }
						error={ validator.eE_Amount.length === 0 }
						onBlur={ e => {
							updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
							setValidator( { ...validator, eE_Amount: formData.eE_Amount || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, eE_Amount: '0' } );
						}}
						onKeyDown={ floatKeyDown }
					/>
					<Message
						hidden={ validator.eE_Amount.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a value for EE Premium.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						required
						name="eS_Amount"
						label="ES:"
						inline
						value={ formData.eS_Amount || '' }
						onChange={ updateField }
						error={ validator.eS_Amount.length === 0 }
						onBlur={ e => {
							updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
							setValidator( { ...validator, eS_Amount: formData.eS_Amount || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, eS_Amount: '0' } );
						}}
						onKeyDown={ floatKeyDown }
					/>
					<Message
						hidden={ validator.eS_Amount.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a value for ES Premium.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						required
						name="eC_Amount"
						label="EC:"
						inline
						value={ formData.eC_Amount || '' }
						onChange={ updateField }
						error={ validator.eC_Amount.length === 0 }
						onBlur={ e => {
							updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
							setValidator( { ...validator, eC_Amount: formData.eC_Amount || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, eC_Amount: '0' } );
						}}
						onKeyDown={ floatKeyDown }
					/>
					<Message
						hidden={ validator.eC_Amount.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a value for EC Premium.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						required
						name="eF_Amount"
						label="EF:"
						inline
						value={ formData.eF_Amount || '' }
						onChange={ updateField }
						error={ validator.eF_Amount.length === 0 }
						onBlur={ e => {
							updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
							setValidator( { ...validator, eF_Amount: formData.eF_Amount || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, eF_Amount: '0' } );
						}}
						onKeyDown={ floatKeyDown }
					/>
					<Message
						hidden={ validator.eF_Amount.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a value for EF Premium.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ operation
						? () => {
							handleUpdate();
							clearData();
							hidePlansModal();
						}
						: () => {
							handleCreateNew();
							clearData();
							hidePlansModal();
						}
					}
					disabled={
						( formData.name ? formData.name.length === 0 : true )
            || ( formData.vendor ? formData.vendor.length === 0 : true )
            || ( formData.code ? formData.code.length === 0 : true )
            || ( formData.description ? formData.description.length === 0 : true )
            || ( formData.eE_Amount ? formData.eE_Amount.length === 0 : true )
            || ( formData.eS_Amount ? formData.eS_Amount.length === 0 : true )
            || ( formData.eC_Amount ? formData.eC_Amount.length === 0 : true )
            || ( formData.eF_Amount ? formData.eF_Amount.length === 0 : true )
					}
				/>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hidePlansModal();
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default PlansModal;
