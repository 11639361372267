import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { OperationsContext } from '../OperationsContext';
import PlansFilter from './PlansFilter';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import PlansModal from './PlansModal';
import { maskCurrency } from '../../../Services/inputMasks';

const Plans = () => {
	// context
	const {
		plans,
		planType,
		year,
		getPlans,
		deletePlan,
		showPlansModal,
		deleteConfirmModal,
		toggleDeleteConfirmModal,
		tableLoader
	} = useContext( OperationsContext );

	// state
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ planId, setPlanId ] = useState( '' );

	useEffect( () => {
		getPlans();
	}, [ getPlans, planType, year ] );

	const columns = [
		{
			id: 'name',
			Header: <div>Name</div>,
			accessor: row => `${ row.name }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.name.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{row.original.name }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'code',
			Header: <div>Code</div>,
			accessor: row => `${ row.code }`,
			filterMethod: ( filter, row ) => _.includes( row._original.code.toUpperCase(), filter.value.toUpperCase() ),
			filterable: true,
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{ row.original.code }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'details',
			Header: <div>Details</div>,
			accessor: row => `${ row }`,
			filterable: false,
			sortable: false,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>
							<div>EE: { maskCurrency( row.original.eE_Amount ) }</div>
							<div>ES: { maskCurrency( row.original.eS_Amount ) }</div>
							<div>EC: { maskCurrency( row.original.eC_Amount ) }</div>
							<div>EF: { maskCurrency( row.original.eF_Amount ) }</div>
						</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			accessor: 'id',
			Header: 'Edit / Remove',
			sortable: false,
			filterable: false,
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						planid={ row.original.id }
						onClick={ ( e, { planid } ) => showPlansModal( planid ) }
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.name );
							setPlanId( row.original.id );
							toggleDeleteConfirmModal();
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<div>
			<Header as="h2" style={{ fontWeight: 'normal' }}>Plan Administration</Header>
			<Segment>
				<PlansFilter />
				<Table
					data={ plans }
					columns={ columns }
					loading={ tableLoader }
				/>
				<br />
				<p>Records: { plans.length }</p>
			</Segment>
			<PlansModal />
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Plan"
				open={ deleteConfirmModal }
				closeModal={ () => toggleDeleteConfirmModal() }
				onClick={ () => deletePlan( planId ) }
			/>
		</div>
	);
};

export default Plans;
