import React from 'react';
import { Divider, Form, Header, Segment } from 'semantic-ui-react';

const PlanInfoMedical = ( { info, updateInfo } ) => {
	return (
		<Form>
			<Form.Group>
				<Form.Input
					name="field_A1"
					value={ info.field_A1 || '' }
					label="Health Savings Account"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Prescription Drug Copay (30-Day Supply)</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_B1"
						width={ 8 }
						value={ info.field_B1 || '' }
						label="Description"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B2"
						value={ info.field_B2 || '' }
						label="Generic"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_B3"
						value={ info.field_B3 || '' }
						label="Brand Preferred List"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_B4"
						value={ info.field_B4 || '' }
						label="Brand Non Preferred"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_B5"
						value={ info.field_B5 || '' }
						label="Tier 4"
						width={ 4 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Mail Order (90-Day Supply)</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_C1"
						width={ 8 }
						value={ info.field_C1 || '' }
						label="Description"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C2"
						value={ info.field_C2 || '' }
						label="Generic"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C3"
						value={ info.field_C3 || '' }
						label="Brand Preferred List"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C4"
						value={ info.field_C4 || '' }
						label="Brand Non Preferred"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C5"
						value={ info.field_C5 || '' }
						label="Tier 4"
						width={ 4 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Form.Group>
				<Form.Input
					name="field_D1"
					value={ info.field_D1 || '' }
					label="Office/Specialist Visit Copay"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Co-Insurance</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_E1"
						width={ 8 }
						value={ info.field_E1 || '' }
						label="Description"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E2"
						value={ info.field_E2 || '' }
						label="Preferred Care"
						width={ 4 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_E3"
						value={ info.field_E3 || '' }
						label="Non-Preferred Care"
						width={ 4 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Out-Of-Pocket</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_E4"
						value={ info.field_E4 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Segment>
					<Form.Group>
						<Form.Input
							name="field_E5"
							value={ info.field_E5 || '' }
							label="Preferred Care"
							width={ 8 }
							onChange={ updateInfo }
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							name="field_E6"
							value={ info.field_E6 || '' }
							label="One Person"
							width={ 4 }
							onChange={ updateInfo }
						/>
						<Form.Input
							name="field_E7"
							value={ info.field_E7 || '' }
							label="Two + People"
							width={ 4 }
							onChange={ updateInfo }
						/>
					</Form.Group>
				</Segment>
				<Segment>
					<Form.Group>
						<Form.Input
							name="field_E8"
							value={ info.field_E8 || '' }
							label="Non-Preferred Care"
							width={ 8 }
							onChange={ updateInfo }
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							name="field_E9"
							value={ info.field_E9 || '' }
							label="One Person"
							width={ 4 }
							onChange={ updateInfo }
						/>
						<Form.Input
							name="field_E10"
							value={ info.field_E10 || '' }
							label="Two + People"
							width={ 4 }
							onChange={ updateInfo }
						/>
					</Form.Group>
				</Segment>
			</Segment>

			<Form.Group>
				<Form.Input
					name="field_F1"
					value={ info.field_F1 || '' }
					label="Wellness Benefit"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Form.Group>
				<Form.Input
					name="field_G1"
					value={ info.field_G1 || '' }
					label="Live Health Online"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Form.Group>
				<Form.Input
					name="field_H1"
					value={ info.field_H1 || '' }
					label="Urgent Care"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Form.Group>
				<Form.Input
					name="field_I1"
					value={ info.field_I1 || '' }
					label="Emergency Room"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>
		</Form>
	);
};

export default PlanInfoMedical;
