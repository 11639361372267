import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { AdminContext } from '../Admin/AdminContext';
import { maskPhone } from '../../Services/inputMasks';
import Table from '../Shared/Table';
import DeleteConfirmModal from '../Shared/DeleteConfirmModal';
import AdminModal from '../Admin/AdminModal';
import Spinner from '../Shared/Spinner';

const Admin = () => {
	const {
		accessToken,
		tableLoader,
		uiLoader,
		loadingMessage,
		users,
		user,
		getUsers,
		deleteUser,
		showAdminModal,
		deleteConfirmModal,
		toggleDeleteConfirmModal,
		spinner
	} = useContext( AdminContext );

	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ userId, setUserId ] = useState( '' );

	useEffect( () => {
		getUsers();
	}, [ getUsers, accessToken ] );

	const columns = [
		{
			Header: 'ID',
			accessor: 'id',
			filterable: true,
			sortable: true
		},
		{
			Header: 'First Name',
			accessor: 'firstName',
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.firstName.toLowerCase(), filter.value.toLowerCase() ),
			sortable: true
		},
		{
			Header: 'Last Name',
			accessor: 'lastName',
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.lastName.toLowerCase(), filter.value.toLowerCase() ),
			sortable: true
		},
		{
			id: 'email',
			Header: 'Email Address',
			accessor: 'email',
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.email.toLowerCase(), filter.value.toLowerCase() ),
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <a href={ `mailto:${ row.original.email }` }>{ row.original.email }</a>
		},
		{
			Header: 'Phone',
			accessor: 'phone',
			filterable: true,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div style={{ whiteSpace: 'normal' }}>
					{ maskPhone( row.original.phone ) }
				</div>
			)
		},
		{
			accessor: 'id',
			Header: 'Edit / Remove',
			sortable: false,
			filterable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						userid={ row.original.id }
						onClick={ ( e, { userid } ) => {
							showAdminModal( userid );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( `${ row.original.firstName } ${ row.original.lastName }` );
							setUserId( row.original.id );
							toggleDeleteConfirmModal();
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<>
			<div className="titleHeader">
				{ uiLoader
					? <span>
						<Loader active inverted inline size="tiny" style={{ marginRight: '0.5rem', marginBottom: '2px' }}/>
						{ loadingMessage }
					</span>
					: 'Administration'
				}
			</div>
			<div className="formContainer">
				<Segment basic style={{ padding: '2rem', margin: '0 auto' }}>
					<Header as="h2" style={{ fontWeight: 'normal' }}>Users Administration Table</Header>
					<Segment>
						<Segment clearing>
							<Button
								animated="fade"
								style={{ width: '150px', marginRight: '10px' }}
								primary
								onClick={ showAdminModal }
								floated="right"
							>
								<Button.Content visible>
									<Icon name="plus" />
								</Button.Content>
								<Button.Content hidden> Add User </Button.Content>
							</Button>
						</Segment>
						<Table
							data={ users }
							columns={ columns }
							loading={ tableLoader }
						/>
						<br />
						<p>Total Users: { users.length }</p>
					</Segment>
				</Segment>
			</div>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="User"
				open={ deleteConfirmModal }
				closeModal={ () => toggleDeleteConfirmModal() }
				onClick={ () => deleteUser( userId ) }
			/>
			<AdminModal user={ user } />
			<Spinner active={ spinner } />
		</>
	);
};

export default Admin;
