import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Message, Segment } from 'semantic-ui-react';
import parser from 'query-string-parser';
import { useForm } from '../../Hooks/useForm';
import { AuthContext } from './AuthContext';

const ConfirmPasswordChange = props => {

  // context
  const { resetPasswordFromConfirm } = useContext( AuthContext );

  // state
  const [ disabled, setDisabled ] = useState( true );
  const [ buttonColor, setButtonColor ] = useState( 'grey' );

  // form handler
  const { formData, updateField } = useForm({
    password: '',
    confirm: ''
  });

  useEffect( () => {
    if (
      formData.password === formData.confirm &&
      formData.password.length > 3
    ) {
      if ( disabled === true ) {
        setDisabled( false );
        setButtonColor( 'teal' );
      }
    }
  }, [ formData ] );

  const handlePasswordChange = () => {
    if ( formData.password === formData.confirm ) {
      const penCode = props.location.pathname.replace( '/confirm/', '' );
      const password = formData.password;
      resetPasswordFromConfirm( penCode, password );
      props.history.push( '/login' );
    }
  };

  return (
    <div className="loginWrapper">
      <Form size="large">
        <Segment className="loginForm">
          <img
            className="loginLogo"
            src="../assets/images/logo.png"
            alt="WorkSmart"
          />
           <Message>
            <p>If you wish to change your current password, please enter your new desired password.</p>
          </Message>
          <Input
            type="password"
            name="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="New Password"
            value={ formData.password }
            onChange={ updateField }
          />
          <Input
            type="password"
            name="confirm"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Confirm New Password"
            value={ formData.confirm }
            onChange={ updateField }
          />

          <Button
            className="savePass"
            color={ buttonColor }
            fluid
            disabled={ disabled }
            size="large"
            style={{
              marginTop: '10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            onClick={ handlePasswordChange }
          >
            Change Password
          </Button>
        </Segment>
      </Form>
    </div>
  );
};

export default ConfirmPasswordChange;
