import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';
import { useForm } from '../../../../Hooks/useForm';
// import { maskCurrency, unmaskCurrency } from '../../../../Services/inputMasks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClipboard,
	faTimes
} from '@fortawesome/free-solid-svg-icons';

import MedicalTab from './MedicalTab';
import DentalTab from './DentalTab';
import VisionTab from './VisionTab';
import LifeTab from './LifeTab';
import DisabilityTab from './DisabilityTab';
import GroupInfoTab from './GroupInfoTab';
import Alert from '../../../Shared/Alert';
import RetirementTab from './RetirementTab';
import RenewalTab from './RenewalTab';

const BenefitGroupModal = ( { closeModal, open } ) => {
	const {
		customer,
		clientBenefit,
		benefitGroup,
		updateBenefitGroup,
		clientBenefitReadonly,
		notesModal,
		toggleNotesModal
	} = useContext( CustomersContext );

	const [ uiDisabled, setUiDisabled ] = useState( false );
	const [ activeIndex, setActiveIndex ] = useState( 0 );
	const [ saveAlert, setSaveAlert ] = useState( false );
	const [ planUpdate, setPlanUpdate ] = useState( false );

	const [ medicalPlans, setMedicalPlans ] = useState( benefitGroup.medical.plans );
	const [ dentalPlans, setDentalPlans ] = useState( benefitGroup.dental.plans );
	const [ visionPlans, setVisionPlans ] = useState( benefitGroup.vision.plans );

	const { formData, updateField, hasChanged, reinitializeData, setData } = useForm( {} );

	useEffect( () => {
		setData( {
			basicLife: benefitGroup.life.basicLife,
			employerPaid: benefitGroup.life.employerPaid.id,
			volunteerLife: benefitGroup.life.volunteerLife,
			shortTerm: benefitGroup.disability.shortTerm.id,
			longTerm: benefitGroup.disability.longTerm.id,
			name: benefitGroup.benefitGroup.name,
			isBenefitDollars: benefitGroup.isBenefitDollars,
			isBelowMin: benefitGroup.isBelowMin,
			// dollars: maskCurrency( benefitGroup.dollars ),
			isWeekly: benefitGroup.isWeekly,
			isBiWeekly: benefitGroup.isBiWeekly,
			isSemiMonthly: benefitGroup.isSemiMonthly,
			isMonthly: benefitGroup.isMonthly,
			medicalTier: benefitGroup.medicalTier.id,
			planType: benefitGroup.retirement.planType.id,
			planSubType: benefitGroup.retirement.planSubType.id,
			minimumAge: benefitGroup.retirement.minimumAgeType.id,
			vesting: benefitGroup.retirement.vestingType.id,
			vestingOther: benefitGroup.retirement.vestingType.other,
			service: benefitGroup.retirement.serviceType.id,
			serviceOther: benefitGroup.retirement.serviceType.other,
			matchPerc: benefitGroup.retirement.matchPerc,
			matchNote: benefitGroup.retirement.matchNote,
			planStartDate: new Date( benefitGroup.retirement.planStartDate ),
			enrollmentType: benefitGroup.retirement.enrollmentType.id,
			planMergerRequired: benefitGroup.retirement.planMergerRequired.id,
			adoptionAgreement: benefitGroup.retirement.adoptionAgreement?.id,
			testing: benefitGroup.retirement.testing,
			ownerNote: benefitGroup.retirement.ownerNote,
			familyNote: benefitGroup.retirement.familyNote,
			officerNote: benefitGroup.retirement.officerNote,
			contactDate: benefitGroup.renewal.contactDate && benefitGroup.renewal.contactDate !== '0001-01-01T00:00:00' ? new Date( benefitGroup.renewal.contactDate ) : null,
			contactInitial: benefitGroup.renewal.contactInitial
		} );
		setMedicalPlans( benefitGroup.medical.plans );
		setDentalPlans( benefitGroup.dental.plans );
		setVisionPlans( benefitGroup.vision.plans );
	}, [ benefitGroup, setData ] );

	// state
	const [ validator, setValidator ] = useState( {
		name: 'string',
		// dollars: '0',
		medicalTier: 0
	} );

	const updateMedicalPlans = useCallback( ( id, plan ) => {
		setMedicalPlans( prev => prev.map( prevPlan => {
			if ( prevPlan.id === id ) {
				return {
					...prevPlan,
					...plan
				};
			}

			return prevPlan;
		} ) );

		setPlanUpdate( true );
	}, [] );

	const updateMedicalLumpSumHSA = useCallback( lumpSumHSA => {
		setMedicalPlans( prev => prev.map( plan => {
			if ( plan.hasHSA ) {
				return { ...plan, isAnnual_HSA: lumpSumHSA };
			}

			return plan;
		} ) );

		setPlanUpdate( true );
	}, [] );

	const updateDentalPlans = useCallback( ( id, plan ) => {
		setDentalPlans( prev => {
			const updatedPlans = prev.map( prevPlan => {
				if ( prevPlan.id === id ) {
					return {
						...prevPlan,
						...plan
					};
				}

				return prevPlan;
			} );

			setPlanUpdate( true );

			return updatedPlans;
		} );
	}, [] );

	const updateVisionPlans = useCallback( ( id, plan ) => {
		setVisionPlans( prev => {
			const updatedPlans = prev.map( prevPlan => {
				if ( prevPlan.id === id ) {
					return {
						...prevPlan,
						...plan
					};
				}

				return prevPlan;
			} );

			setPlanUpdate( true );

			return updatedPlans;
		} );
	}, [] );

	const fixedTimeZoneOffset = date => {
		if ( !date ) {
			return null;
		}

		return new Date( date.getTime() - date.getTimezoneOffset() * 60000 ).toJSON();
	};

	const handleSave = useCallback( async () => {
		const updatedBG = {
			...benefitGroup,
			benefitGroup: {
				...benefitGroup.benefitGroup,
				name: formData.name
			},
			isBenefitDollars: formData.isBenefitDollars,
			isBelowMin: formData.isBelowMin,
			// dollars: unmaskCurrency( formData.dollars ),
			medicalTier: { id: formData.medicalTier },
			isWeekly: formData.isWeekly,
			isBiWeekly: formData.isBiWeekly,
			isSemiMonthly: formData.isSemiMonthly,
			isMonthly: formData.isMonthly,
			medical: {
				...benefitGroup.medical,
				plans: [ ...medicalPlans ]
			},
			dental: {
				...benefitGroup.dental,
				plans: [ ...dentalPlans ]
			},
			vision: {
				...benefitGroup.vision,
				plans: [ ...visionPlans ]
			},
			life: {
				...benefitGroup.life,
				basicLife: formData.basicLife,
				volunteerLife: formData.volunteerLife,
				employerPaid: { id: formData.employerPaid }
			},
			disability: {
				...benefitGroup.disability,
				shortTerm: { id: formData.shortTerm },
				longTerm: { id: formData.longTerm }
			},
			retirement: {
				...benefitGroup.retirement,
				planType: {
					...benefitGroup.retirement.planType,
					id: formData.planType
				},
				planSubType: {
					...benefitGroup.retirement.planSubType,
					id: formData.planSubType
				},
				minimumAgeType: {
					...benefitGroup.retirement.minimumAgeType,
					id: formData.minimumAge
				},
				vestingType: {
					...benefitGroup.retirement.vestingType,
					id: formData.vesting,
					other: formData.vestingOther
				},
				serviceType: {
					...benefitGroup.retirement.serviceType,
					id: formData.service,
					other: formData.serviceOther
				},
				matchPerc: formData.matchPerc,
				matchNote: formData.matchNote,
				planStartDate: fixedTimeZoneOffset( formData.planStartDate ),
				enrollmentType: {
					...benefitGroup.retirement.enrollmentType,
					id: formData.enrollmentType
				},
				planMergerRequired: {
					...benefitGroup.retirement.planMergerRequired,
					id: formData.planMergerRequired
				},
				adoptionAgreement: {
					...benefitGroup.retirement.adoptionAgreement,
					id: formData.adoptionAgreement
				},
				testing: formData.testing,
				ownerNote: formData.ownerNote,
				familyNote: formData.familyNote,
				officerNote: formData.officerNote
			},
			renewal: {
				...benefitGroup.renewal,
				contactDate: formData.contactDate === null ? '0001-01-01T00:00:00' : fixedTimeZoneOffset( formData.contactDate ),
				contactInitial: formData.contactInitial
			}
		};

		await updateBenefitGroup( updatedBG );
		reinitializeData();
		setSaveAlert( true );
		setPlanUpdate( false );
	}, [ updateBenefitGroup, benefitGroup, formData, medicalPlans, dentalPlans, visionPlans, reinitializeData ] );

	const frequencyOptions = useMemo( () => [
		{ text: 'Monthly', value: 'monthly' },
		...benefitGroup.isSemiMonthly ? [ { text: 'Semi-monthly', value: 'semi-monthly' } ] : [],
		...benefitGroup.isBiWeekly ? [ { text: 'Bi-Weekly', value: 'bi-weekly' } ] : [],
		...benefitGroup.isWeekly ? [ { text: 'Weekly', value: 'weekly' } ] : [],
		{ text: 'Annually', value: 'annually' }
	], [ benefitGroup ] );

	const panes = useMemo( () => [
		{
			menuItem: { key: 'medical', disabled: uiDisabled, content: 'Medical' },
			// eslint-disable-next-line react/display-name
			render: () => <MedicalTab
				// dollars={ formData.dollars }
				frequencyOptions={ frequencyOptions }
				isBenefitDollars={ benefitGroup.isBenefitDollars }
				medical={ benefitGroup.medical }
				medicalTier={ benefitGroup.medicalTier }
				omitMedical={ benefitGroup.omitMedical }
				plans={ medicalPlans }
				updateLumpSumHSA={ updateMedicalLumpSumHSA }
				updatePlans={ updateMedicalPlans }
				setUiDisabled={ setUiDisabled }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'dental', disabled: uiDisabled, content: 'Dental' },
			// eslint-disable-next-line react/display-name
			render: () => <DentalTab
				dental={ benefitGroup.dental }
				// dollars={ formData.dollars }
				frequencyOptions={ frequencyOptions }
				plans={ dentalPlans }
				updatePlans={ updateDentalPlans }
				setUiDisabled={ setUiDisabled }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'vision', disabled: uiDisabled, content: 'Vision' },
			// eslint-disable-next-line react/display-name
			render: () => <VisionTab
				// dollars={ formData.dollars }
				vision={ benefitGroup.vision }
				frequencyOptions={ frequencyOptions }
				plans={ visionPlans }
				updatePlans={ updateVisionPlans }
				setUiDisabled={ setUiDisabled }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'disability', disabled: uiDisabled, content: 'Disability' },
			// eslint-disable-next-line react/display-name
			render: () => <DisabilityTab
				formData={ formData }
				updateField={ updateField }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'life', disabled: uiDisabled, content: 'Life' },
			// eslint-disable-next-line react/display-name
			render: () => <LifeTab
				formData={ formData }
				updateField={ updateField }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'retirement', disabled: uiDisabled, content: 'Retirement' },
			// eslint-disable-next-line react/display-name
			render: () => <RetirementTab
				formData={ formData }
				updateField={ updateField }
				readonly={ clientBenefitReadonly }
			/>
		},
		{
			menuItem: { key: 'info', disabled: uiDisabled, content: 'Group Info' },
			// eslint-disable-next-line react/display-name
			render: () => <GroupInfoTab
				formData={ formData }
				updateField={ updateField }
				validator={ validator }
				setValidator={ setValidator }
				readonly={ clientBenefitReadonly }
			/>
		},
		/* benefitGroup.renewal.renewalStatus.id !== 183 && */{
			menuItem: { key: 'renewal', disabled: uiDisabled, content: 'Renewal' },
			// eslint-disable-next-line react/display-name
			render: () => <RenewalTab
				formData={ formData }
				updateField={ updateField }
			/>
		}
	], [ uiDisabled, frequencyOptions, benefitGroup.isBenefitDollars, benefitGroup.medical, benefitGroup.medicalTier, benefitGroup.omitMedical, benefitGroup.dental, benefitGroup.vision, medicalPlans, updateMedicalLumpSumHSA, updateMedicalPlans, clientBenefitReadonly, dentalPlans, updateDentalPlans, visionPlans, updateVisionPlans, formData, updateField, validator ] );

	const handleTabChange = useCallback( async ( e, { activeIndex } ) => {
		if ( !uiDisabled ) {
			if ( hasChanged || planUpdate ) {
				await handleSave();
				setSaveAlert( true );
				setPlanUpdate( false );
			}

			setActiveIndex( activeIndex );
		}
	}, [ uiDisabled, planUpdate, handleSave, hasChanged, setActiveIndex, setPlanUpdate, setSaveAlert ] );

	const mountNode = document.querySelector( '#clientModal' );

	return (
		<Modal
			id="benfit-benefitGroup-modal"
			open={ open }
			onClose={ closeModal }
			closeOnDimmerClick={ false }
			size="fullscreen"
			mountNode={ mountNode }
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span>
					{ customer.prismID } - { customer.name }
					<br />
					{ benefitGroup?.benefitGroup.name } ({ clientBenefit.year })
					{ clientBenefitReadonly ? <p style={{ float: 'right' }}>Read Only</p> : null }

				</span>
				<Button
					basic={ !customer.noteCount }
					inverted={ customer.noteCount }
					secondary
					onClick={ toggleNotesModal }
					icon="clipboard"
					color={ customer.noteCount ? 'blue' : null }
				>
					<Button.Content>
						<FontAwesomeIcon
							icon={ notesModal ? faTimes : faClipboard }
							style={{ fontSize: '1.25em' }}
						/>
					</Button.Content>
				</Button>
			</Modal.Header>
			<Modal.Content scrolling>
				<Tab
					activeIndex={ activeIndex }
					onTabChange={ handleTabChange }
					panes={ panes }
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Save"
					onClick={ handleSave }
					disabled={ uiDisabled }
					primary
				/>
				<Button
					content="Close"
					onClick={ closeModal }
					style={{ backgroundColor: '#F58229', color: '#fff' }}
				/>
			</Modal.Actions>
			<Alert
				open={ saveAlert }
				closeModal={ () => setSaveAlert( false ) }
				message="group saved!"
			/>
		</Modal>
	);
};

export default BenefitGroupModal;
