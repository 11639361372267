import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Loader, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../Customers/CustomersContext';
import Leads from '../Customers/Sales/Leads/Leads';
import RFPList from '../Customers/Sales/RFP/RFPList';
import Prospects from '../Customers/Sales/Prospects/Prospects';
import Archive from '../Customers/Sales/Archive/Archive';
import Spinner from '../Shared/Spinner';

const Sales = () => {
	// context
	const { uiLoader, loadingMessage, spinner } = useContext( CustomersContext );

	return (
		<>
			<div className="titleHeader">
				{ uiLoader
					? <span>
						<Loader active inverted inline size="tiny" style={{ marginRight: '0.5rem', marginBottom: '2px' }}/>
						{ loadingMessage }
					</span>
					: 'Sales'
				}
			</div>
			<div className="formContainer">
				<Segment basic style={{ padding: '2rem', margin: '0 auto' }}>
					<Route path="/main/sales" exact component={ Leads } />
					<Route path="/main/sales/rfp" component={ RFPList } />
					<Route path="/main/sales/prospects" component={ Prospects } />
					<Route path="/main/sales/archived" component={ Archive } />
				</Segment>
			</div>
			<Spinner active={ spinner } />
		</>
	);
};

export default Sales;
