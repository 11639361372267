import React, { useContext, useEffect } from 'react';
import { OperationsContext } from '../OperationsContext';
import { Button, Dropdown, Icon, Segment } from 'semantic-ui-react';

const TiersFilter = props => {

  const {
    getTiers,
    tierTypes,
    tierType,
    handleTierTypeChange,
    years,
    year,
    handleYearChange,
    showTiersModal,
    resetFilter
  } = useContext( OperationsContext );

  useEffect( () => {
    if ( tierType && year ) {
      resetFilter( getTiers );
    }
  }, [] );

  return (
    <Segment>
      <span style={{ margin: '20px' }}>Year</span>
      <Dropdown
        value={ year }
        selection
        options={ years }
        onChange={ ( e, { value } ) => handleYearChange( value ) }
      />
      <span style={{ margin: '0 20px 0' }}>Type</span>
      <Dropdown
        value={ tierType }
        selection
        options={ tierTypes }
        onChange={ ( e, { value } ) => handleTierTypeChange( value ) }
      />
      {/*<Button
        animated="fade"
        primary
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => getTiers() }
      >
        <Button.Content visible>
          <Icon name="search" />
        </Button.Content>
        <Button.Content hidden> Search </Button.Content>
      </Button>*/}
      <Button
        animated="fade"
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => resetFilter( getTiers ) }
      >
        <Button.Content visible>
          <Icon name="undo" />
        </Button.Content>
        <Button.Content hidden> Reset </Button.Content>
      </Button>
      <Button
        animated="fade"
        primary
        floated="right"
        onClick={ showTiersModal }
        style={{ width: '150px', marginRight: '10px' }}
      >
        <Button.Content visible>
          <Icon name="add" />
        </Button.Content>
        <Button.Content hidden>New Tier</Button.Content>
      </Button>
    </Segment>
  );
};

export default TiersFilter;
