import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import AuthProvider from './Components/Auth/AuthContext';
import App from './App';

import 'babel-polyfill';

import 'semantic-ui-css/semantic.min.css';
import './index.css';

ReactDOM.render(
	<CookiesProvider>
		<AuthProvider>
			<App />
		</AuthProvider>
	</CookiesProvider>,
	document.querySelector( '#root' )
);
