import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Label, Modal, Segment } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { AuthContext } from './AuthContext';
import Spinner from '../Shared/Spinner';
import history from '../../history';

const LoginForm = () => {
	// context
	const { isLoading, isValid, handleLogin, passwordReset } = useContext( AuthContext );

	// state
	const [ appVersion, setAppVersion ] = useState( '' );
	const [ forgotPass, setForgotPass ] = useState( false );
	const [ confirmForgotten, setConfirmForgotten ] = useState( false );

	useEffect( () => {
		const version = localStorage.getItem( 'appVersion' );

		setAppVersion( JSON.parse( version ) );
	}, [] );

	// form handler
	const { formData, updateField } = useForm( {
		username: '',
		password: ''
	} );

	return (
		<div className="loginWrapper">
			<div>
				<Modal
					open={ forgotPass }
					size="tiny"
				>
					<Modal.Header>
						Reset Password?
					</Modal.Header>
					<Modal.Content>
						<>
							<p>Please enter the email address associated with your account below and a link to reset your password will be sent to you.</p>
							<Form>
								<Form.Input
									name="username"
									label="Email Address"
									value={ formData.username }
									onChange={ updateField }
								/>
							</Form>
						</>
					</Modal.Content>
					<Modal.Actions>
						<Button
							primary
							onClick={ () => {
								setForgotPass( false );
								setConfirmForgotten( true );
								passwordReset( formData.username );
							}}
						>
							Reset
						</Button>
						<Button
							style={{ backgroundColor: '#F58229', color: '#fff' }}
							onClick={ () => setForgotPass( false ) }
						>
							Cancel
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal size="mini" open={ confirmForgotten } onClose={ () => setConfirmForgotten( false ) }>
					<Modal.Content>
						<p>An email is being sent to your email address.</p>
					</Modal.Content>
				</Modal>
			</div>
			<div className="login-form" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Form size="large">
					<Segment className="loginForm">
						<img
							className="loginLogo"
							src="assets/images/logo.png"
							alt="WorkSmart"
						/>
						<Input
							fluid
							icon="user"
							iconPosition="left"
							placeholder="E-mail address"
							name="username"
							value={ formData.username }
							onChange={ updateField }
						/>
						<Input
							fluid
							icon="lock"
							iconPosition="left"
							placeholder="Password"
							type="password"
							name="password"
							value={ formData.password }
							onChange={ updateField }
						/>
						<Label
							basic
							pointing
							className={
								`invalid ${ isValid ? 'hide' : 'show' }`
							}
						>
							Invalid Credentials
						</Label>
						<Button
							color="blue"
							fluid
							size="large"
							style={{
								marginTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto'
							}}
							onClick={ async () => {
								const isValid = await handleLogin( formData );

								if ( !isValid ) {
									history.push( './twoAuth' );
								}
							} }
						>
							Login
						</Button>
						<Button
							className="forgotPass"
							fluid
							size="small"
							style={{
								marginTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '50%',
								backgroundColor: '#F58229',
								color: '#fff'
							}}
							onClick={ () => setForgotPass( true ) }
						>
							Forgot Password
						</Button>
						<small style={{ position: 'absolute', top: '0.25rem', right: '0.5rem' }}>version { appVersion ? appVersion : null }</small>
					</Segment>
				</Form>
			</div>
			<Spinner active={ isLoading } />
		</div>
	);
};

export default LoginForm;
