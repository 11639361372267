import React, { useCallback, useContext } from 'react';
import { Button, Form, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';
// import { maskCurrency, floatKeyDown } from '../../../../Services/inputMasks';
import Spinner from '../../../Shared/Spinner';

const GroupInfoTab = ( { formData, updateField, validator, setValidator, readonly } ) => {
	const { clientMedicalTiers, getEmployeeBenefitDoc, employeeBenefitsDocSpinner } = useContext( CustomersContext );

	const findOption = useCallback( ( val, options ) => {
		const opt = options.find( option => option.value === val );

		return opt?.text || '';
	}, [] );

	return (
		<Tab.Pane>
			<Form>
				<Form.Group>
					<Form.Input
						required
						name="name"
						value={ formData.name || '' }
						label="Benefit Group Name"
						width={ 16 }
						maxLength={ 255 }
						onChange={ updateField }
						error={ validator.name.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, name: formData.name || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, name: 'string' } );
						}}
						readOnly={ readonly }
					/>
					{/* <Form.Input
						required
						name="dollars"
						value={ formData.dollars || '' }
						label="Benefit Dollars"
						width={ 16 }
						onChange={ updateField }
						error={ validator.dollars.length === 0 }
						onBlur={ e => {
							updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
							setValidator( { ...validator, dollars: formData.dollars || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, dollars: '0' } );
						}}
						onKeyDown={ floatKeyDown }
						readOnly={ readonly }
					/> */}
					{ formData.medicalTier !== 1263
						? <Form.Field width={ 3 } style={{ textAlign: 'center' }}>
							<label>Benefit Dollars</label>
							<Form.Checkbox
								disabled={ formData.isBelowMin }
								name="isBenefitDollars"
								inline={ false }
								style={{ marginTop: '1rem' }}
								checked={ formData.isBenefitDollars }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
						: null
					}<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
						<label>Below Minimum</label>
						<Form.Checkbox
							disabled={ formData.isBenefitDollars }
							name="isBelowMin"
							inline={ false }
							style={{ marginTop: '1rem' }}
							checked={ formData.isBelowMin }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
						/>
					</Form.Field>
					{ readonly
						? <Form.Input
							value={ findOption( formData.medicalTier, clientMedicalTiers ) }
							label="MedicalTier"
							width={ 16 }
						/>
						: <Form.Select
							name="medicalTier"
							value={ formData.medicalTier || '' }
							label="MedicalTier"
							options={ clientMedicalTiers }
							width={ 16 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
				<Form.Group>
					<Form.Field>
						<label>Frequency</label>
					</Form.Field>
				</Form.Group>
				<Form.Group inline>
					{ formData.isWeekly
						? <Button
							basic
							style={{ marginRight: '0.5rem' }}
							icon="file pdf"
							size="mini"
							onClick={ () => getEmployeeBenefitDoc( 51 ) }
						/>
						: null
					}
					<Form.Field width={ 3 }>
						<label>Weekly</label>
						<Form.Checkbox
							name="isWeekly"
							checked={ formData.isWeekly || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
					{ formData.isBiWeekly
						? <Button
							basic
							style={{ marginRight: '0.5rem' }}
							icon="file pdf"
							size="mini"
							onClick={ () => getEmployeeBenefitDoc( 52 ) }
						/>
						: null
					}
					<Form.Field width={ 3 }>
						<label>Bi Weekly</label>
						<Form.Checkbox
							name="isBiWeekly"
							checked={ formData.isBiWeekly || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
					{ formData.isSemiMonthly
						? <Button
							basic
							style={{ marginRight: '0.5rem' }}
							icon="file pdf"
							size="mini"
							onClick={ () => getEmployeeBenefitDoc( 53 ) }
						/>
						: null
					}
					<Form.Field width={ 3 }>
						<label>Semi Monthly</label>
						<Form.Checkbox
							name="isSemiMonthly"
							checked={ formData.isSemiMonthly || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
					{ formData.isMonthly
						? <Button
							basic
							style={{ marginRight: '0.5rem' }}
							icon="file pdf"
							size="mini"
							onClick={ () => getEmployeeBenefitDoc( 54 ) }
						/>
						: null
					}
					<Form.Field width={ 3 }>
						<label>Monthly</label>
						<Form.Checkbox
							name="isMonthly"
							checked={ formData.isMonthly || false }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
							readOnly={ readonly }
						/>
					</Form.Field>
				</Form.Group>
			</Form>
			<Spinner active={ employeeBenefitsDocSpinner } />
		</Tab.Pane>
	);
};

export default GroupInfoTab;
