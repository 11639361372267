import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Label, Segment } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { AuthContext } from './AuthContext';
import Spinner from '../Shared/Spinner';

const TwoAuthForm = () => {
	// context
	const { isLoading, isValid, handleTwoAuth, resendTwoAuth } = useContext( AuthContext );

	// state
	const [ appVersion, setAppVersion ] = useState( '' );
	const [ codeResent, setCodeResent ] = useState( false );

	useEffect( () => {
		if ( codeResent ) {
			setTimeout( () => {
				setCodeResent( false );
			}, 10000 );
		}
	} );

	useEffect( () => {
		const version = localStorage.getItem( 'appVersion' );

		setAppVersion( JSON.parse( version ) );
	}, [] );

	// form handler
	const { formData, updateField } = useForm( { code: '' } );

	return (
		<div className="loginWrapper">
			<div>
			</div>
			<div className="login-form" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Form size="large">
					<Segment className="loginForm">
						<img
							className="loginLogo"
							src="assets/images/logo.png"
							alt="WorkSmart"
						/>
						<Input
							fluid
							placeholder="Auth Code"
							name="code"
							value={ formData.code }
							onChange={ updateField }
						/>
						<Label
							basic
							pointing
							className={
								`invalid ${ isValid ? 'hide' : 'show' }`
							}
						>
							Invalid Code
						</Label>
						<Button
							color="blue"
							fluid
							size="large"
							style={{
								marginTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto'
							}}
							onClick={ () => handleTwoAuth( formData.code ) }
						>
							Authenticate
						</Button>
						<Button
							className="forgotPass"
							fluid
							size="small"
							style={{
								marginTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '50%',
								backgroundColor: '#F58229',
								color: '#fff'
							}}
							onClick={ async () => {
								const response = await resendTwoAuth();

								setCodeResent( response );
							} }
						>
							{ codeResent ? 'Code Sent!' : 'Resend Code' }
						</Button>
						<small style={{ position: 'absolute', top: '0.25rem', right: '0.5rem' }}>version { appVersion ? appVersion : null }</small>
					</Segment>
				</Form>
			</div>
			<Spinner active={ isLoading } />
		</div>
	);
};

export default TwoAuthForm;
