import React from 'react';
import { Button, Confirm } from 'semantic-ui-react';

// @props: name, type, open, closeModal, onClick

const DeleteConfirmModal = props => {
	return (
		<Confirm
			className="delete-confirm-modal"
			open={ props.open }
			size="tiny"
			header={ `Delete ${ props.type }?` }
			content={
				<div className="content" style={{ fontSize: '16px' }}>
          Are you sure you want to delete <strong>{ props.name || `this ${ props.type.toLowerCase() }` }</strong>?
					{ props.message }
				</div>
			}
			cancelButton="Cancel"
			confirmButton={ () => (
				<Button
					negative
					onClick={ () => {
						props.onClick();
						props.closeModal();
					} }
				>
          Delete
				</Button>
			) }
			onCancel={ props.closeModal }
		/>
	);
};

export default DeleteConfirmModal;
