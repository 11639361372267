import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const VariableCodePopup = () => {
	return (
		<Popup
			position="top center"
			trigger={
				<Icon
					name="exclamation circle"
					color="red"
				/>
			}
			wide="very"
		>
			<Popup.Content>
				<p>
					This code does not exist.
					<br />
					Please ask an administrator to add this code to the WC Rates in Operations
				</p>
			</Popup.Content>
		</Popup>
	);
};

export default VariableCodePopup;
