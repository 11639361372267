import React, { useContext, useEffect } from 'react';
import { Button, Divider, Form, Header, List, Tab, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { maskCurrency, integerKeyDown, floatKeyDown } from '../../../Services/inputMasks';
import PayCyclesTable from './PayCyclesTable';
import UnemploymentWitholdingsTable from './UnemploymentWitholdingsTable';
import WorkersCompTable from './WorkersCompTable';
import AdminRatesTables from './AdminRatesTables';
import DocumentUploadModal from './DocumentUploadModal';

const PayrollTab = ( { formData, updateField, handleUpdate } ) => {
	const {
		customer,
		documents,
		showDocumentUploadModal,
		mapDocuments
	} = useContext( CustomersContext );

	useEffect( () => {
		if ( `${ customer.rfp.payRoll.wcTier }` !== `${ formData.wcTier }` ) {
			handleUpdate();
		}
	}, [ formData.wcTier, customer, handleUpdate ] );

	const panes = [
		{
			menuItem: 'General',
			render: () => (
				<Tab.Pane>
					<PayCyclesTable/>
					<Form>
						<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0 }}>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Uses Payroll Service</label>
								<Form.Checkbox
									name="usesService"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.usesService || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							<Form.Input
								name="payrollVendor"
								value={ formData.payrollVendor || '' }
								label="Name of Payroll Vendor"
								width={ 6 }
								maxLength={ 255 }
								onChange={ updateField }
							/>
							{/* <Form.Input
                name='conversionFee'
                value={ formData.conversionFee || '' }
                label="Conversion Fee"
                width={ 3 }
                onChange={ updateField }
                onBlur={ ( e ) => { updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } }) } }
                onKeyDown={ floatKeyDown }
              /> */}
						</Form.Group>
						<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0 }}>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Pays 1099 Contractors</label>
								<Form.Checkbox
									name="contractors"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.contractors || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							<Form.Input
								type="number"
								name="contractorCount"
								value={ formData.contractorCount || '' }
								label="Number of contractors"
								width={ 3 }
								min={ 0 }
								onChange={ updateField }
								onKeyDown={ integerKeyDown }
							/>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Uses Contractor Service</label>
								<Form.Checkbox
									name="usesContractorService"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.usesContractorService || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							{ formData.usesContractorService
								? <Form.Input
									name="contractorServiceRate"
									value={ formData.contractorServiceRate || '' }
									label="Monthly Rate"
									width={ 3 }
									min={ 0 }
									onChange={ updateField }
									onBlur={ e => {
										updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
									} }
									onKeyDown={ floatKeyDown }
								/>
								: null
							}
							<Form.Select
								name="contractorServiceCycles"
								label="Cycles"
								width={ 3 }
								value={ parseInt( formData.contractorServiceCycles ) >= 0 ? formData.contractorServiceCycles : '' }
								options={[
									{ text: '0', value: 0 },
									{ text: '12', value: 12 },
									{ text: '24', value: 24 },
									{ text: '26', value: 26 },
									{ text: '52', value: 52 }
								]}
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value } } );
								} }
							/>
						</Form.Group>
						<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0 }}>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Uses Time Clock</label>
								<Form.Checkbox
									name="usesTimeclock"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.usesTimeclock || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							<Form.Input
								type="number"
								name="timeclockCount"
								value={ formData.timeclockCount || '' }
								label="Number of Time Clocks"
								width={ 3 }
								min={ 0 }
								onChange={ updateField }
								onKeyDown={ integerKeyDown }
							/>
							<Form.Input
								name="timeclockCharge"
								value={ formData.timeclockCharge || '' }
								label="Time Clock Charge"
								width={ 3 }
								min={ 0 }
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
								} }
								onKeyDown={ floatKeyDown }
							/>
						</Form.Group>
					</Form>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Tax Info',
			render: () => (
				<Tab.Pane>
					<UnemploymentWitholdingsTable />
					<Form>
						<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0 }}>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Is a Non-Profit</label>
								<Form.Checkbox
									name="isNFP"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.isNFP || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Subject to FUTA & SUTA</label>
								<Form.Checkbox
									name="subjectToFUTA"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.subjectToFUTA || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							<Form.Input
								name="unemploymentClaim"
								value={ formData.unemploymentClaim || '' }
								label="Unemployment Claim"
								width={ 3 }
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
								} }
								onKeyDown={ floatKeyDown }
							/>

							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Is Tax Obligation Current</label>
								<Form.Checkbox
									name="isTaxCurrent"
									inline={ false }
									style={{ marginTop: '1rem' }}
									checked={ formData.isTaxCurrent || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
					</Form>
					<Header>Documents</Header>
					<Header as="h4">Indiana Quarterly Tax Returns</Header>
					<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
						{ mapDocuments( documents.taxInfo.filter( doc => doc.typeID === 37 ) ) }
						<List.Item>
							<Button
								basic
								circular
								icon="add"
								onClick={ () => showDocumentUploadModal( null, 37 ) }
								size="large"
							/>
						</List.Item>
					</List>
					{/* DWD */}
					<Header as="h4">Indiana DWD Merit Rate Notice</Header>
					<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
						{ mapDocuments( documents.taxInfo.filter( doc => doc.typeID === 171 ) ) }
						<List.Item>
							<Button
								basic
								circular
								icon="add"
								onClick={ () => showDocumentUploadModal( null, 171 ) }
								size="large"
							/>
						</List.Item>
					</List>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Work Comp',
			render: () => (
				<Tab.Pane>
					<Form>
						<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
							<Form.Field style={{ marginRight: '1rem' }}>
								<label>Risk Rate</label>
							</Form.Field>
							<Form.Group inline>
								<Form.Radio
									id="wcTier-110"
									name="wcTier"
									label="110%"
									width={ 4 }
									value={ 110 }
									checked={ parseInt( formData.wcTier ) === 110 }
									onChange={ updateField }
								/>
								<br />
								<Form.Radio
									id="wcTier-120"
									name="wcTier"
									label="120%"
									width={ 4 }
									value={ 120 }
									checked={ parseInt( formData.wcTier ) === 120 }
									onChange={ updateField }
								/>
								<br />
								<Form.Radio
									id="wcTier-130"
									name="wcTier"
									label="130%"
									width={ 4 }
									value={ 130 }
									checked={ parseInt( formData.wcTier ) === 130 }
									onChange={ updateField }
								/>
								<br />
								<Form.Radio
									id="wcTier-140"
									name="wcTier"
									label="140%"
									width={ 4 }
									value={ 140 }
									checked={ parseInt( formData.wcTier ) === 140 }
									onChange={ updateField }
								/>
							</Form.Group>
						</Form.Group>
					</Form>
					<WorkersCompTable riskRate={ formData.wcTier / 100 } />
					<Header>Documents</Header>
					<Header as="h4">WC Policies</Header>
					<List horizontal style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
						{ mapDocuments( documents.taxInfo.filter( doc => doc.typeID === 68 ) ) }
						<List.Item>
							<Button
								basic
								circular
								icon="add"
								onClick={ () => showDocumentUploadModal( null, 68 ) }
								size="large"
							/>
						</List.Item>
					</List>
				</Tab.Pane>
			)
		},
		{
			menuItem: 'Admin Rates',
			render: () => (
				<Tab.Pane>
					<AdminRatesTables
						formData={ formData }
						updateField={ updateField }
						notes={{ note_1: formData.note_1, note_2: formData.note_2 }}
						updateNotes={ updateField }
					/>
				</Tab.Pane>
			)
		}
	];

	return (
		<Tab.Pane>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Header>Payroll</Header>
			</div>
			<Divider />
			<Tab
				panes={ panes }
			/>
			<DocumentUploadModal />
		</Tab.Pane>
	);
};

export default PayrollTab;
