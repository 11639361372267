import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { maskCurrency, unmaskCurrency, integerKeyDown, floatKeyDown, stateOptions } from '../../../Services/inputMasks';
import PercentageInput from '../../Shared/PercentageInput';

const WorkersCompModal = props => {

  // context
  const { postWorkersComp, WCRates, mappedWCRates } = useContext( CustomersContext );

  // form handler
  const { formData, updateField, setData } = useForm({});

  // state
  const [ validator, setValidator ] = useState({
    state: 'string',
    employeeCount: 0,
    code: '####',
    rate: 0,
    proposedRate: 0,
    monthlyWages: '0',
    currentCost: '0',
    proposedCost: '0',
    annualWages: '0'
  });

  // props
  const { record, setRecord, workersCompModal, setWorkersCompModal } = props;

  // set formData to customer data
  useEffect( () => {
    if ( record ) {
      const recordData = {
        state: record.state,
        employeeCount: record.employeeCount,
        code: record.code,
        rate: record.rate,
        proposedRate: record.proposedRate,
        monthlyWages: maskCurrency( record.monthlyWages ),
        currentCost: maskCurrency( record.currentCost ),
        proposedCost: maskCurrency( record.proposedCost ),
        annualWages: maskCurrency( record.annualWages ),
        override: record.override
      };
      setData( recordData );
    }
    // on unmount
    return () => {
      setData({});
    };
  }, [ record, setData ] );

  // prepare data for api call (update)
  const handleUpdate = () => {
    postWorkersComp({
      ...record,
      state: formData.state,
      employeeCount: formData.employeeCount,
      code: formData.code,
      rate: formData.rate,
      proposedRate: formData.proposedRate,
      monthlyWages: unmaskCurrency( formData.monthlyWages ),
      currentCost: unmaskCurrency( formData.currentCost ),
      proposedCost: unmaskCurrency( formData.proposedCost ),
      annualWages: unmaskCurrency( formData.annualWages ),
      override: formData.override
    });
    setWorkersCompModal( false );
  };

  // prepare data for api call (create)
  const handleCreateNew = () => {
    postWorkersComp({
      id: 0,
      state: formData.state,
      employeeCount: formData.employeeCount,
      code: formData.code,
      rate: formData.rate,
      monthlyWages: unmaskCurrency( formData.monthlyWages ),
      annualWages: unmaskCurrency( formData.annualWages ),
      override: false
    });
    setWorkersCompModal( false );
  };

  // clear out record, formData, and validator when modal closes
  const clearData = () => {
    setRecord( '' );
    setData({});
    setValidator({
      state: 'string',
      employeeCount: 0,
      code: '####',
      rate: 0,
      proposedRate: 0,
      monthlyWages: '0',
      currentCost: '0',
      proposedCost: '0',
      annualWages: '0'
    });
  };

  // useEffect( () => {
  //   if ( formData.code ) {
  //     const rate = WCRates.find( rate => formData.state ? rate.code === formData.code && rate.state === formData.state : rate.code === formData.code );
  //     if ( rate ) {
  //       const value = rate.state;
  //       updateField({ target: { name: 'state', value }});
  //     }
  //   }
  // }, [ formData.code ] );

  return (
    <Modal
      id="workers-comp-modal"
      open={ workersCompModal }
      closeOnDimmerClick={ false }
      size="tiny"
    >
      <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        { record ? 'Edit Record' : 'Add New Record' }
        { record
            ? <Form>
                <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '1rem' }}>Override</label>
                  <Form.Checkbox
                    name="override"
                    toggle
                    checked={ formData.override || false }
                    onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }); } }
                  />
                </Form.Field>
              </Form>
            : null
        }
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Select
              required
              name="state"
              value={ formData.state || '' }
              label="State"
              options={ stateOptions }
              width={ 4 }
              fluid
              search
              onChange={ ( e, { name, value } ) => {
                updateField({ target: { name: name, value: value } });
                updateField( { target: { name: 'code', value: '' } } );
              }}
              error={ validator.state.length === 0 }
              onBlur={ () => {
                setValidator( { ...validator, state: formData.state || '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, state: 'XX' } );
              }}
            />
          </Form.Group>
          <Message
            hidden={
              validator.state.length > 0
            }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ validator.state.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please select a state.
              </Message.Item>
            </Message.List>
          </Message>

          <Form.Group>
            <Form.Input
              required
              type="number"
              name="employeeCount"
              value={ parseInt( formData.employeeCount ) >= 0 ? formData.employeeCount : '' }
              label="Number of Employees"
              width={ 8 }
              min={ 0 }
              onChange={ updateField }
              error={ !( parseInt( validator.employeeCount ) >= 0 ) }
              onBlur={ () => {
                setValidator( { ...validator, employeeCount: parseInt( formData.employeeCount ) >= 0 ? formData.employeeCount : '' } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, employeeCount: 0 } );
              }}
              onKeyDown={ integerKeyDown }
            />
            { mappedWCRates.filter( opt => opt.text.startsWith( formData.state ) ).length
              ? <Form.Select
                  required
                  name="code"
                  autoComplete="fake"
                  value={ formData.code || 0 }
                  label="WC Code"
                  options={ formData.state
                    ? [ { key: 0, text: 'Select an option', value: 0 }, ...mappedWCRates.filter( opt => opt.text.startsWith( formData.state ) ) ]
                    : [ { key: 0, text: 'Select an state', value: 0 } ]
                  }
                  width={ 8 }
                  disabled={ !formData.state }
                  fluid
                  search
                  onChange={ ( e, { name, value } ) => { updateField({ target: { name: name, value: value } }); } }
                  error={ validator.code.length === 0 }
                  onBlur={ () => {
                    setValidator( { ...validator, code: formData.code || '' } );
                  }}
                  onFocus={ () => {
                    setValidator( { ...validator, code: '####' } );
                  }}
                />
              : <Form.Input
                  required
                  name="code"
                  value={ formData.code || '' }
                  label="WC Code"
                  width={ 8 }
                  maxLength={ 4 }
                  disabled={ !formData.state }
                  onChange={ updateField }
                  error={ validator.code.length === 0 }
                  onBlur={ () => {
                    setValidator( { ...validator, code: formData.code || '' } );
                  }}
                  onFocus={ () => {
                    setValidator( { ...validator, code: '####' } );
                  }}
                />
            }
          </Form.Group>
          <Message
            hidden={
              parseInt( validator.employeeCount ) >= 0
              && validator.code.length > 0
            }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ parseInt( validator.employeeCount ) >= 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a value for Number of Employees.
              </Message.Item>
              <Message.Item
                hidden={ validator.code.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please select a value for WC Code.
              </Message.Item>
            </Message.List>
          </Message>

          <Form.Group>
            <Form.Input
              required
              name="monthlyWages"
              value={ formData.monthlyWages || '' }
              label="Monthly Wages"
              width={ 8 }
              onChange={ updateField }
              error={ validator.monthlyWages.length === 0 }
              onBlur={ e => {
                updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                setValidator({ ...validator, monthlyWages: formData.monthlyWages || '' });
                if ( e.target.value ) {
                  let monthly = unmaskCurrency( e.target.value );
                  updateField({ target: { name: 'annualWages', value: maskCurrency( monthly * 12 ) } });
                  setValidator({ ...validator, annualWages: `${ monthly * 12 }` });
                }
              }}
              onFocus={ () => {
                setValidator( { ...validator, monthlyWages: '0' } );
              }}
              onKeyDown={ floatKeyDown }
            />
            <Form.Input
              required
              name="annualWages"
              value={ formData.annualWages || '' }
              label="Annual Wages"
              width={ 8 }
              onChange={ updateField }
              error={ validator.annualWages.length === 0 }
              onBlur={ e => {
                updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                setValidator({ ...validator, annualWages: formData.annualWages || '' });
                if ( e.target.value ) {
                  let annual = unmaskCurrency( e.target.value );
                  updateField({ target: { name: 'monthlyWages', value: maskCurrency( annual / 12 ) } });
                  setValidator({ ...validator, monthlyWages: `${ annual / 12 }` });
                }
              }}
              onFocus={ () => {
                setValidator( { ...validator, annualWages: '0' } );
              }}
              onKeyDown={ floatKeyDown }
            />
          </Form.Group>
          <Message
            hidden={
              validator.monthlyWages.length > 0
              && validator.annualWages.length > 0
            }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ validator.monthlyWages.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a value for Monthly Wages.
              </Message.Item>
              <Message.Item
                hidden={ validator.annualWages.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a value for Annual Wages.
              </Message.Item>
            </Message.List>
          </Message>

          <Form.Group>
            <PercentageInput
              required
              width={ 8 }
              name="rate"
              label="Current WC Rate"
              value={ formData.rate }
              onChange={ updateField }
              error={ !( parseFloat( validator.rate ) >= 0 ) }
              onBlur={ () => setValidator( { ...validator, rate: parseFloat( formData.rate ) >= 0 ? parseFloat( formData.rate ).toString() : '' } ) }
              onFocus={ () => setValidator( { ...validator, rate: 0 } ) }
            />
            { record
                ? <PercentageInput
                    required={ Boolean( formData.override ) }
                    width={ 8 }
                    name="proposedRate"
                    label="Proposed Base Rate"
                    value={ formData.proposedRate }
                    onChange={ updateField }
                    error={ !( parseFloat( validator.proposedRate ) >= 0 ) }
                    onBlur={ () => setValidator( { ...validator, proposedRate: parseFloat( formData.proposedRate ) >= 0 ? parseFloat( formData.proposedRate ).toString() : '' } ) }
                    onFocus={ () => setValidator( { ...validator, proposedRate: 0 } ) }
                    readOnly={ !formData.override }
                    disabled={ !formData.override }
                  />
                : null
            }
          </Form.Group>
          <Message
            hidden={
              parseInt( validator.rate ) >= 0
              && parseInt( validator.proposedRate ) >= 0
            }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ parseInt( validator.rate ) >= 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a value for Current WC Rate.
              </Message.Item>
              { record 
                ? <Message.Item
                    hidden={ parseInt( validator.proposedRate ) >= 0 }
                  >
                    <Icon name="exclamation circle" />
                    Please enter a value for Proposed Base Rate.
                  </Message.Item>
                : null
              }
            </Message.List>
          </Message>

          { record
              ? <>
                <Form.Group>
                  <Form.Input
                    required={ Boolean( formData.override ) }
                    name="currentCost"
                    value={ formData.currentCost || '' }
                    label="Current WC Cost"
                    readOnly={ !formData.override }
                    disabled={ !formData.override }
                    width={ 8 }
                    onChange={ updateField }
                    error={ validator.currentCost.length === 0 }
                    onBlur={ e => {
                      updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                      setValidator({ ...validator, currentCost: formData.currentCost || '' });
                    }}
                    onFocus={ () => {
                      setValidator( { ...validator, currentCost: '0' } );
                    }}
                    onKeyDown={ floatKeyDown }
                  />
                  <Form.Input
                    required={ Boolean( formData.override ) }
                    name="proposedCost"
                    value={ formData.proposedCost || '' }
                    label="Proposed WC Cost"
                    readOnly={ !formData.override }
                    disabled={ !formData.override }
                    width={ 8 }
                    onChange={ updateField }
                    error={ validator.proposedCost.length === 0 }
                    onBlur={ e => {
                      updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                      setValidator({ ...validator, proposedCost: formData.proposedCost || '' });
                    }}
                    onFocus={ () => {
                      setValidator( { ...validator, proposedCost: '0' } );
                    }}
                    onKeyDown={ floatKeyDown }
                  />
                </Form.Group>
                <Message
                  hidden={
                    validator.currentCost.length > 0
                    && validator.proposedCost.length > 0
                  }
                  negative
                  style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
                >
                  <Message.List style={{ listStyle: 'none' }}>
                    <Message.Item
                      hidden={ validator.currentCost.length > 0 }
                    >
                      <Icon name="exclamation circle" />
                      Please enter a value for Current WC Cost.
                    </Message.Item>
                    <Message.Item
                      hidden={ validator.proposedCost.length > 0 }
                    >
                      <Icon name="exclamation circle" />
                      Please enter a value for Proposed WC Cost.
                    </Message.Item>
                  </Message.List>
                </Message>
              </>
            : null
          }
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Save"
          onClick={ () => {
            record ? handleUpdate() : handleCreateNew();
            clearData();
            setWorkersCompModal( false );
          }}
          disabled={
            ( formData.state ? formData.state.length === 0 : true )
            || !( parseInt( formData.employeeCount ) >= 0 )
            || ( formData.code ? formData.code.length === 0 : true )
            || !( parseInt( formData.rate ) >= 0 )
            || record && formData.override ? !( parseInt( formData.proposedRate ) >= 0 ) : false
            || ( formData.currentCost ? formData.currentCost.length === 0 : true )
            || ( formData.proposedCost ? formData.proposedCost.length === 0 : true )
            || record && formData.override ? ( formData.monthlyWages ? formData.monthlyWages.length === 0 : true ) : false
            || record && formData.override ? ( formData.annualWages ? formData.annualWages.length === 0 : true ) : false
          }
        >
        </Button>
        <Button
          style={{ backgroundColor: '#F58229', color: '#fff' }}
          content="Cancel"
          onClick={ () => {
            clearData();
            setWorkersCompModal( false );
          }}
        >
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WorkersCompModal;
