import React, { useContext, useEffect } from 'react';
import { OperationsContext } from '../OperationsContext';
import { Button, Dropdown, Icon, Segment } from 'semantic-ui-react';

const PlansFilter = props => {

  const {
    getPlans,
    planTypes,
    planType,
    handlePlanTypeChange,
    years,
    year,
    handleYearChange,
    showPlansModal,
    resetFilter
  } = useContext( OperationsContext );

  useEffect( () => {
    if ( planType && year ) {
      resetFilter( getPlans );
    }
  }, [] );

  return (
    <Segment>
      <span style={{ margin: '20px' }}>Year</span>
      <Dropdown
        value={ year }
        selection
        options={ years }
        onChange={ ( e, { value } ) => handleYearChange( value ) }
      />
      <span style={{ margin: '0 20px 0' }}>Type</span>
      <Dropdown
        value={ planType }
        selection
        options={ planTypes }
        onChange={ ( e, { value } ) => handlePlanTypeChange( value ) }
      />
      {/*<Button
        animated="fade"
        primary
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => getPlans() }
      >
        <Button.Content visible>
          <Icon name="search" />
        </Button.Content>
        <Button.Content hidden> Search </Button.Content>
      </Button>*/}
      <Button
        animated="fade"
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => resetFilter( getPlans ) }
      >
        <Button.Content visible>
          <Icon name="undo" />
        </Button.Content>
        <Button.Content hidden> Reset </Button.Content>
      </Button>
      <Button
        animated="fade"
        primary
        floated="right"
        onClick={ showPlansModal }
        style={{ width: '150px', marginRight: '10px' }}
      >
        <Button.Content visible>
          <Icon name="add" />
        </Button.Content>
        <Button.Content hidden>New Plan</Button.Content>
      </Button>
    </Segment>
  );
};

export default PlansFilter;
