import React from 'react';
import { Confirm } from 'semantic-ui-react';

// @props: name, type, open, closeModal, onClick

const ResetConfirmModal = props => {
	return (
		<Confirm
			className="reset-confirm-modal"
			open={ props.open }
			size="tiny"
			header={ 'Attention' }
			content={
				<div className="content" style={{ fontSize: '16px' }}>
          All values will be reset to the minimum contribution. Are you sure you would like to continue?
				</div>
			}
			cancelButton="Cancel"
			confirmButton="Continue"
			onCancel={ props.closeModal }
			onConfirm={ () => {
				props.onClick();
				props.closeModal();
			}}
		/>
	);
};

export default ResetConfirmModal;
