import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { useForm } from '../../../Hooks/useForm';
import { floatKeyDown } from '../../../Services/inputMasks';

const EmployeePlanModal = ( { onAdd, onEdit, employeeToEdit, open, closeModal } ) => {
	// form handler
	const { formData, updateField, setData } = useForm( {
		id: 0,
		active: true,
		...employeeToEdit
	} );

	useEffect( () => {
		setData( {
			id: 0,
			active: true,
			...employeeToEdit
		} );
	}, [ employeeToEdit, setData ] );

	// state
	const [ validator, setValidator ] = useState( {
		name: 'string',
		eE_Amount: 0,
		eS_Amount: 0,
		eC_Amount: 0,
		eF_Amount: 0
	} );

	// prepare data for api call (create)
	const handleSave = () => {
		if ( Object.keys( employeeToEdit ).length ) {
			onEdit( formData );
		} else {
			onAdd( formData );
		}

		closeModal();
	};

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		console.log( 'clear' );
		setData( {
			id: 0,
			active: true,
			...employeeToEdit
		} );
		setValidator( {
			name: 'string',
			eE_Amount: 0,
			eS_Amount: 0,
			eC_Amount: 0,
			eF_Amount: 0
		} );
	};

	const onClose = () => {
		clearData();
		closeModal();
	};

	return (
		<>
			<Modal
				id="medical-plans-modal"
				open={ open }
				onClose={ onClose }
				closeOnDimmerClick={ false }
			>
				<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{ 'Add Employee' }
				</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Group>
							<Form.Input
								required
								name="name"
								value={ formData.name || '' }
								label="Employee Name"
								width={ 16 }
								maxLength={ 125 }
								onChange={ updateField }
								error={ validator.name.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, name: formData.name || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, name: 'string' } );
								}}
							/>
						</Form.Group>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={ validator.name.length > 0 }
						>
							<Message.List>
								<Message.Item
									hidden={ validator.name.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a employee name.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Group>
							<Form.Input
								required
								type="number"
								name="eE_Amount"
								value={ parseFloat( formData.eE_Amount ) >= 0 ? formData.eE_Amount : '' }
								step={ 0.01 }
								icon="dollar sign"
								iconPosition="left"
								label="EE Cost"
								width={ 4 }
								min={ 0 }
								disabled={ formData.eS_Amount > 0 || formData.eF_Amount > 0 || formData.eC_Amount > 0 }
								error={ !( parseFloat( validator.eE_Amount ) >= 0
									|| parseFloat( validator.eS_Amount ) >= 0
									|| parseFloat( validator.eC_Amount ) >= 0
									|| parseFloat( validator.eF_Amount ) >= 0 )
								}
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
									setValidator( {
										...validator,
										eE_Amount: parseFloat( formData.eE_Amount ) >= 0 ? parseFloat( formData.eE_Amount ) : '',
										eS_Amount: parseFloat( formData.eS_Amount ) >= 0 ? parseFloat( formData.eS_Amount ) : '',
										eC_Amount: parseFloat( formData.eC_Amount ) >= 0 ? parseFloat( formData.eC_Amount ) : '',
										eF_Amount: parseFloat( formData.eF_Amount ) >= 0 ? parseFloat( formData.eF_Amount ) : ''
									} );
								}}
								onFocus={ () => {
									setValidator( {
										...validator,
										eE_Amount: 0,
										eS_Amount: 0,
										eC_Amount: 0,
										eF_Amount: 0
									} );
								}}
								onKeyDown={ floatKeyDown }
							/>
							<Form.Input
								required
								type="number"
								name="eS_Amount"
								value={ parseFloat( formData.eS_Amount ) >= 0 ? formData.eS_Amount : '' }
								step={ 0.01 }
								icon="dollar sign"
								iconPosition="left"
								label="ES Cost"
								width={ 4 }
								min={ 0 }
								disabled={ formData.eE_Amount > 0 || formData.eF_Amount > 0 || formData.eC_Amount > 0 }
								error={ !( parseFloat( validator.eE_Amount ) >= 0
									|| parseFloat( validator.eS_Amount ) >= 0
									|| parseFloat( validator.eC_Amount ) >= 0
									|| parseFloat( validator.eF_Amount ) >= 0 )
								}
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
									setValidator( {
										...validator,
										eE_Amount: parseFloat( formData.eE_Amount ) >= 0 ? parseFloat( formData.eE_Amount ) : '',
										eS_Amount: parseFloat( formData.eS_Amount ) >= 0 ? parseFloat( formData.eS_Amount ) : '',
										eC_Amount: parseFloat( formData.eC_Amount ) >= 0 ? parseFloat( formData.eC_Amount ) : '',
										eF_Amount: parseFloat( formData.eF_Amount ) >= 0 ? parseFloat( formData.eF_Amount ) : ''
									} );
								}}
								onFocus={ () => {
									setValidator( {
										...validator,
										eE_Amount: 0,
										eS_Amount: 0,
										eC_Amount: 0,
										eF_Amount: 0
									} );
								}}
								onKeyDown={ floatKeyDown }
							/>
							<Form.Input
								required
								type="number"
								name="eC_Amount"
								value={ parseFloat( formData.eC_Amount ) >= 0 ? formData.eC_Amount : '' }
								step={ 0.01 }
								icon="dollar sign"
								iconPosition="left"
								label="EC Cost"
								width={ 4 }
								min={ 0 }
								disabled={ formData.eS_Amount > 0 || formData.eF_Amount > 0 || formData.eE_Amount > 0 }
								error={ !( parseFloat( validator.eE_Amount ) >= 0
									|| parseFloat( validator.eS_Amount ) >= 0
									|| parseFloat( validator.eC_Amount ) >= 0
									|| parseFloat( validator.eF_Amount ) >= 0 )
								}
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
									setValidator( {
										...validator,
										eE_Amount: parseFloat( formData.eE_Amount ) >= 0 ? parseFloat( formData.eE_Amount ) : '',
										eS_Amount: parseFloat( formData.eS_Amount ) >= 0 ? parseFloat( formData.eS_Amount ) : '',
										eC_Amount: parseFloat( formData.eC_Amount ) >= 0 ? parseFloat( formData.eC_Amount ) : '',
										eF_Amount: parseFloat( formData.eF_Amount ) >= 0 ? parseFloat( formData.eF_Amount ) : ''
									} );
								}}
								onFocus={ () => {
									setValidator( {
										...validator,
										eE_Amount: 0,
										eS_Amount: 0,
										eC_Amount: 0,
										eF_Amount: 0
									} );
								}}
								onKeyDown={ floatKeyDown }
							/>

							<Form.Input
								required
								type="number"
								name="eF_Amount"
								value={ parseFloat( formData.eF_Amount ) >= 0 ? formData.eF_Amount : '' }
								step={ 0.01 }
								icon="dollar sign"
								iconPosition="left"
								label="EF Cost"
								width={ 4 }
								min={ 0 }
								disabled={ formData.eS_Amount > 0 || formData.eE_Amount > 0 || formData.eC_Amount > 0 }
								error={ !( parseFloat( validator.eE_Amount ) >= 0
									|| parseFloat( validator.eS_Amount ) >= 0
									|| parseFloat( validator.eC_Amount ) >= 0
									|| parseFloat( validator.eF_Amount ) >= 0 )
								}
								onChange={ updateField }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
									setValidator( {
										...validator,
										eE_Amount: parseFloat( formData.eE_Amount ) >= 0 ? parseFloat( formData.eE_Amount ) : '',
										eS_Amount: parseFloat( formData.eS_Amount ) >= 0 ? parseFloat( formData.eS_Amount ) : '',
										eC_Amount: parseFloat( formData.eC_Amount ) >= 0 ? parseFloat( formData.eC_Amount ) : '',
										eF_Amount: parseFloat( formData.eF_Amount ) >= 0 ? parseFloat( formData.eF_Amount ) : ''
									} );
								}}
								onFocus={ () => {
									setValidator( {
										...validator,
										eE_Amount: 0,
										eS_Amount: 0,
										eC_Amount: 0,
										eF_Amount: 0
									} );
								}}
								onKeyDown={ floatKeyDown }
							/>
						</Form.Group>
						<Message
							hidden={
								parseFloat( validator.eE_Amount ) >= 0
									|| parseFloat( validator.eS_Amount ) >= 0
									|| parseFloat( validator.eC_Amount ) >= 0
									|| parseFloat( validator.eF_Amount ) >= 0
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
									Please enter a value for EE, ES, EC, or EF Cost.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button
						primary
						content="Save"
						onClick={ () => {
							handleSave();
							clearData();
							closeModal();
						}}
						disabled={
							( formData.name ? formData.name.length === 0 || formData.name.length > 125 : true )
              || !( parseFloat( formData.eE_Amount ) >= 0
									|| parseFloat( formData.eS_Amount ) >= 0
									|| parseFloat( formData.eC_Amount ) >= 0
									|| parseFloat( formData.eF_Amount ) >= 0
              )
						}
					>
					</Button>
					<Button
						style={{ backgroundColor: '#F58229', color: '#fff' }}
						content="Cancel"
						onClick={ () => {
							clearData();
							closeModal();
						}}
					>
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
};

export default EmployeePlanModal;
