import React from 'react';
import { Form } from 'semantic-ui-react';
import { floatKeyDown } from '../../Services/inputMasks';

const PercentageInput = ( {
	required = false,
	name,
	label,
	value,
	onChange,
	width = null,
	error = false,
	onBlur = () => {},
	...props
} ) => {
	return (
		<Form.Input
			required={ required }
			type="number"
			min={ 0 }
			icon="percent"
			width={ width }
			name={ name }
			label={ label }
			value={ parseFloat( value ) >= 0 ? value : '' }
			onChange={ onChange }
			error={ error }
			onBlur={ () => {
				onChange( { target: { name, value: parseFloat( value ).toString() } } );
				onBlur();
			}}
			onKeyDown={ floatKeyDown }
			{ ...props }
		/>
	);
};

export default PercentageInput;
