import React, { forwardRef } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const Table = forwardRef( ( props, ref ) => {
	return (
		<div>
			<ReactTable
				data={ props.data }
				loading={ props.loading || false }
				defaultPageSize={ props.pageSize || 10 }
				columns={ props.columns }
				showPagination={ !props.hidePagination }
				{ ...props }
				style={{ ...props.style, textAlign: 'center' }}
				ref={ ref }
			/>
		</div>
	);
} );

Table.displayName = 'Table';

export default Table;
