/* eslint-disable camelcase */
import React, { memo, useEffect, useRef } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import usePlanValues from '../../../../Hooks/usePlanValues';

const gridStyles = { marginBottom: '2rem' };

const PremiumOnlyPlanTable = ( { id, minimum, multiplier, omitMedical, plan, planName, update } ) => {
	const changeCounter = useRef( 0 );

	// form handler
	const {
		hasChanged,
		lumpSumHSA,
		planValues,
		formData,
		frequency,
		setFrequency,
		setHasChanged,
		setMinimum
	} = usePlanValues( plan, false, false, false );

	const formTopStyles = { paddingBottom: 0 };

	useEffect( () => {
		if ( minimum > 0 ) {
			setMinimum();
		}
	}, [ minimum, setMinimum ] );

	useEffect( () => {
		setFrequency( multiplier );
	}, [ multiplier, setFrequency ] );

	useEffect( () => {
		if ( hasChanged > changeCounter.current ) {
			const updatedValues = Object.entries( planValues ).reduce( ( acc, [ k, v ] ) => {
				const value = k.includes( '_HSA' )
					? v / frequency.hsa
					: v / frequency.premium;

				return { ...acc, [ k ]: value };
			}, {} ); // will return a planValues object with the multiplier applied to each value

			update( id, { ...updatedValues, isAnnual_HSA: lumpSumHSA } );
			changeCounter.current = hasChanged;
		}
	}, [ id, hasChanged, planValues, update, frequency, setHasChanged, lumpSumHSA ] );

	useEffect( () => {
		if ( hasChanged > changeCounter.current ) {
			const updatedValues = Object.entries( planValues ).reduce( ( acc, [ k, v ] ) => {
				return { ...acc, [ k ]: v };
			}, {} ); // will return a planValues object with the multiplier applied to each value */

			update( id, { ...updatedValues } );
			changeCounter.current = hasChanged;
		}
	}, [ id, hasChanged, planValues, update, setHasChanged ] );

	return (
		<>
			<Grid style={ gridStyles }>
				{/* headers */}
				<Grid.Row>
					<Grid.Column width={ 2 }><strong>{ omitMedical ? null : planName }</strong></Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EE</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>ES</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EC</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>FAM</Grid.Column>
				</Grid.Row>
				{/* premium */}
				<Grid.Row style={ formTopStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }>Premium</Grid.Column>
					<Grid.Column width={ 3 }>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eE_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eS_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eC_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eF_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

export default memo( PremiumOnlyPlanTable );
