import { useCallback, useEffect, useRef, useReducer, useState } from 'react';

const UPDATE = 'UPDATE';
const SET = 'SET';

const formReducer = ( state, action ) => {
	switch ( action.type ) {
		case UPDATE:
			return { ...state, [ action.payload.name ]: action.payload.value };
		case SET:
			return {}.toString.call( action.payload ) === '[object Function]' ? action.payload( state ) : action.payload;
		default:
			return state;
	}
};

export const useForm = ( initialState = {} ) => {
	const [ state, dispatch ] = useReducer( formReducer, initialState );
	const [ hasChanged, setHasChanged ] = useState( false );

	// a reference to the initialState
	let initialData = useRef( initialState );

	// set initialData to state and set hasChanged to false
	const reinitializeData = useCallback( () => {
		initialData.current = state;
		setHasChanged( false );
	}, [ state ] );

	// set or overwrite the entire state object at once, reinitialize data unless false is passed as the second argument
	const setData = useCallback( ( data, reinitialize = true ) => {
		if ( reinitialize ) {
			initialData.current = data;
		}

		if ( data ) {
			dispatch( {
				type: SET,
				payload: data
			} );
		}
	}, [] );

	// set state to intialData
	const resetData = useCallback( () => {
		dispatch( {
			type: SET,
			payload: initialData.current
		} );
	}, [] );

	const compareData = useCallback( data => {
		// make sure the data is in the same order as the initialData
		const orderedData = {};

		for ( let key in initialData.current ) {
			orderedData[ key ] = data[ key ];
		}

		const areEqual = Object.keys( data ).length === Object.keys( initialData.current ).length ? JSON.stringify( orderedData ) === JSON.stringify( initialData.current ) : false;

		if ( areEqual ) {
			setHasChanged( false );
		} else {
			setHasChanged( true );
		}
	}, [] );

	useEffect( () => {
		compareData( state );
	}, [ compareData, state ] );

	// update a field in state
	const updateField = useCallback( ( { target } ) => {
		dispatch( {
			type: UPDATE,
			payload: { name: target.name, value: target.value }
		} );
	}, [] );

	return {
		formData: state,
		hasChanged,
		initialData,
		updateField,
		setData,
		reinitializeData,
		resetData
	};
};
