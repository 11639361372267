/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import PayCyclesModal from './PayCyclesModal';
import { maskCurrency } from '../../../Services/inputMasks';

const PayCyclesTable = () => {
	const { payCycles, deletePayCycle } = useContext( CustomersContext );

	const [ record, setRecord ] = useState( '' );
	const [ payCyclesModal, setPayCyclesModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );

	const data = payCycles;

	const columns = [
		{
			Header: 'Description',
			accessor: 'description',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Cycles Per Year',
			accessor: 'cycleCount',
			filterable: false,
			sortable: true
		},
		{
			Header: 'F / T',
			accessor: 'ftCount',
			filterable: false,
			sortable: true
		},
		{
			Header: 'P / T',
			accessor: 'ptCount',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Wages Per Month',
			accessor: 'wages',
			filterable: false,
			sortable: true,
			Cell: row => <div>{ maskCurrency( row.original.wages ) }</div>
		},
		{
			Header: 'Edit / Remove',
			accessor: 'id',
			sortable: false,
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						rfpid={ row.original.id }
						onClick={ () => {
							const thisRecord = data.find( record => record.id === row.original.id );

							setRecord( thisRecord );
							setPayCyclesModal( true );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.description );
							setDeleteId( row.original.id );
							setDeleteConfirmModal( true );
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<>
			<Segment basic clearing>
				<Header as="span" style={{ lineHeight: '36px', fontSize: '2rem', fontWeight: 'normal' }}>
        Pay Cycles
				</Header>
				<Button
					content="Add Record"
					floated="right"
					onClick={ () => setPayCyclesModal( true ) }
				/>
			</Segment>
			{ Object.keys( data ).length > 0
				? <Table
					key={ data.length }
					data={ data }
					columns={ columns }
					pageSize={ data.length > 1 ? data.length : 1 }
					hidePagination
					style={{ marginBottom: '2rem' }}
				/>
				: <p style={{ padding: '0 0 1rem 1rem' }}>No records found.</p>
			}
			<PayCyclesModal
				record={ record }
				setRecord={ setRecord }
				payCyclesModal={ payCyclesModal }
				setPayCyclesModal={ setPayCyclesModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Record"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deletePayCycle( deleteId ) }
			/>
		</>
	);
};

export default PayCyclesTable;
