import React, { useContext, useState } from 'react';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import WorkersCompModal from './WorkersCompModal';
import { maskCurrency } from '../../../Services/inputMasks';
import BaseRatePopup from './BaseRatePopup';
import VariableCodePopup from './VariableCodePopup';

const WorkersCompTable = ( { riskRate }) => {

  // context
  const { workersComp, deleteWorkersComp, mappedWCRates } = useContext( CustomersContext );

  // state
  const [ record, setRecord ] = useState( '' );
  const [ workersCompModal, setWorkersCompModal ] = useState( false );
  const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
  const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
  const [ deleteId, setDeleteId ] = useState( '' );

  const data = workersComp;

  const columns = [
    {
      Header: 'State',
      accessor: 'state',
      filterable: false,
      sortable: true,
    },
    {
      Header: '# of Employees',
      accessor: 'employeeCount',
      filterable: false,
      sortable: true
    },
    {
      Header: 'WC Code',
      accessor: 'code',
      filterable: false,
      sortable: true,
      Cell:
      row => {
        return (
          row.original.code.startsWith( 'V' )
            ? <div>
              { row.original.code.replace( 'V', '' ) }&nbsp;
              <VariableCodePopup/>
            </div>
            : <span>{ row.original.code }</span>
        );
      },
    },
    {
      Header: 'Current WC Rate',
      accessor: 'rate',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            row.original.rate
              ? <div>{ row.original.rate }%</div>
              : null
          );
        },
    },
    {
      Header: 'Proposed WC Rate',
      accessor: 'proposedRate',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            row.original.proposedRate
              && !mappedWCRates.filter( opt => opt.text.startsWith( row.original.state ) ).length
                ? <span>{ row.original.proposedRate }%&nbsp;</span>
                : <div>
                  { ( row.original.proposedRate * riskRate ).toFixed( 2 ) }%&nbsp;
                  <BaseRatePopup baseRate={ row.original.proposedRate } riskRate={ riskRate } />
                </div>
          );
        },
    },
    {
      Header: <div>Current WC Cost</div>,
      accessor: 'currentCost',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            <div>{ maskCurrency( row.original.currentCost ) }</div>
          );
        }
    },
    {
      Header: <div>Proposed WC Cost</div>,
      accessor: 'proposedCost',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            <div>{ maskCurrency( row.original.proposedCost ) }</div>
          );
        }
    },
    {
      Header: 'Monthly Wages',
      accessor: 'monthlyWages',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            <div>{ maskCurrency( row.original.monthlyWages ) }</div>
          );
        }
    },
    {
      Header: 'Annual Wages',
      accessor: 'annualWages',
      filterable: false,
      sortable: true,
      Cell:
        row => {
          return (
            <div>{ maskCurrency( row.original.annualWages ) }</div>
          );
        }
    },
    {
      Header: 'Edit / Remove',
      accessor: 'id',
      sortable: false,
      Cell: row => (
        <div>
          <Button
            icon
            primary
            size="tiny"
            rfpid={ row.original.id }
            onClick={ () => {
              const thisRecord = data.find( record => record.id === row.original.id );
              setRecord( thisRecord );
              setWorkersCompModal( true );
            }}
          >
            <Icon name="pencil" />
          </Button>
          <Button
            icon
            negative
            size="tiny"
            onClick={ () => {
              setDeleteConfirmModalNameProp( row.original.state );
              setDeleteId( row.original.id );
              setDeleteConfirmModal( true );
            }}
          >
            <Icon name="trash" />
          </Button>
        </div>
      )
    },
  ];

  return (
    <>
      <Segment basic clearing>
        <Header as="span" style={{ lineHeight: '36px', fontSize: '2rem', fontWeight: 'normal' }}>
        Workers Compensation
        </Header>
        <Button
          content="Add Record"
          floated="right"
          onClick={ () => setWorkersCompModal( true ) }
        />
      </Segment>
      { Object.keys( data ).length > 0
        ? <Table
            key={ data.length }
            data={ data }
            columns={ columns }
            pageSize={ data.length }
            hidePagination
            style={{ marginBottom: '2rem' }}
          />
        : <p style={{ padding: '0 0 1rem 1rem' }}>No records found.</p>
      }
      <WorkersCompModal
        record={ record }
        setRecord={ setRecord }
        workersCompModal={ workersCompModal }
        setWorkersCompModal={ setWorkersCompModal }
      />
      <DeleteConfirmModal
        name={ deleteConfirmModalNameProp }
        type="Record"
        open={ deleteConfirmModal }
        closeModal={ () => setDeleteConfirmModal( false ) }
        onClick={ () => deleteWorkersComp( deleteId ) }
      />
    </>
  );
};

export default WorkersCompTable;
