import React, { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

const AdminRatesNotesModal = ( { title, name, value, onChange, onSave } ) => {
	const [ modalOpen, setModalOpen ] = useState( false );

	return (
		<Modal
			open={ modalOpen }
			size="mini"
			trigger={
				<Form.Button
					onClick={ () => setModalOpen( true )}
					style={{ marginTop: 0 }}
					icon
				>
					<Icon name="edit" />
				</Form.Button>
			}
		>
			<Modal.Header>
				{ title }
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.TextArea
						name={ name }
						value={ value }
						onChange={ onChange }
					/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save and Close"
					onClick={ () => {
						onSave();
						setModalOpen( false );
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default AdminRatesNotesModal;
