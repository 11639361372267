import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Card, Divider, Form, Header, Icon, Message, Modal, Segment } from 'semantic-ui-react';
import { AdminContext } from './AdminContext';
import { useForm } from '../../Hooks/useForm';
import { maskPhone, unmaskPhone, validatePhone, validateEmail, integerKeyDown } from '../../Services/inputMasks';

const AdminModal = () => {
	// context
	const {
		user,
		createUser,
		updateUser,
		adminModal,
		hideAdminModal
	} = useContext( AdminContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ role, setRole ] = useState( '' );
	const [ editPass, setEditPass ] = useState( true );
	const [ validator, setValidator ] = useState( {
		firstName: 'string',
		lastName: 'string',
		email: 'string@string.com',
		phone: '1111111111',
		roleId: 1,
		password: 'string',
		confirm: 'string'
	} );
	const [ passwordError, setPasswordError ] = useState( false );

	// Set formData to operation data
	useEffect( () => {
		if ( user ) {
			const userData = {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phone: user.phone,
				isSalesAgent: user.isSalesAgent,
				roleId: user.role.id,
				password: '',
				confirm: ''
			};

			setData( userData );
			setValidator( userData );
			setEditPass( false );
		}

		return () => {
			setData( {} );
		};
	}, [ user, setData ] );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {
			firstName: 'string',
			lastName: 'string',
			email: 'string@string.com',
			phone: '1111111111',
			roleId: 1,
			password: 'string',
			confirm: 'string'
		} );
		setEditPass( true );
	};

	// update role in header dynamically
	useEffect( () => {
		setRole( parseInt( formData.roleId ) === 1
			? 'Admin'
			: parseInt( formData.roleId ) === 2
				? 'Manager'
				: parseInt( formData.roleId ) === 3
					? 'User'

					/* : parseInt( formData.roleId ) === 4
					     ? 'Read Only' */
					: '' );
	}, [ formData ] );

	return (
		<Modal
			open={ adminModal }
			size="small"
			className="admin-modal"
		>
			<Modal.Header>
				{ user
					? <Card style={{ width: '100%', border: 'none', boxShadow: 'none', textAlign: 'center' }}>
						<Card.Content>
							<Card.Header>
								<Header>
									<Icon name="user" style={{ display: 'block', margin: '0 auto' }}/>
									{ formData.firstName || '' } { formData.lastName || '' }
									<Header sub>{ role }</Header>
								</Header>
							</Card.Header>
						</Card.Content>
					</Card>
					: <Header>Create New User Account</Header>
				}
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Segment basic>
						<Header>User Info</Header>
					</Segment>
					<Segment basic>
						<Form.Group>
							<Form.Input
								required
								name="firstName"
								label="First Name"
								width={ 8 }
								maxLength={ 65 }
								value={ formData.firstName || '' }
								onChange={ updateField }
								error={ validator.firstName.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, firstName: formData.firstName || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, firstName: 'string' } );
								}}
							/>
							<Form.Input
								required
								name="lastName"
								label="Last Name"
								width={ 8 }
								value={ formData.lastName || '' }
								maxLength={ 65 }
								onChange={ updateField }
								error={ validator.lastName.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, lastName: formData.lastName || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, lastName: 'string' } );
								}}
							/>
						</Form.Group>
						<Message
							hidden={
								validator.firstName.length > 0
                && validator.lastName.length > 0
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.firstName.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a first name.
								</Message.Item>
								<Message.Item
									hidden={ validator.lastName.length > 0 }
								>
									<Icon name="exclamation circle" />
                  Please enter a last name.
								</Message.Item>
							</Message.List>
						</Message>
						<Form.Group>
							<Form.Input
								required
								name="email"
								label="Email Address"
								icon
								width={ 8 }
								maxLength={ 125 }
								value={ formData.email || '' }
								onChange={ updateField }
								error={ validator.email.length > 0 && !validateEmail( validator.email ) }
								onBlur={ () => {
									setValidator( { ...validator, email: formData.email || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, email: 'string@string.com' } );
								}}
							>
								<input />
								{ user && formData.email
									? <Icon name="mail" link={ true } onClick={ () => {
										window.location = `mailto:${ formData.email }`;
									} }/>
									: null
								}
							</Form.Input>
							<Form.Input
								name="phone"
								value={ maskPhone( formData.phone ) || '' }
								label="Phone"
								width={ 5 }
								maxLength={ 14 }
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value: unmaskPhone( value ) } } );
								}}
								error={ validator.phone && !validatePhone( validator.phone ) }
								onBlur={ () => {
									setValidator( { ...validator, phone: formData.phone || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, phone: '1111111111' } );
								}}
								onKeyDown={ integerKeyDown }
							/>
							<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
								<label>Sales Agent</label>
								<Form.Checkbox
									name="isSalesAgent"
									style={{ marginTop: '0.5rem' }}
									checked={ formData.isSalesAgent || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
						</Form.Group>
						<Message
							hidden={
								( validator.email.length === 0 || validateEmail( validator.email ) )
                && ( !validator.phone || validatePhone( validator.phone ) )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.email.length === 0 || validateEmail( validator.email ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid email address.
								</Message.Item>
								<Message.Item
									hidden={ !validator.phone || validatePhone( validator.phone ) }
								>
									<Icon name="exclamation circle" />
                  Please enter a valid phone number.
								</Message.Item>
							</Message.List>
						</Message>
						{/* <Form.Field required>
							<label>Role</label>
						</Form.Field> */}
						<Form.Group /* inline */>

							<Form.Select
								name="roleId"
								value={ formData.roleId || '' }
								label="Role"
								options={ [
									{ key: 0, text: 'Select an option', value: 0 },
									{ key: 1, text: 'Admin', value: 1 },

									/* { key: 2, text: 'User Read Only', value: 2 },
									   { key: 3, text: 'User', value: 3 }, */
									{ key: 4, text: 'Sales Manager', value: 4 },
									{ key: 5, text: 'Sales User', value: 5 },
									{ key: 7, text: 'Client Manager', value: 7 },
									{ key: 8, text: 'Client User', value: 8 }
								] }
								width={ 6 }
								fluid
								onChange={ ( e, { name, value } ) => {
									if ( !value ) {
										updateField( { target: { name: 'roleId', value: '' } } );
									}

									updateField( { target: { name, value } } );
								} }
							/>
							{/* <Form.Radio
								id="roleId-administrator"
								name="roleId"
								label="Administrator"
								width={ 4 }
								value={ 1 }
								checked={ parseInt( formData.roleId ) === 1 }
								onChange={ updateField }
							/>
							<br />
							<Form.Radio
								id="roleId-manager"
								name="roleId"
								label="Manager"
								width={ 4 }
								value={ 2 }
								checked={ parseInt( formData.roleId ) === 2 }
								onChange={ updateField }
							/>
							<br />
							<Form.Radio
								id="roleId-user"
								name="roleId"
								label="User"
								width={ 4 }
								value={ 3 }
								checked={ parseInt( formData.roleId ) === 3 }
								onChange={ updateField }
							/>
							<br />
              <Form.Radio
                id="roleId-readOnly"
                name="roleId"
                label="Read Only"
                width={ 4 }
                value={ 4 }
                checked={ parseInt( formData.roleId ) === 4 }
                onChange={ updateField }
              />*/}
						</Form.Group>
					</Segment>
					<Divider />
					<Accordion>
						<Accordion.Title
							active={ editPass }
							onClick={ () => user ? setEditPass( !editPass ) : null }>
							<Segment basic>
								{ user
									? <Header><Icon name="dropdown" /> Edit Password</Header>
									: <Header>Password</Header>
								}
							</Segment>
						</Accordion.Title>
						<Accordion.Content active={ editPass }>
							<Segment basic>
								<Form.Group>
									<Form.Input
										type="password"
										name="password"
										label="Password"
										width={ 8 }
										maxLength={ 65 }
										value={ formData.password || '' }
										onChange={ updateField }
										error={ !user ? validator.password.length === 0 : false }
										onBlur={ () => {
											setValidator( { ...validator, password: formData.password || '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, password: 'string' } );
										}}
									/>
									<Form.Input
										type="password"
										name="confirm"
										label="Confirm Password"
										width={ 8 }
										maxLength={ 65 }
										value={ formData.confirm || '' }
										onChange={ updateField }
										error={ !user ? validator.confirm.length === 0 : false }
										onBlur={ () => {
											setValidator( { ...validator, confirm: formData.confirm || '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, confirm: 'string' } );
										}}
									/>
								</Form.Group>
							</Segment>
						</Accordion.Content>
					</Accordion>
				</Form>
				<Modal
					size="mini"
					open={ passwordError }
					onClose={ () => setPasswordError( false ) }
				>
					<Modal.Content>
            Passwords do not match!
					</Modal.Content>
				</Modal>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						if ( formData.password ? formData.password === formData.confirm : true ) {
							user ? updateUser( formData ) : createUser( formData );
							clearData();
							hideAdminModal();
						} else {
							setPasswordError( true );
						}
					}}
					disabled={
						user
							? ( formData.firstName ? formData.firstName.length === 0 : true )
                || ( formData.lastName ? formData.lastName.length === 0 : true )
                || ( formData.email ? formData.email.length > 0 && !validateEmail( formData.email ) : false )
                || ( formData.phone ? formData.phone.length > 0 && !validatePhone( formData.phone ) : false )
                || !formData.roleId
							: ( formData.firstName ? formData.firstName.length === 0 : true )
                || ( formData.lastName ? formData.lastName.length === 0 : true )
                || ( formData.email ? formData.email.length > 0 && !validateEmail( formData.email ) : false )
                || ( formData.phone ? formData.phone.length > 0 && !validatePhone( formData.phone ) : false )
                || !formData.roleId
                || ( formData.password ? formData.password.length === 0 : true )
                || ( formData.confirm ? formData.confirm.length === 0 : true )
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideAdminModal();
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default AdminModal;
