import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Loader, Segment } from 'semantic-ui-react';
import { OperationsContext } from '../Operations/OperationsContext';
import Tiers from '../Operations/Tiers/Tiers';
import Plans from '../Operations/Plans/Plans';
import Rates from '../Operations/Rates/Rates';
import Fees from '../Operations/Fees/Fees';
import Files from '../Operations/Files/Files';
import Procedures from '../Operations/Procedures/Procedures';
import Spinner from '../Shared/Spinner';

const Operations = () => {
	// context
	const { uiLoader, loadingMessage, spinner, spinnerProgress } = useContext( OperationsContext );

	return (
		<>
			<div className="titleHeader">
				{ uiLoader
					? <span>
						<Loader active inverted inline size="tiny" style={{ marginRight: '0.5rem', marginBottom: '2px' }}/>
						{ loadingMessage }
					</span>
					: 'Operations'
				}
			</div>
			<div className="formContainer">
				<Segment basic style={{ padding: '2rem', margin: '0 auto' }}>
					<Route
						path="/main/operations"
						exact
						render={ props => <Tiers { ...props } /> }
					/>
					<Route path="/main/operations/plans" component={ Plans } />
					<Route path="/main/operations/rates" component={ Rates } />
					<Route path="/main/operations/fees" component={ Fees } />
					<Route path="/main/operations/files" component={ Files } />
					<Route path="/main/operations/procedures" component={ Procedures } />
				</Segment>
			</div>
			<Spinner active={ spinner } progress={ spinnerProgress } />
		</>
	);
};

export default Operations;
