/* eslint-disable camelcase */
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { /* Checkbox, */ Grid, Icon, Input, Message } from 'semantic-ui-react';
import usePlanValues from '../../../../Hooks/usePlanValues';
import { floatKeyDown, maskCurrency } from '../../../../Services/inputMasks';

const gridStyles = { marginBottom: '2rem' };
const formMiddleStyles = { paddingTop: '0.25rem', paddingBottom: 0 };
const formBottomStyles = { paddingTop: '0.25rem', paddingBottom: 0 };

const BenefitGroupPlanTable = ( { flatRate, id, isAnnual_HSA, minimum, multiplier, omitMedical, plan, planName, update, /* setUiDisabled, */ readonly } ) => {
	const changeCounter = useRef( 0 );

	// form handler
	const {
		hasChanged,
		// lumpSumHSA,
		planValues,
		formData,
		frequency,
		validator,
		maskValue,
		unmaskValue,
		setFrequency,
		setHasChanged,
		setLumpSumHSA,
		setMinimum,
		setValidator,
		updateValue
	} = usePlanValues( plan, false, isAnnual_HSA, flatRate );

	useEffect( () => {
		setLumpSumHSA( isAnnual_HSA );
	}, [ isAnnual_HSA, setLumpSumHSA ] );

	// useEffect( () => {
	// 	if ( planValues.eE_Employer < validator.minimumContribution
	//        || planValues.eS_Employer < validator.minimumContribution
	//        || planValues.eC_Employer < validator.minimumContribution
	//        || planValues.eF_Employer < validator.minimumContribution
	// 	) {
	// 		setUiDisabled( true );
	// 	} else {
	// 		setUiDisabled( false );
	// 	}
	// }, [ setUiDisabled, planValues, validator.minimumContribution ] );

	useEffect( () => {
		if ( minimum > 0 ) {
			setMinimum();
		}
	}, [ minimum, setMinimum ] );

	useEffect( () => {
		setFrequency( multiplier );
	}, [ multiplier, setFrequency ] );

	const handleClickSelect = useCallback( e => !readonly && e.target.select(), [ readonly ] );

	useEffect( () => {
		if ( hasChanged > changeCounter.current ) {
			const updatedValues = Object.entries( planValues ).reduce( ( acc, [ k, v ] ) => {
				const value = k.includes( '_HSA' )
					? isAnnual_HSA
						? v
						: v / frequency.hsa
					: v / frequency.premium;

				return { ...acc, [ k ]: value };
			}, {} ); // will return a planValues object with the multiplier applied to each value

			update( id, { ...updatedValues } );
			changeCounter.current = hasChanged;
		}
	}, [ id, hasChanged, planValues, update, frequency, setHasChanged, isAnnual_HSA ] );

	// useEffect( () => {
	// 	if ( hasChanged > changeCounter.current ) {
	// 		const updatedValues = Object.entries( planValues ).reduce( ( acc, [ k, v ] ) => {
	// 			return { ...acc, [ k ]: v };
	// 		}, {} ); // will return a planValues object with the multiplier applied to each value */

	// 		update( id, { ...updatedValues } );
	// 		changeCounter.current = hasChanged;
	// 	}
	// }, [ id, hasChanged, planValues, update, setHasChanged ] );

	return (
		<>
			<Grid style={ gridStyles }>
				{/* headers */}
				<Grid.Row>
					<Grid.Column width={ 2 }><strong>{ omitMedical ? null : planName }</strong></Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EE</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>ES</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EC</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>FAM</Grid.Column>
				</Grid.Row>
				{/* employer */}
				{ omitMedical
					? null
					: <Grid.Row style={ formMiddleStyles } verticalAlign="middle">
						<Grid.Column width={ 2 }>Employer</Grid.Column>
						<Grid.Column width={ 3 }>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eE_Employer"
								value={ formData.eE_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={ validator.eE_Employer < planValues.minimumContribution }
								onBlur={ !readonly
									? e => {
										maskValue( e.target.name ); // set to maskedValue onBlur
										setValidator( { ...validator, eE_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
									}
									: null
								}
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eE_Employer: planValues.minimumContribution } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"

								// label={ {
								//         basic: true,
								//         content: planValues.eS_Employer > planValues.eE_Employer ? maskCurrency( planValues.eS_Employer - planValues.eE_Employer, false ) : '0.00',
								//         style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								//       } }
								//       labelPosition="right"
								name="eS_Employer"
								value={ formData.eS_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={ validator.eS_Employer < planValues.minimumContribution }
								onBlur={ !readonly
									? e => {
										maskValue( e.target.name ); // set to maskedValue onBlur
										setValidator( { ...validator, eS_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
									}
									: null
								}
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eS_Employer: planValues.minimumContribution } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"

								// label={ {
								//         basic: true,
								//         content: planValues.eC_Employer > planValues.eE_Employer ? maskCurrency( planValues.eC_Employer - planValues.eE_Employer, false ) : '0.00',
								//         style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								//       } }
								//       labelPosition="right"
								name="eC_Employer"
								value={ formData.eC_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								error={ validator.eC_Employer < planValues.minimumContribution }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								onBlur={ !readonly
									? e => {
										maskValue( e.target.name ); // set to maskedValue onBlur
										setValidator( { ...validator, eC_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
									}
									: null
								}
								onFocus={ !readonly
									? e => {
										unmaskValue( e.target.name ); // set formData to planValue when editing
										setValidator( { ...validator, eC_Employer: planValues.minimumContribution } ); // set the validator to the minimum
									}
									: null
								}
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"

								// label={ {
								//         basic: true,
								//         content: planValues.eF_Employer > planValues.eE_Employer ? maskCurrency( planValues.eF_Employer - planValues.eE_Employer, false ) : '0.00',
								//         style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								//       } }
								//       labelPosition="right"
								name="eF_Employer"
								value={ formData.eF_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={ validator.eF_Employer < planValues.minimumContribution }
								onBlur={ !readonly
									? e => {
										maskValue( e.target.name ); // set to maskedValue onBlur
										setValidator( { ...validator, eF_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
									}
									: null
								}
								onFocus={ !readonly
									? e => {
										unmaskValue( e.target.name ); // set formData to planValue when editing
										setValidator( { ...validator, eF_Employer: planValues.minimumContribution } ); // set the validator to the minimum
									}
									: null
								}
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
					</Grid.Row>
				}

				{/* hsa */}
				<Grid.Row style={ formMiddleStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }>HSA</Grid.Column>
					<Grid.Column width={ 3 }>
						<Input
							required
							fluid
							icon="dollar"
							iconPosition="left"
							name="eE_HSA"
							value={ formData.eE_HSA }
							onClick={ handleClickSelect }
							onChange={ ( e, { name, value } ) => {
								updateValue( { name, value } );
							}}
							onBlur={ !readonly ? e => {
								maskValue( e.target.name ); // set to maskedValue onBlur
							} : null }
							onFocus={ !readonly ? e => {
								unmaskValue( e.target.name ); // set formData to planValue when editing
							} : null }
							onKeyDown={ floatKeyDown }
							readOnly={ readonly }
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							fluid
							icon="dollar"
							iconPosition="left"
							name="eS_HSA"
							value={ formData.eS_HSA }
							onClick={ handleClickSelect }
							onChange={ ( e, { name, value } ) => {
								updateValue( { name, value } );
							}}
							onBlur={ !readonly ? e => {
								maskValue( e.target.name ); // set to maskedValue onBlur
							} : null }
							onFocus={ !readonly ? e => {
								unmaskValue( e.target.name ); // set formData to planValue when editing
							} : null }
							onKeyDown={ floatKeyDown }
							readOnly={ readonly }
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							fluid
							icon="dollar"
							iconPosition="left"
							name="eC_HSA"
							value={ formData.eC_HSA }
							onClick={ handleClickSelect }
							onChange={ ( e, { name, value } ) => {
								updateValue( { name, value } );
							}}
							onBlur={ !readonly ? e => {
								maskValue( e.target.name ); // set to maskedValue onBlur
							} : null }
							onFocus={ !readonly ? e => {
								unmaskValue( e.target.name ); // set formData to planValue when editing
							} : null }
							onKeyDown={ floatKeyDown }
							readOnly={ readonly }
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							fluid
							icon="dollar"
							iconPosition="left"
							name="eF_HSA"
							value={ formData.eF_HSA }
							onClick={ handleClickSelect }
							onChange={ ( e, { name, value } ) => {
								updateValue( { name, value } );
							}}
							onBlur={ !readonly ? e => {
								maskValue( e.target.name ); // set to maskedValue onBlur
							} : null }
							onFocus={ !readonly ? e => {
								unmaskValue( e.target.name ); // set formData to planValue when editing
							} : null }
							onKeyDown={ floatKeyDown }
							readOnly={ readonly }
						/>
					</Grid.Column>
				</Grid.Row>

				{/* errors */}
				<Grid.Row style={ formBottomStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }></Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={ validator.eE_Employer >= planValues.minimumContribution }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eE_Employer >= planValues.minimumContribution }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( planValues.minimumContribution ) }.` }</small>
								</Message.Item>
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={ validator.eS_Employer >= planValues.minimumContribution }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eS_Employer >= planValues.minimumContribution }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( planValues.minimumContribution ) }.` }</small>
								</Message.Item>
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={ validator.eC_Employer >= planValues.minimumContribution }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eC_Employer >= planValues.minimumContribution }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( planValues.minimumContribution ) }.` }</small>
								</Message.Item>
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={ validator.eF_Employer >= planValues.minimumContribution }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eF_Employer >= planValues.minimumContribution }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( planValues.minimumContribution ) }.` }</small>
								</Message.Item>
							</Message.List>
						</Message>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{/* <Checkbox
				name="lumpSumHSA"
				readOnly={ readonly }
				label="Lump Sum HSA"
				checked={ lumpSumHSA }
				onChange={ ( e, { checked } ) => {
					setLumpSumHSA( checked );
				} }
				tabIndex="-1"
			/> */}
		</>
	);
};

export default memo( BenefitGroupPlanTable );
