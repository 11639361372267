import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { OperationsContext } from '../OperationsContext';
import RatesFilter from './RatesFilter';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import RatesModal from './RatesModal';
import SUTARatesModal from './SUTARatesModal';
import WCRatesModal from './WCRatesModal';
import { maskCurrency } from '../../../Services/inputMasks';

const Rates = () => {
	// context
	const {
		rates,
		rateType,
		year,
		getRates,
		deleteRate,
		showRatesModal,
		showSutaRatesModal,
		showWCRatesModal,
		deleteConfirmModal,
		toggleDeleteConfirmModal,
		tableLoader
	} = useContext( OperationsContext );

	// state
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ rateId, setRateId ] = useState( '' );
	const [ rateAsPercentage, setRateAsPercentage ] = useState( false );

	useEffect( () => {
		getRates();

		if (
			rateType === 7
			|| rateType === 48
			|| rateType === 70
			|| rateType === 71
			|| rateType === 191
		) {
			setRateAsPercentage( true );
		} else {
			setRateAsPercentage( false );
		}
	}, [ getRates, rateType, year ] );

	const columns = [
		{
			id: 'state',
			Header: <div>State</div>,
			accessor: row => `${ row.state }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.state.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{row.original.state }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'code',
			Header: <div>Code</div>,
			accessor: row => `${ row.code }`,
			filterMethod: ( filter, row ) => _.includes( row._original.code.toUpperCase(), filter.value.toUpperCase() ),
			filterable: true,
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{ row.original.code }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'rate',
			Header: <div>Rate</div>,
			accessor: row => `${ row.rate }`,
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{ rateAsPercentage ? `${ row.original.rate }%` : `${ maskCurrency( row.original.rate ) }` }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'value',
			Header: <div>Cutoff</div>,
			accessor: row => `${ row.value }`,
			sortable: true,
			Cell:
				row => {
					return (
						<div style={{ whiteSpace: 'normal' }}>{ maskCurrency( row.original.value ) }</div>
					);
				},
			style: { whiteSpace: 'unset' }
		},
		{
			accessor: 'id',
			Header: `Edit${ rateType !== 70 && rateType !== 71 ? ' / Remove' : '' }`,
			sortable: false,
			filterable: false,
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						rateid={ row.original.id }
						onClick={ ( e, { rateid } ) => {
							if ( rateType === 7 ) {
								showSutaRatesModal( rateid );
							} else if ( rateType === 48 ) {
								showWCRatesModal( rateid );
							} else {
								showRatesModal( rateid );
							}
						}}
					>
						<Icon name="pencil" />
					</Button>
					{ rateType !== 70 && rateType !== 71
						? <Button
							icon
							negative
							size="tiny"
							onClick={ () => {
								setDeleteConfirmModalNameProp( row.original.state );
								setRateId( row.original.id );
								toggleDeleteConfirmModal();
							}}
						>
							<Icon name="trash" />
						</Button>
						: null
					}
				</div>
			)
		}
	];

	return (
		<div>
			<Header as="h2" style={{ fontWeight: 'normal' }}>Rate Administration</Header>
			<Segment>
				<RatesFilter />
				<Table
					data={ rates }
					columns={ rateType === 7
						? [ columns[ 0 ], columns[ 2 ], columns[ 3 ], columns[ 4 ] ]
						: rateType === 48
							? [ columns[ 0 ], columns[ 1 ], columns[ 2 ], columns[ 4 ] ]
							: [ columns[ 2 ], columns[ 4 ] ]
					}
					loading={ tableLoader }
					// pageSize={ rateType === 70 || rateType === 71 ? 1 : null  }
				/>
				<br />
				<p>Records: { rates.length }</p>
			</Segment>
			<RatesModal percentage={ rateAsPercentage } />
			<SUTARatesModal />
			<WCRatesModal />
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Rate"
				open={ deleteConfirmModal }
				closeModal={ () => toggleDeleteConfirmModal() }
				onClick={ () => deleteRate( rateId ) }
			/>
		</div>
	);
};

export default Rates;
