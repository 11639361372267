import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { withCookies } from 'react-cookie';
import { List, Segment } from 'semantic-ui-react';
import { Settings } from '../Global/config';

export const CustomersContext = React.createContext();

class CustomersProvider extends React.PureComponent {
	constructor( props ) {
		super( props );
		const { cookies, authedUser } = props;

		this.state = {
			accessToken: cookies.get( 'token' ),
			clientBenefit: null,
			clientBenefitReadonly: authedUser.role.id === 4 || authedUser.role.id === 5 || authedUser.role.id === 8,
			benefitGroup: null,
			noteTypes: [],
			aleStatuses: [],
			eligibilityPeriods: [],
			rehirePeriods: [],
			pfFtPeriods: [],
			subsidiaryGroups: [],
			shortTerm: [],
			longTerm: [],
			employerPaid: [],
			// document upload
			document: null,
			documentTypeID: 0,
			documents: [],
			docUploadProgress: null,
			documentUploadModal: false,
			// loaders
			spinner: false,
			uiLoader: false,
			loadingMessage: '',
			tableLoader: false,
			employeeBenefitsDocSpinner: false,
			// lookups
			salesAgents: [],
			sources: [],
			leadStatuses: [],
			rfpStatuses: [],
			prospectStatuses: [],
			companyTypes: [],
			archiveReasons: [],
			marketingTypes: [],
			payrollCycles: [],
			medicalTiers: [],
			clientMedicalTiers: [],
			retirementPlans: [],
			proposalTypes: [],
			retirementPlanTypes: [],
			retirementPlanSubTypes: [],
			enrollmentTypes: [],
			minAges: [],
			vestingTypes: [],
			serviceTypes: [],
			planMergerTypes: [],
			adoptionAgreementTypes: [],
			renewalInstrucionTypes: [],
			years: [],
			// data
			customer: null,
			filteredCustomers: [],
			contacts: [],
			locations: [],
			payCycles: [],
			witholdings: [],
			workersComp: [],
			WCRates: [],
			sutaStates: [],
			mappedWCRates: [],
			adminRates: [],
			wsPlans: [],
			mappedWsPlans: [],
			medicalPlans: [],
			proposals: [],
			proposal: null,
			proposalError: null,
			comparison: null,
			comparisonError: null,
			rfpPremiumSheet: null,
			rfpPremiumError: null,
			lastSync: '',
			leads: [],
			rfpList: [],
			prospects: [],
			archived: [],
			clients: [],
			rfpYear: '',
			renewalInstructions: null,
			// modals
			leadsModal: false,
			rfpModal: false,
			prospectsModal: false,
			clientsModal: false,
			archiveConfirmModal: false,
			deleteConfirmModal: false,
			termInactiveConfirmModal: false,
			unarchiveConfirmModal: false,
			notesModal: false,
			// filters
			agentId: authedUser.role.id === 1 || authedUser.role.id === 7 || authedUser.role.id === 4 && !authedUser.isSalesAgent
				? 0
				: authedUser.id,
			startDate: moment()
				.subtract( 60, 'days' )
				.startOf( 'day' )
				.toDate(),
			endDate: moment()
				.endOf( 'day' )
				.toDate()
		};
	}

	componentDidMount() {
		this.lookupNoteTypes();
		this.lookupAleStatuses();
		this.lookupEligibilityPeriods();
		this.lookupRehirePeriods();
		this.lookupPtFtPeriods();
		this.lookupShortTerm();
		this.lookupLongTerm();
		this.lookupEmployerPaid();
		this.lookupSalesAgents();
		this.lookupSources();
		this.lookupLeadStatuses();
		this.lookupRfpStatuses();
		this.lookupProspectStatuses();
		this.lookupCompanyTypes();
		this.lookupArchiveReasons();
		this.lookupMarketingTypes();
		this.lookupPayrollCycles();
		this.lookupRetirementPlans();
		this.lookupProposalTypes();
		this.lookupRetirementPlanTypes();
		this.lookupEnrollmentTypes();
		this.lookupMinAges();
		this.lookupVestingTypes();
		this.lookupServiceTypes();
		this.lookupPlanMergerTypes();
		this.lookupAdoptionAgreementTypes();
		this.lookupRenewalInstructionTypes();
		this.setYears();
	}

	async componentDidUpdate( prevProps, prevState ) {
		if ( this.state.customer ) {
			if (
				this.state.customer.customerStage.id === 4
				&& prevState.customer?.operatingYear !== this.state.customer.operatingYear
			) {
				const clientMedicalTiers = await this.lookupMedicalTiers( this.state.customer.operatingYear || new Date().getFullYear() );

				this.setState( { clientMedicalTiers } );
			} else if ( prevState.rfpYear !== this.state.rfpYear ) {
				const medicalTiers = await this.lookupMedicalTiers( this.state.rfpYear || new Date().getFullYear() );

				this.setState( { medicalTiers } );

				this.getWCRates( this.state.rfpYear || new Date().getFullYear() );
				this.getSutaStates( this.state.rfpYear || new Date().getFullYear() );
			}
		}
	}

  updateArray = ( array, obj ) => array.map( item => item.id !== obj.id ? item : obj );

  // document upload

	uploadDocument = async ( customerId, typeId, data ) => {
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/document/upload`,
			params: { typeId },
			data,
			onUploadProgress: progress => {
				var percentCompleted = Math.round( progress.loaded * 100 / progress.total );

				this.setState( { docUploadProgress: percentCompleted } );
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'multipart/form-data',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { docUploadProgress: null } );
		this.setState( prevState => {
			let documents = { ...prevState.documents };

			switch ( response.data.typeID ) {
				case 37:
				case 68:
				case 171:
					documents.taxInfo = [ ...documents.taxInfo, response.data ];
					break;
				case 38:
				case 39:
					documents.medical = [ ...documents.medical, response.data ];
					break;
				case 40:
				case 41:
					documents.dental = [ ...documents.dental, response.data ];
					break;
				case 42:
					documents.life = [ ...documents.life, response.data ];
					break;
				case 43:
				case 44:
					documents.disability = [ ...documents.disability, response.data ];
					break;
				case 46:
				case 47:
					documents.vision = [ ...documents.vision, response.data ];
					break;
				default:
					break;
			}

			return { documents };
		} );
	}

	deleteDocument = async id => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/document/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( () => {
			let documents = Object.entries( this.state.documents ).reduce( ( acc, [ key, value ] ) => {
				const newDocs = value.filter( doc => doc.id !== id );

				return { ...acc, [ key ]: newDocs };
			}, {} );

			return { documents };
		} );
	}

	mapDocuments = arr => {
		return arr.map( ( document, i ) => {
			return (
				<List.Item
					as={ Segment }
					style={{ cursor: 'pointer', padding: '0', margin: '0 1rem 0 0' }}
					key={ i }
				>
					<Segment style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
						<List.Icon
							name="file"
							size="big"
							verticalAlign="middle"
							style={{ marginRight: '0.5rem' }}
						/>
						<List.Content>
							<List.Description>{ document.name }</List.Description>
							<a href={ `${ document.url }/${ document.name }` } target="_blank" rel="noopener noreferrer"><small>view</small></a>&nbsp;<small>|</small>&nbsp;<a href="#" onClick={ () => this.deleteDocument( document.id ) }><small>delete</small></a>
						</List.Content>
					</Segment>
				</List.Item>
			);
		} );
	}

	showDocumentUploadModal = ( document, typeID ) => {
		this.setState( {
			documentUploadModal: true,
			document: document ? document : null,
			documentTypeID: typeID
		} );
	}

	hideDocumentUploadModal = () => {
		this.setState( {
			documentUploadModal: false,
			document: ''
		} );
	}

	// lookups

	mapLookup = ( data, text = 'name' ) => {
		return data.map( option => {
			return {
				key: option.id,
				text: option[ text ],
				value: option.id
			};
		} );
	}

	lookupNoteTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 27 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const noteTypes = response.data[ 0 ].types;

		this.setState( { noteTypes } );
	}

	lookupAleStatuses = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 26 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const aleStatuses = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { aleStatuses } );
	}

	lookupEligibilityPeriods = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 20 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const eligibilityPeriods = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { eligibilityPeriods } );
	}

	lookupRehirePeriods = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 21 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const rehirePeriods = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { rehirePeriods } );
	}

	lookupPtFtPeriods = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 22 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const ptFtPeriods = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { ptFtPeriods } );
	}

	lookupSubsidiaryGroups = async year => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/subsidiarygroups',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { year }
		} );
		const subsidiaryGroups = await this.mapLookup( response.data, 'description' );

		this.setState( { subsidiaryGroups } );
	}

	lookupShortTerm = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 23 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const shortTerm = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { shortTerm } );
	}

	lookupLongTerm = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 24 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const longTerm = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { longTerm } );
	}

	lookupEmployerPaid = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 25 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const employerPaid = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { employerPaid } );
	}

	lookupSalesAgents = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/salesagents',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const salesAgents = await this.mapLookup( response.data, 'description' );

		this.setState( { salesAgents } );
	}

	lookupSources = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 5 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const sources = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { sources } );
	}

	lookupLeadStatuses = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/children',
			params: { parent: 1 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const leadStatuses = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { leadStatuses } );
	}

	lookupRfpStatuses = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/children',
			params: { parent: 2 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const rfpStatuses = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { rfpStatuses } );
	}

	lookupProspectStatuses = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/children',
			params: { parent: 3 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const prospectStatuses = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { prospectStatuses } );
	}

	lookupCompanyTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 8 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const companyTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { companyTypes } );
	}

	lookupArchiveReasons = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 35 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const archiveReasons = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { archiveReasons } );
	}

	lookupMarketingTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 9 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const marketingTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { marketingTypes } );
	}

	lookupPayrollCycles = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 13 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const payrollCycles = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { payrollCycles } );
	}

	lookupMedicalTiers = async year => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/tiers',
			params: {
				typeId: 5,
				year
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const medicalTiers = response.data.map( option => {
			return {
				key: option.id,
				text: `${ option.description } (${ option.year })`,
				value: option.id
			};
		} );

		return medicalTiers;
	}

	lookupRetirementPlans = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 10 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const retirementPlans = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { retirementPlans } );
	}

	lookupProposalTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 18 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const proposalTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { proposalTypes } );
	}

	lookupRetirementPlanTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 28 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const retirementPlanTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { retirementPlanTypes } );
	}

	lookupRetirementPlanSubTypes = async parent => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/children',
			params: { parent },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const retirementPlanSubTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { retirementPlanSubTypes } );
	}

	lookupEnrollmentTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 30 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const enrollmentTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { enrollmentTypes } );
	}

	lookupMinAges = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 31 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const minAges = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { minAges } );
	}

	lookupVestingTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 32 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const vestingTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { vestingTypes } );
	}

	lookupServiceTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 33 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const serviceTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { serviceTypes } );
	}

	lookupPlanMergerTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 34 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const planMergerTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { planMergerTypes } );
	}

	lookupAdoptionAgreementTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 37 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const adoptionAgreementTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { adoptionAgreementTypes } );
	}

	lookupRenewalInstructionTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 39 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const renewalInstructionTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { renewalInstructionTypes } );
	}

	setYears = () => {
		const currentYear = new Date().getFullYear();
		const relevantYears = [
			currentYear + 1,
			currentYear
		];

		for ( let i = currentYear; i > 2010; i-- ) {
			relevantYears.push( i - 1 );
		}

		const years = relevantYears.map( ( year, i ) => {
			return {
				key: i + 1,
				text: year,
				value: year
			};
		} );

		this.setState( { years } );
	}

	// api calls

	getCustomerList = async ( typeId, agentId = this.state.agentId, startDate = this.state.startDate, endDate = this.state.EndDate, sync = false ) => {
		// eslint-disable-next-line no-param-reassign
		startDate = moment( startDate ).startOf( 'day' )
			.format( 'YYYY-MM-DD' );
		// eslint-disable-next-line no-param-reassign
		endDate = moment( endDate ).add( 1, 'days' )
			.startOf( 'day' )
			.format( 'YYYY-MM-DD' );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			// modify startDate and endDate params
			url: '/customers/search',
			params: {
				typeId,
				agentId,
				start: startDate,
				end: endDate,
				sync
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { lastSync: response.data.lastSyncOn } );

		return response.data;
	}

	exportCustomers = async () => {
		const { data: { reportName, url } } = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			// modify startDate and endDate params
			url: '/customers/search/extract',
			data: { customerIds: this.state.filteredCustomers },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		const link = document.createElement( 'a' );

		link.href = url;
		link.download = `${ reportName }.csv`;
		link.click();
	}

	setFilteredCustomers = filteredCustomers => {
		this.setState( { filteredCustomers } );
	}

	getCustomer = async id => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			customer: response.data,
			contacts: response.data.contacts,
			locations: response.data.locations
		} );

		if ( response.data.customerStage.id > 1 ) {
			this.setState( {
				payCycles: response.data.rfp.payRoll.payCycles,
				witholdings: response.data.rfp.payRoll.taxInfo.witholdings,
				workersComp: response.data.rfp.payRoll.workersComp,
				adminRates: response.data.rfp.adminRates,
				rfpYear: response.data.rfp.rfpYear,
				clientBenefit: response.data.client,
				documents: {
					taxInfo: response.data.rfp.payRoll.taxInfo.documents,
					medical: response.data.rfp.benefits.medical.documents,
					dental: response.data.rfp.benefits.dental.documents,
					vision: response.data.rfp.benefits.vision.documents,
					life: response.data.rfp.benefits.life.documents,
					disability: response.data.rfp.benefits.disability.documents
				},
				medicalPlans: response.data.rfp.benefits.medical.plans,
				proposals: response.data.documents
			} );
		}
	}

	postCustomer = async data => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/customer',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
	}

	deleteCustomer = async id => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
	}

	archiveCustomer = async ( id, reasonCodeID ) => {
		await axios( {
			method: 'put',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }/archive`,
			params: { reasonCodeID },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
	}

	unarchiveCustomer = async id => {
		await axios( {
			method: 'put',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }/archive/reverse`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
	}

	promoteCustomer = async id => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }/promote`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
	}

	// change endpoint when get contacts is available
	getContacts = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			contacts: response.data.contacts,
			spinner: false
		} );

	// await this.setState({
	// customer: {
	// ...this.state.customer,
	// contacts: this.state.contacts
	// }
	// });
	}

	postContact = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/contact`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getContacts( customerId );
	}

	deleteContact = async ( contactId, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/customer/contact/${ contactId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getContacts( customerId );
	}

	// change endpoint when get locations is available
	getLocations = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			locations: response.data.locations,
			spinner: false
		} );

	// await this.setState({
	// customer: {
	// ...this.state.customer,
	// locations: this.state.locations
	// }
	// });
	}

	postLocation = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/location`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getLocations( customerId );
	}

	deleteLocation = async ( locationId, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/customer/location/${ locationId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getLocations( customerId );
	}

	// change endpoint when get payCycles is available
	getPayCycles = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			payCycles: response.data.rfp.payRoll.payCycles,
			adminRates: response.data.rfp.adminRates,
			spinner: false
		} );

	// await this.setState({
	// customer: {
	// ...this.state.customer,
	// rfp: {
	// ...this.state.customer.rfp,
	// payRoll: {
	// ...this.state.customer.rfp.payRoll,
	// payCycles: this.state.payCyles
	// }
	// }
	// }
	// });
	}

	postPayCycle = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/payCycle`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getPayCycles( customerId );
	}

	deletePayCycle = async ( payCycleId, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `customer/rfp/payCycle/${ payCycleId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getPayCycles( customerId );
	}

	// change endpoint when get witholdings is available
	getWitholdings = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { witholdings: response.data.rfp.payRoll.taxInfo.witholdings, spinner: false } );

	// await this.setState({
	// customer: {
	// ...this.state.customer,
	// rfp: {
	// ...this.state.customer.rfp,
	// payRoll: {
	// ...this.state.customer.rfp.payRoll,
	// taxInfo: {
	// ...this.state.customer.rfp.payRoll.taxInfo,
	// witholdings: this.state.witholdings
	// }
	// }
	// }
	// }
	// });
	}

	postWitholding = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/witholding`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getWitholdings( customerId );
	}

	deleteWitholding = async ( witholdingId, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/customer/rfp/witholding/${ witholdingId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getWitholdings( customerId );
	}

	// change endpoint when get workersComp is available
	getWorkersComp = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			workersComp: response.data.rfp.payRoll.workersComp,
			spinner: false
		} );

	// await this.setState({
	// customer: {
	// ...this.state.customer,
	// rfp: {
	// ...this.state.customer.rfp,
	// payRoll: {
	// ...this.state.customer.rfp.payRoll,
	// workersComp:
	// this.state.workerComp
	// }
	// }
	// }
	// });
	}

	postWorkersComp = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/workersComp`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getWorkersComp( customerId );
	}

	deleteWorkersComp = async ( workersCompId, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `customer/rfp/workersComp/${ workersCompId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getWorkersComp( customerId );
	}

	getWCRates = async ( year = this.state.year ) => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/rates',
			params: {
				typeId: 48,
				year
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			WCRates: response.data,
			mappedWCRates: response.data.map( ( rate, i ) => {
				return {
					key: i + 1,
					text: `${ rate.state } - ${ rate.code }`,
					value: rate.code
				};
			} )
		} );
	}

	getSutaStates = async ( year = this.state.year ) => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/rates',
			params: {
				typeId: 7,
				year
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const sutaStates = [ '', ...response.data.map( ( { state } ) => state ) ];

		this.setState( { sutaStates } );
	}

	getWsPlans = async ( year, typeId = 0 ) => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/plans',
			params: {
				year,
				typeId
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			wsPlans: response.data,
			mappedWsPlans: response.data.map( ( plan, i ) => {
				return {
					key: i + 1,
					text: plan.name,
					value: plan.id
				};
			} )
		} );
	}

	getMedicalPlans = async customerId => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/rfp/medical/plans`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			medicalPlans: response.data,
			spinner: false
		} );
	}

	postMedicalPlan = async ( data, customerId = this.state.customer.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }/rfp/medical/plan`,
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getMedicalPlans( customerId );
	}

	deleteMedicalPlan = async id => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/customer/rfp/medical/plan/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getMedicalPlans( this.state.customer.id );
	}

	getDocuments = async ( customerId = this.state.customer.id ) => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ customerId }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			proposals: response.data.documents,
			spinner: false
		} );
	}

	deleteDocument = async id => {
		this.setState( { spinner: true } );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/document/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getDocuments();
	}

	getLeads = async ( agentId = this.state.agentId, startDate = this.state.startDate, endDate = this.state.EndDate, sync = false ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving leads...'
		} );
		const { results: leads } = await this.getCustomerList( 1, agentId, startDate, endDate, sync );

		this.setState( { leads } );
		this.setState( {
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	createLead = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating new lead...',
			leads: [],
			tableLoader: true
		} );
		await this.postCustomer( data );
		await this.getLeads();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateLead = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating lead...',
			leads: [],
			tableLoader: true
		} );
		await this.postCustomer( data );
		await this.getLeads();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteLead = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing lead...'
		} );
		await this.deleteCustomer( id );
		await this.getLeads();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	promoteLead = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Promoting lead...'
		} );
		await this.promoteCustomer( id );
		await this.getLeads();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	archiveLead = async ( id, reasonCodeID ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Archiving lead...'
		} );
		await this.archiveCustomer( id, reasonCodeID );
		await this.getLeads();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getRfpList = async ( agentId = this.state.agentId, startDate = this.state.startDate, endDate = this.state.EndDate ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving RFP list...'
		} );
		const { results: rfpList } = await this.getCustomerList( 2, agentId, startDate, endDate );

		this.setState( { rfpList } );
		this.setState( {
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateRfp = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating RFP...',
			rfpList: [],
			tableLoader: true
		} );
		await this.postCustomer( data );
		await this.getCustomer( data.id );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteRfp = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing RFP...'
		} );
		await this.deleteCustomer( id );
		await this.getRfpList();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	promoteRfp = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Promoting RFP...'
		} );
		await this.promoteCustomer( id );
		await this.getRfpList();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	archiveRfp = async ( id, reasonCodeID ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Archiving RFP...'
		} );
		await this.archiveCustomer( id, reasonCodeID );
		await this.getRfpList();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	saveAndCalculate = async data => {
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/customer/rfp/calculate',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { adminRates: response.data } );
	}

	getProspects = async ( agentId = this.state.agentId, startDate = this.state.startDate, endDate = this.state.EndDate ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving prospects...'
		} );
		const { results: prospects } = await this.getCustomerList( 3, agentId, startDate, endDate );

		this.setState( {
			prospects,
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateProspect = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating prospect...'
		} );
		await this.postCustomer( data );
		await this.getCustomer( data.id );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteProspect = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing prospect...'
		} );
		await this.deleteCustomer( id );
		await this.getProspects();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	promoteProspect = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Promoting prospect...'
		} );
		await this.promoteCustomer( id );
		await this.getProspects();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	archiveProspect = async ( id, reasonCodeID ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Archiving Prospect...'
		} );
		await this.archiveCustomer( id, reasonCodeID );
		await this.getProspects();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	generateComparison = async () => {
		try {
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/report/benefitcomp',
				params: { CustomerID: this.state.customer.id },
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			if ( response.status === 200 ) {
				this.setState( { comparison: response.data } );
			}
		} catch ( err ) {
			this.setState( { comparisonError: err } );
		}
	}

	clearComparison = () => {
		this.setState( { comparison: null } );
	}

	getRfpPremiumSheet = async ( medicalTier = this.state.customer.rfp.medical.medicalTier ) => {
		try {
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: 'report/premiums',
				params: {
					CustomerID: this.state.customer.id,
					tierID: medicalTier
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			if ( response.status === 200 ) {
				this.setState( { rfpPremiumSheet: response.data } );
			}
		} catch ( err ) {
			this.setState( { rfpPremiumError: err } );
		}
	}

	clearRfpPremiumSheet = () => {
		this.setState( { rfpPremiumSheet: null } );
	}

	generateProposal = async data => {
		try {
			const response = await axios( {
				method: 'post',
				baseURL: Settings.apiUrl,
				url: `/report/proposal/${ this.state.customer.id }`,
				data,
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			if ( response.status === 200 ) {
				this.setState( { proposal: response.data } );
			}
		} catch ( err ) {
			this.setState( { proposalError: err } );
		}
	}

	clearProposal = () => {
		this.setState( { proposal: null } );
	}

	getArchived = async ( agentId = this.state.agentId, startDate = this.state.startDate, endDate = this.state.EndDate, sync = false ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving archived records...'
		} );
		const { results: archived } = await this.getCustomerList( 165, agentId, startDate, endDate, sync );

		this.setState( { archived } );
		this.setState( {
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteArchived = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing archived record...'
		} );
		await this.deleteCustomer( id );
		await this.getArchived();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	unarchiveArchived = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Unarchiving record...'
		} );
		await this.unarchiveCustomer( id );
		await this.getArchived();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getClients = async ( agentId = 0, startDate = moment( '1/1/1900' ).toDate(), endDate = moment().toDate() ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving clients...'
		} );
		const { results: clients } = await this.getCustomerList( 4, agentId, startDate, endDate );

		this.setState( {
			clients,
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateClient = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating client...'
		} );
		await this.postCustomer( data );
		await this.getCustomer( data.id );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteClient = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing client...'
		} );
		await this.deleteCustomer( id );
		await this.getClients();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	renewClient = async ( id = this.state.customer.id ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Renewing client...'
		} );

		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/renewal/run/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getBenefitGroup();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	termClient = async ( id = this.state.customer.id ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Terming Inactive Client...'
		} );

		await axios( {
			method: 'put',
			baseURL: Settings.apiUrl,
			url: `/customer/${ id }/term`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getClients();

		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	unlockRenewal = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Unlocking client renewal submission...'
		} );

		await axios( {
			method: 'patch',
			baseURL: Settings.apiUrl,
			url: `/renewal/${ id }/reset`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getBenefitGroup();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	approveRenewal = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Approving client renewal submission...'
		} );

		await axios( {
			method: 'patch',
			baseURL: Settings.apiUrl,
			url: `/renewal/${ id }/approve`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getBenefitGroup();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	resendRenewal = async ( email, id = this.state.benefitGroup.renewal.benefitGroupRecordID ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Resending client renewal submission...'
		} );

		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `/renewal/${ id }/resend`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { email }
		} );

		await this.getBenefitGroup();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	handleClientActivation = async ( id, isInactive ) => {
		const action = isInactive ? 'activate' : 'inactivate';

		try {
			const response = await axios( {
				method: 'put',
				baseURL: Settings.apiUrl,
				url: `/customer/${ id }/${ action }`,
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			if ( response.status === 200 ) {
				this.getClients();
			}
		} catch ( err ) {
			console.log( err );
		}
	}

	setClientBenefitYear = async year => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ this.state.customer.id }/client`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { year }
		} );

		this.setState( { clientBenefit: response.data } );
	}

	setClientBenefitReadonly = bool => {
		this.setState( { clientBenefitReadonly: bool } );
	}

	getBenefitGroups = async () => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ this.state.customer.id }/client/${ this.state.customer.client.id }/groups`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			clientBenefit: {
				...this.state.clientBenefit,
				groups: response.data
			},
			spinner: false
		} );
	}

	getBenefitGroup = async () => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/customer/${ this.state.customer.id }/client/${ this.state.customer.client.id }/group/${ this.state.benefitGroup.id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( {
			benefitGroup: response.data,
			spinner: false
		} );
	}

	setBenefitGroup = group => {
		this.setState( { benefitGroup: group } );
	}

	updateBenefitGroup = async data => {
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `customer/${ this.state.customer.id }/client/${ this.state.customer.client.id }/group`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			data
		} );

		this.setState( { benefitGroup: response.data } );
	};

	deleteBenefitGroup = async groupID => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `customer/${ this.state.customer.id }/client/${ this.state.customer.client.id }/group/${ groupID }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getBenefitGroups();
	};

	getRenewalInstructions = async ( typeID, id = this.state.benefitGroup.renewal.benefitGroupRecordID ) => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/appcopy/list/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { typeID }
		} );

		const newCopy = {
			relID: id,
			type: { id: typeID },
			content: ''
		};

		console.log( response.data );

		this.setState( {
			renewalInstructions: response.data[ 0 ] || newCopy,
			spinner: false
		} );
	}

	updateRenewalInstructions = async content => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/appcopy',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			data: {
				...this.state.renewalInstructions,
				content
			}
		} );

		this.setState( {
			renewalInstructions: response.data,
			spinner: false
		} );
	};

	getEmployeeBenefitDoc = async ( PayCycleID, BenefitGroupID = this.state.benefitGroup.id ) => {
		this.setState( { employeeBenefitsDocSpinner: true } );

		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: 'report/employeebenefitdoc',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: {
				BenefitGroupID,
				PayCycleID
			}
		} );

		window.open( data.url );
		this.setState( { employeeBenefitsDocSpinner: false } );
	}

	addSubsidiaryGroup = async ( data, customerId = this.state.customer.id, clientId = this.state.customer.client.id ) => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: `customer/${ customerId }/client/${ clientId }/subsidiary`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			data
		} );
		await this.getCustomer( customerId );
		await this.lookupSubsidiaryGroups( this.state.customer.client.year );
	}

	changeRfpYear = async ( year, customerId = this.state.customer.id ) => {
		this.setState( { spinner: true } );
		const response = await axios( {
			method: 'patch',
			baseURL: Settings.apiUrl,
			url: `customer/${ customerId }/rfpYear`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { year }
		} );

		if ( response ) {
			await this.getCustomer( customerId );
			this.setState( { rfpYear: year } );
		}

		this.setState( { spinner: false } );
	}

	// modal handlers

	showLeadsModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			await this.getCustomer( id );
		}

		this.setState( {
			leadsModal: true,
			spinner: false
		} );
	}

	hideLeadsModal = () => {
		this.setState( {
			customer: null,
			leadsModal: false
		} );
	}

	showRfpModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			await this.getCustomer( id );
		}

		this.setState( {
			rfpModal: true,
			spinner: false
		} );
	}

	hideRfpModal = () => {
		this.setState( {
			customer: null,
			contacts: [],
			locations: [],
			payCycles: [],
			witholdings: [],
			workersComp: [],
			documents: [],
			rfpModal: false
		} );
	}

	showProspectsModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			await this.getCustomer( id );
		}

		this.setState( {
			prospectsModal: true,
			spinner: false
		} );
	}

	hideProspectsModal = () => {
		this.setState( {
			customer: null,
			contacts: [],
			locations: [],
			payCycles: [],
			witholdings: [],
			workersComp: [],
			documents: [],
			prospectsModal: false
		} );
	}

	showClientsModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			await this.getCustomer( id );
		}

		this.setState( {
			clientsModal: true,
			spinner: false
		} );
	}

	hideClientsModal = () => {
		this.setState( {
			customer: null,
			clientsModal: false
		} );
	}

	toggleArchiveConfirmModal = () => {
		this.setState( prevState => ( { archiveConfirmModal: !prevState.archiveConfirmModal } ) );
	}

	toggleDeleteConfirmModal = () => {
		this.setState( prevState => ( { deleteConfirmModal: !prevState.deleteConfirmModal } ) );
	}

	toggleTermInactiveConfirmModal = () => {
		this.setState( prevState => ( { termInactiveConfirmModal: !prevState.termInactiveConfirmModal } ) );
	}

	toggleUnarchiveConfirmModal = () => {
		this.setState( prevState => ( { unarchiveConfirmModal: !prevState.unarchiveConfirmModal } ) );
	}

	toggleNotesModal = () => {
		this.setState( prevState => ( { notesModal: !prevState.notesModal } ) );
	}

	// filter handlers

	refreshView = ( { callback, startDate = this.state.startDate, endDate = this.state.endDate, agentId = this.state.agentId, sync = false } ) => {
		// eslint-disable-next-line no-param-reassign
		startDate = moment( startDate ).startOf( 'day' )
			.format( 'YYYY-MM-DD' );
		// eslint-disable-next-line no-param-reassign
		endDate = moment( endDate ).endOf( 'day' )
			.format( 'YYYY-MM-DD' );
		callback( agentId, startDate, endDate, sync );
	}

	filteredSearch = callback => {
		// let { startDate } = this.state;
		// let { endDate } = this.state;

		this.refreshView( { callback } );
	}

	handleAgentIdChange = agentId => {
		this.setState( { agentId } );
	}

	handleStartDateChange = date => {
		if ( date ) {
			let startDate = moment( date ).toDate();

			this.setState( { startDate } );
		} else {
			let startDate = moment( '1/1/1900' ).toDate();

			this.setState( { startDate } );
		}
	}

	handleEndDateChange = date => {
		if ( date ) {
			let endDate = moment( date ).toDate();

			this.setState( { endDate } );
		} else {
			let endDate = moment( '1/1/1900' ).toDate();

			this.setState( { endDate } );
		}
	}

	resetFilter = callback => {
		const { authedUser } = this.props;
		// eslint-disable-next-line no-extra-parens
		const agentId = authedUser.role.id === 1 || authedUser.role.id === 7 || authedUser.role.id === 4 && !authedUser.isSalesAgent
			? 0
			: authedUser.id;

		this.handleAgentIdChange( agentId );
		let startDate = moment()
			.subtract( 60, 'days' )
			.startOf( 'day' )
			.toDate();
		let endDate = moment()
			.endOf( 'day' )
			.toDate();

		this.handleStartDateChange( startDate );
		this.handleEndDateChange( endDate );
		this.refreshView( {
			callback,
			startDate,
			endDate,
			agentId
		} );
	}

	unlockRfpSubmission = async () => {
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/submission/reverse',
			params: { CustomerID: this.state.customer.id },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		return response.data;
	}

	render() {
		return (
			<CustomersContext.Provider
				value={{
					...this.state,
					lookupSubsidiaryGroups: this.lookupSubsidiaryGroups,
					lookupRetirementPlanSubTypes: this.lookupRetirementPlanSubTypes,
					uploadDocument: this.uploadDocument,
					mapDocuments: this.mapDocuments,
					hideDocumentUploadModal: this.hideDocumentUploadModal,
					showDocumentUploadModal: this.showDocumentUploadModal,
					exportCustomers: this.exportCustomers,
					setFilteredCustomers: this.setFilteredCustomers,
					getCustomer: this.getCustomer,
					archiveCustomer: this.archiveCustomer,
					unarchiveCustomer: this.unarchiveCustomer,
					postContact: this.postContact,
					deleteContact: this.deleteContact,
					postLocation: this.postLocation,
					deleteLocation: this.deleteLocation,
					postPayCycle: this.postPayCycle,
					deletePayCycle: this.deletePayCycle,
					postWitholding: this.postWitholding,
					deleteWitholding: this.deleteWitholding,
					postWorkersComp: this.postWorkersComp,
					deleteWorkersComp: this.deleteWorkersComp,
					getWCRates: this.getWCRates,
					getSutaStates: this.getSutaStates,
					getWsPlans: this.getWsPlans,
					postMedicalPlan: this.postMedicalPlan,
					deleteMedicalPlan: this.deleteMedicalPlan,
					getDocuments: this.getDocuments,
					deleteDocument: this.deleteDocument,
					getLeads: this.getLeads,
					createLead: this.createLead,
					updateLead: this.updateLead,
					deleteLead: this.deleteLead,
					promoteLead: this.promoteLead,
					archiveLead: this.archiveLead,
					getRfpList: this.getRfpList,
					updateRfp: this.updateRfp,
					deleteRfp: this.deleteRfp,
					promoteRfp: this.promoteRfp,
					archiveRfp: this.archiveRfp,
					getProspects: this.getProspects,
					updateProspect: this.updateProspect,
					deleteProspect: this.deleteProspect,
					promoteProspect: this.promoteProspect,
					archiveProspect: this.archiveProspect,
					generateProposal: this.generateProposal,
					clearProposal: this.clearProposal,
					generateComparison: this.generateComparison,
					clearComparison: this.clearComparison,
					getRfpPremiumSheet: this.getRfpPremiumSheet,
					clearRfpPremiumSheet: this.clearRfpPremiumSheet,
					getArchived: this.getArchived,
					deleteArchived: this.deleteArchived,
					unarchiveArchived: this.unarchiveArchived,
					getClients: this.getClients,
					updateClient: this.updateClient,
					deleteClient: this.deleteClient,
					renewClient: this.renewClient,
					termClient: this.termClient,
					unlockRenewal: this.unlockRenewal,
					approveRenewal: this.approveRenewal,
					resendRenewal: this.resendRenewal,
					handleClientActivation: this.handleClientActivation,
					setClientBenefitYear: this.setClientBenefitYear,
					setClientBenefitReadonly: this.setClientBenefitReadonly,
					getBenefitGroups: this.getBenefitGroups,
					getBenefitGroup: this.getBenefitGroup,
					setBenefitGroup: this.setBenefitGroup,
					updateBenefitGroup: this.updateBenefitGroup,
					deleteBenefitGroup: this.deleteBenefitGroup,
					getRenewalInstructions: this.getRenewalInstructions,
					updateRenewalInstructions: this.updateRenewalInstructions,
					getEmployeeBenefitDoc: this.getEmployeeBenefitDoc,
					addSubsidiaryGroup: this.addSubsidiaryGroup,
					changeRfpYear: this.changeRfpYear,
					saveAndCalculate: this.saveAndCalculate,
					showLeadsModal: this.showLeadsModal,
					hideLeadsModal: this.hideLeadsModal,
					showRfpModal: this.showRfpModal,
					hideRfpModal: this.hideRfpModal,
					showProspectsModal: this.showProspectsModal,
					hideProspectsModal: this.hideProspectsModal,
					showClientsModal: this.showClientsModal,
					hideClientsModal: this.hideClientsModal,
					toggleArchiveConfirmModal: this.toggleArchiveConfirmModal,
					toggleDeleteConfirmModal: this.toggleDeleteConfirmModal,
					toggleTermInactiveConfirmModal: this.toggleTermInactiveConfirmModal,
					toggleUnarchiveConfirmModal: this.toggleUnarchiveConfirmModal,
					toggleNotesModal: this.toggleNotesModal,
					refreshView: this.refreshView,
					handleAgentIdChange: this.handleAgentIdChange,
					handleStartDateChange: this.handleStartDateChange,
					handleEndDateChange: this.handleEndDateChange,
					resetFilter: this.resetFilter,
					unlockRfpSubmission: this.unlockRfpSubmission
				}}
			>
				{ this.props.children }
			</CustomersContext.Provider>
		);
	}
}

export default withCookies( CustomersProvider );
