import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Confirm, Form, Icon, Ref, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';
import Datepicker from '../../../Shared/Datepicker';
import Table from '../../../Shared/Table';

const RenewalTab = ( { formData, updateField } ) => {
	// context
	const {
		customer,
		benefitGroup,
		renewClient,
		unlockRenewal,
		approveRenewal,
		resendRenewal,
		uiLoader,
		loadingMessage,
		renewalInstructions,
		renewalInstructionTypes,
		getRenewalInstructions,
		updateRenewalInstructions
	} = useContext( CustomersContext );

	const primaryContact = customer.contacts.find( customer => customer.primary );

	const [ unlockConfirm, setUnlockConfirm ] = useState( false );
	const [ approveConfirm, setApproveConfirm ] = useState( false );
	const [ renewConfirm, setRenewConfirm ] = useState( false );
	const [ resendEmail, setResendEmail ] = useState( primaryContact.email );
	const [ isSendingRenewal, setIsSendingRenewal ] = useState( false );
	const [ emailSent, setEmailSent ] = useState( false );
	const [ instructionType, setInstructionType ] = useState( renewalInstructionTypes[ 0 ].value );
	const [ instructions, setInstructions ] = useState( '' );

	useEffect( () => {
		getRenewalInstructions( instructionType );
	}, [ getRenewalInstructions, instructionType ] );

	useEffect( () => {
		setInstructions( renewalInstructions?.content ?? '' );
	}, [ renewalInstructions ] );

	let urlRef = useRef( null );

	const handleRenewalInstructionType = useCallback( type => {
		setInstructionType( type );
	}, [] );

	const renewalStatus = useMemo( () => {
		switch ( benefitGroup.renewal.renewalStatus.id ) {
			case 174:
				return 'Renewed';
			case 175:
				return 'Pending Receipt';
			case 176:
				return 'Renewal Received';
			case 177:
				return 'Pending Submission';
			case 178:
				return 'Pending Review';
			case 179:
				return 'Renewal Omitted';
			case 183:
				return 'Pending Renewal';
			default:
				return '';
		}
	}, [ benefitGroup.renewal.renewalStatus.id ] );

	const columns = [
		{
			Header: 'Date',
			accessor: 'dateStamp',
			filterable: false,
			sortable: true,
			Cell: row => <div>{ new Date( row.original.dateStamp ).toLocaleDateString() }</div>,
			width: 100
		},
		{
			Header: 'Name',
			accessor: 'name',
			filterable: false,
			sortable: true
		},
		{
			Header: 'View',
			accessor: 'id',
			sortable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						onClick={ () => {
							window.open( row.original.url );
						}}
					>
						<Icon name="search" />
					</Button>
				</div>
			),
			width: 120
		}
	];

	return (
		<Tab.Pane>
			<Form style={{ paddingBottom: '2rem' }}>
				<Form.Group>
					<Form.Field width={ 4 }>
						<label>
              Renewal Status&nbsp;&nbsp;
							{ renewalStatus === 'Pending Review' || renewalStatus === 'Renewed'
								? <span
									style={{ cursor: 'pointer' }}
									onClick={ () => setUnlockConfirm( true ) }
								>
                  [&nbsp;unlock&nbsp;]
								</span>
								: null
							}
						</label>
						<Form.Input
							value={ benefitGroup.renewal.renewalStatus.description }
							transparent
							readOnly
						/>
					</Form.Field>

					{ renewalStatus === 'Pending Receipt' || renewalStatus === 'Renewal Received' || renewalStatus === 'Pending Submission'
						? <Form.Input
							action={{
								color: 'blue',
								disabled: emailSent,
								icon: emailSent ? 'check' : 'send',
								onClick: async () => {
									await resendRenewal( resendEmail );
									setEmailSent( true );
								}
							}}
							value={ resendEmail }
							label="Resend Renewal"
							onChange={ ( _e, { value } ) => {
								setResendEmail( value );
								setEmailSent( false );
							}}
							width={ 8 }
						/>
						: null
					}

					{ benefitGroup.renewal.submissionDate
						&& ( renewalStatus === 'Pending Review' || renewalStatus === 'Renewed' )
						? <Form.Input
							value={ new Date( benefitGroup.renewal.submissionDate ).toLocaleDateString() }
							label="Submission Date"
							transparent
							readOnly
							width={ 4 }
						/>
						: null
					}

					<Datepicker
						label="Last Contact Date"
						name="contactDate"
						selected={ formData.contactDate }
						onChange={ e => {
							updateField( { target: { name: 'contactDate', value: e } } );
						} }
					/>
					<Form.Input
						name="contactInitial"
						value={ formData.contactInitial }
						label="Initials"
						onChange={ updateField }
						width={ 2 }
						maxLength={ 3 }
					/>
				</Form.Group>

				{ renewalStatus !== 'Renewal Omitted' && renewalStatus !== 'Pending Renewal'
					? <>
						<Form.Group>
							<Form.Field width={ 16 }>
								<label>
										Renewal URL&nbsp;&nbsp;
									<span
										style={{ cursor: 'pointer' }}
										onClick={ () => {
											urlRef.current.childNodes[ 0 ].childNodes[ 0 ].select();
											document.execCommand( 'copy' );
										}}
									>
										[&nbsp;copy&nbsp;]
									</span>
									&nbsp;&nbsp;
								</label>
								<Ref innerRef={ urlRef }>
									<Form.Input
										value={ benefitGroup.renewal.renewalURL }
										transparent
										readOnly
									/>
								</Ref>
							</Form.Field>
							{ benefitGroup.renewal.renewalStatusDate
								&& renewalStatus === 'Renewed'
								? <Form.Input
									value={ new Date( benefitGroup.renewal.renewalStatusDate ).toLocaleDateString() }
									label="Renewal Date"
									transparent
									readOnly
									width={ 4 }
								/>
								: null
							}
						</Form.Group>

						{ renewalStatus === 'Pending Review'
							? <Form.Group>
								<Button
									secondary
									onClick={ () => setApproveConfirm( true ) }
									color="blue"
								>
									<Button.Content>
									Approve
									</Button.Content>
								</Button>
							</Form.Group>
							: null
						}
					</>
					: <Button
						secondary
						onClick={ () => {
							setRenewConfirm( true );
						} }
						color="blue"
					>
						<Button.Content>
							Send Renewal
						</Button.Content>
					</Button>
				}

				{ benefitGroup.isBenefitDollars
					&& ( renewalStatus !== 'Pending Review'
					&& renewalStatus !== 'Renewed' )
					? <>
						<Form.Select
							label="Instruction Type"
							width={ 6 }
							value={ instructionType }
							options={ renewalInstructionTypes }
							onChange={ ( _e, { value } ) => {
								handleRenewalInstructionType( value );
							} }
						/>
						<Form.TextArea
							label="Instruction Box Notification"
							width={ 14 }
							rows={ 6 }
							value={ instructions }
							onChange={ ( _e, { value } ) => {
								setInstructions( value );
							} }
						/>
						<Button
							disabled= { instructions === renewalInstructions?.content }
							onClick={ () => {
								updateRenewalInstructions( instructions );
							} }
							color="blue"
						>
							<Button.Content>
								Save
							</Button.Content>
						</Button>
					</>
					: null
				}
			</Form>
			{ benefitGroup.renewal.documents && Object.keys( benefitGroup.renewal.documents ).length > 0
				? <Table
					key={ benefitGroup.renewal.documents.length }
					data={ benefitGroup.renewal.documents }
					columns={ columns }
					pageSize={ benefitGroup.renewal.documents.length }
					hidePagination
				/>
				: <p style={{ padding: '1rem 0 1rem 1rem' }}>No archived renewal submissions found.</p>
			}
			<Confirm
				className="unlock-confirm-modal"
				open={ unlockConfirm }
				size="tiny"
				header={ 'Unlock Submission?' }
				content={ <div className="content" style={{ fontSize: '16px' }}>
					{ uiLoader
						? <p>{ loadingMessage }</p>
						: <p>Would you like to unlock the submission portal for this benefit group?</p>
					}
				</div>}
				cancelButton="Cancel"
				confirmButton="Unlock"
				onCancel={ () => setUnlockConfirm( false ) }
				onConfirm={ async () => {
					await unlockRenewal( benefitGroup.renewal.benefitGroupRecordID );
					setUnlockConfirm( false );
				}}
			/>
			<Confirm
				className="approve-confirm-modal"
				open={ approveConfirm }
				size="tiny"
				header={ 'Approve Submission?' }
				content={ <div className="content" style={{ fontSize: '16px' }}>
					{ uiLoader
						? <p>{ loadingMessage }</p>
						: <p>Would you like to approve the renewal submission for this benefit group?</p>
					}
				</div> }
				cancelButton="Cancel"
				confirmButton="Approve"
				onCancel={ () => setApproveConfirm( false ) }
				onConfirm={ async () => {
					await approveRenewal( benefitGroup.renewal.benefitGroupRecordID );
					setApproveConfirm( false );
				}}
			/>
			<Confirm
				className="renew-confirm-modal"
				open={ renewConfirm }
				size="tiny"
				header={ 'Send Renewal?' }
				content={ <div className="content" style={{ fontSize: '16px' }}>
					{ uiLoader
						? <p>{ loadingMessage }</p>
						: <p>Would you like to open the renewal portal for this client?</p>
					}
				</div> }
				cancelButton="Cancel"
				confirmButton={{
					content: 'Renew',
					loading: isSendingRenewal
				}}
				onCancel={ () => setRenewConfirm( false ) }
				onConfirm={ async () => {
					setIsSendingRenewal( true );
					await renewClient();
					setRenewConfirm( false );
					setIsSendingRenewal( false );
				}}
			/>
		</Tab.Pane>
	);
};

export default RenewalTab;
