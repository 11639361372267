/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Menu, Modal, Segment, Tab, TransitionablePortal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserTag,
	faBuilding,
	faUserCircle,
	faMapPin,
	faDollarSign,
	faStarOfLife,
	faInfo,
	faFileSignature,
	faTasks,
	faRunning,
	faBalanceScale
} from '@fortawesome/free-solid-svg-icons';
import { CustomersContext } from '../../CustomersContext';
import { useForm } from '../../../../Hooks/useForm';
import { unmaskFein, maskCurrency, unmaskCurrency, validateZip, validateFein } from '../../../../Services/inputMasks';
import CompanyInfoTab from '../../ModalComponents/CompanyInfoTab';
import SalesTab from '../../ModalComponents/SalesTab';
import ContactsTab from '../../ModalComponents/ContactsTab';
import LocationsTab from '../../ModalComponents/LocationsTab';
import PayrollTab from '../../ModalComponents/PayrollTab';
import BenefitsTab from '../../ModalComponents/BenefitsTab';
import SessionInfoTab from '../../ModalComponents/SessionInfoTab';
import ComparisonTab from '../../ModalComponents/ComparisonTab';
import ProposalTab from '../../ModalComponents/ProposalTab';
import ChecklistTab from '../../ModalComponents/ChecklistTab';
import Alert from '../../../Shared/Alert';
import Spinner from '../../../Shared/Spinner';

const ProspectsModal = () => {
	// context
	const {
		customer,
		contacts,
		locations,
		payCycles,
		witholdings,
		workersComp,
		getProspects,
		updateProspect,
		promoteProspect,
		prospectsModal,
		hideProspectsModal,
		spinner
	} = useContext( CustomersContext );

	// state
	const [ validator, setValidator ] = useState( {} );
	const [ activeIndex, setActiveIndex ] = useState( 0 );
	const [ checklistWarning, setChecklistWarning ] = useState( false );
	const [ saveAlert, setSaveAlert ] = useState( false );
	const [ initialData, setInitialData ] = useState( {} );

	// form handler
	const { formData, updateField, setData } = useForm();

	// set formData to customer data
	useEffect( () => {
		if ( customer ) {
			const prospectData = {
				// company info
				name: customer.name,
				address: customer.address,
				address2: customer.address2,
				city: customer.city,
				state: customer.state,
				zip: customer.zip,
				// profile
				description: customer.profile.description,
				companyType: customer.profile.companyType.id,
				w2Wages: customer.profile.w2Wages,
				dateOfCorp: new Date( customer.profile.dateOfCorp ),
				fein: customer.profile.fein,
				indianaDomiciled: customer.profile.indianaDomiciled,
				wsMarketing: customer.profile.wsMarketing.id,
				// sales
				status: customer.customerStatus.id,
				source: customer.source.id,
				salesAgent: customer.salesAgent.id,
				operatingYear: customer.operatingYear,
				// payroll
				usesService: customer.rfp.payRoll.usesService,
				payrollVendor: customer.rfp.payRoll.vendor || '',
				conversionFee: maskCurrency( customer.rfp.payRoll.conversionFee ),
				contractors: customer.rfp.payRoll.contractors,
				contractorCount: customer.rfp.payRoll.contractorCount,
				usesContractorService: customer.rfp.payRoll.usesContractorService,
				contractorServiceRate: maskCurrency( customer.rfp.payRoll.contractorServiceRate ),
				contractorServiceCycles: customer.rfp.payRoll.contractorServiceCycles,
				usesTimeclock: customer.rfp.payRoll.usesTimeclock,
				timeclockCount: customer.rfp.payRoll.timeclockCount,
				timeclockCharge: maskCurrency( customer.rfp.payRoll.timeclockCharge ),
				wcTier: customer.rfp.payRoll.wcTier,
				includeWorkersComp: customer.rfp.payRoll.includeWorkersComp,
				// tax info
				isNFP: customer.rfp.payRoll.taxInfo.isNFP,
				subjectToFUTA: customer.rfp.payRoll.taxInfo.subjectToFUTA,
				unemploymentClaim: maskCurrency( customer.rfp.payRoll.taxInfo.unemploymentClaim ),
				isTaxCurrent: customer.rfp.payRoll.taxInfo.isTaxCurrent,
				// adminRates
				rate: maskCurrency( customer.rfp.adminRates.rate ),
				payCycles: customer.rfp.adminRates.payCycles,
				wS_WK: maskCurrency( customer.rfp.adminRates.wS_WK ),
				wS_WK_Override: customer.rfp.adminRates.wS_WK_Override,
				wS_BW: maskCurrency( customer.rfp.adminRates.wS_BW ),
				wS_BW_Override: customer.rfp.adminRates.wS_BW_Override,
				wS_SM: maskCurrency( customer.rfp.adminRates.wS_SM ),
				wS_SM_Override: customer.rfp.adminRates.wS_SM_Override,
				wS_MO: maskCurrency( customer.rfp.adminRates.wS_MO ),
				wS_MO_Override: customer.rfp.adminRates.wS_MO_Override,
				wsFee: maskCurrency( customer.rfp.adminRates.wsFee ),
				wsFee_Override: customer.rfp.adminRates.wsFee_Override,
				note_1: customer.rfp.adminRates.note_1,
				note_2: customer.rfp.adminRates.note_2,
				// benefits

				// medical
				medicalSponsorsPlan: customer.rfp.benefits.medical.sponsorsPlan,
				medicalTier: customer.rfp.benefits.medical.medicalTier.id,
				medicalPlanVendor: customer.rfp.benefits.medical.planVendor || '',
				medicalRenewalDate: new Date( customer.rfp.benefits.medical.renewalDate ),
				plansOffered: customer.rfp.benefits.medical.plansOffered,
				poS_Offered: customer.rfp.benefits.medical.poS_Offered,
				hmO_Offered: customer.rfp.benefits.medical.hmO_Offered,
				ppO_Offered: customer.rfp.benefits.medical.ppO_Offered,
				hdhP_Offered: customer.rfp.benefits.medical.hdhP_Offered,
				hrA_Offered: customer.rfp.benefits.medical.hrA_Offered,
				hsaVendor: customer.rfp.benefits.medical.hsaVendor || '',
				basicLife_Offered: customer.rfp.benefits.medical.basicLife_Offered,
				section_125_Offered: customer.rfp.benefits.medical.section_125_Offered,
				flexibleSpendingAccount: customer.rfp.benefits.medical.flexibleSpendingAccount,
				extendsCobra: customer.rfp.benefits.medical.extendsCobra,
				basicLifeAmount: maskCurrency( customer.rfp.benefits.medical.basicLifeAmount ),
				cobraVendor: customer.rfp.benefits.medical.cobraVendor || '',
				cobraCount: customer.rfp.benefits.medical.cobraCount,
				// dental
				dentalSponsorsPlan: customer.rfp.benefits.dental.sponsorsPlan,
				dentalPlanVendor: customer.rfp.benefits.dental.planVendor || '',
				dentalRenewalDate: new Date( customer.rfp.benefits.dental.renewalDate ),
				// vision
				visionSponsorsPlan: customer.rfp.benefits.vision.sponsorsPlan,
				visionPlanVendor: customer.rfp.benefits.vision.planVendor || '',
				visionRenewalDate: new Date( customer.rfp.benefits.vision.renewalDate ),
				// life
				lifeSponsorsPlan: customer.rfp.benefits.life.sponsorsPlan,
				lifePlanVendor: customer.rfp.benefits.life.planVendor || '',
				lifeRenewalDate: new Date( customer.rfp.benefits.life.renewalDate ),
				// disability
				stD_SponsorsPlan: customer.rfp.benefits.disability.stD_SponsorsPlan,
				stD_PlanVendor: customer.rfp.benefits.disability.stD_PlanVendor || '',
				stD_RenewalDate: new Date( customer.rfp.benefits.disability.stD_RenewalDate ),
				ltD_SponsorsPlan: customer.rfp.benefits.disability.ltD_SponsorsPlan,
				ltD_PlanVendor: customer.rfp.benefits.disability.ltD_PlanVendor || '',
				ltD_RenewalDate: new Date( customer.rfp.benefits.disability.ltD_RenewalDate ),
				// retirement
				retirementSponsorsPlan: customer.rfp.benefits.retirement.sponsorsPlan,
				retirementPlanVendor: customer.rfp.benefits.retirement.planVendor || '',
				retirementRenewalDate: new Date( customer.rfp.benefits.retirement.renewalDate ),
				retirementPlanTypeID: customer.rfp.benefits.retirement.planType.id,
				// ancillary
				hasBenefits: customer.rfp.benefits.ancillary.hasBenefits,
				ancillaryDescription: customer.rfp.benefits.ancillary.description,
				// checklist
				step1_1: customer.rfp.checklist.step1_1,
				step1_2: customer.rfp.checklist.step1_2,
				step2_1: customer.rfp.checklist.step2_1,
				step2_2: customer.rfp.checklist.step2_2,
				step3_1: customer.rfp.checklist.step3_1,
				step3_2: customer.rfp.checklist.step3_2,
				step3_3: customer.rfp.checklist.step3_3
			};

			setInitialData( prospectData );
			setData( prospectData );
			setValidator( prospectData );
		}

		// on unmount
		return () => {
			setData( {} );
			setValidator( {} );
		};
	}, [ customer, setData ] );

	const fixedTimeZoneOffset = date => {
		if ( !date ) {
			return null;
		}

		return new Date( date.getTime() - date.getTimezoneOffset() * 60000 ).toJSON();
	};

	// prepare data for api call
	const handleUpdate = async () => {
		setInitialData( formData );
		await updateProspect( {
			...customer,
			name: formData.name,
			address: formData.address,
			address2: formData.address2,
			city: formData.city,
			state: formData.state,
			zip: formData.zip,
			operatingYear: formData.operatingYear,
			customerStatus: { id: formData.status },
			salesAgent: { id: formData.salesAgent },
			source: { id: formData.source },
			// update conntacts from contacts object
			contacts,
			// update location from locations object
			locations,
			profile: {
				description: formData.description,
				companyType: { id: formData.companyType },
				w2Wages: formData.w2Wages,
				dateOfCorp: fixedTimeZoneOffset( formData.dateOfCorp ),
				fein: unmaskFein( formData.fein ),
				indianaDomiciled: formData.indianaDomiciled,
				wsMarketing: { id: formData.wsMarketing }
			},
			rfp: {
				...customer.rfp,
				payRoll: {
					...customer.rfp.payRoll,
					usesService: formData.usesService,
					vendor: formData.payrollVendor,
					conversionFee: unmaskCurrency( formData.conversionFee ),
					contractors: formData.contractors,
					contractorCount: formData.contractorCount,
					usesContractorService: formData.usesContractorService,
					contractorServiceRate: unmaskCurrency( formData.contractorServiceRate ),
					contractorServiceCycles: formData.contractorServiceCycles,
					usesTimeclock: formData.usesTimeclock,
					timeclockCount: formData.timeclockCount,
					timeclockCharge: unmaskCurrency( formData.timeclockCharge ),
					wcTier: formData.wcTier,
					includeWorkersComp: formData.includeWorkersComp,
					taxInfo: {
						...customer.rfp.payRoll.taxInfo,
						isNFP: formData.isNFP,
						subjectToFUTA: formData.subjectToFUTA,
						unemploymentClaim: unmaskCurrency( formData.unemploymentClaim ),
						isTaxCurrent: formData.isTaxCurrent,
						// update witholdings from the witholdings object
						witholdings
					},
					// update payCycles from the payCycles object
					payCycles,
					// update workersComp from the workersComp object
					workersComp
				},
				adminRates: {
					...customer.rfp.adminRates,
					rate: unmaskCurrency( formData.rate ),
					payCycles: formData.payCycles,
					wS_WK: unmaskCurrency( formData.wS_WK ),
					wS_WK_Override: formData.wS_WK_Override,
					wS_BW: unmaskCurrency( formData.wS_BW ),
					wS_BW_Override: formData.wS_BW_Override,
					wS_SM: unmaskCurrency( formData.wS_SM ),
					wS_SM_Override: formData.wS_SM_Override,
					wS_MO: unmaskCurrency( formData.wS_MO ),
					wS_MO_Override: formData.wS_MO_Override,
					wsFee: unmaskCurrency( formData.wsFee ),
					wsFee_Override: formData.wsFee_Override,
					note_1: formData.note_1,
					note_2: formData.note_2
				},
				benefits: {
					...customer.rfp.benefits,
					medical: {
						...customer.rfp.benefits.medical,
						sponsorsPlan: formData.medicalSponsorsPlan,
						medicalTier: { id: formData.medicalTier },
						planVendor: formData.medicalPlanVendor,
						renewalDate: fixedTimeZoneOffset( formData.medicalRenewalDate ),
						plansOffered: formData.plansOffered,
						poS_Offered: formData.poS_Offered,
						hmO_Offered: formData.hmO_Offered,
						ppO_Offered: formData.ppO_Offered,
						hdhP_Offered: formData.hdhP_Offered,
						hrA_Offered: formData.hrA_Offered,
						hsaVendor: formData.hsaVendor,
						basicLife_Offered: formData.basicLife_Offered,
						section_125_Offered: formData.section_125_Offered,
						flexibleSpendingAccount: formData.flexibleSpendingAccount,
						extendsCobra: formData.extendsCobra,
						basicLifeAmount: unmaskCurrency( formData.basicLifeAmount ),
						cobraVendor: formData.cobraVendor,
						cobraCount: formData.cobraCount
					},
					dental: {
						...customer.rfp.benefits.dental,
						sponsorsPlan: formData.dentalSponsorsPlan,
						planVendor: formData.dentalPlanVendor,
						renewalDate: fixedTimeZoneOffset( formData.dentalRenewalDate )
					},
					vision: {
						...customer.rfp.benefits.vision,
						sponsorsPlan: formData.visionSponsorsPlan,
						planVendor: formData.visionPlanVendor,
						renewalDate: fixedTimeZoneOffset( formData.visionRenewalDate )
					},
					life: {
						...customer.rfp.benefits.life,
						sponsorsPlan: formData.lifeSponsorsPlan,
						planVendor: formData.lifePlanVendor,
						renewalDate: fixedTimeZoneOffset( formData.lifeRenewalDate )
					},
					disability: {
						...customer.rfp.benefits.disability,
						stD_SponsorsPlan: formData.stD_SponsorsPlan,
						stD_PlanVendor: formData.stD_PlanVendor,
						stD_RenewalDate: fixedTimeZoneOffset( formData.stD_RenewalDate ),
						ltD_SponsorsPlan: formData.ltD_SponsorsPlan,
						ltD_PlanVendor: formData.ltD_PlanVendor,
						ltD_RenewalDate: fixedTimeZoneOffset( formData.ltD_RenewalDate )
					},
					retirement: {
						...customer.rfp.benefits.retirement,
						sponsorsPlan: formData.sponsorsPlan,
						planVendor: formData.planVendor,
						renewalDate: fixedTimeZoneOffset( formData.retirementRenewalDate ),
						planType: { id: formData.retirementPlanType }
					},
					ancillary: {
						...customer.rfp.benefits.ancillary,
						hasBenefits: formData.hasBenefits,
						description: formData.ancillaryDescription
					}
				},
				checklist: {
					...customer.rfp.checklist,
					step1_1: formData.step1_1,
					step1_2: formData.step1_2,
					step2_1: formData.step2_1,
					step2_2: formData.step2_2,
					step3_1: formData.step3_1,
					step3_2: formData.step3_2,
					step3_3: formData.step3_3
				}
			}
		} );
		setSaveAlert( true );
	};

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {} );
		setActiveIndex( 0 );
	};

	const panes = [
		{
			menuItem: (
				<Menu.Item
					key="company"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faBuilding } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<CompanyInfoTab
					formData={ formData }
					updateField={ updateField }
					validator={ validator }
					setValidator={ setValidator }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="sales"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faUserTag } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<SalesTab
					formData={ formData }
					updateField={ updateField }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="contacts"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faUserCircle } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => <ContactsTab />
		},
		{
			menuItem: (
				<Menu.Item
					key="locations"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faMapPin } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => <LocationsTab />
		},
		{
			menuItem: (
				<Menu.Item
					key="payroll"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faDollarSign } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<PayrollTab
					formData={ formData }
					updateField={ updateField }
					validator={ validator }
					setValidator={ setValidator }
					handleUpdate={ handleUpdate }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="benefits"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faStarOfLife } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<BenefitsTab
					formData={ formData }
					updateField={ updateField }
					validator={ validator }
					setValidator={ setValidator }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="sessionInfo"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faInfo } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => <SessionInfoTab />
		},
		{
			menuItem: (
				<Menu.Item
					key="comparison"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faBalanceScale } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<ComparisonTab
					formData={ formData }
					updateField={ updateField }
					handleUpdate={ handleUpdate }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="proposal"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faFileSignature } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<ProposalTab
					formData={ formData }
					updateField={ updateField }
					updateProspect={ handleUpdate }
				/>
			)
		},
		{
			menuItem: (
				<Menu.Item
					key="checklist"
					style={{ width: '70px', height: '70px' }}
					className="icon-tab"
				>
					<FontAwesomeIcon icon={ faTasks } style={{ fontSize: '3.25rem', color: '#2654a3', margin: '0 auto' }} />
				</Menu.Item>
			),
			// eslint-disable-next-line react/display-name
			render: () => (
				<ChecklistTab
					formData={ formData }
					updateField={ updateField }
				/>
			)
		}
	];

	return (
		<Modal
			open={ prospectsModal }
			onClose={ hideProspectsModal }
			closeOnDimmerClick={ false }
			className="prospects-modal"
			size="fullscreen"
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Prospects
				<Button
					basic
					secondary
					animated="fade"
					style={{ width: '130px' }}
					onClick={ () => {
						// will eventually be customer.prospect.proposal.checklist
						const data = {
							step1_1: formData.step1_1,
							step1_2: formData.step1_2

							/* step2_1: formData.step2_1,
							   step2_2: formData.step2_2,
							   step3_1: formData.step3_1,
							   step3_2: formData.step3_2,
							   step3_3: formData.step3_3 */
						};
						const checklist = Object.values( data );

						if ( checklist.every( task => task ) ) {
							handleUpdate();
							promoteProspect( customer.id );
							hideProspectsModal();
						} else {
							setChecklistWarning( true );
							setActiveIndex( panes.length - 1 );
						}
					}}
				>
					<Button.Content visible>
						<FontAwesomeIcon icon={ faRunning } style={{ fontSize: '1.25em' }} />
					</Button.Content>
					<Button.Content hidden style={{ fontSize: '0.9rem' }}> Promote to Client </Button.Content>
				</Button>
			</Modal.Header>
			<Modal.Content scrolling style={{ paddingTop: 0 }}>
				<div style={{ backgroundColor: '#27BEBD88', borderTop: '1px solid black', margin: '0 -1.5rem 1.5rem', padding: '0 14px', lineHeight: '2rem' }}>
					{ customer
						? <React.Fragment>
							<span style={{ marginRight: '3rem' }}><strong>Company:</strong> { customer.name }</span>
							<span style={{ marginRight: '3rem' }}><strong>Location:</strong> { `${ customer.city }, ${ customer.state }` }</span>
							<span style={{ marginRight: '3rem' }}><strong>Assignment Date:</strong> { new Date( customer.customerStage.date ).toLocaleDateString() }</span>
							<span style={{ marginRight: '3rem' }}><strong>Operating Year:</strong> { customer.operatingYear }</span>
						</React.Fragment>
						: null
					}
				</div>
				<Tab
					panes={ panes }
					activeIndex={ activeIndex }
					onTabChange={ ( e, { activeIndex } ) => {
						setActiveIndex( activeIndex );

						if ( formData !== initialData ) {
							handleUpdate();
							setSaveAlert( true );
						}
					}}
					style={{ padding: '0' }}
				/>
				<TransitionablePortal
					open={ checklistWarning }
					onOpen={ () => setChecklistWarning( true ) }
					closeOnDocumentClick={ false }
				>
					<Segment
						clearing
						style={{ width: '20%', left: '40%', position: 'fixed', top: '33%', zIndex: 1001 }}
					>
						<Header>Action Required</Header>
						<p>You must complete the proposal checklist before promoting this customer to a client.</p>
						<Button
							floated="right"
							content="OK"
							onClick={ () => setChecklistWarning( false ) }
						/>
					</Segment>
				</TransitionablePortal>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						handleUpdate();
						setSaveAlert( true );
					}}
					disabled={
						( formData.name ? formData.name.length === 0 : true )
            || ( formData.address ? formData.address.length === 0 : true )
            || ( formData.city ? formData.city.length === 0 : true )
            || ( formData.state ? formData.state.length === 0 : true )
            || ( formData.zip ? !validateZip( formData.zip ) : true )
            || ( formData.fein ? formData.fein.length > 0 && !validateFein( formData.fein ) : false )
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Close"
					onClick={ () => {
						clearData();
						hideProspectsModal();
						getProspects();
					}}
				>
				</Button>
			</Modal.Actions>
			<Alert
				open={ saveAlert }
				closeModal={ () => setSaveAlert( false ) }
				message="Prospect saved!"
			/>
			<Spinner active={ spinner } />
		</Modal>
	);
};

export default ProspectsModal;
