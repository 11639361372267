import React, { useContext, useEffect } from 'react';
import { OperationsContext } from '../OperationsContext';
import { Button, Dropdown, Icon, Segment } from 'semantic-ui-react';

const FeesFilter = props => {

  const {
    getFees,
    feeTypes,
    feeType,
    handleFeeTypeChange,
    years,
    year,
    handleYearChange,
    showAdminFeesModal,
    showImplementationFeesModal,
    resetFilter
  } = useContext( OperationsContext );

  useEffect( () => {
    if ( feeType && year ) {
      resetFilter( getFees );
    }
  }, [] );

  return (
    <Segment>
      <span style={{ margin: '20px' }}>Year</span>
      <Dropdown
        value={ year }
        selection
        options={ years }
        onChange={ ( e, { value } ) => handleYearChange( value ) }
      />
      <span style={{ margin: '0 20px 0' }}>Type</span>
      <Dropdown
        value={ feeType }
        selection
        options={ feeTypes }
        onChange={ ( e, { value } ) => handleFeeTypeChange( value ) }
      />
      <Button
        animated="fade"
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => resetFilter( getFees ) }
      >
        <Button.Content visible>
          <Icon name="undo" />
        </Button.Content>
        <Button.Content hidden> Reset </Button.Content>
      </Button>
      <Button
        animated="fade"
        primary
        floated="right"
        onClick={ () => {
          if ( feeType === 49 ) {
            showAdminFeesModal();
          } else if ( feeType === 50 ) {
            showImplementationFeesModal();
          }
        }}
        style={{ width: '150px', marginRight: '10px' }}
      >
        <Button.Content visible>
          <Icon name="add" />
        </Button.Content>
        <Button.Content hidden>New Fee</Button.Content>
      </Button>
    </Segment>
  );
};

export default FeesFilter;
