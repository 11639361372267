import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { floatKeyDown } from '../../../../Services/inputMasks';
import roundToHundredth from '../../../../Services/roundToHundredth';

const PercentageInput = ( {
	ceiling,
	floor,
	label,
	name,
	setValidator,
	updateValue,
	validator,
	value
} ) => {
	const [ hasFocus, setHasFocus ] = useState( false );
	const [ percentage, setPercentage ] = useState( value / ceiling * 100 );
	const [ maskedPercentage, setMaskedPercentage ] = useState( Math.round( value / ceiling * 100 * 100 ) / 100 );

	useEffect( () => {
		setMaskedPercentage( Math.round( value / ceiling * 100 * 100 ) / 100 );
	}, [ ceiling, value ] );

	return (
		<Input
			required
			fluid
			label={ label ? label : null }
			labelPosition={ label ? 'right' : null }
			icon="percent"
			iconPosition="left"
			name={ name }
			value={ hasFocus ? percentage : maskedPercentage }
			onClick={ e => e.target.select() }
			onChange={ ( e, { value } ) => {
				setPercentage( value );
			}}
			error={
				validator[ name ] > ceiling
				// || validator[ name ] < floor
			}
			onBlur={ e => {
				updateValue( { name, value: roundToHundredth( e.target.value / 100 * ceiling ), mask: true } );
				setValidator( { ...validator, [ name ]: percentage / 100 * ceiling || '' } );
				setHasFocus( false );
			}}
			onFocus={ e => {
				setValidator( { ...validator, [ name ]: floor } );
				setHasFocus( true );
				e.target.select();
			}}
			onKeyDown={ floatKeyDown }
		/>
	);
};

export default PercentageInput;
