import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';
import { floatKeyDown } from '../../../Services/inputMasks';
import DecimalInput from '../../Shared/DecimalInput';

const TiersModal = props => {

  // context
  const {
    operation,
    createTier,
    updateTier,
    tiersModal,
    hideTiersModal
  } = useContext( OperationsContext );

  // form handler
  const { formData, updateField, setData } = useForm();

  // state
  const [ validator, setValidator ] = useState({
    description: 'string',
    rate: 0
  });

  // Set formData to operation data
  useEffect( () => {
    if ( operation ) {
      const tierData = {
        description: operation.description,
        rate: operation.rate
      };
      setData( tierData );
    }
    return () => {
      setData( {} );
    };
  }, [ operation, setData ] );

  // clear out formData, and validator when modal closes
  const clearData = () => {
    setData( {} );
    setValidator({
      description: 'string',
      rate: 0
    });
  };

  return (
    <Modal
      open={ tiersModal }
      size="mini"
    >
      <Modal.Header>
        Administration Rate
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            name="description"
            label="Description"
            value={ formData.description || '' }
            maxLength={ 125 }
            onChange={ updateField }
            error={ validator.description.length === 0 }
            onBlur={ () => {
              setValidator( { ...validator, description: formData.description || '' } );
            }}
            onFocus={ () => {
              setValidator( { ...validator, description: 'string' } );
            }}
          />
          <Message
            hidden={ validator.description.length > 0 }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ validator.description.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a description.
              </Message.Item>
            </Message.List>
          </Message>
          <DecimalInput
            name="rate"
            label="Rate"
            value={ formData.rate || '' }
            onChange={ updateField }
            error={ !( parseInt( validator.rate ) >= 0 ) }
            onBlur={ () => setValidator( { ...validator, rate: parseInt( formData.rate ) >= 0 ? formData.rate : '' } ) }
            onFocus={ () => setValidator( { ...validator, rate: 0 } ) }
          />
          <Message
            hidden={
              parseInt( validator.rate ) >= 0
            }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item>
                <Icon name="exclamation circle" />
                Please enter a rate.
              </Message.Item>
            </Message.List>
          </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Save"
          onClick={ operation
            ? () => {
                updateTier({ ...operation, ...formData });
                clearData();
                hideTiersModal();
              }
            : () => {
                createTier( formData );
                clearData();
                hideTiersModal();
              }
          }
          disabled={
            ( formData.description ? formData.description.length === 0 || formData.description.length > 125 : true )
            || !( parseInt( formData.rate ) >= 0 )
          }
        >
        </Button>
          <Button
            style={{ backgroundColor: '#F58229', color: '#fff' }}
            content="Cancel"
            onClick={ () => {
              clearData();
              hideTiersModal();
            }}
          >
          </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TiersModal;
