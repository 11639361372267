import React, { useContext, useState } from 'react';
import { Router, Link, Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import history from '../../history';
import Sidebar from '../Sidebar/Sidebar';
import PageIndex from '../Pages/PageIndex';
import { AuthContext } from '../Auth/AuthContext';
import AdminProvider from '../Admin/AdminContext';
import UserProfile from './UserProfile';
import WarningModal from '../Auth/WarningModal';

const Main = () => {
	// context
	const { handleLogout, authedUser } = useContext( AuthContext );

	// state
	const [ hideSidebar, setHideSidebar ] = useState( false );

	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<AdminProvider>
				<Router history={ history }>
					<Sidebar
						url={ history.location.pathname }
						hidden={ hideSidebar }
					/>
					<div style={{ flexGrow: 1 }}>
						<div className="headerImage">
							<img src={ `${ process.env.PUBLIC_URL }/assets/images/logo.png` } alt="WorkSmart Logo" />
							<nav id="site-navigation" style={{ display: 'inline-flex', padding: '1rem', margin: 'none', fontSize: '1.2rem' }}>
								{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 5 || authedUser.role.id === 7
									? <Link
										to="/main/sales"
										style={{ padding: '0.5rem 10px 0' }}
										onClick={ () => setHideSidebar( false ) }
									>
                    Sales
									</Link>
									: null
								}
								{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 5 || authedUser.role.id === 7 || authedUser.role.id === 8
									? <Link
										to="/main/clients"
										style={{ padding: '0.5rem 10px 0', borderLeft: '1px solid' }}
										onClick={ () => setHideSidebar( true ) }
									>
                    Clients
									</Link>
									: null
								}
								{ authedUser.role.id === 1 || authedUser.role.id === 4 || authedUser.role.id === 7
									? <Link
										to="/main/operations"
										style={{ padding: '0.5rem 10px 0', borderLeft: '1px solid' }}
										onClick={ () => setHideSidebar( false ) }
									>
                    Operations
									</Link>
									: null
								}
								{ authedUser.role.id === 1
									? <Link
										to="/main/reports"
										style={{ padding: '0.5rem 10px 0', borderLeft: '1px solid' }}
										onClick={ () => setHideSidebar( true ) }
									>
                    Reports
									</Link>
									: null
								}
								{ authedUser.role.id === 1 /* || authedUser.role.id === 4 || authedUser.role.id === 7 */
									? <Link
										to="/main/administration"
										style={{ padding: '0.5rem 10px 0', borderLeft: '1px solid' }}
										onClick={ () => setHideSidebar( true ) }
									>
                    Administration
									</Link>
									: null
								}
							</nav>
							<div className="headerLinks">
								<div className="left">
									<Icon name="user" />
									<UserProfile />
								</div>
								<div className="right">
									<span onClick={ handleLogout }>Logout</span>
								</div>
							</div>
						</div>
						<PageIndex />
						<Redirect
							from="*"
							to={ authedUser.role.id === 8 || authedUser.role.id === 7
								? 'main/clients'
								: '/main/sales' }
						/>
					</div>
				</Router>
			</AdminProvider>
			<WarningModal />
		</div>
	);
};

export default Main;
