import React from 'react';
import { Route } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import SalesMenu from '../Customers/Sales/SalesMenu';
import OperationsMenu from '../Operations/OperationsMenu';

const Sidebar = props => {
	return (
		<div id="sidebar" className={ props.hidden ? 'hidden' : null }>
			<Icon name="bars" size="big" style={{ color: '#2654A3' }} />
			<Route path="/main/sales" component={ SalesMenu } />
			<Route path="/main/operations" component={ OperationsMenu } />
		</div>
	);
};

export default Sidebar;
