import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';
import { maskCurrency, unmaskCurrency, floatKeyDown } from '../../../Services/inputMasks';

const ImplementationFeesModal = props => {

  // context
  const {
    operation,
    createFee,
    updateFee,
    implementationFeesModal,
    hideImplementationFeesModal,
    payrollCycles,
    employeeRanges,
    feeType,
    year
  } = useContext( OperationsContext );

  // form handler
  const { formData, updateField, setData } = useForm();

  // state
  const [ validator, setValidator ] = useState({
    employeeRange: 1,
    feeAmount: '0'
  });

  // Set formData to operation data
  useEffect( () => {
    if ( operation ) {
      const feeData = {
        employeeRange: operation.employeeRange.id,
        feeAmount: maskCurrency( operation.feeAmount )
      };
      setData( feeData );
    }
    return () => {
      setData( {} );
    };
  }, [ operation, setData ] );

  // prepare data for updateLead api call
  const handleUpdate = () => {
    updateFee({
      ...operation,
      payCycle: {
        id: 0
      },
      employeeRange: {
        id: formData.employeeRange
      },
      feeAmount: unmaskCurrency( formData.feeAmount ),
    });
  };

  // prepare data for updateLead api call
  const handleCreateNew = () => {
    createFee({
      id: 0,
      year: year,
      feeType: {
        id: feeType,
      },
      payCycle: {
        id: 0
      },
      employeeRange: {
        id: formData.employeeRange
      },
      feeAmount: unmaskCurrency( formData.feeAmount ),
    });
  };

  // clear out formData, and validator when modal closes
  const clearData = () => {
    setData( {} );
    setValidator({
      employeeRange: 1,
      feeAmount: '0'
    });
  };

  return (
    <Modal
      open={ implementationFeesModal }
      size="mini"
    >
      <Modal.Header>
        Implementation Fee
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Select
              required
              name="employeeRange"
              value={ formData.employeeRange || 0 }
              label="Number of Employees"
              options={ [ { key: 0, text: 'Select an option', value: 0 }, ...employeeRanges ] }
              width={ 10 }
              fluid
              onChange={ ( e, { name, value } ) => { updateField({ target: { name: name, value: value } }); } }
              error={ validator.employeeRange === 0 }
              onBlur={ () => {
                setValidator( { ...validator, employeeRange: formData.employeeRange || 0 } );
              }}
              onFocus={ () => {
                setValidator( { ...validator, employeeRange: 1 } );
              }}
            />
            <Form.Input
              required
              name="feeAmount"
              value={ formData.feeAmount || '' }
              label="Fee"
              width={ 6 }
              onChange={ updateField }
              error={ validator.feeAmount.length === 0 }
              onBlur={ ( e ) => {
                updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                setValidator({ ...validator, feeAmount: formData.feeAmount || '' });
              }}
              onFocus={ () => {
                setValidator( { ...validator, feeAmount: '0' } );
              }}
              onKeyDown={ floatKeyDown }
            />
          </Form.Group>
          <Message
            hidden={ validator.employeeRange > 0 && validator.feeAmount.length > 0 }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item
                hidden={ validator.employeeRange }
              >
                <Icon name="exclamation circle" />
                Please select a Number of Employees.
              </Message.Item>
              <Message.Item
                hidden={ validator.feeAmount.length > 0 }
              >
                <Icon name="exclamation circle" />
                Please enter a value for Fee.
              </Message.Item>
            </Message.List>
          </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Save"
          onClick={ operation
            ? () => {
                handleUpdate();
                clearData();
                hideImplementationFeesModal();
              }
            : () => {
                handleCreateNew( formData );
                clearData();
                hideImplementationFeesModal();
              }
          }
          disabled={
            formData.employeeRange === 0
            || ( formData.feeAmount ? formData.feeAmount.length === 0: true )
          }
        >
        </Button>
          <Button
            style={{ backgroundColor: '#F58229', color: '#fff' }}
            content="Cancel"
            onClick={ () => {
              clearData();
              hideImplementationFeesModal();
            }}
          >
          </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ImplementationFeesModal;
