import React from 'react';
import axios from 'axios';
import { Settings } from '../Global/config';
import { withCookies } from 'react-cookie';

export const AuthContext = React.createContext();

class AuthProvider extends React.PureComponent {
	constructor( props ) {
		super( props );
		this.state = {
			authedUser: null,
			isLoading: false,
			isValid: true,
			isAuthed: false,
			userToken: '',
			userID: '',
			// activeModules: [],
			// idle timer
			warningTime: 1000 * 60 * 25, // ms * s * m
			warnTimer: null,
			logoutTime: 1000 * 60 * 30,
			logoutTimer: null,
			warningModal: false,
			twoAuthUsername: ''
		};
	}

	componentWillUnmount() {
		this.destroyTimer();
	}

	handleLogin = async user => {
		this.setState( { isLoading: true } );
		let password = btoa( user.password );

		try {
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/Users/Authenticate',
				params: {
					UserName: user.username,
					Password: password
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json'
				}
			} );
			const { userID, valid } = response.data;
			const userToken = response.data.token;

			if ( !valid ) {
				this.setState( {
					isLoading: false,
					isValid: true,
					twoAuthUsername: user.username
				} );

				return false;
			}

			this.setState( {
				isValid: true,
				userID,
				userToken
			} );
			await this.props.cookies.set(
				'token',
				userToken,
				{ path: '/' }
			);
			await this.getUserProfile( userID, userToken );
			// await this.getSysSettings();
			this.createTimer();
		} catch ( error ) {
			this.setState( {
				isLoading: false,
				isValid: false
			} );
		}

		return true;
	}

	handleTwoAuth = async code => {
		this.setState( { isLoading: true } );

		try {
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/Users/phase2authenticate',
				params: {
					username: this.state.twoAuthUsername,
					code
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json'
				}
			} );
			const { userID } = response.data;
			const userToken = response.data.token;

			this.setState( {
				isValid: true,
				userID,
				userToken,
				twoAuthUsername: ''
			} );
			await this.props.cookies.set(
				'token',
				userToken,
				{ path: '/' }
			);
			await this.getUserProfile( userID, userToken );
			// await this.getSysSettings();
			this.createTimer();
		} catch ( error ) {
			this.setState( {
				isLoading: false,
				isValid: false
			} );
		}
	}

	resendTwoAuth = async () => {
		try {
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/Users/resend2auth',
				params: { username: this.state.twoAuthUsername },
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json'
				}
			} );

			return response.data;
		} catch ( error ) {
			return false;
			// handle
		}
	}

	getUserProfile = async ( id, token ) => {
		const { cookies } = this.props;
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `/users/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: token
			}
		} );
		const authedUser = response.data;

		cookies.set( 'authedUser', authedUser );
		this.setState( {
			authedUser,
			isLoading: false,
			isAuthed: true
		} );
	}

	// getSysSettings = async () => {
	// 	const response = await axios( {
	// 		method: 'get',
	// 		baseURL: Settings.apiUrl,
	// 		url: '/settings/all',
	// 		headers: {
	// 			api_key: Settings.apiKey,
	// 			Accept: 'application/json',
	// 			AccessToken: this.state.userToken
	// 		}
	// 	} );
	// 	let activeModules = response.data.modules;

	// 	this.setState( { activeModules } );
	// }

	handleLogout = () => {
		this.setState( {
			isAuthed: false,
			AccessToken: '',
			userID: '',
			userToken: '',
			authedUser: '',
			isValid: true
		} );
		this.props.cookies.remove( 'token' );
		this.props.cookies.remove( 'authedUser' );
		this.destroyTimer();
	}

	passwordReset = email => {
		axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/users/forgotpass',
			params: { email },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json'
			}
		} );
	}

	resetPasswordFromConfirm = ( penCode, password ) => {
		const btoaPassword = btoa( password );

		axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/users/confirmpass',
			params: {
				penCode,
				password: btoaPassword
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json'
			}
		} );
	}

	// idle timer

	createTimer = () => {
		const events = [
			'load',
			'mousemove',
			'mousedown',
			'click',
			'scroll',
			'keypress'
		];

		for ( let i in events ) {
			window.addEventListener( events[ i ], this.resetTimer );
		}

		this.setTimer();
	}

	setTimer = () => {
		this.warnTimer = setTimeout( this.warn, this.state.warningTime );
		this.logoutTimer = setTimeout( this.logout, this.state.logoutTime );
	};

	resetTimer = () => {
		if ( this.state.warningModal ) {
			this.setState( { warningModal: false } );
		}

		this.clearTimer();
		this.setTimer();
	};

	clearTimer = () => {
		if ( this.warnTimer ) {
			clearTimeout( this.warnTimer );
		}

		if ( this.logoutTimer ) {
			clearTimeout( this.logoutTimer );
		}
	};

	destroyTimer = () => {
		const events = [
			'load',
			'mousemove',
			'mousedown',
			'click',
			'scroll',
			'keypress'
		];

		for ( let i in events ) {
			window.removeEventListener( events[ i ], this.resetTimer );
		}

		this.setState( { warningModal: false } );
	}

	warn = () => {
		this.setState( { warningModal: true } );
	};

	logout = () => {
		this.handleLogout();
	};

	render() {
		return (
			<AuthContext.Provider
				value={{
					...this.state,
					handleLogin: this.handleLogin,
					handleTwoAuth: this.handleTwoAuth,
					resendTwoAuth: this.resendTwoAuth,
					handleLogout: this.handleLogout,
					passwordReset: this.passwordReset,
					resetPasswordFromConfirm: this.resetPasswordFromConfirm
				}}
			>
				{ this.props.children }
			</AuthContext.Provider>
		);
	}
}

export default withCookies( AuthProvider );
