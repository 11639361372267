import React, { useContext } from 'react';
import { Divider, Form, Header, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';

const SalesTab = props => {
	// context
	const {
		years,
		customer,
		rfpStatuses,
		prospectStatuses,
		salesAgents
	} = useContext( CustomersContext );

	// form handler
	const { formData, updateField } = props;

	return (
		<Tab.Pane>
			<Header>Sales</Header>
			<Divider />
			<Form>
				<Form.Select
					name="status"
					label={
						customer.customerStage.id === 2
							? 'RFP Status'
							: customer.customerStage.id === 3
								? 'Prospect Status'
								: customer.customerStage.id === 4
									? 'Client Status'
									: ''
					}
					width={ 6 }
					value={ formData.status }
					options={
						customer.customerStage.id === 2
							? rfpStatuses
							: customer.customerStage.id === 3
								? prospectStatuses
								: []
					}
					onChange={ ( e, { name, value } ) => {
						updateField( { target: { name, value } } );
					}}
				/>
				{/* <Form.Select
          name="source"
          label="Source"
          width={ 6 }
          value={ formData.source }
          options={ sources }
          onChange={ ( e, { name, value } ) => {
            updateField({ target: { name: name, value: value } });
          }}
        /> */}
				<Form.Input
					label="Source"
					readOnly
					value={ customer.source.description }
					width={ 6 }
				/>
				<Form.Select
					name="salesAgent"
					label="Sales Associate"
					width={ 6 }
					value={ formData.salesAgent }
					options={ salesAgents }
					onChange={ ( e, { name, value } ) => {
						updateField( { target: { name, value } } );
					}}
				/>
				<Form.Select
					name="operatingYear"
					label="Operating Year"
					width={ 6 }
					value={ formData.operatingYear }
					options={ years }
					onChange={ ( e, { name, value } ) => updateField( { target: { name, value } } ) }
				/>
				{ customer.customerStage.id === 3
					? <p><small>* When changing your operating year, please be sure to check your medical plans to select a new tier and assign new WS equivalent plans from the new operating year under &ldquo;Plan Comparison&rdquo;</small></p>
					: null
				}
			</Form>
		</Tab.Pane>
	);
};

export default SalesTab;
