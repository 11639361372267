import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import Datepicker from '../Shared/Datepicker';
import { ReportsContext } from './ReportsContext';
import { useForm } from '../../Hooks/useForm';

const ReportModal = ( { generate, fields, name } ) => {
	// context
	const {
		clients,
		reportModal,
		hideReportModal
	} = useContext( ReportsContext );

	const initialFormData = {
		year: null,
		start: null,
		end: null,
		customerIds: [],
		firstName: '',
		lastName: ''
	};

	const initialValidatorData = {
		year: 1111,
		start: new Date(),
		end: new Date(),
		customerIds: [ '' ],
		firstName: '',
		lastName: ''
	};

	// form handler
	const { formData, updateField, setData } = useForm( initialFormData );

	// state
	const [ reportLoading, setReportLoading ] = useState( false );
	const [ validator, setValidator ] = useState( initialValidatorData );
	const [ years, setYears ] = useState( [] );

	useEffect( () => {
		const currentYear = new Date().getFullYear();
		const fiveYears = [
			currentYear + 1,
			currentYear,
			currentYear - 1,
			currentYear - 2,
			currentYear - 3,
			currentYear - 4
		];
		const yearOptions = fiveYears.map( ( year, i ) => {
			return {
				key: i,
				text: year,
				value: year
			};
		} );

		setYears( yearOptions );
	}, [] );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setReportLoading( false );
		setData( initialFormData );
		setValidator( initialValidatorData );
	};

	const mapFields = useCallback( () => fields.map( field => {
		switch ( field ) {
			case 'year':
				return (
					<Form.Group key="year">
						<Form.Select
							name="year"
							label="Year"
							width={ 16 }
							value={ formData.year }
							options={ years }
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
						<Message
							hidden={ Boolean( validator.year ) }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
									Please select a  year.
								</Message.Item>
							</Message.List>
						</Message>
					</Form.Group>
				);

			case 'dates':
				return (
					<Form.Group key="dates">
						<Datepicker
							label="Start Date"
							name="start"
							selected={ formData.start }
							onChange={ e => {
								updateField( { target: { name: 'start', value: e } } );
							} }
						/>
						<Datepicker
							label="End Date"
							name="end"
							selected={ formData.end }
							onChange={ e => {
								updateField( { target: { name: 'end', value: e } } );
							} }
						/>
						<Message
							hidden={ Boolean( validator.start ) && Boolean( validator.end ) }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.start }
								>
									<Icon name="exclamation circle" />
									Please enter an start date.
								</Message.Item>
								<Message.Item
									hidden={ validator.end }
								>
									<Icon name="exclamation circle" />
									Please enter an end date.
								</Message.Item>
								<Message.Item
									hidden={ validator.start.getFullYear() - validator.end.getFullYear() * 12 + ( validator.end.getMonth - validator.start.getMonth() ) <= 36 }
								>
									<Icon name="exclamation circle" />
									Please enter an end date.
								</Message.Item>
							</Message.List>
						</Message>
					</Form.Group>
				);

			case 'customerIds':
				return (
					<Form.Group key="customerIds">
						<Form.Select
							name="customerIds"
							label="Clients"
							width={ 16 }
							value={ formData.customerIds }
							options={ clients }
							multiple
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
						<Message
							hidden={ Boolean( validator.customerIds.length ) }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
									Please select one or more clients.
								</Message.Item>
							</Message.List>
						</Message>
					</Form.Group>
				);

			case 'name':
				return (
					<Form.Group key="name">
						<Form.Input
							label="First Name"
							name="firstName"
							selected={ formData.firstName }
							onChange={ updateField }
							width={ 8 }
						/>
						<Form.Input
							label="Last Name"
							name="lastName"
							selected={ formData.lastName }
							onChange={ updateField }
							width={ 8 }
						/>
					</Form.Group>
				);

			default:
				return null;
		}
	} ), [ clients, fields, formData, updateField, validator, years ] );

	return (
		<Modal
			open={ reportModal }
			size="mini"
		>
			<Modal.Header>{ name }</Modal.Header>
			<Modal.Content>
				{ reportLoading
					? <p><Icon name="spinner" loading />Generating report, please wait...</p>
					: <Form>
						{ mapFields() }
					</Form>
				}
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Generate"
					onClick={ async () => {
						setReportLoading( true );

						const { url } = await generate( formData );

						window.open( url );
						clearData();
						hideReportModal();
					} }
					disabled={ fields.date
						&& ( !formData.start
							|| !formData.end
							|| formData.start.getFullYear() - formData.end.getFullYear() * 12 + ( formData.end.getMonth - formData.start.getMonth() ) > 36
						)
            || fields.customerId && !formData.customerIds.length
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideReportModal();
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default ReportModal;
