import React, { useMemo, useCallback, useContext, useEffect } from 'react';
import BenefitGroupsTable from './BenefitGroupsTable';
import { Divider, Dropdown, Form, Header, Segment, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { AuthContext } from '../../Auth/AuthContext';

const ClientBenefitsTab = ( { formData, updateField } ) => {
	const {
		customer,
		getCustomer,
		clientBenefit,
		eligibilityPeriods,
		rehirePeriods,
		ptFtPeriods,
		setClientBenefitYear,
		clientBenefitReadonly,
		setClientBenefitReadonly
	} = useContext( CustomersContext );

	const { authedUser } = useContext( AuthContext );

	const yearOptions = useMemo( () => customer.benefitYears.map( year => ( { text: year, value: year, key: year } ) ), [ customer.benefitYears ] );

	const handleYearChange = useCallback( ( e, { value } ) => {
		setClientBenefitYear( value );
	}, [ setClientBenefitYear ] );

	useEffect( () => {
		if ( authedUser.role.id !== 4 && authedUser.role.id !== 5 && authedUser.role.id !== 8 ) {
			setClientBenefitReadonly( clientBenefit.year !== customer.operatingYear );
		}
	}, [ clientBenefit.year, customer.operatingYear, setClientBenefitReadonly, authedUser ] );

	if (
		clientBenefit.subsidiaryGroup.primaryClientID
		&& clientBenefit.subsidiaryGroup.primaryClientID !== clientBenefit.id
	) {
		return (
			<Tab.Pane>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Header style={{ margin: '0' }}>Benefits</Header>
				</div>
				<Divider />
				<p>
					<span
						style={ { color: '#4183c4', cursor: 'pointer' } }
						onClick={ () => getCustomer( clientBenefit.subsidiaryGroup.primaryCustomerID ) }
					>
						Click here
					</span> to view client benefit information for { clientBenefit.subsidiaryGroup.name } </p>
			</Tab.Pane>
		);
	}

	return (
		<Tab.Pane>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Header style={{ margin: '0' }}>Benefits</Header>
				<span>
					Year&nbsp;
					<Dropdown
						defaultValue={ customer.operatingYear }
						label="year"
						inline
						options={ yearOptions }
						onChange={ handleYearChange }
						size="tiny"
					/>
				</span>
			</div>
			<Divider />
			<Form>
				<Form.Group as={ Segment } style={{ marginLeft: 0, marginRight: 0 }}>
					{ clientBenefitReadonly
						? <Form.Input
							readOnly
							value={ clientBenefit.eligibility.description }
							label="Eligibility Period"
							width={ 16 }
						/>
						: <Form.Select
							required
							name="eligibility"
							value={ formData.eligibility || '' }
							label="Eligibility Period"
							options={ eligibilityPeriods }
							width={ 16 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ clientBenefitReadonly
						? <Form.Input
							readOnly
							value={ clientBenefit.rehire.description }
							label="Rehire Period"
							width={ 16 }
						/>
						: <Form.Select
							required
							name="rehire"
							value={ formData.rehire || '' }
							label="Rehire Period"
							options={ rehirePeriods }
							width={ 16 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ clientBenefitReadonly
						? <Form.Input
							readOnly
							value={ clientBenefit.ptft.description }
							label="PT/FT Period"
							width={ 16 }
						/>
						: <Form.Select
							required
							name="ptft"
							value={ formData.ptft || '' }
							label="PT/FT Period"
							options={ ptFtPeriods }
							width={ 16 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
			</Form>
			<BenefitGroupsTable />
		</Tab.Pane>
	);
};

export default ClientBenefitsTab;
