import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Divider, Dropdown, Form, Grid, Header, Icon, Modal, Segment, Tab, Table } from 'semantic-ui-react';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
// import YearChangeConfirmModal from './YearChangeConfirmModal';
import { CustomersContext } from '../CustomersContext';
import MedicalPlansModal from './MedicalPlansModal';

const ComparisonTab = props => {
	// props
	const { formData, updateField, handleUpdate, sandbox } = props;

	// context
	const {
		customer,
		rfpYear,
		changeRfpYear,
		wsPlans,
		medicalPlans,
		getWsPlans,
		medicalTiers,
		postMedicalPlan,
		deleteMedicalPlan,
		generateComparison,
		clearComparison,
		comparison,
		comparisonError,
		getRfpPremiumSheet,
		clearRfpPremiumSheet,
		rfpPremiumSheet,
		rfpPremiumError
	} = useContext( CustomersContext );

	useEffect( () => {
		getWsPlans( formData.operatingYear );
	}, [ getWsPlans, formData.operatingYear ] );

	const yearOptions = useMemo( () => sandbox ? customer.benefitYears.map( year => ( { text: year, value: year, key: year } ) ) : null, [ customer.benefitYears, sandbox ] );

	// state
	const [ plan, setPlan ] = useState( '' );
	const [ medicalPlansModal, setMedicalPlansModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );
	const [ comparisonModal, setComparisonModal ] = useState( false );
	const [ premiumModal, setPremiumModal ] = useState( false );

	const disableGenerateButton = () => {
		return !medicalPlans.some( plan => plan.selected ) || !formData.medicalTier;
	};

	const handleComparisonModalClose = () => {
		setComparisonModal( false );
		clearComparison();
	};

	const handlePremiumModalClose = () => {
		setPremiumModal( false );
		clearRfpPremiumSheet();
	};

	const mapMedicalPlans = () => {
		return medicalPlans.map( ( plan, i ) => {
			return (
				<Table.Row key={ i }>
					<Table.Cell textAlign="center">
						<Checkbox
							checked={ plan.selected }
							onChange={ () => postMedicalPlan( { ...plan, selected: !plan.selected } ) }
						/>
					</Table.Cell>
					<Table.Cell>{ plan.planName }</Table.Cell>
					<Table.Cell>
						<Button
							icon
							primary
							size="tiny"
							onClick={ () => {
								setPlan( plan );
								setMedicalPlansModal( true );
							}}
						>
							<Icon name="pencil" />
						</Button>
						<Button
							icon
							negative
							size="tiny"
							onClick={ () => {
								setDeleteConfirmModalNameProp( plan.planName );
								setDeleteId( plan.id );
								setDeleteConfirmModal( true );
							}}
						>
							<Icon name="trash" />
						</Button>
					</Table.Cell>
				</Table.Row>
			);
		} );
	};

	const mapWsPlans = () => {
		return wsPlans.map( ( plan, i ) => {
			return (
				<Table.Row key={ i }>
					<Table.Cell textAlign="center">
						{ i + 1 }
					</Table.Cell>
					<Table.Cell>{ plan.name }</Table.Cell>
				</Table.Row>
			);
		} );
	};

	return (
		<Tab.Pane>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Header>Plan Comparison</Header>
				{ sandbox
					? <span>
						Plan Year&nbsp;
						<Dropdown
							value={ rfpYear }
							label="year"
							inline
							options={ yearOptions }
							onChange={ ( e, { value } ) => changeRfpYear( value ) }
							size="tiny"
						/>
					</span>
					: null
				}
			</div>
			<Divider />
			<Grid columns="2" stretched>
				<Grid.Row>
					<Grid.Column width={ 6 }>
						<Form>
							<fieldset style={{ height: '100%' }}>
								<legend style={{ padding: '0 0.5rem' }}>Worksmart Plans</legend>
								<Form.Select
									name="medicalTier"
									label="Medical Tier"
									width={ 16 }
									value={ formData.medicalTier || 0 }
									options={[
										{ key: 0, text: 'Select an option', value: 0 },
										...medicalTiers
									]}
									onChange={ ( e, { name, value } ) => {
										updateField( { target: { name, value } } );
									} }
								/>
								<Table basic="very">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell></Table.HeaderCell>
											<Table.HeaderCell>Plan Name</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{ wsPlans.length > 0
											? mapWsPlans()
											: <Table.Row>
												<Table.Cell colSpan="4" textAlign="center">
                              There are no WorkSmart plans
												</Table.Cell>
											</Table.Row>
										}
									</Table.Body>
								</Table>
							</fieldset>
						</Form>
					</Grid.Column>
					<Grid.Column width={ 10 }>
						<Form>
							<fieldset style={{ height: '100%' }}>
								<legend style={{ padding: '0 0.5rem' }}>Customer Plans</legend>
								<Form.Button
									primary
									fluid
									content="Add New"
									onClick={ () => setMedicalPlansModal( true ) }
								/>
								<Table basic="very">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={ 2 }></Table.HeaderCell>
											<Table.HeaderCell width={ 12 }>Plan Name</Table.HeaderCell>
											<Table.HeaderCell width={ 2 }></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{ medicalPlans.length > 0
											? mapMedicalPlans()
											: <Table.Row>
												<Table.Cell colSpan="5" textAlign="center">
                              There are no customer plans
												</Table.Cell>
											</Table.Row>
										}
									</Table.Body>
								</Table>
							</fieldset>
						</Form>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{ justifyContent: 'space-between', paddingLeft: '1rem', paddingRight: '1rem' }}>

					{/* <a href="https://wsapi.azurewebsites.net/temp_reports/rptBenefitPremiumSheet.pdf" target="_blank" rel="noopener noreferrer">*/}
					<Segment
						style={{ cursor: 'pointer' }}
						onClick={ () => {
							getRfpPremiumSheet( formData.medicalTier ? formData.medicalTier : null );
							setPremiumModal( true );
						}}
					>
						<Icon name="file" />
              RFP Premium Benefit Sheet
					</Segment>
					{/* </a>*/}
					<Button
						disabled={ disableGenerateButton() }
						primary
						content="Generate Comparison"
						onClick={ async () => {
							await handleUpdate();
							generateComparison();
							setComparisonModal( true );
						}}
					/>
				</Grid.Row>
			</Grid>
			<MedicalPlansModal
				plan={ plan }
				setPlan={ setPlan }
				medicalPlansModal={ medicalPlansModal }
				setMedicalPlansModal={ setMedicalPlansModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Plan"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deleteMedicalPlan( deleteId ) }
			/>
			{/* comparison modal */}
			<Modal open={ comparisonModal } onClose={ handleComparisonModalClose } size="mini">
				<Modal.Header>Comparison</Modal.Header>
				<Modal.Content>
					{ comparison
						? <>
							<p>Your comparison has been generated!</p>
							<a href={ comparison.url } target="_blank" rel="noopener noreferrer"><Icon name="file" /> View Comparison</a>
						</>
						: comparisonError
							? <p>Something went wrong. If the problem persists, please contact support.</p>
							: <p><Icon name="spinner" loading /> Generating comparison...</p>
					}
				</Modal.Content>
				<Modal.Actions>
					<Button
						content="close"
						disabled={ !comparison }
						onClick={ handleComparisonModalClose }
					/>
				</Modal.Actions>
			</Modal>
			{/* premium modal */}
			<Modal open={ premiumModal } onClose={ handlePremiumModalClose } size="mini">
				<Modal.Header>RFP Premium Sheet</Modal.Header>
				<Modal.Content>
					{ rfpPremiumSheet
						? <>
							<p>Your RFP Benefit Premium Sheet has been generated!</p>
							<a href={ rfpPremiumSheet.url } target="_blank" rel="noopener noreferrer"><Icon name="file" /> View Premium Sheet</a>
						</>
						: rfpPremiumError
							? <p>Something went wrong. If the problem persists, please contact support.</p>
							: <p><Icon name="spinner" loading /> Generating Premium Sheet...</p>
					}
				</Modal.Content>
				<Modal.Actions>
					<Button
						content="close"
						disabled={ !rfpPremiumSheet }
						onClick={ handlePremiumModalClose }
					/>
				</Modal.Actions>
			</Modal>
		</Tab.Pane>
	);
};

export default ComparisonTab;
