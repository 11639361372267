import React, { useContext } from 'react';
import { OperationsContext } from '../OperationsContext';
import { Button, Dropdown, Icon, Segment } from 'semantic-ui-react';

const FilesFilter = () => {
	const {
		// getFiles,
		fileTypes,
		fileType,
		handleFileTypeChange,
		showFilesModal,
		showAnthemClaimsModal
		// resetFilter
	} = useContext( OperationsContext );

	return (
		<Segment>
			<span style={{ margin: '0 20px 0' }}>Type</span>
			<Dropdown
				value={ fileType }
				selection
				options={ fileTypes }
				onChange={ ( e, { value } ) => handleFileTypeChange( value ) }
			/>
			{/* <Button
				animated="fade"
				style={{ width: '100px', marginLeft: '20px' }}
				onClick={ () => resetFilter( getFiles ) }
			>
				<Button.Content visible>
					<Icon name="undo" />
				</Button.Content>
				<Button.Content hidden> Reset </Button.Content>
			</Button> */}
			<Button
				animated="fade"
				primary
				floated="right"
				onClick={ () => {
					if ( fileType === 166 ) { // anthem claims
						showAnthemClaimsModal();
					} else { // 167 - prism premium
						showFilesModal();
					}
				}}
				style={{ width: '150px', marginRight: '10px' }}
			>
				<Button.Content visible>
					<Icon name="add" />
				</Button.Content>
				<Button.Content hidden>New File</Button.Content>
			</Button>
		</Segment>
	);
};

export default FilesFilter;
