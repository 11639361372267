import React, { useCallback, useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';

const Note = ( { note, onSubmit, onDelete } ) => {
	const [ editable, setEditable ] = useState( false );
	const [ content, setContent ] = useState( note.content );
	const [ loading, setLoading ] = useState( false );

	const handleSubmit = useCallback( async () => {
		setLoading( true );
		await onSubmit( { ...note, content } );
		setContent( note.content );
		setEditable( false );
		setLoading( false );
	}, [ content, note, onSubmit ] );

	const toggleEdit = useCallback( () => {
		setEditable( prev => !prev );
	}, [] );

	const handleDelete = useCallback( async () => {
		setLoading( true );
		await onDelete( note.id );
		setLoading( false );
	}, [ note.id, onDelete ] );

	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', paddingBottom: '1rem', borderBottom: '1px solid rgba(34,36,38,.15)' }}>
			{ editable
				?	<Form>
					<Form.TextArea
						value={ content }
						onChange={ ( e, { value } ) => setContent( value ) }
					/>
					<Form.Field style={{ textAlign: 'right' }}>
						<Button
							content="Cancel"
							disabled={ loading }
							size="mini"
							onClick={ toggleEdit }
						/>
						<Button
							content="Save"
							color="primary"
							disabled={ !content || content === note.content }
							loading={ loading }
							size="mini"
							onClick={ handleSubmit }
						/>
					</Form.Field>
				</Form>
				: <>
					<p style={{ maxWidth: '500px' }}>{ note.content }</p>
					<span style={{ maxWidth: '500px', display: 'flex' }}><small>{ note.createdBy.name }&nbsp;-&nbsp;</small><small>{ new Date( note.createdBy.createdOn.replace( 'Z', '' ) ).toLocaleDateString() }</small><span style={{ marginLeft: 'auto' }}><Icon name="pencil" link onClick={ toggleEdit } /><Icon name="trash" link onClick={ handleDelete } /></span></span>
				</>
			}
		</div>
	);
};

export default Note;
