import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Dropdown, Tab } from 'semantic-ui-react';
import PlanTable from './PlanTable';
import ResetConfirmModal from './ResetConfirmModal';

const VisionTab = ( { vision, frequencyOptions, plans, updatePlans, setUiDisabled, readonly } ) => {
	const [ frequency, setFrequency ] = useState( 'monthly' );
	const [ percentage, setPercentage ] = useState( false );
	const [ percentageDisabled, setPercentageDisabled ] = useState( false );
	const [ isFlat, setIsFlat ] = useState( vision.flatRate );
	const [ minimum, setMinimum ] = useState( 0 );
	const [ resetConfirmModal, setResetConfirmModal ] = useState( false );

	const mapPlans = useCallback( () => plans.map( ( { id, planName, active, hasHSA, ...rest } ) => {
		return (
			<PlanTable
				active={ active }
				flatRate={ isFlat }
				id={ id }
				key={ id }
				minimum={ minimum }
				multiplier={ frequency }
				percentage={ percentage }
				plan={ { ...rest, minimumContribution: vision.minContribution } }
				planName={ planName }
				hasHSA={ hasHSA }
				setUiDisabled={ setUiDisabled }
				update={ updatePlans }
				readonly={ readonly }
			/>
		);
	} ), [ plans, isFlat, minimum, frequency, percentage, vision.minContribution, setUiDisabled, updatePlans, readonly ] );

	return (
		<Tab.Pane>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
				<strong>{ `Payments Per Year: ${ frequency === 'semi-monthly' ? '24' : frequency === 'bi-weekly' ? '24 (26 HSA payments)' : frequency === 'weekly' ? '48 (52 HSA payments)' : '12' }` }</strong>
				<div>
					<Dropdown
						disabled={ percentage }
						value={ frequency }
						inline
						options={ frequencyOptions }
						onChange={ ( e, { value } ) => {
							setFrequency( value );
						} }
						size="tiny"
					/>
					<Button
						disabled={ readonly }
						content="Reset"
						onClick={ () => setResetConfirmModal( true ) }
						style={{ marginLeft: '2rem' }}
					/>
					<Checkbox
						label="Percentage"
						checked={ percentage }
						disabled={ percentageDisabled || frequency !== 'monthly' }
						onChange={ ( e, { checked } ) => {
							setPercentage( checked );
						} }
						size="small"
						style={{ marginLeft: '2rem' }}
					/>
					<Checkbox
						disabled={ readonly }
						label="Flat Rate"
						checked={ isFlat }
						onChange={ ( e, { checked } ) => {
							setIsFlat( checked );
							setPercentage( false );
							setPercentageDisabled( prev => !prev );
						} }
						size="small"
						style={{ marginLeft: '2rem' }}
					/>
				</div>
			</div>
			<ResetConfirmModal
				open={ resetConfirmModal }
				closeModal={ () => setResetConfirmModal( false ) }
				onClick={ () => setMinimum( prev => prev + 1 ) }
			/>
			{ mapPlans() }
		</Tab.Pane>
	);
};

export default VisionTab;
