import React, { useState } from 'react';
import { Button, Confirm, Select } from 'semantic-ui-react';

// @props: name, type, open, closeModal, onClick

const ArchiveConfirmModal = props => {
	const [ reasonCodeID, setReasonCodeID ] = useState();

	return (
		<Confirm
			className="archive-confirm-modal"
			open={ props.open }
			size="tiny"
			header={ `Archive ${ props.type }?` }
			content={
				<div className="content" style={{ fontSize: '16px' }}>
					<label>Please select a reason</label>
					<br />
					<Select
						options={ props.reasons }
						onChange={ ( e, { value } ) => setReasonCodeID( value ) }
					/>
				</div>
			}
			cancelButton="Cancel"
			confirmButton={ () => (
				<Button
					primary
					onClick={ () => {
						props.onClick( reasonCodeID );
						props.closeModal();
					} }
				>
          Archive
				</Button>
			) }
			onCancel={ props.closeModal }
		/>
	);
};

export default ArchiveConfirmModal;
