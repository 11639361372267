import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { maskCurrency, unmaskCurrency, integerKeyDown } from '../../../Services/inputMasks';
import PercentageInput from '../../Shared/PercentageInput';

const UnemploymentWitholdingsModal = props => {
	// context
	const { postWitholding, sutaStates } = useContext( CustomersContext );

	// form handler
	const { formData, updateField, setData } = useForm( {} );

	// state
	const [ validator, setValidator ] = useState( {
		state: 'string',
		employeeCount: 0,
		sutaNum: 'string',
		taxRate: 0,
		proposedTaxRate: 0,
		whAccount: 'string'

		/* currentSUTA: '0',
       proposedSUTA: '0' */
	} );

	// props
	const { record, setRecord, unemploymentWitholdingsModal, setUnemploymentWitholdingsModal } = props;

	// set formData to customer data
	useEffect( () => {
		if ( record ) {
			const recordData = {
				state: record.state,
				employeeCount: record.employeeCount,
				sutaNum: record.sutaNum,
				taxRate: record.taxRate,
				proposedTaxRate: record.proposedTaxRate,
				whAccount: record.whAccount,
				currentSUTA: maskCurrency( record.currentSUTA ),
				proposedSUTA: maskCurrency( record.proposedSUTA ),
				override: record.override
			};

			setData( recordData );
		}

		// on unmount
		return () => {
			setData( {} );
		};
	}, [ record, setData ] );

	// prepare data for api call (update)
	const handleUpdate = () => {
		postWitholding( {
			...record,
			state: formData.state,
			employeeCount: formData.employeeCount,
			sutaNum: formData.sutaNum,
			taxRate: formData.taxRate,
			proposedTaxRate: formData.proposedTaxRate,
			whAccount: formData.whAccount,
			currentSUTA: unmaskCurrency( formData.currentSUTA ),
			proposedSUTA: unmaskCurrency( formData.proposedSUTA ),
			override: formData.override
		} );
		setUnemploymentWitholdingsModal( false );
	};

	// prepare data for api call (create)
	const handleCreateNew = () => {
		postWitholding( {
			id: 0,
			state: formData.state,
			employeeCount: formData.employeeCount,
			sutaNum: formData.sutaNum,
			taxRate: formData.taxRate,
			// proposedTaxRate: formData.proposedTaxRate,
			whAccount: formData.whAccount,

			/* currentSUTA: unmaskCurrency( formData.currentSUTA ),
         proposedSUTA: unmaskCurrency( formData.proposedSUTA ), */
			override: false
		} );
		setUnemploymentWitholdingsModal( false );
	};

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		setRecord( '' );
		setData( {} );
		setValidator( {
			state: 'string',
			employeeCount: 0,
			sutaNum: 'string',
			taxRate: 0,
			proposedTaxRate: 0,
			whAccount: 'string'

			/* currentSUTA: '0',
         proposedSUTA: '0' */
		} );
	};

	return (
		<Modal
			id="unemployment-witholdings-modal"
			open={ unemploymentWitholdingsModal }
			closeOnDimmerClick={ false }
			size="mini"
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				{ record ? 'Edit Record' : 'Add New Record' }
				{ record
					? <Form>
						<Form.Field style={{ display: 'flex', alignItems: 'center' }}>
							<label style={{ marginRight: '1rem' }}>Override</label>
							<Form.Checkbox
								name="override"
								toggle
								checked={ formData.override || false }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
					</Form>
					: null
				}
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group>
						<Form.Select
							required
							name="state"
							value={ formData.state || '' }
							label="State"
							options={ sutaStates.map( ( state, i ) => ( { key: i, value: state, text: state } ) ) }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
							error={ validator.state.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, state: formData.state || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, state: 'XX' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={ validator.state.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please select a state.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Input
							required
							type="number"
							name="employeeCount"
							value={ parseInt( formData.employeeCount ) >= 0 ? formData.employeeCount : '' }
							label="Number of Employees"
							width={ 8 }
							onChange={ updateField }
							error={ !( parseInt( validator.employeeCount ) >= 0 ) }
							onBlur={ () => {
								setValidator( { ...validator, employeeCount: parseInt( formData.employeeCount ) >= 0 ? formData.employeeCount : '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, employeeCount: 0 } );
							}}
							onKeyDown={ integerKeyDown }
						/>
						<Form.Input
							required
							name="sutaNum"
							value={ formData.sutaNum || '' }
							label="SUTA Number"
							width={ 8 }
							onChange={ updateField }
							error={ validator.sutaNum.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, sutaNum: formData.sutaNum || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, sutaNum: 'string' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={
							parseInt( validator.employeeCount ) >= 0
              && validator.sutaNum.length > 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ parseInt( validator.employeeCount ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Number of Employees.
							</Message.Item>
							<Message.Item
								hidden={ validator.sutaNum.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a SUTA number.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Input
							required
							name="whAccount"
							value={ formData.whAccount || '' }
							label="Witholdings Account"
							width={ 16 }
							maxLength={ 25 }
							onChange={ updateField }
							error={ validator.whAccount.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, whAccount: formData.whAccount || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, whAccount: 'string' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={ validator.whAccount.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.whAccount.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Witholdings Account.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<PercentageInput
							required
							width={ 8 }
							name="taxRate"
							label="Current Tax Rate"
							value={ formData.taxRate }
							onChange={ updateField }
							error={ !( parseFloat( validator.taxRate ) >= 0 ) }
							onBlur={ () => setValidator( { ...validator, taxRate: parseFloat( formData.taxRate ) >= 0 ? parseFloat( formData.taxRate ).toString() : '' } ) }
							onFocus={ () => setValidator( { ...validator, taxRate: 0 } ) }
						/>
						{ record
							? <PercentageInput
								required={ Boolean( formData.override ) }
								width={ 8 }
								name="proposedTaxRate"
								label="Proposed Tax Rate"
								value={ formData.proposedTaxRate }
								onChange={ updateField }
								error={ !( parseFloat( validator.proposedTaxRate ) >= 0 ) }
								onBlur={ () => setValidator( { ...validator, proposedTaxRate: parseFloat( formData.proposedTaxRate ) >= 0 ? parseFloat( formData.proposedTaxRate ).toString() : '' } ) }
								onFocus={ () => setValidator( { ...validator, proposedTaxRate: 0 } ) }
								readOnly={ !formData.override }
								disabled={ !formData.override }
							/>
							: null
						}
					</Form.Group>
					<Message
						hidden={
							parseFloat( validator.taxRate ) >= 0
              && parseFloat( validator.proposedTaxRate ) >= 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ parseFloat( validator.taxRate ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Current Tax Rate.
							</Message.Item>
							<Message.Item
								hidden={ parseFloat( validator.proposedTaxRate ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Proposed Tax Rate.
							</Message.Item>
						</Message.List>
					</Message>
					{ record
						? <>
							<Form.Group>
								<Form.Input
									name="currentSUTA"
									value={ formData.currentSUTA || '' }
									label="Current SUTA"
									readOnly
									transparent
									width={ 8 }

									/* onChange={ updateField }
                     error={ validator.currentSUTA.length === 0 }
                     onBlur={ ( e ) => {
                       updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                       setValidator({ ...validator, currentSUTA: formData.currentSUTA || '' });
                     }}
                     onFocus={ () => {
                       setValidator( { ...validator, currentSUTA: '0' } );
                     }}
                     onKeyDown={ floatKeyDown } */
								/>
								<Form.Input
									name="proposedSUTA"
									value={ formData.proposedSUTA || '' }
									readOnly
									transparent
									label="Proposed SUTA"
									width={ 8 }

									/* onChange={ updateField }
                     error={ validator.proposedSUTA.length === 0 }
                     onBlur={ ( e ) => {
                       updateField({ target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } });
                       setValidator({ ...validator, proposedSUTA: formData.proposedSUTA || '' });
                     }}
                     onFocus={ () => {
                       setValidator( { ...validator, proposedSUTA: '0' } );
                     }}
                     onKeyDown={ floatKeyDown } */
								/>
							</Form.Group>
							{/* <Message
                hidden={
                  validator.currentSUTA.length > 0
                  && validator.proposedSUTA.length > 0
                }
                negative
                style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
              >
                <Message.List style={{ listStyle: 'none' }}>
                  <Message.Item
                    hidden={ validator.currentSUTA.length > 0 }
                  >
                    <Icon name="exclamation circle" />
                    Please enter a value for Current SUTA.
                  </Message.Item>
                  <Message.Item
                    hidden={ validator.proposedSUTA.length > 0 }
                  >
                    <Icon name="exclamation circle" />
                    Please enter a value for Proposed SUTA.
                  </Message.Item>
                </Message.List>
              </Message> */}
						</>
						: null
					}
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						record ? handleUpdate() : handleCreateNew();
						clearData();
						setUnemploymentWitholdingsModal( false );
					}}
					disabled={
						( formData.state ? formData.state.length === 0 : true )
            || !( parseInt( formData.employeeCount ) >= 0 )
            || ( formData.sutaNum ? formData.sutaNum.length === 0 : true )
            || ( formData.whAccount ? formData.whAccount.length === 0 : true )
            || !( parseFloat( formData.taxRate ) >= 0 )
            || ( record && formData.override ? !( parseFloat( formData.proposedTaxRate ) >= 0 ) : false )

						/* || ( formData.currentSUTA ? formData.currentSUTA.length === 0 : true )
               || ( formData.proposedSUTA ? formData.proposedSUTA.length === 0 : true ) */
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						setUnemploymentWitholdingsModal( false );
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default UnemploymentWitholdingsModal;
