import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { maskPhone, unmaskPhone, validatePhone, validateZip, validateEmail, stateOptions, integerKeyDown } from '../../../Services/inputMasks';

const ContactsModal = props => {
	// context
	const { customer, postContact } = useContext( CustomersContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ validator, setValidator ] = useState( {
		contactFirstName: 'string',
		contactLastName: 'string',
		contactEmail: 'string@string.com',
		contactPhone: '1111111111',
		contactZip: '12345'
	} );

	// props
	const { contact, setContact, contactsModal, setContactsModal } = props;

	// set formData to customer data
	useEffect( () => {
		if ( contact ) {
			const contactData = {
				contactFirstName: contact.firstName ? contact.firstName : '',
				contactLastName: contact.lastName ? contact.lastName : '',
				contactTitle: contact.title ? contact.title : '',
				contactPrimary: contact.primary ? contact.primary : '',
				contactBenefit: contact.benefitAdmin ? contact.benefitAdmin : '',
				contactEmail: contact.email ? contact.email : '',
				contactPhone: contact.phone ? contact.phone : '',
				contactAddress: contact.address ? contact.address : '',
				contactAddress2: contact.address2 ? contact.address2 : '',
				contactCity: contact.city ? contact.city : '',
				contactState: contact.state ? contact.state : '',
				contactZip: contact.zip ? contact.zip : ''
			};

			setData( contactData );
			setValidator( contactData );
		}

		// on unmount
		return () => setData( {} );
	}, [ contact, setData ] );

	// prepare data for api call (update)
	const handleUpdate = () => {
		postContact( {
			...contact,
			firstName: formData.contactFirstName,
			lastName: formData.contactLastName,
			title: formData.contactTitle,
			primary: formData.contactPrimary,
			benefitAdmin: formData.contactBenefit,
			email: formData.contactEmail,
			phone: formData.contactPhone,
			address: formData.contactAddress,
			address2: formData.contactAddress2,
			city: formData.contactCity,
			state: formData.contactState,
			zip: formData.contactZip
		} );
		setContactsModal( false );
	};

	// prepare data for api call (create)
	const handleCreateNew = () => {
		postContact( {
			id: 0,
			firstName: formData.contactFirstName,
			lastName: formData.contactLastName,
			title: formData.contactTitle,
			primary: formData.contactPrimary,
			benefitAdmin: formData.contactBenefit,
			email: formData.contactEmail,
			phone: formData.contactPhone,
			address: formData.contactAddress,
			address2: formData.contactAddress2,
			city: formData.contactCity,
			state: formData.contactState,
			zip: formData.contactZip
		} );
		setContactsModal( false );
	};

	// clear out contact, formData, and validator when modal closes
	const clearData = () => {
		setContact( '' );
		setData( {} );
		setValidator( {
			contactFirstName: 'string',
			contactLastName: 'string',
			contactEmail: 'string@string.com',
			contactPhone: '1111111111',
			contactZip: '12345'
		} );
	};

	return (
		<Modal
			id="contacts-modal"
			open={ contactsModal }
			onClose={ () => {
				setContact( '' );
				setContactsModal( false );
			}}
			closeOnDimmerClick={ false }
			size="tiny"
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				{ contact ? 'Edit Contact' : 'Add New Contact' }
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group>
						<Form.Input
							required
							name="contactFirstName"
							value={ formData.contactFirstName || '' }
							label="First Name"
							width={ 8 }
							maxLength={ 125 }
							onChange={ updateField }
							error={ validator.contactFirstName.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, contactFirstName: formData.contactFirstName || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, contactFirstName: 'string' } );
							}}
						/>
						<Form.Input
							required
							name="contactLastName"
							value={ formData.contactLastName || '' }
							label="Last Name"
							width={ 8 }
							maxLength={ 125 }
							onChange={ updateField }
							error={ validator.contactLastName.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, contactLastName: formData.contactLastName || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, contactLastName: 'string' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={
							validator.contactFirstName.length > 0
              && validator.contactLastName.length > 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.contactFirstName.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a first name.
							</Message.Item>
							<Message.Item
								hidden={ validator.contactLastName.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a last name.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Input
							name="contactTitle"
							value={ formData.contactTitle || '' }
							label="Title"
							width={ customer.customerStage.id === 4 ? 8 : 13 }
							maxLength={ 50 }
							onChange={ updateField }
						/>
						<Form.Field width={ customer.customerStage.id === 4 ? 4 : 3 } style={{ textAlign: 'center' }}>
							<label>Primary</label>
							<Form.Checkbox
								name="contactPrimary"
								inline={ false }
								style={{ marginTop: '1rem' }}
								disabled={ contact.primary }
								checked={ formData.contactPrimary || false }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
						{ customer.customerStage.id === 4 // client
							? <Form.Field width={ 4 } style={{ textAlign: 'center' }}>
								<label>Benefit</label>
								<Form.Checkbox
									name="contactBenefit"
									inline={ false }
									style={{ marginTop: '1rem' }}
									disabled={ contact.contactBenefit }
									checked={ formData.contactBenefit || false }
									onChange={ ( e, { name, checked } ) => {
										updateField( { target: { name, value: checked } } );
									} }
								/>
							</Form.Field>
							: null
						}
					</Form.Group>
					<Form.Group>
						<Form.Input
							name="contactEmail"
							value={ formData.contactEmail || '' }
							icon
							label="Email Address"
							width={ 10 }
							maxLength={ 255 }
							onChange={ updateField }
							error={ validator.contactEmail.length > 0 && !validateEmail( validator.contactEmail ) }
							onBlur={ () => {
								setValidator( { ...validator, contactEmail: formData.contactEmail || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, contactEmail: 'string@string.com' } );
							}}
						>
							<input />
							{ formData.contactEmail
								? <Icon
									name="mail"
									link={ true }
									onClick={ () => {
										window.location = `mailto:${ formData.contactEmail }`;
									}}
								/>
								: null
							}
						</Form.Input>
						<Form.Input
							name="contactPhone"
							value={ maskPhone( formData.contactPhone ) || '' }
							label="Phone Number"
							width={ 6 }
							maxLength={ 14 }
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value: unmaskPhone( value ) } } );
							}}
							error={ validator.contactPhone.length > 0 && !validatePhone( validator.contactPhone ) }
							onBlur={ () => {
								setValidator( { ...validator, contactPhone: unmaskPhone( formData.contactPhone ) || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, contactPhone: '1111111111' } );
							}}
							onKeyDown={ integerKeyDown }
						/>
					</Form.Group>
					<Message
						hidden={
							( validator.contactEmail.length === 0 || validateEmail( validator.contactEmail ) )
              && ( validator.contactPhone.length === 0 || validatePhone( validator.contactPhone ) )
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.contactEmail.length === 0 || validateEmail( validator.contactEmail ) }
							>
								<Icon name="exclamation circle" />
                Please enter a valid email address.
							</Message.Item>
							<Message.Item
								hidden={ validator.contactPhone.length === 0 || validatePhone( validator.contactPhone ) }
							>
								<Icon name="exclamation circle" />
                Please enter a valid phone number.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Input
						name="contactAddress"
						value={ formData.contactAddress || '' }
						label="Address Line 1"
						width={ 16 }
						maxLength={ 125 }
						onChange={ updateField }
					/>
					<Form.Input
						name="contactAddress2"
						value={ formData.contactAddress2 || '' }
						label="Address Line 2"
						width={ 16 }
						maxLength={ 125 }
						onChange={ updateField }
					/>
					<Form.Group>
						<Form.Input
							name="contactCity"
							value={ formData.contactCity || '' }
							label="City"
							width={ 8 }
							maxLength={ 125 }
							onChange={ updateField }
						/>
						<Form.Select
							name="contactState"
							value={ formData.contactState || '' }
							label="State"
							options={ stateOptions }
							width={ 3 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
						<Form.Input
							name="contactZip"
							value={ formData.contactZip || '' }
							label="Zip"
							width={ 6 }
							maxLength={ 5 }
							onChange={ updateField }
							error={ validator.contactZip.length > 0 && !validateZip( validator.contactZip ) }
							onBlur={ () => {
								setValidator( { ...validator, contactZip: formData.contactZip || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, contactZip: '12345' } );
							}}
							onKeyDown={ integerKeyDown }
						/>
					</Form.Group>
					<Message
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						hidden={ validator.contactZip.length === 0 || validateZip( validator.contactZip ) }
					>
						<Message.List>
							<Message.Item
								hidden={ validator.contactZip.length === 0 || validateZip( validator.contactZip ) }
							>
								<Icon name="exclamation circle" />
                Please enter a valid 5-digit zip code.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						contact ? handleUpdate() : handleCreateNew();
						clearData();
						setContactsModal( false );
					}}
					disabled={
						( formData.contactFirstName ? formData.contactFirstName.length === 0 : true )
            || ( formData.contactLastName ? formData.contactLastName.length === 0 : true )
            || ( formData.contactEmail ? formData.contactEmail.length > 0 && !validateEmail( formData.contactEmail ) : false )
            || ( formData.contactPhone ? formData.contactPhone.length > 0 && !validatePhone( formData.contactPhone ) : false )
            || ( formData.contactZip ? formData.contactZip.length > 0 && !validateZip( formData.contactZip ) : false )
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						setContactsModal( false );
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default ContactsModal;
