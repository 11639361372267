import React, { useContext, useState } from 'react';
import { Button, Form, Icon, Message, Modal, Progress } from 'semantic-ui-react';
import Datepicker from '../../Shared/Datepicker';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';

const AnthemClaimsModal = () => {
	// context
	const {
		createFile,
		anthemClaimsModal,
		hideAnthemClaimsModal,
		fileUploadProgress
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm( {
		file: null,
		invoicedDate: null,
		paidDate: null,
		version: 1
	} );

	// state
	const [ isUploading, setIsUploading ] = useState( false );
	const [ validator, setValidator ] = useState( {
		file: {},
		invoicedDate: new Date(),
		paidDate: new Date(),
		version: 1
	} );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setIsUploading( false );
		setData( {
			file: null,
			invoicedDate: null,
			paidDate: null,
			version: 1
		} );
		setValidator( {
			file: {},
			invoicedDate: new Date(),
			paidDate: new Date(),
			version: 1
		} );
	};

	const options = [
		{ key: 1, value: 1, text: 'Version 01.2022' },
		{ key: 2, value: 2, text: 'Version 03.2023' }
	];

	return (
		<Modal
			open={ anthemClaimsModal }
			size="mini"
		>
			<Modal.Header>
        File
			</Modal.Header>
			<Modal.Content>
				{ !isUploading
					? <Form>
						<Form.Group>
							<Datepicker
								label="Invoiced Date"
								name="invoicedDate"
								selected={ formData.invoicedDate }
								onChange={ e => {
									updateField( { target: { name: 'invoicedDate', value: e } } );
								} }
							/>
							<Datepicker
								label="Paid Date"
								name="paidDate"
								selected={ formData.paidDate }
								onChange={ e => {
									updateField( { target: { name: 'paidDate', value: e } } );
								} }
							/>
							<Message
								hidden={ validator.invoicedDate && validator.paidDate }
								negative
								style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							>
								<Message.List style={{ listStyle: 'none' }}>
									<Message.Item
										hidden={ validator.invoicedDate }
									>
										<Icon name="exclamation circle" />
										Please enter an invoiced date.
									</Message.Item>
									<Message.Item
										hidden={ validator.paidDate }
									>
										<Icon name="exclamation circle" />
										Please enter an paid date.
									</Message.Item>
								</Message.List>
							</Message>
						</Form.Group>

						<Form.Group>
							<Form.Select
								name="version"
								label="Version"
								width={ 16 }
								value={ formData.version }
								options={ options }
								search
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value } } );
								} }
							/>
							<Message
								hidden={ Boolean( validator.version ) }
								negative
								style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							>
								<Message.List style={{ listStyle: 'none' }}>
									<Message.Item>
										<Icon name="exclamation circle" />
										Please select a version.
									</Message.Item>
								</Message.List>
							</Message>
						</Form.Group>

						<input
							type="file"
							name="file"
							label="Upload New File"
							onChange={ e => updateField( { target: { name: 'file', value: e.target.files[ 0 ] } } )}
						/>
						<Message
							hidden={ validator.file }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
									Please upload a file.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
					: <div>
						<p style={{ textAlign: 'center' }}><Icon name="spinner" loading /> Uploading file, please wait...</p>
						<Progress
							size="tiny"
							label={ `${ fileUploadProgress }%` }
							style={ parseInt( fileUploadProgress ) >= 0 ? { display: 'block', margin: '1rem 0' } : { display: 'none' } }
							color={ fileUploadProgress === 100 ? 'green' : null }
							percent={ fileUploadProgress }
						/>
					</div>
				}
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ async () => {
						setIsUploading( true );

						const { file, invoicedDate, paidDate, version } = formData;
						const data = new FormData();

						data.append( 'file', file );

						await createFile( data, invoicedDate, paidDate, version );
						clearData();
						hideAnthemClaimsModal();
					} }
					disabled={
						isUploading || ( !formData.file
						|| !formData.invoicedDate
						|| !formData.paidDate )
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideAnthemClaimsModal();
					}}
					disabled={ isUploading }
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default AnthemClaimsModal;
