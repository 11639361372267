import React, { useContext, useEffect } from 'react';
import { OperationsContext } from '../OperationsContext';
import { Button, Dropdown, Icon, Segment } from 'semantic-ui-react';

const RatesFilter = props => {

  const {
    getRates,
    rateTypes,
    rateType,
    handleRateTypeChange,
    unavailableStates,
    years,
    year,
    handleYearChange,
    showRatesModal,
    showSutaRatesModal,
    showWCRatesModal,
    resetFilter
  } = useContext( OperationsContext );

  useEffect( () => {
    if ( rateType && year ) {
      resetFilter( getRates );
    }
  }, [] );

  return (
    <Segment>
      <span style={{ margin: '20px' }}>Year</span>
      <Dropdown
        value={ year }
        selection
        options={ years }
        onChange={ ( e, { value } ) => handleYearChange( value ) }
      />
      <span style={{ margin: '0 20px 0' }}>Type</span>
      <Dropdown
        value={ rateType }
        selection
        options={ rateTypes }
        onChange={ ( e, { value } ) => handleRateTypeChange( value ) }
      />
      {/*<Button
        animated="fade"
        primary
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => getRates() }
      >
        <Button.Content visible>
          <Icon name="search" />
        </Button.Content>
        <Button.Content hidden> Search </Button.Content>
      </Button>*/}
      <Button
        animated="fade"
        style={{ width: '100px', marginLeft: '20px' }}
        onClick={ () => resetFilter( getRates ) }
      >
        <Button.Content visible>
          <Icon name="undo" />
        </Button.Content>
        <Button.Content hidden> Reset </Button.Content>
      </Button>
      { rateType !== 70 && rateType !== 71
        ? <Button
            animated="fade"
            primary
            floated="right"
            onClick={ () => {
              if ( rateType === 7 ) {
                showSutaRatesModal();
              } else if ( rateType === 48 ){
                showWCRatesModal();
              } else {
                showRatesModal();
              }
            }}
            disabled={ rateType === 7 && unavailableStates.length > 50 }
            style={{ width: '150px', marginRight: '10px' }}
          >
            <Button.Content visible>
              <Icon name="add" />
            </Button.Content>
            <Button.Content hidden>New Rate</Button.Content>
          </Button>
        : null
      }
    </Segment>
  );
};

export default RatesFilter;
