import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { useForm } from '../../../Hooks/useForm';
// import { maskCurrency, unmaskCurrency, floatKeyDown } from '../../../Services/inputMasks';
import { CustomersContext } from '../CustomersContext';

const NewGroupModal = ( { closeModal, open } ) => {
	// context
	const { getBenefitGroups, updateBenefitGroup } = useContext( CustomersContext );

	// form handler
	const { formData, updateField, setData } = useForm( {
		groupName: '',
		dollars: false // ''
	} );

	// state
	const [ validator, setValidator ] = useState( {
		groupName: 'string'
		// dollars: '0'
	} );

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		setData( {
			groupName: '',
			dollars: false // ''
		} );
		setValidator( {
			groupName: 'string'
			// dollars: '0'
		} );
	};

	const handleCreateNewGroup = useCallback( async () => {
		await updateBenefitGroup( {
			id: 0,
			benefitGroup: {
				id: 0,
				name: formData.groupName
			},
			isBenefitDollars: formData.isBenefitDollars,
			// dollars: unmaskCurrency( formData.dollars ),
			medicalTier: { id: formData.medicalTier },
			isWeekly: false,
			isBiWeekly: false,
			isMonthly: false,
			medical: {},
			dental: {},
			vision: {},
			disability: {},
			life: {}
		} );

		await getBenefitGroups();
	}, [ formData.isBenefitDollars, /* formData.dollars */ formData.groupName, formData.medicalTier, getBenefitGroups, updateBenefitGroup ] );

	return (
		<Modal
			open={ open }
			size="small"
		>
			<Modal.Header>Create New Benefit Group</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group>
						<Form.Input
							required
							name="groupName"
							value={ formData.groupName || '' }
							label="Benefit Group Name"
							width={ 8 }
							maxLength={ 255 }
							onChange={ updateField }
							error={ validator.groupName.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, groupName: formData.groupName || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, groupName: 'string' } );
							}}
						/>
						{/* <Form.Input
							required
							name="dollars"
							value={ formData.dollars || '' }
							label="Benefit Dollars"
							width={ 8 }
							onChange={ updateField }
							error={ validator.dollars.length === 0 }
							onBlur={ e => {
								updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
								setValidator( { ...validator, dollars: formData.dollars || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, dollars: '0' } );
							}}
							onKeyDown={ floatKeyDown }
						/> */}
						<Form.Field width={ 3 } style={{ textAlign: 'center' }} required>
							<label>Benefit Dollars</label>
							<Form.Checkbox
								name="isBenefitDollars"
								inline={ false }
								style={{ marginTop: '1rem' }}
								checked={ formData.isBenefitDollars }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
						{/* <Form.Select
							required
							name="medicalTier"
							fluid
							options={ clientMedicalTiers }
							value={ parseInt( formData.medicalTier ) >= 0 ? formData.medicalTier : 0 }
							label="Medical Tier"
							width={ 8 }
							onChange={ ( e, { name, value } ) => { updateField({ target: { name: name, value: value } }); } }
							error={ !( parseInt( validator.medicalTier ) >= 0 ) }
							onBlur={ () => {
								setValidator( { ...validator, medicalTier: parseInt( formData.medicalTier ) >= 0 ? formData.medicalTier : '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, medicalTier: 0 } );
							}}
						/> */}
					</Form.Group>
					<Message
						hidden={ validator.groupName.length > 0 || validator.dollars.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.groupName.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a groupName.
							</Message.Item>
							{/* <Message.Item
								hidden={ validator.dollars.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Benefit Dollars.
							</Message.Item> */}
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						handleCreateNewGroup();
						clearData();
						closeModal();
					}}
					disabled={
						formData.groupName.length === 0
						// || formData.dollars.length === 0
					}
				/>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Close"
					onClick={ closeModal }
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default NewGroupModal;
