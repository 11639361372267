import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import Table from '../../Shared/Table';
import ClientRenewalModal from './ClientRenewalModal';

const Procedures = () => {
	// context
	const {
		procedures,
		tableLoader,
		getProcedures,
		showClientRenewalModal
	} = useContext( OperationsContext );

	useEffect( () => {
		getProcedures();
	}, [ getProcedures ] );

	const getOperation = useCallback( id => {
		switch ( id ) {
			case 188: { // id for renewal
				return showClientRenewalModal;
			}

			/* case 2: { // id for roll
			   	return showClientRenewalModal;
			   } */

			default: {
				console.log( 'no operation for this procedure' );

				return null;
			}
		}
	}, [ showClientRenewalModal ] );

	const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			style: { textAlign: 'left' },
			width: 300
		},
		{
			Header: 'Description',
			accessor: 'description',
			style: { textAlign: 'left' }
		},
		{
			accessor: 'id',
			Header: '',
			sortable: false,
			filterable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						primary
						disabled={ !row.original.isAvailable }
						size="tiny"
						onClick={ () => {
							const operation = getOperation( row.original.id );

							if ( operation ) {
								operation();
							}
						} }
					>
						Run Procedure
					</Button>
				</div>
			),
			width: 160
		}
	];

	return (
		<div>
			<Header as="h2" style={{ fontWeight: 'normal' }}>Procedures</Header>
			<Segment>
				<Table
					data={ procedures }
					columns={ columns }
					loading={ tableLoader }
					// pageSize={ rateType === 70 || rateType === 71 ? 1 : null  }
				/>
			</Segment>
			<ClientRenewalModal />
		</div>
	);
};

export default Procedures;
