import React, { useContext, useState } from 'react';
import { Button, Divider, Header, Icon, Modal, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import ContactsModal from './ContactsModal';
import { maskPhone } from '../../../Services/inputMasks';

const ContactsTab = () => {
	// context
	const {
		contacts,
		deleteContact
	} = useContext( CustomersContext );

	// state
	const [ contact, setContact ] = useState( '' );
	const [ contactsModal, setContactsModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );
	const [ isPrimaryErrorModal, setIsPrimaryErrorModal ] = useState( false );

	const columns = [
		{
			accessor: 'id',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: () => <Icon name="user" style={{ border: '1px solid black', padding: '1rem', display: 'flex', fontSize: '1.5rem', alignItems: 'center', justifyContent: 'center' }}/>,
			style: { textAlign: 'center' },
			width: 40
		},
		{
			Header: 'Contact Name',
			accessor: 'name',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => {
				return (
					<div>
						{ row.original.primary ? <Icon name="star" /> : null } { row.original.benefitAdmin ? <Icon name="asterisk" /> : null } {`${ row.original.firstName } ${ row.original.lastName }`}
					</div>
				);
			}
		},
		{
			Header: 'Title',
			accessor: 'title',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Address',
			accessor: 'address',
			filterable: false,
			sortable: true
		},
		{
			Header: 'City',
			accessor: 'city',
			filterable: false,
			sortable: true,
			width: 100
		},
		{
			Header: 'State',
			accessor: 'state',
			filterable: false,
			sortable: true,
			width: 60
		},
		{
			Header: 'Zip',
			accessor: 'zip',
			filterable: false,
			sortable: true,
			width: 60
		},
		{
			Header: 'Email',
			accessor: 'email',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <a href={ `mailto:${ row.original.email }` }>{ row.original.email }</a>
		},
		{
			Header: 'Phone',
			accessor: 'phone',
			filterable: false,
			sortable: true,
			Cell: row => row.original.phone ? maskPhone( row.original.phone ) : null,
			width: 120
		},
		{
			Header: 'Edit / Remove',
			accessor: 'id',
			sortable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						onClick={ () => {
							setContact( row.original );
							setContactsModal( true );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							if ( !row.original.primary ) {
								setDeleteConfirmModalNameProp( `${ row.original.firstName } ${ row.original.lastName }` );
								setDeleteId( row.original.id );
								setDeleteConfirmModal( true );
							} else {
								setIsPrimaryErrorModal( true );
							}
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			),
			width: 120
		}
	];

	return (
		<Tab.Pane>
			<Header>Contacts</Header>
			<Divider />
			<Button
				style={{ marginBottom: '1rem' }}
				onClick={ () => setContactsModal( true ) }
			>
        Add Contact
			</Button>
			{ Object.keys( contacts ).length > 0
				? <Table
					key={ contacts.length }
					data={ contacts }
					columns={ columns }
					pageSize={ contacts.length }
					hidePagination
				/>
				: <p style={{ padding: '1rem 0 1rem 1rem' }}>No contacts found.</p>
			}
			<ContactsModal
				contact={ contact }
				setContact={ setContact }
				contactsModal={ contactsModal }
				setContactsModal={ setContactsModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Contact"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deleteContact( deleteId ) }
			/>
			<Modal
				open={ isPrimaryErrorModal }
				onClose={ () => setIsPrimaryErrorModal( false ) }
				size="mini"
			>
				<Modal.Content>
          Cannot delete the primary contact.
					<br />Please set another contact to the primary contact before deleting this one.
				</Modal.Content>
			</Modal>
		</Tab.Pane>
	);
};

export default ContactsTab;
