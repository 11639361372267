import React, { useContext } from 'react';
import { Button, Form, Icon, Modal, Progress } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';

const DocumentUploadModal = () => {
	// context
	const {
		customer,
		document,
		documentTypeID,
		docUploadProgress,
		uploadDocument,
		documentUploadModal,
		hideDocumentUploadModal
	} = useContext( CustomersContext );

	// form handler
	const { formData, updateField } = useForm();

	return (
		<Modal
			open={ documentUploadModal }
			onClose={ hideDocumentUploadModal }
			closeOnDimmerClick={ false }
			size="mini"
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        Upload Document
			</Modal.Header>
			<Modal.Content>
				<Form>
					{ document
						? document.name
							? <>
								<a href={ `${ document.url }/${ document.name }` } target="_blank" rel="noopener noreferrer">
									<Form.Input
										readOnly
										transparent
										icon
										iconPosition="right"
										name="currentFileName"
										value={ document.name }
										label="Current File"
									>
										<input />
										<Icon name="download" style={{ paddingRight: '1rem' }} />
									</Form.Input>
								</a>
								<br />
							</>
							: null
						: null
					}
					<input
						type="file"
						name="file"
						label="Upload New File"
						onChange={ e => updateField( { target: { name: 'file', value: e.target.files[ 0 ] } } )}
					/>
				</Form>
				<Progress
					size="tiny"
					label={ `${ docUploadProgress }%` }
					style={ parseInt( docUploadProgress ) >= 0 ? { display: 'block', margin: '1rem 0' } : { display: 'none' } }
					color={ docUploadProgress === 100 ? 'green' : null }
					percent={ docUploadProgress }
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ async () => {
						const data = new FormData();

						data.append( 'file', formData.file );
						await uploadDocument( customer.id, documentTypeID, data );
						hideDocumentUploadModal();
					}}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ hideDocumentUploadModal }
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default DocumentUploadModal;
