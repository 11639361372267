import React, { useContext, useMemo, useState } from 'react';
import { Button, Divider, Dropdown, Form, Header, Icon, Modal, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import Table from '../../Shared/Table';

const ProposalTab = props => {
	// context
	const {
		customer,
		medicalTiers,
		getDocuments,
		deleteDocument,
		generateProposal,
		clearProposal,
		changeRfpYear,
		rfpYear,
		proposals,
		proposal,
		proposalError
	} = useContext( CustomersContext );

	// form handler
	const { formData, updateField, sandbox } = props;

	const yearOptions = useMemo( () => sandbox ? customer.benefitYears.map( year => ( { text: year, value: year, key: year } ) ) : null, [ customer.benefitYears, sandbox ] );

	const [ proposalModal, setProposalModal ] = useState( false );
	const [ archiveProposal, setArchiveProposal ] = useState( false );
	const [ planComparison, setPlanComparison ] = useState( true );
	const [ includeMedical, setIncludeMedical ] = useState( true );
	const [ includeDental, setIncludeDental ] = useState( false );
	const [ includeVision, setIncludeVision ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );

	const handleModalClose = async () => {
		setProposalModal( false );
		clearProposal();
		await getDocuments();
	};

	const columns = [
		{
			Header: 'Date',
			accessor: 'dateStamp',
			filterable: false,
			sortable: true,
			Cell: row => <div>{ new Date( row.original.dateStamp ).toLocaleDateString() }</div>,
			width: 100
		},
		{
			Header: 'Name',
			accessor: 'name',
			filterable: false,
			sortable: true
		},

		// {
		// 	Header: 'Description',
		// 	accessor: 'description',
		// 	filterable: false,
		// 	sortable: true
		// },
		{
			Header: 'View / Remove',
			accessor: 'id',
			sortable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						onClick={ () => {
							window.open( row.original.url );
						}}
					>
						<Icon name="search" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.name );
							setDeleteId( row.original.id );
							setDeleteConfirmModal( true );
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			),
			width: 120
		}
	];

	return (
		<>
			<Tab.Pane>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Header>Proposal Template</Header>
					{ sandbox
						? <span>
						Plan Year&nbsp;
							<Dropdown
								value={ rfpYear }
								label="year"
								inline
								options={ yearOptions }
								onChange={ ( e, { value } ) => changeRfpYear( value ) }
								size="tiny"
							/>
						</span>
						: null
					}
				</div>
				<Divider />
				<Form>
					<Form.Group style={{ justifyContent: 'space-between' }}>
						<Form.Select
							name="medicalTier"
							label="Medical Tier"
							width={ 4 }
							value={ formData.medicalTier || 0 }
							options={[
								{ key: 0, text: 'Select an option', value: 0 },
								...medicalTiers
							]}
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					</Form.Group>
					<Form.Group>
						{/* <Form.Select
            required
            name="documentVersion"
            value={ 69 }
            label="Document Version"
            options={[
              ...proposalTypes
            ]}
            width={ 5 }
            onChange={ ( e, { name, value } ) => { updateField({ target: { name: name, value: value } }); } }
          /> */}

						<Form.Group
							grouped
							style={{ paddingRight: '0.5em', paddingLeft: '0.5rem' }}
						>
							<label style={{ display: 'block' }}>Include Plans?</label>
							<Form.Field style={{ display: 'inline-block' }}>
								<Form.Checkbox
									label="Medical"
									name="medical"
									style={{ marginRight: '1rem' }}
									checked={ includeMedical }
									onChange={ ( e, { checked } ) => {
										setIncludeMedical( checked );
									} }
								/>
							</Form.Field>
							<Form.Field style={{ display: 'inline-block' }}>
								<Form.Checkbox
									label="Dental"
									name="dental"
									style={{ marginRight: '1rem' }}
									checked={ includeDental }
									onChange={ ( e, { checked } ) => {
										setIncludeDental( checked );
									} }
								/>
							</Form.Field>
							<Form.Field style={{ display: 'inline-block' }}>
								<Form.Checkbox
									label="Vision"
									name="vision"
									style={{ marginRight: '1rem' }}
									checked={ includeVision }
									onChange={ ( e, { checked } ) => {
										setIncludeVision( checked );
									} }
								/>
							</Form.Field>
						</Form.Group>
						<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
							<label>Include Workers&apos; Comp</label>
							<Form.Checkbox
								name="includeWorkersComp"
								style={{ marginTop: '1rem' }}
								checked={ formData.includeWorkersComp }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
						<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
							<label>Archive</label>
							<Form.Checkbox
								name="archive"
								style={{ marginTop: '1rem' }}
								checked={ archiveProposal }
								onChange={ ( e, { checked } ) => {
									setArchiveProposal( checked );
								} }
							/>
						</Form.Field>
						<Form.Field width={ 3 } style={{ textAlign: 'center' }}>
							<label>Plan Comparison</label>
							<Form.Checkbox
								name="planComparison"
								style={{ marginTop: '1rem' }}
								checked={ planComparison }
								onChange={ ( e, { checked } ) => {
									setPlanComparison( checked );
								} }
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Button
							width={ 3 }
							style={{ display: 'block' }}
							primary
							content="Generate Proposal"
							onClick={ async () => {
								await props.updateProspect();
								generateProposal( {
									archive: archiveProposal,
									planComparison,
									medical: includeMedical,
									dental: includeDental,
									vision: includeVision
								} );
								setProposalModal( true );
							}}
						/>
					</Form.Group>
				</Form>
				{ Object.keys( proposals ).length > 0
					? <Table
						key={ proposals.length }
						data={ proposals }
						columns={ columns }
						pageSize={ proposals.length }
						hidePagination
					/>
					: <p style={{ padding: '1rem 0 1rem 1rem' }}>No archived proposals found.</p>
				}
				<Modal
					open={ proposalModal }
					onClose={ handleModalClose }
					closeOnDimmerClick={ false }
					size="mini"
				>
					<Modal.Header>Proposal</Modal.Header>
					<Modal.Content>
						{ proposal
							? <>
								<p>Your proposal has been generated!</p>
								<a href={ proposal.url } target="_blank" rel="noopener noreferrer"><Icon name="file" /> View Proposal</a>
							</>
							: proposalError
								? <p>Something went wrong. Please try again. If the problem persists, please contact support.</p>
								: <p><Icon name="spinner" loading /> Generating proposal...</p>
						}
					</Modal.Content>
					<Modal.Actions>
						<Button
							content="close"
							onClick={ handleModalClose }
						/>
					</Modal.Actions>
				</Modal>
			</Tab.Pane>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Archived Proposal"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deleteDocument( deleteId ) }
			/>
		</>
	);
};

export default ProposalTab;
