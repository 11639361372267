import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';
// import { stateOptions } from '../../../Services/inputMasks';
import { maskCurrency, floatKeyDown, unmaskCurrency } from '../../../Services/inputMasks';
import PercentageInput from '../../Shared/PercentageInput';

const RatesModal = ( { percentage } ) => {
	// context
	const {
		operation,
		createRate,
		updateRate,
		// unavailableStates,
		ratesModal,
		hideRatesModal,
		rateType,
		year
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ validator, setValidator ] = useState( {
		state: 'string',
		rate: '0'
	} );

	// set formData to operation data
	useEffect( () => {
		if ( operation ) {
			const rateData = {
				state: operation.state,
				rate: operation.rate
			};

			setData( rateData );
		}

		return () => {
			setData( {} );
		};
	}, [ operation, setData ] );

	// prepare data for updateLead api call
	const handleUpdate = () => {
		updateRate( {
			...operation,
			state: formData.state,
			rate: formData.rate
		} );
	};

	// prepare data for updateLead api call
	const handleCreateNew = () => {
		createRate( {
			type: { id: rateType },
			year,
			state: formData.state,
			rate: percentage ? formData.rate : unmaskCurrency( formData.rate )
		} );
	};

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setData( {} );
		setValidator( {
			state: 'string',
			rate: '0'
		} );
	};

	// const filteredStateOptions = () => {
	// 	if ( operation ) {
	// 		return stateOptions.filter( option => option.value === operation.state || !unavailableStates.includes( option.value ) );
	// 	}

	// return stateOptions.filter( option => !unavailableStates.includes( option.value ) );
	// };

	return (
		<Modal
			open={ ratesModal }
			size="mini"
		>
			<Modal.Header>
        Administration Rate
			</Modal.Header>
			<Modal.Content>
				<Form>
					{/* <Form.Select
            required
            name="state"
            value={ formData.state || '' }
            label="State"
            options={ filteredStateOptions() }
            width={ 3 }
            fluid
            search
            onChange={ ( e, { name, value } ) => {
              updateField({ target: { name: name, value: value } });
            }}
            error={ validator.state.length === 0 }
            onBlur={ () => {
              setValidator( { ...validator, state: formData.state || '' } );
            }}
            onFocus={ () => {
              setValidator( { ...validator, state: 'XX' } );
            }}
          />
          <Message
            hidden={ validator.state.length > 0 }
            negative
            style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
          >
            <Message.List style={{ listStyle: 'none' }}>
              <Message.Item>
                <Icon name="exclamation circle" />
                Please select a state.
              </Message.Item>
            </Message.List>
          </Message> */}
					{
						percentage
							? <PercentageInput
								required
								name="rate"
								label="Rate"
								value={ formData.rate || '' }
								onChange={ updateField }
								error={ !( parseInt( validator.rate ) >= 0 ) }
								onBlur={ () => setValidator( { ...validator, rate: parseInt( formData.rate ) >= 0 ? formData.rate : '' } ) }
								onFocus={ () => setValidator( { ...validator, rate: 0 } ) }
							/>
							: <Form.Input
								required
								name="rate"
								label="Rate"
								icon="dollar"
								value={ formData.rate || '' }
								onChange={ updateField }
								error={ validator.rate.length === 0 }
								onBlur={ e => {
									updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
									setValidator( { ...validator, rate: formData.rate || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, rate: '0' } );
								}}
								onKeyDown={ floatKeyDown }
							/>
					}
					<Message
						hidden={
							percentage ? parseInt( validator.rate ) >= 0 : validator.rate.length > 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item>
								<Icon name="exclamation circle" />
                Please enter a rate.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ operation
						? () => {
							handleUpdate();
							clearData();
							hideRatesModal();
						}
						: () => {
							handleCreateNew();
							clearData();
							hideRatesModal();
						}
					}
					disabled={
						( formData.state ? formData.state.length === 0 : true )
            || percentage ? parseInt( formData.rate ) <= 0 : formData.rate ? formData.rate.length === 0 : true
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideRatesModal();
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default RatesModal;
