import React, { useContext, useState } from 'react';
import { Button, Divider, Header, Icon, Modal, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { ReactComponent as GMIcon } from './googlemapsicon.svg';
import LocationsModal from './LocationsModal';

const LocationsTab = () => {
	const { locations, deleteLocation } = useContext( CustomersContext );

	const [ location, setLocation ] = useState( '' );
	const [ locationsModal, setLocationsModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );
	const [ isPrimaryErrorModal, setIsPrimaryErrorModal ] = useState( false );

	const columns = [
		{
			accessor: 'id',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<>
					{ row.original.address
						? <a href={ `https://www.google.com/maps/dir/?api=1&destination=${ row.original.address.replace( / /g, '+' ) }+${ row.original.city },+${ row.original.state }+${ row.original.zip }` } target="_blank" rel="noopener noreferrer">
							<GMIcon />
						</a>
						: <GMIcon />
					}
				</>
			),
			width: 40
		},
		{
			Header: 'Label',
			accessor: 'label',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					{ row.original.primary ? <Icon name="star" /> : null }{ row.original.label }
				</div>
			)
		},
		{
			Header: 'Address',
			accessor: 'address',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					{ row.original.address }
					<br />
					{ row.original.address2 }
				</div>
			)
		},
		{
			Header: 'City',
			accessor: 'city',
			filterable: false,
			sortable: true
		},
		{
			Header: 'State',
			accessor: 'state',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Zip',
			accessor: 'zip',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Edit / Remove',
			accessor: 'id',
			sortable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						onClick={ () => {
							setLocation( row.original );
							setLocationsModal( true );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							if ( !row.original.primary ) {
								setDeleteConfirmModalNameProp( row.original.label );
								setDeleteId( row.original.id );
								setDeleteConfirmModal( true );
							} else {
								setIsPrimaryErrorModal( true );
							}
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<Tab.Pane>
			<Header>Locations</Header>
			<Divider />
			<Button
				style={{ marginBottom: '1rem' }}
				onClick={ () => setLocationsModal( true ) }
			>
        Add Location
			</Button>
			{ Object.keys( locations ).length > 0
				? <Table
					key={ locations.length }
					data={ locations }
					columns={ columns }
					pageSize={ locations.length }
					hidePagination
				/>
				: <p style={{ padding: '1rem 0 1rem 1rem' }}>No locations found.</p>
			}
			<LocationsModal
				location={ location }
				setLocation={ setLocation }
				locationsModal={ locationsModal }
				setLocationsModal={ setLocationsModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Location"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deleteLocation( deleteId ) }
			/>
			<Modal
				open={ isPrimaryErrorModal }
				onClose={ () => setIsPrimaryErrorModal( false ) }
				size="mini"
			>
				<Modal.Content>
          Cannot delete the primary location.
					<br />Please set another location as the primary location before deleting this one.
				</Modal.Content>
			</Modal>
		</Tab.Pane>
	);
};

export default LocationsTab;
