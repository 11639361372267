import React from 'react';
import { Divider, Form, Header, Tab } from 'semantic-ui-react';

const ChecklistTab = props => {
	// form handler
	const { formData, updateField } = props;

	return (
		<Tab.Pane>
			<Header>Prospect Checklist</Header>
			<Divider />
			<Form>
				{/* <Header as="h4">Stage 1</Header> */}
				<Form.Group inline>
					<Form.Checkbox
						name="step1_1"
						label="Proposal Approved"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step1_1 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				<Form.Group inline>
					<Form.Checkbox
						name="step1_2"
						label="CSA Completed"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step1_2 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				{/* <Header as="h4">Stage 2</Header>
				<Form.Group inline>
					<Form.Checkbox
						name="step2_1"
						label="Proposal Complete"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step2_1 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				<Form.Group inline>
					<Form.Checkbox
						name="step2_2"
						label="Proposal Approved"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step2_2 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				<Header as="h4">Stage 3</Header>
				<Form.Group inline>
					<Form.Checkbox
						name="step3_1"
						label="Proposal Sent to Client"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step3_1 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				<Form.Group inline>
					<Form.Checkbox
						name="step3_2"
						label="Proposal Accepted by Client"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step3_2 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group>
				<Form.Group inline>
					<Form.Checkbox
						name="step3_3"
						label="WorkSmart Visit Scheduled"
						width={ 16 }
						style={{ marginTop: '1rem' }}
						checked={ formData.step3_3 }
						onChange={ ( e, { name, checked } ) => {
							updateField( { target: { name, value: checked } } );
						} }
					/>
				</Form.Group> */}
			</Form>
		</Tab.Pane>
	);
};

export default ChecklistTab;
