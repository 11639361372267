import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { maskCurrency, unmaskCurrency, integerKeyDown, floatKeyDown } from '../../../Services/inputMasks';

const PayCyclesModal = props => {
	// context
	const { postPayCycle } = useContext( CustomersContext );

	// form handler
	const { formData, updateField, setData } = useForm();

	// state
	const [ validator, setValidator ] = useState( {
		description: 'string',
		cycleCount: 0,
		ftCount: 0,
		ptCount: 0,
		wages: '0'
	} );

	// props
	const { record, setRecord, payCyclesModal, setPayCyclesModal } = props;

	// set formData to customer data
	useEffect( () => {
		if ( record ) {
			const recordData = {
				description: record.description,
				cycleCount: record.cycleCount,
				ftCount: record.ftCount,
				ptCount: record.ptCount,
				wages: maskCurrency( record.wages )
			};

			setData( recordData );
		}

		// on unmount
		return () => {
			setData( {} );
		};
	}, [ record, setData ] );

	// prepare data for api call (update)
	const handleUpdate = () => {
		postPayCycle( {
			...record,
			description: formData.description,
			cycleCount: formData.cycleCount,
			ftCount: formData.ftCount,
			ptCount: formData.ptCount,
			wages: unmaskCurrency( formData.wages )
		} );
		setPayCyclesModal( false );
	};

	// prepare data for api call (create)
	const handleCreateNew = () => {
		postPayCycle( {
			id: 0,
			description: formData.description,
			cycleCount: formData.cycleCount,
			ftCount: formData.ftCount,
			ptCount: formData.ptCount,
			wages: unmaskCurrency( formData.wages )
		} );
		setPayCyclesModal( false );
	};

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		setRecord( '' );
		setData( {} );
		setValidator( {
			description: 'string',
			cycleCount: 0,
			ftCount: 0,
			ptCount: 0,
			wages: '0'
		} );
	};

	return (
		<Modal
			id="payCycles-modal"
			open={ payCyclesModal }
			closeOnDimmerClick={ false }
			size="mini"
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				{ record ? 'Edit Pay Cycle' : 'Add New Pay Cycle' }
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Input
						required
						name="description"
						value={ formData.description || '' }
						label="Description"
						width={ 16 }
						maxLength={ 255 }
						onChange={ updateField }
						error={ validator.description.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, description: formData.description || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, description: 'string' } );
						}}
					/>
					<Message
						hidden={ validator.description.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.description.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a description.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Select
							required
							name="cycleCount"
							fluid
							options={[
								{ text: 'Select an option', value: 0 },
								{ text: '12 (monthly)', value: 12 },
								{ text: '24 (semi-monthly)', value: 24 },
								{ text: '26 (bi-weekly)', value: 26 },
								{ text: '52 (weekly)', value: 52 }
							]}
							value={ parseInt( formData.cycleCount ) >= 0 ? formData.cycleCount : 0 }
							label="Cycles Per Year"
							width={ 8 }
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
							error={ !( parseInt( validator.cycleCount ) >= 0 ) }
							onBlur={ () => {
								setValidator( { ...validator, cycleCount: parseInt( formData.cycleCount ) >= 0 ? formData.cycleCount : '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, cycleCount: 0 } );
							}}
						/>
						<Form.Input
							required
							type="number"
							name="ftCount"
							value={ parseInt( formData.ftCount ) >= 0 ? formData.ftCount : '' }
							label="Full Time"
							width={ 8 }
							min={ 0 }
							onChange={ updateField }
							error={ !( parseInt( validator.ftCount ) >= 0 ) }
							onBlur={ () => {
								setValidator( { ...validator, ftCount: parseInt( formData.ftCount ) >= 0 ? formData.ftCount : '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, ftCount: 0 } );
							}}
							onKeyDown={ integerKeyDown }
						/>
					</Form.Group>
					<Message
						hidden={
							parseInt( validator.cycleCount ) >= 0 && parseInt( validator.ftCount ) >= 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ parseInt( validator.cycleCount ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Cycles Per Year.
							</Message.Item>
							<Message.Item
								hidden={ parseInt( validator.ftCount ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Full Time.
							</Message.Item>
						</Message.List>
					</Message>
					<Form.Group>
						<Form.Input
							required
							type="number"
							name="ptCount"
							value={ parseInt( formData.ptCount ) >= 0 ? formData.ptCount : '' }
							label="Part Time"
							width={ 8 }
							min={ 0 }
							onChange={ updateField }
							error={ !( parseInt( validator.ptCount ) >= 0 ) }
							onBlur={ () => {
								setValidator( { ...validator, ptCount: parseInt( formData.ptCount ) >= 0 ? formData.ptCount : '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, ptCount: 0 } );
							}}
							onKeyDown={ integerKeyDown }
						/>
						<Form.Input
							required
							name="wages"
							value={ formData.wages || '' }
							label="Wages Per Month"
							width={ 8 }
							onChange={ updateField }
							error={ validator.wages.length === 0 }
							onBlur={ e => {
								updateField( { target: { name: e.target.name, value: maskCurrency( e.target.value ) || '' } } );
								setValidator( { ...validator, wages: formData.wages || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, wages: '0' } );
							}}
							onKeyDown={ floatKeyDown }
						/>
					</Form.Group>
					<Message
						hidden={
							parseInt( validator.ptCount ) >= 0
              && validator.wages.length > 0
						}
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ parseInt( validator.ptCount ) >= 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Part Time.
							</Message.Item>
							<Message.Item
								hidden={ validator.wages.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a value for Wages Per Month.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						record ? handleUpdate() : handleCreateNew();
						clearData();
						setPayCyclesModal( false );
					}}
					disabled={
						( formData.description ? formData.description.length === 0 : true )
            || !( parseInt( formData.cycleCount ) >= 0 )
            || !( parseInt( formData.ftCount ) >= 0 )
            || !( parseInt( formData.ptCount ) >= 0 )
            || ( formData.wages ? formData.wages.length === 0 : true )
					}
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						setPayCyclesModal( false );
					}}
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default PayCyclesModal;
