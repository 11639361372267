import React from 'react';
import { Form } from 'semantic-ui-react';
import { floatKeyDown } from '../../Services/inputMasks';

const DecimalInput = ({
	required = false,
	name,
	label,
	value,
	onChange,
	width = null,
	error = false,
	setValidator,
	onBlur = () => {},
	...props
}) => {
	return (
		<Form.Input
			required={ required }
			type="number"
			min={ 0 }
			width={ width }
			name={ name }
			label={ label }
			value={ parseFloat( value ) >= 0 ? value : '' }
			onChange={ onChange }
			error={ error }
			onBlur={ () => {
				onChange({ target: { name: 'rate', value: parseFloat( value ).toString() } });
				onBlur();
			}}
			onKeyDown={ floatKeyDown }
			{ ...props }
		/>
	);
};

export default DecimalInput;