import React from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';
import { Settings } from '../Global/config';

export const OperationsContext = React.createContext();

class OperationsProvider extends React.PureComponent {
	constructor( props ) {
		super( props );
		const { cookies } = props;

		this.state = {
			accessToken: cookies.get( 'token' ),
			// loaders
			spinner: false,
			uiLoader: false,
			loadingMessage: '',
			tableLoader: false,
			// lookups
			clients: [],
			unavailableStates: [],
			years: [],
			tierTypes: [],
			planTypes: [],
			rateTypes: [],
			fileTypes: [],
			feeTypes: [],
			payrollCycles: [],
			employeeRanges: [],
			// data
			operation: null,
			tiers: [],
			plans: [],
			rates: [],
			files: [],
			fileUploadProgress: null,
			fees: [],
			procedures: [],
			spinnerProgress: null,
			// modals
			tiersModal: false,
			plansModal: false,
			ratesModal: false,
			sutaRatesModal: false,
			wcRatesModal: false,
			filesModal: false,
			anthemClaimsModal: false,
			adminFeesModal: false,
			implementationFeesModal: false,
			clientRenewalModal: false,
			deletConfirmModal: false,
			// filters
			year: '',
			tierType: '',
			planType: '',
			rateType: '',
			fileType: '',
			feeType: '',
			payrollCycle: ''
		};
	}

	componentDidMount() {
		// lookups
		this.setYears();
		this.lookupClients();
		this.lookupTierTypes();
		this.lookupPlanTypes();
		this.lookupRateTypes();
		this.lookupFileTypes();
		this.lookupFeeTypes();
		this.lookupPayrollCycles();
		this.lookupEmployeeRanges();
	}

	componentDidUpdate( prevProps, prevState ) {
		if ( prevState.rates !== this.state.rates ) {
			this.getUnavailableStates();
		}
	}

	// methods

	updateArray = ( array, obj ) => array.map( item => item.id !== obj.id ? item : obj );

	getUnavailableStates = () => {
		const unavailableStates = this.state.rates.map( rate => rate.state );

		this.setState( { unavailableStates } );
	}

	setYears = () => {
		const currentYear = new Date().getFullYear();
		const fiveYears = [
			currentYear + 1,
			currentYear,
			currentYear - 1,
			currentYear - 2,
			currentYear - 3,
			currentYear - 4
		];
		const years = fiveYears.map( ( year, i ) => {
			return {
				key: i,
				text: year,
				value: year
			};
		} );

		this.setState( {
			years,
			year: years[ 1 ].value
		} );
	}

	// lookups

	mapLookup = ( data, text = 'name' ) => {
		return data.map( option => ( {
			key: option.id,
			text: option[ text ],
			value: option.id
		} ) );
	}

	lookupClients = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups/clients',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const clients = await this.mapLookup( response.data.types, 'description' );

		this.setState( { clients } );
	}

	lookupTierTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 2 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const tierTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( {
			tierTypes,
			tierType: tierTypes[ 0 ].value
		} );
	}

	lookupPlanTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 3 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const planTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( {
			planTypes,
			planType: planTypes[ 0 ].value
		} );
	}

	lookupRateTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 4 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const rateTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( {
			rateTypes,
			rateType: rateTypes[ 0 ].value
		} );
	}

	lookupFileTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 36 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const fileTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( {
			fileTypes,
			fileType: fileTypes[ 0 ].value
		} );
	}

	lookupFeeTypes = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 12 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const feeTypes = await this.mapLookup( response.data[ 0 ].types );

		this.setState( {
			feeTypes,
			feeType: feeTypes[ 0 ].value
		} );
	}

	lookupPayrollCycles = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 13 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const payrollCycles = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { payrollCycles } );
	}

	lookupEmployeeRanges = async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/lookups',
			params: { type: 14 },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		const employeeRanges = await this.mapLookup( response.data[ 0 ].types );

		this.setState( { employeeRanges } );
	}

	// api calls

	getTiers = async ( typeId = this.state.tierType, year = this.state.year ) => {
		if ( typeId && year ) {
			this.setState( {
				tableLoader: true,
				uiLoader: true,
				loadingMessage: 'Retrieving tiers...'
			} );
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/tiers',
				params: {
					typeId,
					year
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			this.setState( { tiers: response.data } );
			this.setState( {
				tableLoader: false,
				uiLoader: false,
				loadingMessage: ''
			} );
		}
	}

	createTier = async formData => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating a new tier...'
		} );
		const data = {
			id: 0,
			type: { id: this.state.tierType },
			year: this.state.year,
			description: formData.description,
			rate: parseFloat( formData.rate )
		};
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/tier',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { tiers: [ ...this.state.tiers, response.data ] } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateTier = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating tier...'
		} );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/tier',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { tiers: this.updateArray( this.state.tiers, response.data ) } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteTier = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing tier...'
		} );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/tier/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getTiers();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getPlans = async ( typeId = this.state.planType, year = this.state.year ) => {
		if ( typeId && year ) {
			this.setState( {
				tableLoader: true,
				uiLoader: true,
				loadingMessage: 'Retrieving plans'
			} );
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/plans',
				params: {
					typeId,
					year
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			this.setState( { plans: response.data } );
			this.setState( {
				tableLoader: false,
				uiLoader: false,
				loadingMessage: ''
			} );
		}
	}

	createPlan = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating a new plan...'
		} );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/plan',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { plans: [ ...this.state.plans, response.data ] } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updatePlan = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating plan...'
		} );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/plan',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { plans: this.updateArray( this.state.plans, response.data ) } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deletePlan = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing plan...'
		} );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/plan/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getPlans();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getRates = async ( typeId = this.state.rateType, year = this.state.year ) => {
		if ( typeId && year ) {
			this.setState( {
				tableLoader: true,
				uiLoader: true,
				loadingMessage: 'Retrieving rates'
			} );
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/rates',
				params: {
					typeId,
					year
				},
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			this.setState( { rates: response.data } );
			this.setState( {
				tableLoader: false,
				uiLoader: false,
				loadingMessage: ''
			} );
		}
	}

	createRate = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating a new rate...'
		} );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/rate',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { rates: [ ...this.state.rates, response.data ] } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateRate = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating rate...'
		} );
		const response = await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/rate',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { rates: this.updateArray( this.state.rates, response.data ) } );
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteRate = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing rate...'
		} );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/rate/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getRates();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getFiles = async ( typeId = this.state.fileType ) => {
		if ( typeId ) {
			this.setState( {
				tableLoader: true,
				uiLoader: true,
				loadingMessage: 'Retrieving files'
			} );
			const response = await axios( {
				method: 'get',
				baseURL: Settings.apiUrl,
				url: '/fileImports',
				params: { typeId },
				headers: {
					api_key: Settings.apiKey,
					Accept: 'application/json',
					AccessToken: this.state.accessToken
				}
			} );

			this.setState( { files: response.data } );
			this.setState( {
				tableLoader: false,
				uiLoader: false,
				loadingMessage: ''
			} );
		}
	}

	createFile = async ( data, invoicedDate, paidDate, version, typeId = this.state.fileType ) => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating a new file...'
		} );

		let params = {};
		let url = '';

		switch ( typeId ) {
			case 166:
				url = 'fileImport/upload/claims';
				params = {
					invoicedDate,
					paidDate,
					version
				};
				break;
			case 167:
				url = 'fileImport/upload/premiums';
				break;
			default:
				throw new Error( 'Invalid file type' );
		}

		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url,
			params,
			data,
			onUploadProgress: progress => {
				var percentCompleted = Math.round( progress.loaded * 100 / progress.total );

				this.setState( { fileUploadProgress: percentCompleted } );
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'multipart/form-data',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { fileUploadProgress: null } );

		await this.getFiles();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteFile = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing file...'
		} );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/fileImport/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getFiles();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getFees = async ( typeId = this.state.feeType, year = this.state.year ) => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving fees'
		} );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/fees',
			params: {
				typeId,
				year
			},
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { fees: response.data } );
		this.setState( {
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	createFee = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Creating a new fee...'
		} );
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/fee',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getFees();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	updateFee = async data => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Updating fee...'
		} );
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/fee',
			data,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		await this.getFees();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	deleteFee = async id => {
		this.setState( {
			uiLoader: true,
			loadingMessage: 'Removing fee...'
		} );
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/fee/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );
		await this.getFees();
		this.setState( {
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	getProcedures = async () => {
		this.setState( {
			tableLoader: true,
			uiLoader: true,
			loadingMessage: 'Retrieving procedures'
		} );
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/processes',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		this.setState( { procedures: response.data } );
		this.setState( {
			tableLoader: false,
			uiLoader: false,
			loadingMessage: ''
		} );
	}

	renewClients = async customerIds => {
		this.setState( {
			spinnerProgress: null,
			spinner: true,
			uiLoader: true,
			loadingMessage: 'Renewing clients...'
		} );

		const { data: id } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: 'process/renewal/run',
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			},
			params: { customerIds: customerIds.join( ',' ) }
		} );

		this.setState( { spinnerProgress: 0 } );

		let data = await this.pollProcess( id );

		while ( data.complete < 100 ) {
			this.setState( { spinnerProgress: data.complete } );
			data = await this.pollProcess( id );
		}

		this.setState( {
			spinnerProgress: null,
			spinner: false,
			uiLoader: false,
			loadingMessage: ''
		} );

		return data;
	}

	resetRenewalProgress = () => {
		this.setState( { spinnerProgress: null } );
	}

	pollProcess = async id => {
		const { data } = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: `process/poll/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: this.state.accessToken
			}
		} );

		return data;
	}

	// modal handlers

	showTiersModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.tiers.find( tier => tier.id === id );

			this.setState( { operation } );
		}

		this.setState( { tiersModal: true } );
		this.setState( { spinner: false } );
	}

	hideTiersModal = () => {
		this.setState( {
			operation: null,
			tiersModal: false
		} );
	}

	showPlansModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.plans.find( plan => plan.id === id );

			this.setState( { operation } );
		}

		this.setState( { plansModal: true } );
		this.setState( { spinner: false } );
	}

	hidePlansModal = () => {
		this.setState( {
			operation: null,
			plansModal: false
		} );
	}

	showRatesModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.rates.find( rate => rate.id === id );

			this.setState( { operation } );
		}

		this.setState( {
			ratesModal: true,
			spinner: false
		} );
	}

	hideRatesModal = () => {
		this.setState( {
			operation: null,
			ratesModal: false
		} );
	}

	showSutaRatesModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.rates.find( rate => rate.id === id );

			this.setState( { operation } );
		}

		this.setState( { sutaRatesModal: true } );
		this.setState( { spinner: false } );
	}

	hideSutaRatesModal = () => {
		this.setState( {
			operation: null,
			sutaRatesModal: false
		} );
	}

	showWCRatesModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.rates.find( rate => rate.id === id );

			this.setState( { operation } );
		}

		this.setState( { wcRatesModal: true } );
		this.setState( { spinner: false } );
	}

	hideWCRatesModal = () => {
		this.setState( {
			operation: null,
			wcRatesModal: false
		} );
	}

	showFilesModal = () => {
		this.setState( { filesModal: true } );
	}

	hideFilesModal = () => {
		this.setState( { filesModal: false } );
	}

	showAnthemClaimsModal = () => {
		this.setState( { anthemClaimsModal: true } );
	}

	hideAnthemClaimsModal = () => {
		this.setState( { anthemClaimsModal: false } );
	}

	showAdminFeesModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.fees.find( fee => fee.id === id );

			this.setState( { operation } );
		}

		this.setState( { adminFeesModal: true } );
		this.setState( { spinner: false } );
	}

	hideAdminFeesModal = () => {
		this.setState( {
			operation: null,
			adminFeesModal: false
		} );
	}

	showImplementationFeesModal = async id => {
		this.setState( { spinner: true } );

		if ( id ) {
			const operation = await this.state.fees.find( fee => fee.id === id );

			this.setState( { operation } );
		}

		this.setState( { implementationFeesModal: true } );
		this.setState( { spinner: false } );
	}

	hideImplementationFeesModal = () => {
		this.setState( {
			operation: null,
			implementationFeesModal: false
		} );
	}

	showClientRenewalModal = () => {
		this.setState( { clientRenewalModal: true } );
	}

	hideClientRenewalModal = () => {
		this.setState( { clientRenewalModal: false } );
	}

	toggleDeleteConfirmModal = () => {
		this.setState( prevState => ( { deleteConfirmModal: !prevState.deleteConfirmModal } ) );
	}

	// filter handlers

	handleYearChange = year => {
		this.setState( { year } );
	}

	handleTierTypeChange = tierType => {
		this.setState( { tierType } );
	}

	handlePlanTypeChange = planType => {
		this.setState( { planType } );
	}

	handleRateTypeChange = rateType => {
		this.setState( { rateType } );
	}

	handleFileTypeChange = fileType => {
		this.setState( { fileType } );
	}

	handleFeeTypeChange = feeType => {
		this.setState( { feeType } );
	}

	resetFilter = async callback => {
		this.setState( prevState => ( {
			year: prevState.years[ 1 ].value,
			tierType: prevState.tierTypes[ 0 ].value,
			planType: prevState.planTypes[ 0 ].value,
			rateType: prevState.rateTypes[ 0 ].value
		} ) );
		await callback();
	}

	render() {
		return (
			<OperationsContext.Provider
				value={{
					...this.state,
					// api calls
					getTiers: this.getTiers,
					createTier: this.createTier,
					updateTier: this.updateTier,
					deleteTier: this.deleteTier,
					getPlans: this.getPlans,
					createPlan: this.createPlan,
					updatePlan: this.updatePlan,
					deletePlan: this.deletePlan,
					getRates: this.getRates,
					createRate: this.createRate,
					updateRate: this.updateRate,
					deleteRate: this.deleteRate,
					getFiles: this.getFiles,
					createFile: this.createFile,
					deleteFile: this.deleteFile,
					getFees: this.getFees,
					createFee: this.createFee,
					updateFee: this.updateFee,
					deleteFee: this.deleteFee,
					getProcedures: this.getProcedures,
					renewClients: this.renewClients,
					pollProcess: this.pollProcess,
					resetRenewalProgress: this.resetRenewalProgress,
					// modal handlers
					showTiersModal: this.showTiersModal,
					hideTiersModal: this.hideTiersModal,
					showPlansModal: this.showPlansModal,
					hidePlansModal: this.hidePlansModal,
					showRatesModal: this.showRatesModal,
					hideRatesModal: this.hideRatesModal,
					showSutaRatesModal: this.showSutaRatesModal,
					hideSutaRatesModal: this.hideSutaRatesModal,
					showWCRatesModal: this.showWCRatesModal,
					hideWCRatesModal: this.hideWCRatesModal,
					showAdminFeesModal: this.showAdminFeesModal,
					hideAdminFeesModal: this.hideAdminFeesModal,
					showFilesModal: this.showFilesModal,
					hideFilesModal: this.hideFilesModal,
					showClientRenewalModal: this.showClientRenewalModal,
					hideClientRenewalModal: this.hideClientRenewalModal,
					showAnthemClaimsModal: this.showAnthemClaimsModal,
					hideAnthemClaimsModal: this.hideAnthemClaimsModal,
					showImplementationFeesModal: this.showImplementationFeesModal,
					hideImplementationFeesModal: this.hideImplementationFeesModal,
					toggleDeleteConfirmModal: this.toggleDeleteConfirmModal,
					// filter handlers
					handleYearChange: this.handleYearChange,
					handleTierTypeChange: this.handleTierTypeChange,
					handlePlanTypeChange: this.handlePlanTypeChange,
					handleRateTypeChange: this.handleRateTypeChange,
					handleFileTypeChange: this.handleFileTypeChange,
					handleFeeTypeChange: this.handleFeeTypeChange,
					resetFilter: this.resetFilter
				}}
			>
				{ this.props.children }
			</OperationsContext.Provider>
		);
	}
}

export default withCookies( OperationsProvider );
