import React, { useContext, useState } from 'react';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import UnemploymentWitholdingsModal from './UnemploymentWitholdingsModal';
import { maskCurrency } from '../../../Services/inputMasks';

const UnemploymentWitholdingsTable = () => {
	const { witholdings, deleteWitholding } = useContext( CustomersContext );

	const [ record, setRecord ] = useState( '' );
	const [ unemploymentWitholdingsModal, setUnemploymentWitholdingsModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );

	const data = witholdings;

	const columns = [
		{
			Header: 'State',
			accessor: 'state',
			filterable: false,
			sortable: true
		},
		{
			Header: '# of Employees',
			accessor: 'employeeCount',
			filterable: false,
			sortable: true,
			Footer: (
				<>
					<strong>Total: </strong>
					{ data.reduce( ( acc, row ) => {
						return acc + row.employeeCount;
					}, 0 )}
				</>
			)
		},
		{
			Header: 'SUTA #',
			accessor: 'sutaNum',
			filterable: false,
			sortable: true
		},
		{
			Header: 'Current Tax Rate',
			accessor: 'taxRate',
			filterable: false,
			sortable: true,
			Cell: row => row.original.taxRate
				? <div>{ row.original.taxRate }%</div>
				: null
		},
		{
			Header: 'Proposed Tax Rate',
			accessor: 'proposedTaxRate',
			filterable: false,
			sortable: true,
			Cell: row => row.original.proposedTaxRate
				? <div>{ row.original.proposedTaxRate }%</div>
				: null
		},
		{
			Header: <div>Witholding Account #</div>,
			accessor: 'whAccount',
			filterable: false,
			sortable: true,
			style: { whiteSpace: 'unset' }
		},
		{
			Header: 'Current SUTA',
			accessor: 'currentSUTA',
			filterable: false,
			sortable: true,
			Cell: row => <div>{ maskCurrency( row.original.currentSUTA ) }</div>,
			Footer: (
				<>
					<strong>Total: </strong>
					{ maskCurrency( data.reduce( ( acc, row ) => acc + row.currentSUTA, 0 ) ) }
				</>
			)
		},
		{
			Header: 'Proposed SUTA',
			accessor: 'proposedSUTA',
			filterable: false,
			sortable: true,
			Cell: row => <div>{ maskCurrency( row.original.proposedSUTA ) }</div>,
			Footer: (
				<>
					<strong>Total: </strong>
					{ maskCurrency( data.reduce( ( acc, row ) => acc + row.proposedSUTA, 0 ) ) }
				</>
			)
		},
		{
			Header: 'Edit / Remove',
			accessor: 'id',
			sortable: false,
			Cell: row => (
				<div>
					<Button
						icon
						primary
						size="tiny"
						rfpid={ row.original.id }
						onClick={ () => {
							const thisRecord = data.find( record => record.id === row.original.id );

							setRecord( thisRecord );
							setUnemploymentWitholdingsModal( true );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setDeleteConfirmModalNameProp( row.original.state );
							setDeleteId( row.original.id );
							setDeleteConfirmModal( true );
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	return (
		<>
			<Segment basic clearing>
				<Header as="span" style={{ lineHeight: '36px', fontSize: '2rem', fontWeight: 'normal' }}>
        Unemployment and Witholdings
				</Header>
				<Button
					content="Add Record"
					floated="right"
					onClick={ () => setUnemploymentWitholdingsModal( true ) }
				/>
			</Segment>
			{ Object.keys( data ).length > 0
				? <Table
					key={ data.length }
					data={ data }
					columns={ columns }
					pageSize={ data.length }
					hidePagination
					style={{ marginBottom: '2rem' }}
				/>
				: <p style={{ padding: '0 0 1rem 1rem' }}>No records found.</p>
			}
			<UnemploymentWitholdingsModal
				record={ record }
				setRecord={ setRecord }
				unemploymentWitholdingsModal={ unemploymentWitholdingsModal }
				setUnemploymentWitholdingsModal={ setUnemploymentWitholdingsModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Record"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ () => deleteWitholding( deleteId ) }
			/>
		</>
	);
};

export default UnemploymentWitholdingsTable;
