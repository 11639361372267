import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Icon, Segment, Modal } from 'semantic-ui-react';
import { AuthContext } from '../Auth/AuthContext';
import { CustomersContext } from './CustomersContext';
// import Datepicker from '../Shared/Datepicker';

const CustomersFilter = props => {
	// context
	const { authedUser } = useContext( AuthContext );

	const {
		lastSync,
		salesAgents,
		agentId,
		// startDate,
		// endDate,
		exportCustomers,
		handleAgentIdChange,
		// handleStartDateChange,
		// handleEndDateChange,
		// resetFilter,
		refreshView
	} = useContext( CustomersContext );

	const [ syncModal, setSyncModal ] = useState( false );

	// props
	const { callback, /* showModal, */ showSalesForceSync } = props;

	const minutesSinceSync = useCallback( () => {
		const msecs = new Date() - new Date( lastSync );
		const mins = msecs / 60000;
		const hours = Math.floor( mins / 60 );
		const remainingMins = Math.floor( mins % 60 );

		return `${ hours } hr ${ remainingMins } min`;
	}, [ lastSync ] );

	const agentIdOptions = () => [ { key: 'all', text: 'All Sales Agents', value: 0 }, ...salesAgents ];

	return (
		<Segment>
			<Form>
				<Form.Group inline >
					{/* <Datepicker
						name="startDate"
						selected={ startDate }
						onChange={ e => {
							handleStartDateChange( e );
							refreshView( { callback, startDate: e } );
						}}
					/> */}
					{/* <Datepicker
						name="endDate"
						selected={ endDate }
						onChange={ e => {
							handleEndDateChange( e );
							refreshView( { callback, endDate: e } );
						}}
					/> */}
					{ authedUser.role.id === 1 || authedUser.role.id === 4
						? <Form.Select
							value={ agentId }
							selection
							options={ agentIdOptions() }
							onChange={ ( e, { value } ) => {
								handleAgentIdChange( value );
								refreshView( { callback, agentId: value } );
							}}
						/>
						: null
					}
					{/* <Button
            animated="fade"
            primary
            style={{ width: '100px', marginLeft: '20px' }}
            onClick={ () => filteredSearch( callback ) }
          >
            <Button.Content visible>
              <Icon name="search" />
            </Button.Content>
            <Button.Content hidden> Search </Button.Content>
          </Button> */}
					{/* <Button
						animated="fade"
						style={{ width: '100px' }}
						onClick={ () => resetFilter( callback ) }
					>
						<Button.Content visible>
							<Icon name="undo" />
						</Button.Content>
						<Button.Content hidden> Reset </Button.Content>
					</Button> */}
					{/* { showModal
						? <Button
							primary
							animated="fade"
							style={{ width: '100px' }}
							onClick={ () => showModal() }
						>
							<Button.Content visible>
								<Icon name="add" />
							</Button.Content>
							<Button.Content hidden> New Lead </Button.Content>
						</Button>
						: null
					} */}
					{ showSalesForceSync
						? <>
							<Button
								primary
								animated="fade"
								style={{ width: '160px', marginLeft: '20px' }}
								onClick={ () => new Date() - new Date( lastSync ) <= 10 * 60 * 1000
									? setSyncModal( true )
									: refreshView( { callback, sync: true } )
								}
							>
								<Button.Content visible>
									<Icon name="sync" />
								</Button.Content>
								<Button.Content hidden>Sync with Salesforce</Button.Content>
							</Button>
							{ lastSync ? <span style={{ marginLeft: '20px' }}>Last synced { minutesSinceSync() } ago</span> : null }
							<Modal
								closeOnDimmerClick
								onClose={ () => setSyncModal( false ) }
								open={ syncModal }
								size = "mini"
								style={{ textAlign: 'center' }}
							>
								<Modal.Content>
									{/* eslint-disable-next-line no-undef */}
									<img src={ `${ process.env.PUBLIC_URL }/assets/images/tiredIcon.png` } alt="" style={{ width: '6rem', height: '6rem' }} />
									<p>Wow that trip to Salesforce is exhausting!<br />Can I have just { Math.ceil( ( Date.parse( lastSync ) + 600000 - new Date().getTime() ) / 60000 ) } more minutes before we do that again?
									</p>
								</Modal.Content>
							</Modal>
						</>
						: null
					}
					<Button
						primary
						animated="fade"
						style={{ width: '100px', marginLeft: 'auto' }}
						onClick={ exportCustomers }
					>
						<Button.Content visible>
							<Icon name="share square" />
						</Button.Content>
						<Button.Content hidden> Export </Button.Content>
					</Button>
				</Form.Group>
			</Form>
		</Segment>
	);
};

export default CustomersFilter;
