import React, { useEffect } from 'react';
import { Icon, Segment, TransitionablePortal } from 'semantic-ui-react';

const Alert = props => {
	const { open, closeModal, message } = props;

	useEffect( () => {
		const i = setInterval( () => {
			closeModal();
		}, 3000 );

		return () => {
			clearInterval( i );
		};
	}, [ closeModal ] );

	return (
		<TransitionablePortal open={ open } transition={{ animation: 'fade', duration: 1500 }}>
			<Segment
				className="alert"
				style={{
					position: 'fixed',
					top: '2rem',
					left: '2rem',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '200px',
					height: '66.66px',
					zIndex: 9999,
					margin: 'auto'
				}}
			>
				<Icon style={{ fontSize: '1.5rem', marginRight: '1rem' }} color="green" name="check circle" />
				<p style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: '1em', textAlign: 'center' }}>{ message }</p>
			</Segment>
		</TransitionablePortal>
	);
};

export default Alert;
