import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const PlanInfoPopup = ( { content } ) => {
	return (
		<Popup
			position="top center"
			positionFixed
			trigger={
				<Icon name="info circle" />
			}
		>
			<Popup.Content>
				{ content }
			</Popup.Content>
		</Popup>
	);
};

export default PlanInfoPopup;
