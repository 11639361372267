import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Dropdown, Tab } from 'semantic-ui-react';
import { maskCurrency } from '../../../../Services/inputMasks';
import BenefitGroupPlanTable from './BenefitGroupPlanTable';
import PlanTable from './PlanTable';
import ResetConfirmModal from './ResetConfirmModal';
import PremiumOnlyPlanTable from './PremiumOnlyPlanTable';

const MedicalTab = ( { frequencyOptions, isBenefitDollars, medical, medicalTier, omitMedical, plans, updateLumpSumHSA, updatePlans, setUiDisabled, readonly } ) => {
	const [ frequency, setFrequency ] = useState( 'monthly' );
	const [ percentage, setPercentage ] = useState( false );
	const [ percentageDisabled, setPercentageDisabled ] = useState( false );
	const [ isFlat, setIsFlat ] = useState( medical.flatRate );
	const [ minimum, setMinimum ] = useState( 0 );
	const [ resetConfirmModal, setResetConfirmModal ] = useState( false );
	const [ lumpSumHSA, setLumpSumHSA ] = useState( plans.some( ( { hasHSA, isAnnual_HSA } ) => hasHSA && isAnnual_HSA ) );

	useEffect( () => {
		updateLumpSumHSA( lumpSumHSA );
	}, [ lumpSumHSA, updateLumpSumHSA ] );

	const handleLumpSumHSA = useCallback( ( e, { checked } ) => {
		setLumpSumHSA( checked );
	}, [] );

	const mapPlans = useCallback( () => plans.map( ( { id, planName, active, isAnnual_HSA, hasHSA, noContribution, ...rest } ) => {
		return (
			<>
				{ noContribution
					? <PremiumOnlyPlanTable
						id={ id }
						key={ id }
						minimum={ minimum }
						multiplier={ frequency }
						omitMedical={ omitMedical }
						plan={ { ...rest, minimumContribution: medical.minContribution } }
						planName={ planName }
						update={ updatePlans }
					/>
					: omitMedical || isBenefitDollars
						? <BenefitGroupPlanTable
							flatRate={ isFlat }
							id={ id }
							isAnnual_HSA={ isAnnual_HSA }
							key={ id }
							minimum={ minimum }
							multiplier={ frequency }
							omitMedical={ omitMedical }
							plan={ { ...rest, minimumContribution: medical.minContribution } }
							planName={ planName }
							setUiDisabled={ setUiDisabled }
							update={ updatePlans }
							readonly={ readonly }
						/>
						: <PlanTable
							isAnnual_HSA={ isAnnual_HSA }
							active={ active }
							flatRate={ isFlat }
							id={ id }
							key={ id }
							minimum={ minimum }
							multiplier={ frequency }
							percentage={ percentage }
							plan={ { ...rest, minimumContribution: medical.minContribution } }
							planName={ planName }
							hasHSA={ hasHSA }
							setUiDisabled={ setUiDisabled }
							update={ updatePlans }
							readonly={ readonly }
						/>
				}
			</>
		);
	} ), [ plans, isBenefitDollars, omitMedical, minimum, frequency, medical.minContribution, setUiDisabled, updatePlans, readonly, isFlat, percentage ] );

	return (
		<Tab.Pane>
			<div
				style={{
					position: 'sticky',
					top: '-1.5rem',
					margin: '-1rem',
					padding: '1rem 1rem 0',
					zIndex: 1,
					backgroundColor: '#fff'
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
					<strong>{ `${ medicalTier.description } - Payments Per Year: ${ frequency === 'semi-monthly' ? '24' : frequency === 'bi-weekly' ? '24 (26 HSA payments)' : frequency === 'weekly' ? '48 (52 HSA payments)' : '12' } - Minimum Contribution: ${ maskCurrency( medical.minContribution ) }` }</strong>
					<div>
						<Dropdown
							disabled={ percentage }
							value={ frequency }
							inline
							options={ frequencyOptions }
							onChange={ ( e, { value } ) => {
								setFrequency( value );
							} }
							size="tiny"
							tabIndex="-1"
						/>
						{ isBenefitDollars || omitMedical
							? null
							: <Button
								disabled={ readonly }
								content="Reset"
								onClick={ () => setResetConfirmModal( true ) }
								style={{ marginLeft: '2rem' }}
								tabIndex="-1"
							/>
						}
						{ isBenefitDollars || omitMedical
							? null
							: <Checkbox
								label="Percentage"
								disabled={ percentageDisabled || frequency !== 'monthly' }
								checked={ percentage }
								onChange={ ( e, { checked } ) => {
									setPercentage( checked );
								} }
								size="small"
								style={{ marginLeft: '2rem' }}
								tabIndex="-1"
							/>
						}
						<Checkbox
							label="Flat Rate"
							checked={ isFlat }
							disabled={ readonly }
							onChange={ ( e, { checked } ) => {
								setIsFlat( checked );
								setPercentage( false );
								setPercentageDisabled( prev => !prev );
							} }
							size="small"
							style={{ marginLeft: '2rem' }}
							tabIndex="-1"
						/>
						<Checkbox
							name="lumpSumHSA"
							label="Lump Sum HSA"
							checked={ lumpSumHSA }
							onChange={ handleLumpSumHSA }
							size="small"
							style={{ marginLeft: '2rem' }}
							tabIndex="-1"
						/>
					</div>
				</div>
				<Divider />
			</div>
			<ResetConfirmModal
				open={ resetConfirmModal }
				closeModal={ () => setResetConfirmModal( false ) }
				onClick={ () => setMinimum( prev => prev + 1 ) }
			/>
			{ mapPlans() }
		</Tab.Pane>
	);
};

export default MedicalTab;
