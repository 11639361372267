import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const BaseRatePopup = ( { baseRate, riskRate } ) => {
	return (
		<Popup
			position="top center"
			trigger={
				<Icon
					name="info circle"
				/>
			}
			wide="very"
		>
			<Popup.Content>
				<p>
					Proposed WC Rate is calculated as Proposed Base Rate x Risk Rate.
					<br />
					{ `${ baseRate }% x ${ riskRate } (${ riskRate * 100 }%) = ${ baseRate * riskRate }%` }
				</p>
			</Popup.Content>
		</Popup>
	);
};

export default BaseRatePopup;
