import React, { useCallback, useContext, useState } from 'react';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import BenefitGroupModal from './BenefitGroupModal';
import NewGroupModal from './NewGroupModal';
import { CustomersContext } from '../CustomersContext';

const BenefitGroupsTable = () => {
	const { clientBenefit, benefitGroup, getBenefitGroups, setBenefitGroup, deleteBenefitGroup, clientBenefitReadonly } = useContext( CustomersContext );

	const [ benefitGroupModal, setBenefitGroupModal ] = useState( null );
	const [ newGroupModal, setNewGroupModal ] = useState( false );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );

	const closeBenefitGroupModal = useCallback( () => {
		getBenefitGroups();
		setBenefitGroupModal( false );
		setBenefitGroup( null );
	}, [ getBenefitGroups, setBenefitGroup ] );

	const columns = [
		{
			id: 'benefitGroupName',
			Header: 'Benefit Group Name',
			accessor: row => `${ row.benefitGroup.name }`,
			filterable: false,
			sortable: true
		},
		{
			id: 'benefitDollars',
			Header: 'Benefit Dollars',
			accessor: 'dollars',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <div><Icon name={ row.original.isBenefitDollars ? 'check' : 'minus' } /></div>
		},
		{
			id: 'medicalTier',
			Header: 'Medical Tier',
			accessor: 'medicalTier',
			filterable: false,
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <div>{ row.original.medicalTier.description }</div>
		},
		{
			Header: clientBenefitReadonly ? 'View' : 'Edit / Remove',
			accessor: 'id',
			sortable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					{ clientBenefitReadonly
						? <Button
							icon
							primary
							size="tiny"
							rfpid={ row.original.id }
							onClick={ () => {
								const group = clientBenefit.groups.find( g => g.id === row.original.id );

								setBenefitGroup( group );
								setBenefitGroupModal( true );
							}}
						>
							<Icon name="search" />
						</Button>
						: <>
							<Button
								icon
								primary
								size="tiny"
								rfpid={ row.original.id }
								onClick={ () => {
									const group = clientBenefit.groups.find( g => g.id === row.original.id );

									setBenefitGroup( group );
									setBenefitGroupModal( true );
								}}
							>
								<Icon name="search" />
							</Button>
							<Button
								icon
								negative
								size="tiny"
								onClick={ () => {
									setDeleteConfirmModalNameProp( row.original.groupName );
									setDeleteId( row.original.id );
									setDeleteConfirmModal( true );
								}}
							>
								<Icon name="trash" />
							</Button>
						</>
					}
				</div>
			)
		}
	];

	return (
		<>
			<Segment basic clearing>
				<Header as="span" style={{ lineHeight: '36px', fontSize: '2rem', fontWeight: 'normal' }}>
          Benefit Groups
				</Header>
				{ clientBenefitReadonly
					? null
					: <Button
						content="Add Group"
						floated="right"
						onClick={ () => setNewGroupModal( true ) }
					/>
				}
			</Segment>
			{ Object.keys( clientBenefit.groups ).length > 0
				? <Table
					key={ clientBenefit.groups.length }
					data={ clientBenefit.groups }
					columns={ columns }
					pageSize={ clientBenefit.groups.length > 1 ? clientBenefit.groups.length : 1 }
					hidePagination
					style={{ marginBottom: '2rem' }}
				/>
				: <p style={{ padding: '0 0 1rem 1rem' }}>No rows found.</p>
			}
			{ benefitGroup
				? <BenefitGroupModal
					closeModal={ closeBenefitGroupModal }
					open={ benefitGroupModal }
				/>
				: null
			}
			<NewGroupModal
				closeModal={ () => setNewGroupModal( false ) }
				open={ newGroupModal }
			/>
			<DeleteConfirmModal
				name={ deleteConfirmModalNameProp }
				type="Record"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ async () => {
					await deleteBenefitGroup( deleteId );
				} }
			/>
		</>
	);
};

export default BenefitGroupsTable;
