import React, { useContext, useState } from 'react';
import { Button, Loader, Segment } from 'semantic-ui-react';
import Table from '../Shared/Table';
import { ReportsContext } from '../Reports/ReportsContext';
import ClaimsSummaryReportModal from '../Reports/ClaimSummaryReportModal';
import ReportModal from '../Reports/ReportModal';

const Reports = () => {
	// context
	const {
		uiLoader,
		loadingMessage,
		tableLoader,
		showReportModal,
		getHighClaimantExtract,
		getAnthemReinsuranceExtract,
		getAnthemReinsuranceDetail,
		getClaimSummaryReport,
		getMedicalLossSummary,
		// getEmployeeClaimsExtract,
		getAnnualDataExtract,
		getTierRateChart
	} = useContext( ReportsContext );

	const [ reportFields, setReportFields ] = useState( [] );
	const [ generate, setGenerate ] = useState( null );
	const [ selected, setSelected ] = useState( '' );

	const data = [
		{
			id: 1,
			name: 'Claim Summary',
			category: 'Customer Reports',
			description: ' Anthem claims comparison with Prism premiums for selected clients by date range.',
			fields: [ 'dates', 'customerIds' ],
			generate: getClaimSummaryReport
		},
		{
			id: 2,
			name: /* 'High Claimant Extract' */ 'Claim Upload Audit',
			category: 'Customer Reports',
			description: 'A report on high claimants.',
			fields: [ 'dates', 'customerIds', 'name' ],
			generate: getHighClaimantExtract
		},
		{
			id: 3,
			name: 'Anthem Reinsurance',
			category: 'Customer Reports',
			description: 'A report on Anthem reinsurance.',
			fields: [ 'dates', 'customerIds', 'name' ],
			generate: getAnthemReinsuranceExtract
		},
		{
			id: 4,
			name: 'Anthem Reinsurance Detail',
			category: 'Customer Reports',
			description: 'A report on Anthem reinsurance.',
			fields: [ 'dates', 'customerIds', 'name' ],
			generate: getAnthemReinsuranceDetail
		},
		{
			id: 5,
			name: 'Medical Loss Summary',
			category: 'Customer Reports',
			description: 'Total Medical Loss Ratio for every client in the selected year.',
			fields: [ 'dates' ],
			generate: getMedicalLossSummary
		},
		// {
		// 	id: 6,
		// 	name: 'Employee Claims Extract',
		// 	category: 'Customer Reports',
		// 	description: 'A report of claims for supplied employee name.',
		// 	fields: [ 'dates', 'customerIds', 'name' ],
		// 	generate: getEmployeeClaimsExtract
		// },
		{
			id: 7,
			name: 'Annual Data Extract',
			category: 'Customer Reports',
			description: 'Complete client data for provided year.',
			fields: [ 'year' ],
			generate: getAnnualDataExtract
		},
		{
			id: 8,
			name: 'Tier Rate Chart',
			category: 'Customer Reports',
			description: 'Report presenting all medical, dental, and vision rates by tier.',
			fields: [ 'year' ],
			generate: getTierRateChart
		}
	];

	const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			filterable: true,
			sortable: true,
			style: { textAlign: 'left' },
			width: 200
		},
		{
			Header: 'Category',
			accessor: 'category',
			filterable: true,
			sortable: true,
			style: { textAlign: 'left' },
			width: 200
		},
		{
			Header: 'Description',
			accessor: 'description',
			sortable: true,
			style: { textAlign: 'left' }
		},
		{
			accessor: 'id',
			Header: '',
			sortable: false,
			filterable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					<Button
						primary
						size="tiny"
						onClick={ () => {
							setSelected( row.original.name );
							setReportFields( row.original.fields );
							setGenerate( () => row.original.generate );
							showReportModal( row.original.id );
						} }
					>
						Run Report
					</Button>
				</div>
			),
			width: 120
		}
	];

	return (
		<>
			<div className="titleHeader">
				{ uiLoader
					? <span>
						<Loader active inverted inline size="tiny" style={{ marginRight: '0.5rem', marginBottom: '2px' }}/>
						{ loadingMessage }
					</span>
					: 'Reports'
				}
			</div>
			<div className="formContainer">
				<Segment basic style={{ padding: '2rem', margin: '0 auto' }}>
					<Table
						data={ data }
						columns={ columns }
						loading={ tableLoader }
					/>
				</Segment>
			</div>
			<ClaimsSummaryReportModal />
			<ReportModal
				name={ selected }
				fields={ reportFields }
				generate={ generate }
			/>
		</>
	);
};

export default Reports;
