import React from 'react';
import { Button, Confirm } from 'semantic-ui-react';

// @props: name, type, open, closeModal, onClick

const UnarchiveConfirmModal = props => {
	return (
		<Confirm
			className="unarchive-confirm-modal"
			open={ props.open }
			size="tiny"
			header={ `Unarchive ${ props.type }?` }
			content={
				<div className="content" style={{ fontSize: '16px' }}>
          Are you sure you want to unarchive <strong>{ props.name || `this ${ props.type.toLowerCase() }` }</strong>?
				</div>
			}
			cancelButton="Cancel"
			confirmButton={ () => (
				<Button
					negative
					onClick={ () => {
						props.onClick();
						props.closeModal();
					} }
				>
          Unarchive
				</Button>
			) }
			onCancel={ props.closeModal }
		/>
	);
};

export default UnarchiveConfirmModal;
