import React, { useContext } from 'react';
import { Divider, Form, Header, Icon, Message, Tab } from 'semantic-ui-react';
import Datepicker from '../../Shared/Datepicker';
import { CustomersContext } from '../CustomersContext';
import { validateZip, maskFein, unmaskFein, validateFein, stateOptions, integerKeyDown } from '../../../Services/inputMasks';

const ContactInfoTab = props => {
	// context
	const {
		companyTypes,
		marketingTypes
	} = useContext( CustomersContext );

	// props
	const { formData, updateField, validator, setValidator } = props;

	return (
		<Tab.Pane>
			<Header>Company Info</Header>
			<Divider />
			<Form>
				<Form.Group>
					<Form.Input
						required
						name="name"
						value={ formData.name || '' }
						label="Company Name"
						width={ 6 }
						maxLength={ 255 }
						onChange={ updateField }
						error={ validator.name.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, name: formData.name || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, name: 'string' } );
						}}
					/>
				</Form.Group>
				<Message
					hidden={ validator.name.length > 0 }
					negative
					style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
				>
					<Message.List style={{ listStyle: 'none' }}>
						<Message.Item
							hidden={ validator.name.length > 0 }
						>
							<Icon name="exclamation circle" />
              Please enter the company&apos;s name.
						</Message.Item>
					</Message.List>
				</Message>
				<Form.Group>
					<Form.Input
						required
						name="address"
						value={ formData.address || '' }
						label="Address Line 1"
						width={ 10 }
						maxLength={ 125 }
						onChange={ updateField }
						error={ validator.address.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, address: formData.address || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, address: 'string' } );
						}}
					/>
				</Form.Group>
				<Message
					negative
					hidden={ validator.address.length > 0 }
					style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
				>
					<Message.List>
						<Message.Item
							hidden={ validator.address.length > 0 }
						>
							<Icon name="exclamation circle" />
              Please enter an address.
						</Message.Item>
					</Message.List>
				</Message>
				<Form.Group>
					<Form.Input
						name="address2"
						value={ formData.address2 || '' }
						label="Address Line 2"
						width={ 10 }
						maxLength={ 125 }
						onChange={ updateField }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						required
						name="city"
						value={ formData.city || '' }
						label="City"
						width={ 5 }
						maxLength={ 125 }
						onChange={ updateField }
						error={ validator.city.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, city: formData.city || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, city: 'string' } );
						}}
					/>
					<Form.Select
						required
						name="state"
						value={ formData.state || '' }
						label="State"
						options={ stateOptions }
						width={ 3 }
						fluid
						search
						onChange={ ( e, { name, value } ) => {
							updateField( { target: { name, value } } );
						} }
						error={ validator.state.length === 0 }
						onBlur={ () => {
							setValidator( { ...validator, state: formData.state || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, state: 'XX' } );
						}}
					/>
					<Form.Input
						required
						name="zip"
						value={ formData.zip || '' }
						label="Zip"
						width={ 3 }
						maxLength={ 5 }
						onChange={ updateField }
						error={ !validateZip( validator.zip ) }
						onBlur={ () => {
							setValidator( { ...validator, zip: formData.zip || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, zip: '12345' } );
						}}
						onKeyDown={ integerKeyDown }
					/>
				</Form.Group>
				<Message
					negative
					style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					hidden={
						validator.city.length > 0
            && validator.state.length > 0
            && validateZip( validator.zip )
					}
				>
					<Message.List>
						<Message.Item
							hidden={ validator.city.length > 0 }
						>
							<Icon name="exclamation circle" />
              Please enter a city.
						</Message.Item>
						<Message.Item
							hidden={ validator.state.length > 0 }
						>
							<Icon name="exclamation circle" />
              Please select a state.
						</Message.Item>
						<Message.Item
							hidden={ validateZip( validator.zip ) }
						>
							<Icon name="exclamation circle" />
              Please enter a valid, 5-digit zip code.
						</Message.Item>
					</Message.List>
				</Message>
				<Header>Company Profile</Header>
				<Divider />
				<Form.Group>
					<Form.Select
						name="companyType"
						label="Type of Corporation"
						width={ 6 }
						value={ formData.companyType }
						options={ [ { key: 0, text: 'Select an option', value: 0 }, ...companyTypes ] }
						onChange={ ( e, { name, value } ) => {
							updateField( { target: { name, value } } );
						} }
					/>
					{ formData.companyType === 18 || formData.companyType === 29 || formData.companyType === 78
						? <Form.Field width={ 4 } style={{ textAlign: 'center' }}>
							<label>Receiving W-2 wages?</label>
							<Form.Checkbox
								name="w2Wages"
								inline={ false }
								style={{ marginTop: '1rem' }}
								checked={ formData.w2Wages }
								onChange={ ( e, { name, checked } ) => {
									updateField( { target: { name, value: checked } } );
								} }
							/>
						</Form.Field>
						: null
					}
				</Form.Group>
				<Form.TextArea
					name="description"
					label="Description of Business"
					width={ 14 }
					rows={ 6 }
					value={ formData.description || '' }
					onChange={ updateField }
				/>
				<Form.Group>
					<Form.Select
						upward={ true }
						name="wsMarketing"
						label="How they heard of WSS"
						width={ 6 }
						value={ formData.wsMarketing }
						options={ [ { key: 0, text: 'Select an option', value: 0 }, ...marketingTypes ] }
						onChange={ ( e, { name, value } ) => {
							updateField( { target: { name, value } } );
						} }
					/>
					<Datepicker
						label="Date of Incorporation"
						name="dateOfCorp"
						selected={ formData.dateOfCorp }
						onChange={ e => {
							updateField( { target: { name: 'dateOfCorp', value: e } } );
						} }
					/>
					<Form.Input
						name="fein"
						placeholder="ex. 12-3456789"
						value={ formData.fein ? maskFein( formData.fein ) : '' }
						label="FEIN"
						width={ 3 }
						maxLength={ 10 }
						onChange={ ( e, { name, value } ) => updateField( { target: { name, value: unmaskFein( value ) } } ) }
						error={ validator.fein ? validator.fein.length > 0 && !validateFein( validator.fein ) : false }
						onBlur={ () => {
							setValidator( { ...validator, fein: unmaskFein( formData.fein ) || '' } );
						}}
						onFocus={ () => {
							setValidator( { ...validator, fein: '111111111' } );
						}}
						onKeyDown={ integerKeyDown }
					/>
					<Form.Field width={ 2 } style={{ textAlign: 'center' }}>
						<label>Indiana Domiciled</label>
						<Form.Checkbox
							name="indianaDomiciled"
							inline={ false }
							style={{ marginTop: '1rem' }}
							checked={ formData.indianaDomiciled }
							onChange={ ( e, { name, checked } ) => {
								updateField( { target: { name, value: checked } } );
							} }
						/>
					</Form.Field>
				</Form.Group>
				<Message
					negative
					style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					hidden={
						validator.fein ? validator.fein.length === 0 || validateFein( validator.fein ) : true
					}
				>
					<Message.List>
						<Message.Item>
							<Icon name="exclamation circle" />
              Please enter a valid 9-digit FEIN.
						</Message.Item>
					</Message.List>
				</Message>
			</Form>
		</Tab.Pane>
	);
};

export default ContactInfoTab;
