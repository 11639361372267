import React, { useCallback, useContext } from 'react';
import { Form, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../../CustomersContext';

const DisabilityTab = ( { formData, updateField, readonly } ) => {
	const { shortTerm, longTerm } = useContext( CustomersContext );

	const findOption = useCallback( ( val, options ) => {
		const opt = options.find( option => option.value === val );

		return opt.text;
	}, [] );

	return (
		<Tab.Pane>
			<Form>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.shortTerm, shortTerm ) }
							label="Short Term Disability"
							width={ 3 }
						/>
						: <Form.Select
							name="shortTerm"
							value={ formData.shortTerm || '' }
							label="Short Term Disability"
							options={ shortTerm }
							width={ 3 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.longTerm, longTerm ) }
							label="Long Term Disability"
							width={ 3 }
						/>
						: <Form.Select
							name="longTerm"
							value={ formData.longTerm || '' }
							label="Long Term Disability"
							options={ longTerm }
							width={ 3 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
			</Form>
		</Tab.Pane>
	);
};

export default DisabilityTab;
