/* eslint-disable camelcase */
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Checkbox, Grid, Icon, Input, Message } from 'semantic-ui-react';
import usePlanValues from '../../../../Hooks/usePlanValues';
import { maskCurrency, floatKeyDown } from '../../../../Services/inputMasks';
import PercentageInput from './PercentageInput';

const gridStyles = { marginTop: '1rem', marginBottom: '2rem' };
const formTopStyles = { paddingBottom: 0 };
const formMiddleStyles = { paddingTop: '0.25rem', paddingBottom: 0 };
const formBottomStyles = { paddingTop: '0.25rem', paddingBottom: 0 };

const PlanTable = ( { isAnnual_HSA, active, flatRate, id, percentage, minimum, multiplier, plan, planName, hasHSA, update, setUiDisabled, readonly } ) => {
	const changeCounter = useRef( 0 );

	// form handler
	const {
		// lumpSumHSA,
		isActive,
		hasChanged,
		planValues,
		formData,
		validator,
		frequency,
		maskValue,
		unmaskValue,
		setFrequency,
		setIsActive,
		setLumpSumHSA,
		setHasChanged,
		setMinimum,
		setValidator,
		updateValue
	} = usePlanValues( plan, active, isAnnual_HSA, flatRate );

	useEffect( () => {
		setLumpSumHSA( isAnnual_HSA );
	}, [ isAnnual_HSA, setLumpSumHSA ] );

	useEffect( () => {
		if ( planValues.eE_Employer > planValues.eE_Premium
			|| planValues.eS_Employer > planValues.eS_Premium
			|| planValues.eC_Employer > planValues.eC_Premium
			|| planValues.eF_Employer > planValues.eF_Premium
		) {
			setUiDisabled( true );
		} else {
			setUiDisabled( false );
		}
	}, [ setUiDisabled, planValues ] );

	useEffect( () => {
		if ( minimum > 0 ) {
			setMinimum();
		}
	}, [ minimum, setMinimum ] );

	useEffect( () => {
		setFrequency( multiplier );
	}, [ multiplier, setFrequency ] );

	const handleClickSelect = useCallback( e => !readonly && e.target.select(), [ readonly ] );

	useEffect( () => {
		if ( hasChanged > changeCounter.current ) {
			const updatedValues = Object.entries( planValues ).reduce( ( acc, [ k, v ] ) => {
				const value = k.includes( '_HSA' )
					? isAnnual_HSA
						? v
						: v / frequency.hsa
					: v / frequency.premium;

				return { ...acc, [ k ]: value };
			}, {} ); // will return a planValues object with the multiplier applied to each value

			update( id, { ...updatedValues, active: isActive } );
			changeCounter.current = hasChanged;
		}
	}, [ id, hasChanged, planValues, update, isActive, frequency, setHasChanged, isAnnual_HSA ] );

	return (
		<>
			<Grid
				style={ !isActive
					? { ...gridStyles, opacity: 0.5, pointerEvents: 'none' }
					: gridStyles
				}
			>
				{/* headers */}
				<Grid.Row>
					<Grid.Column width={ 2 }><strong>{ planName }</strong></Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EE</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>ES</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>EC</Grid.Column>
					<Grid.Column textAlign="center" width={ 3 }>FAM</Grid.Column>
				</Grid.Row>
				{/* premium */}
				<Grid.Row style={ formTopStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }>Premium</Grid.Column>
					<Grid.Column width={ 3 }>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eE_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eS_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eC_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon="dollar"
							iconPosition="left"
							value={ formData.eF_Premium }
							tabIndex="-1"
						/>
					</Grid.Column>
				</Grid.Row>
				{/* employer */}
				<Grid.Row style={ formMiddleStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }>Employer</Grid.Column>
					<Grid.Column width={ 3 }>
						{ percentage
							? <PercentageInput
								ceiling={ planValues.eE_Premium }
								floor={ frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eE_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eE_HSA }
								name="eE_Employer"
								readOnly={ readonly }
								setValidator={ setValidator }
								validator={ validator }
								updateValue={ updateValue }
								value={ planValues.eE_Employer }
							/>
							: <Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eE_Employer"
								value={ formData.eE_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={
									validator.eE_Employer > planValues.eE_Premium
									// || validator.eE_Employer < ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eE_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eE_HSA )
								}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
									setValidator( { ...validator, eE_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eE_Employer: frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eE_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eE_HSA } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						}
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						{ percentage
							? <PercentageInput
								ceiling={ planValues.eS_Premium }
								floor={ frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eS_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eS_HSA }
								label={ {
									basic: true,
									content: planValues.eS_Employer > planValues.eE_Employer ? maskCurrency( planValues.eS_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								name="eS_Employer"
								readOnly={ readonly }
								setValidator={ setValidator }
								validator={ validator }
								updateValue={ updateValue }
								value={ planValues.eS_Employer }
							/>
							: <Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								label={ {
									basic: true,
									content: planValues.eS_Employer > planValues.eE_Employer ? maskCurrency( planValues.eS_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								labelPosition="right"
								name="eS_Employer"
								value={ formData.eS_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={
									!flatRate
										&& validator.eS_Employer > planValues.eS_Premium
										// || validator.eS_Employer < ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eS_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eS_HSA )
								}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
									setValidator( { ...validator, eS_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eS_Employer: frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eS_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eS_HSA } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						}
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						{ percentage
							? <PercentageInput
								ceiling={ planValues.eC_Premium }
								floor={ frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eC_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eC_HSA }
								label={ {
									basic: true,
									content: planValues.eC_Employer > planValues.eE_Employer ? maskCurrency( planValues.eC_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								name="eC_Employer"
								readOnly={ readonly }
								setValidator={ setValidator }
								validator={ validator }
								updateValue={ updateValue }
								value={ planValues.eC_Employer }
							/>
							: <Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								label={ {
									basic: true,
									content: planValues.eC_Employer > planValues.eE_Employer ? maskCurrency( planValues.eC_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								labelPosition="right"
								name="eC_Employer"
								value={ formData.eC_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={
									!flatRate
										&& validator.eC_Employer > planValues.eC_Premium
										// || validator.eC_Employer < ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eC_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eC_HSA )
								}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
									setValidator( { ...validator, eC_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eC_Employer: frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eC_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eC_HSA } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						}
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						{ percentage
							? <PercentageInput
								ceiling={ planValues.eF_Premium }
								floor={ frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eF_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eF_HSA }
								label={ {
									basic: true,
									content: planValues.eF_Employer > planValues.eE_Employer ? maskCurrency( planValues.eF_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								name="eF_Employer"
								readOnly={ readonly }
								setValidator={ setValidator }
								validator={ validator }
								updateValue={ updateValue }
								value={ planValues.eF_Employer }
							/>
							: <Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								label={ {
									basic: true,
									content: planValues.eF_Employer > planValues.eE_Employer ? maskCurrency( planValues.eF_Employer - planValues.eE_Employer, false ) : '0.00',
									style: { borderRadius: '0 .28571429rem .28571429rem 0', fontWeight: 'initial' }
								} }
								labelPosition="right"
								name="eF_Employer"
								value={ formData.eF_Employer }
								maxLength={ 255 }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								error={
									!flatRate
										&& validator.eF_Employer > planValues.eF_Premium
										// || validator.eF_Employer < ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eF_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eF_HSA )
								}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
									setValidator( { ...validator, eF_Employer: planValues[ e.target.name ] } ); // set the validator to the planValue
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
									setValidator( { ...validator, eF_Employer: frequency.premium !== frequency.hsa ? planValues.minimumContribution - planValues.eF_HSA / frequency.hsa * frequency.premium : planValues.minimumContribution - planValues.eF_HSA } ); // set the validator to the minimum
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						}
					</Grid.Column>
				</Grid.Row>
				{/* hsa */}
				{ hasHSA // HDHP
					? <Grid.Row style={ formMiddleStyles } verticalAlign="middle">
						<Grid.Column width={ 2 }>HSA</Grid.Column>
						<Grid.Column width={ 3 }>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eE_HSA"
								value={ formData.eE_HSA }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eS_HSA"
								value={ formData.eS_HSA }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eC_HSA"
								value={ formData.eC_HSA }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
						<Grid.Column
							style={ flatRate
								? { opacity: 0.5, pointerEvents: 'none' }
								: null
							}
							width={ 3 }
						>
							<Input
								required
								fluid
								icon="dollar"
								iconPosition="left"
								name="eF_HSA"
								value={ formData.eF_HSA }
								onClick={ handleClickSelect }
								onChange={ ( e, { name, value } ) => {
									updateValue( { name, value } );
								}}
								onBlur={ !readonly ? e => {
									maskValue( e.target.name ); // set to maskedValue onBlur
								} : null }
								onFocus={ !readonly ? e => {
									unmaskValue( e.target.name ); // set formData to planValue when editing
								} : null }
								onKeyDown={ floatKeyDown }
								readOnly={ readonly }
							/>
						</Grid.Column>
					</Grid.Row>
					: null
				}
				{/* employee */}
				<Grid.Row style={ formBottomStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }>Employee</Grid.Column>
					<Grid.Column width={ 3 }>
						<Input
							required
							readOnly
							fluid
							icon={ percentage ? 'percent' : 'dollar' }
							iconPosition="left"
							value={ planValues.eE_Premium - planValues.eE_Employer > 0
								? percentage
									? Math.round( ( planValues.eE_Premium - planValues.eE_Employer ) / planValues.eE_Premium * 100 * 100 ) / 100
									: maskCurrency( maskCurrency( planValues.eE_Premium, false, false ) - maskCurrency( planValues.eE_Employer, false, false ), false )
								: percentage
									? '0'
									: '0.00'
							}
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon={ percentage ? 'percent' : 'dollar' }
							iconPosition="left"
							value={ planValues.eS_Premium - planValues.eS_Employer > 0
								? percentage
									? Math.round( ( planValues.eS_Premium - planValues.eS_Employer ) / planValues.eS_Premium * 100 * 100 ) / 100
									: maskCurrency( maskCurrency( planValues.eS_Premium, false, false ) - maskCurrency( planValues.eS_Employer, false, false ), false )
								: percentage
									? '0'
									: '0.00'
							}
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon={ percentage ? 'percent' : 'dollar' }
							iconPosition="left"
							value={ planValues.eC_Premium - planValues.eC_Employer > 0
								? percentage
									? Math.round( ( planValues.eC_Premium - planValues.eC_Employer ) / planValues.eC_Premium * 100 * 100 ) / 100
									: maskCurrency( maskCurrency( planValues.eC_Premium, false, false ) - maskCurrency( planValues.eC_Employer, false, false ), false )
								: percentage
									? '0'
									: '0.00'
							}
							tabIndex="-1"
						/>
					</Grid.Column>
					<Grid.Column
						style={ flatRate
							? { opacity: 0.5, pointerEvents: 'none' }
							: null
						}
						width={ 3 }
					>
						<Input
							required
							readOnly
							fluid
							icon={ percentage ? 'percent' : 'dollar' }
							iconPosition="left"
							value={ planValues.eF_Premium - planValues.eF_Employer > 0
								? percentage
									? Math.round( ( planValues.eF_Premium - planValues.eF_Employer ) / planValues.eF_Premium * 100 * 100 ) / 100
									: maskCurrency( maskCurrency( planValues.eF_Premium, false, false ) - maskCurrency( planValues.eF_Employer, false, false ), false )
								: percentage
									? '0'
									: '0.00'
							}
							tabIndex="-1"
						/>
					</Grid.Column>
				</Grid.Row>
				{/* errors */}
				<Grid.Row style={ formBottomStyles } verticalAlign="middle">
					<Grid.Column width={ 2 }></Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={
								validator.eE_Employer <= planValues.eE_Premium
								// && validator.eE_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eE_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eE_HSA )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eE_Employer <= planValues.eE_Premium }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ 'Employer contribution may not exceed premium.' }</small>
								</Message.Item>
								{/* <Message.Item
									hidden={ validator.eE_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eE_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eE_HSA ) }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eE_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eE_HSA ) }.` }</small>
								</Message.Item> */}
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={
								flatRate
								|| validator.eS_Employer <= planValues.eS_Premium
								// && validator.eS_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eS_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eS_HSA )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eS_Employer <= planValues.eS_Premium }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ 'Employer contribution may not exceed premium.' }</small>
								</Message.Item>
								{/* <Message.Item
									hidden={ validator.eS_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eS_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eS_HSA ) }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eS_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eS_HSA ) }.` }</small>
								</Message.Item> */}
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={
								flatRate
								|| validator.eC_Employer <= planValues.eC_Premium
								// && validator.eC_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eC_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eC_HSA )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eC_Employer <= planValues.eC_Premium }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ 'Employer contribution may not exceed premium.' }</small>
								</Message.Item>
								{/* <Message.Item
									hidden={ validator.eC_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eC_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eC_HSA ) }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eC_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eC_HSA ) }.` }</small>
								</Message.Item> */}
							</Message.List>
						</Message>
					</Grid.Column>
					<Grid.Column width={ 3 }>
						<Message
							hidden={
								flatRate
								|| validator.eF_Employer <= planValues.eF_Premium
								// && validator.eF_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eF_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eF_HSA )
							}
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item
									hidden={ validator.eF_Employer <= planValues.eF_Premium }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ 'Employer contribution may not exceed premium.' }</small>
								</Message.Item>
								{/* <Message.Item
									hidden={ validator.eF_Employer >= ( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eF_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eF_HSA ) }
								>
									<Icon name="exclamation circle" size="small" />
									<small>{ `Minimum employer contribution is ${ maskCurrency( frequency.premium !== frequency.hsa ? planValues.minimumContribution - ( ( planValues.eF_HSA / frequency.hsa ) * frequency.premium ) : planValues.minimumContribution - planValues.eF_HSA ) }.` }</small>
								</Message.Item> */}
							</Message.List>
						</Message>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Checkbox
				name="active"
				readOnly={ readonly }
				label="Active"
				checked={ isActive }
				onChange={ ( e, { checked } ) => {
					setIsActive( checked );
				} }
				tabIndex="-1"
			/>
			{/* { hasHSA
				? <>
				&nbsp;&nbsp;&nbsp;
					<Checkbox
						name="lumpSumHSA"
						readOnly={ readonly }
						label="Lump Sum HSA"
						checked={ lumpSumHSA }
						onChange={ ( e, { checked } ) => {
							setLumpSumHSA( checked );
						} }
						tabIndex="-1"
					/>
				</>
				: null
			} */}
		</>
	);
};

export default memo( PlanTable );
