/* eslint-disable react/display-name */
import React, { useCallback, useContext, useState } from 'react';
import { Dropdown, Tab } from 'semantic-ui-react';
import { Rnd } from 'react-rnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CustomersContext } from '../CustomersContext';

import NotePane from './NotePane';

const NotesModal = ( { open, closeModal } ) => {
	const [ activeIndex, setActiveIndex ] = useState( 0 );

	const { noteTypes } = useContext( CustomersContext );

	const options = noteTypes.map( ( type, index ) => ( { key: index, text: type.name, value: index } ) );

	const panes = noteTypes.map( type => ( { render: () => <NotePane title={ type.name } typeId={ type.id } /> } ) );

	const handleChange = useCallback( ( e, { value } ) => {
		setActiveIndex( value );
	}, [] );

	if ( !open ) {
		return null;
	}

	return (
		<Rnd
			default={ {
				x: 32,
				y: 32,
				width: 300,
				height: 450
			} }
			minWidth={ 300 }
			minHeight={ 46 }
			maxWidth={ 600 }
			bounds="window"
			dragHandleClassName="dragHandle"
			style={{ zIndex: 9999 }}
		>
			<div
				style={ {
					height: '100%',
					backgroundColor: '#fff',
					boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
					borderRadius: '.28571429rem',
					overflow: 'hidden'
				} }
			>
				<div
					className="dragHandle"
					style={ {
						position: 'sticky',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						top: 0,
						padding: '1rem',
						backgroundColor: '#2654A3',
						color: '#fff',
						cursor: 'grab'
					} }
				>
					<h3 style={ { lineHeight: 1, margin: 0 } }>Client Notes</h3>
					{/* add button to close the modal */}
					<FontAwesomeIcon
						icon={ faTimes }
						style={{ fontSize: '1em', cursor: 'pointer' }}
						onClick={ closeModal }
					/>
				</div>
				<div style={ { position: 'sticky', top: '46px', padding: '1rem', borderTop: '1px solid rgba(34,36,38,.15)', borderBottom: '1px solid rgba(34,36,38,.15)', zIndex: 1 } }>
					<Dropdown
						options={ options }
						selection
						onChange={ handleChange }
						value={ activeIndex }
					/>
				</div>
				<Tab
					activeIndex={ activeIndex }
					menu={ { style: { minHeight: 0, margin: 0 } } }
					panes={ panes }
					style={{ height: 'calc(100% - 124px)' }}
				/>
			</div>
		</Rnd>
	);
};

export default NotesModal;
