import React, { useContext } from 'react';
import { Card, Divider, Form, Header, Icon, Popup } from 'semantic-ui-react';
import { AdminContext } from '../Admin/AdminContext';
import { AuthContext } from '../Auth/AuthContext';
import { maskPhone } from '../../Services/inputMasks';

const UserProfile = () => {
	// context
	const { showUserProfile } = useContext( AdminContext );
	const { authedUser } = useContext( AuthContext );

	return (
		<Popup
			basic
			wide="very"
			size="small"
			hoverable
			trigger={ <span onClick={ showUserProfile }>User Profile</span> }
			style={{ width: '200px' }}
		>
			<Popup.Header>
				<Card style={{ width: '100%', border: 'none', boxShadow: 'none', textAlign: 'center' }}>
					<Card.Content style={{ paddingBottom: 0 }}>
						<Card.Header>
							<Header>
								<Icon name="user" style={{ display: 'block', margin: '0 auto' }}/>
								<Header sub>{ authedUser.role.description }</Header>
							</Header>
						</Card.Header>
					</Card.Content>
				</Card>
			</Popup.Header>
			<Divider />
			<Popup.Content>
				<Form>
					<Form.Group>
						<Form.Input
							readOnly
							transparent
							label="Name"
							value={ `${ authedUser.firstName } ${ authedUser.lastName }` }
							width={ 16 }
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							readOnly
							transparent
							label="Email Address"
							value={ authedUser.email }
							width={ 16 }
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							readOnly
							transparent
							label="Phone"
							value={ maskPhone( authedUser.phone ) }
							width={ 16 }
						/>
					</Form.Group>
				</Form>
			</Popup.Content>
		</Popup>
	);
};

export default UserProfile;
