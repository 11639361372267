import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Spinner = ( { active, progress } ) => {
	return (
		<Dimmer active={ active } style={{ position: 'fixed', zIndex: 2000 }}>
			<Loader size="large" inverted >
				{ progress >= 0
					? <>
						<span>{ `${ Math.floor( progress ) }%` }</span>
						{ /* <Progress
						indicating
						inverted
						label="Please wait..."
						percent={ progress }
						progress
						size="tiny"
						style={{ width: '200px' }}
					/> */ }
					</>
					: <>Please wait...</>
				}
			</Loader>
		</Dimmer>
	);
};

export default Spinner;
