import React, { useRef } from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';

const Datepicker = ( {
	width = null,
	label,
	name,
	selected,
	onChange
} ) => {
	const datepickerRef = useRef();

	return (
		<Form.Field width={ width }>
			{ label ? <label>{ label }</label> : null }
			<DatePicker
				name={ name }
				ref={ datepickerRef }
				customInput={
					<MaskedInput
						mask={ [ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/ ] }
						keepCharPositions= {true}
						guide = {true}
					/>
				}
				tabIndex={ 0 }
				selected={ selected }
				onChange={ onChange }
				onKeyDown={ e => {
					if ( e.key === 'Tab' ) {
						datepickerRef.current.setOpen( false );
					}
				}}
			/>
		</Form.Field>
	);
};

export default Datepicker;
