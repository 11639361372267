import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Accordion, Button, Form, Icon, Loader, Tab } from 'semantic-ui-react';
import axios from 'axios';
import { CustomersContext } from '../CustomersContext';
import { withCookies } from 'react-cookie';
import { Settings } from '../../Global/config';
import Note from './Note';

const NotePane = ( { cookies, typeId } ) => {
	const { customer } = useContext( CustomersContext );

	const [ loading, setLoading ] = useState( 'retreiving notes...' );
	const [ notes, setNotes ] = useState( [] );
	const [ content, setContent ] = useState( '' );
	const [ activeIndex, setActiveIndex ] = useState( -1 );

	const getNotes = useCallback( async () => {
		const response = await axios( {
			method: 'get',
			baseURL: Settings.apiUrl,
			url: '/notes',
			params: { typeID: typeId, customerID: customer.id },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: cookies.get( 'token' )
			}
		} );

		setNotes( response.data );
	}, [ cookies, customer.id, typeId ] );

	const postNote = useCallback( async data => {
		await axios( {
			method: 'post',
			baseURL: Settings.apiUrl,
			url: '/note',
			params: { customerID: customer.id },
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: cookies.get( 'token' )
			},
			data
		} );
	}, [ cookies, customer.id ] );

	const deleteNote = useCallback( async id => {
		await axios( {
			method: 'delete',
			baseURL: Settings.apiUrl,
			url: `/note/${ id }`,
			headers: {
				api_key: Settings.apiKey,
				Accept: 'application/json',
				AccessToken: cookies.get( 'token' )
			}
		} );
	}, [ cookies ] );

	useEffect( () => {
		( async () => {
			await getNotes();
			setLoading( '' );
		} )();
	}, [ getNotes ] );

	const handleSave = useCallback( async note => {
		const newNote = {
			id: 0,
			benefitGroup: null,
			type: { id: typeId },
			content,
			createdBy: null
		};

		await postNote( note || newNote );
		await getNotes();
		setContent( '' );
		setActiveIndex( -1 );
	}, [ content, getNotes, postNote, typeId ] );

	const handleDelete = useCallback( async id => {
		setLoading( 'Deleting note...' );
		await deleteNote( id );
		await getNotes();
		setContent( '' );
		setActiveIndex( -1 );
		setLoading( '' );
	}, [ deleteNote, getNotes ] );

	const mapNotes = useCallback(
		() => notes.length
			? notes.map( note => <Note key={ note.id } note={ note } onSubmit={ handleSave } onDelete={ handleDelete } /> )
			: <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', paddingBottom: '1rem', borderBottom: '1px solid rgba(34,36,38,.15)' }}>
				<p>No notes found.</p>
			</div>
		, [ handleDelete, handleSave, notes ]
	);

	const handleSubmit = useCallback( () => handleSave(), [ handleSave ] );

	return (
		<Tab.Pane as="div" style={ { height: '100%', padding: '1rem', overflowY: 'auto' } }>
			<Accordion>
				<Accordion.Title
					style={{ fontSize: '0.8rem' }}
					active={ activeIndex === 0 }
					index={ 0 }
					onClick={ ( e, { index } ) => setActiveIndex( prev => prev === index ? -1 : index )}
				>
					<Icon name="dropdown" />
					Add a New Note
				</Accordion.Title>
				<Accordion.Content active={ activeIndex === 0 }>
					<Form>
						<Form.TextArea
							value={ content }
							onChange={ ( e, { value } ) => setContent( value ) }
						/>
						<Form.Field style={{ textAlign: 'right' }}>
							<Button
								content="Save"
								disabled={ !content }
								size="mini"
								onClick={ handleSubmit }
							/>
						</Form.Field>
					</Form>
				</Accordion.Content>
			</Accordion>
			<div style={{ marginTop: '1rem', paddingTop: '1rem', borderTop: '1px solid rgba(34,36,38,.15)' }}>
				{ loading ? <p><Loader size="tiny" active inline /> { loading }</p> : mapNotes() }
			</div>
		</Tab.Pane>
	);
};

export default withCookies( NotePane );
