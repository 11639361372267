import React, { useCallback, useContext, useEffect } from 'react';
import { Divider, Form, Header, Tab } from 'semantic-ui-react';
import { floatKeyDown } from '../../../../Services/inputMasks';
import Datepicker from '../../../Shared/Datepicker';
import { CustomersContext } from '../../CustomersContext';

const RetirementTab = ( { formData, updateField, readonly } ) => {
	const {
		benefitGroup,
		enrollmentTypes,
		retirementPlanTypes,
		retirementPlanSubTypes,
		minAges,
		vestingTypes,
		serviceTypes,
		planMergerTypes,
		adoptionAgreementTypes,
		lookupRetirementPlanSubTypes
	} = useContext( CustomersContext ); // lookups

	useEffect( () => {
		if ( formData.planType ) {
			updateField( { target: { name: 'planSubType', value: benefitGroup.retirement.planSubType.id } } );
			lookupRetirementPlanSubTypes( formData.planType );
		}
	}, [ benefitGroup.retirement.planSubType.id, formData.planType, lookupRetirementPlanSubTypes, updateField ] );

	const findOption = useCallback( ( val, options ) => {
		const opt = options.find( option => option.value === val );

		return opt?.text || '';
	}, [] );

	return (
		<Tab.Pane>
			{ console.log( formData )}
			<Form>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.planType, retirementPlanTypes ) }
							label="Type of Plan"
							width={ 4 }
						/>
						: <Form.Select
							name="planType"
							value={ formData.planType || '' }
							label="Type of Plan"
							options={ retirementPlanTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ formData.planType && formData.planType !== 128
						? readonly
							? <Form.Input
								value={ findOption( formData.planSubType, retirementPlanSubTypes ) }
								label="Sub Type"
								width={ 4 }
							/>
							: <Form.Select
								name="planSubType"
								value={ formData.planSubType || '' }
								label="Sub Type"
								options={ retirementPlanSubTypes }
								width={ 4 }
								fluid
								search
								onChange={ ( e, { name, value } ) => {
									updateField( { target: { name, value } } );
								} }
							/>
						: null
					}
					{ readonly
						? <Form.Input
							value={ findOption( formData.minimumAge, minAges ) }
							label="Minimum Age"
							width={ 4 }
						/>
						: <Form.Select
							name="minimumAge"
							value={ formData.minimumAge || '' }
							label="Minimum Age"
							options={ minAges }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.vesting, vestingTypes ) }
							label="Vesting"
							width={ 4 }
						/>
						: <Form.Select
							name="vesting"
							value={ formData.vesting || '' }
							label="Vesting"
							options={ vestingTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ formData.vesting === 145 // other ID
						? <Form.Input
							required
							name="vestingOther"
							value={ formData.vestingOther || '' }
							label="Explain"
							width={ 4 }
							onChange={ updateField }
							readOnly={ readonly }
						/>
						: null
					}
				</Form.Group>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.service, serviceTypes ) }
							label="Service"
							width={ 4 }
						/>
						: <Form.Select
							name="service"
							value={ formData.service || '' }
							label="Service"
							options={ serviceTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ formData.service === 163 // other ID
						? <Form.Input
							required
							name="serviceOther"
							value={ formData.serviceOther || '' }
							label="Explain"
							width={ 4 }
							onChange={ updateField }
							readOnly={ readonly }
						/>
						: null
					}
				</Form.Group>
				<Form.Group>
					<Form.Input
						icon="percent"
						iconPosition="left"
						name="matchPerc"
						label="Match"
						value={ formData.matchPerc }
						onClick={ e => e.target.select() }
						onChange={ updateField }
						width={ 4 }
						onKeyDown={ floatKeyDown }
						readOnly={ readonly }
					/>
					<Form.TextArea
						label="Up To"
						name="matchNote"
						value={ formData.matchNote || '' }
						onChange={ updateField }
						rows={ 6 }
						width={ 4 }
						readOnly={ readonly }
					/>
				</Form.Group>
				<Form.Group>
					<Datepicker
						label="Plan Start Date"
						name="planStartDate"
						selected={ formData.planStartDate }
						onChange={ e => {
							updateField( { target: { name: 'planStartDate', value: e } } );
						} }
						width={ 4 }
					/>
					{ readonly
						? <Form.Input
							value={ findOption( formData.enrollmentType, enrollmentTypes ) }
							label="Auto-Enroll"
							width={ 4 }
						/>
						: <Form.Select
							name="enrollmentType"
							value={ formData.enrollmentType || '' }
							label="Auto-Enroll"
							options={ enrollmentTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
					{ readonly
						? <Form.Input
							value={ findOption( formData.planMergerRequired, planMergerTypes ) }
							label="Require Plan Merger"
							width={ 4 }
						/>
						: <Form.Select
							name="planMergerRequired"
							value={ formData.planMergerRequired || '' }
							label="Require Plan Merger"
							options={ planMergerTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
				<Form.Group>
					{ readonly
						? <Form.Input
							value={ findOption( formData.adoptionAgreement, adoptionAgreementTypes ) }
							label="Adoption Agreement"
							width={ 4 }
						/>
						: <Form.Select
							name="adoptionAgreement"
							value={ formData.adoptionAgreement || '' }
							label="Adoption Agreement"
							options={ adoptionAgreementTypes }
							width={ 4 }
							fluid
							search
							onChange={ ( e, { name, value } ) => {
								updateField( { target: { name, value } } );
							} }
						/>
					}
				</Form.Group>
				<Form.Group>
					<Form.TextArea
						label="Testing Concerns"
						name="testing"
						value={ formData.testing || '' }
						onChange={ updateField }
						width={ 16 }
						rows={ 6 }
						readOnly={ readonly }
					/>
				</Form.Group>
				<Header>Ownership</Header>
				<Divider />
				<Form.Group>
					<Form.TextArea
						label="Owners"
						name="ownerNote"
						value={ formData.ownerNote || '' }
						onChange={ updateField }
						width={ 16 }
						rows={ 6 }
						readOnly={ readonly }
					/>
				</Form.Group>
				<Form.Group>
					<Form.TextArea
						label="Family"
						name="familyNote"
						value={ formData.familyNote || '' }
						onChange={ updateField }
						width={ 16 }
						rows={ 6 }
						readOnly={ readonly }
					/>
				</Form.Group>
				<Form.Group>
					<Form.TextArea
						label="Officers"
						name="officerNote"
						value={ formData.officerNote || '' }
						onChange={ updateField }
						width={ 16 }
						rows={ 6 }
						readOnly={ readonly }
					/>
				</Form.Group>
			</Form>
		</Tab.Pane>
	);
};

export default RetirementTab;
