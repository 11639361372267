import React from 'react';
import { Divider, Form, Header, Segment } from 'semantic-ui-react';

import PlanInfoPopup from './PlanInfoPopup';

const PlanInfoForm = ( { info, updateInfo } ) => {
	return (
		<Form>
			<Form.Group>
				<Form.Input
					name="field_A1"
					value={ info.field_A1 || '' }
					label="Calendar Year Deductible"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Form.Group>
				<Form.Input
					name="field_B1"
					width={ 8 }
					value={ info.field_B1 || '' }
					label="Annual Maximum Paid By Plan"
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Preventative Care</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_B2"
						value={ info.field_B2 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B3"
						value={ info.field_B3 || '' }
						label={ <label>Diagnostic & Preventative Services&nbsp;<PlanInfoPopup content="Exams & Cleanings. Includes flouride & space maintainers - to age 16" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_B4"
						value={ info.field_B4 || '' }
						label={ <label>Sealants&nbsp;<PlanInfoPopup content="To prevent decay of permanent teeth - to age 16" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B5"
						value={ info.field_B5 || '' }
						label={ <label>Brush Biopsy&nbsp;<PlanInfoPopup content="To detect oral cancer" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C1"
						width={ 8 }
						value={ info.field_C1 || '' }
						label="X-Rays"
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Basic Services</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_C2"
						value={ info.field_C2 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C3"
						value={ info.field_C3 || '' }
						label={ <label>Emergency Palliative Treatment&nbsp;<PlanInfoPopup content="To temporarily remove pain" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C4"
						value={ info.field_C4 || '' }
						label={ <label>Minor Restorative Services&nbsp;<PlanInfoPopup content="Fillings & Crown Repair" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C5"
						value={ info.field_C5 || '' }
						label={ <label>Oral Surgery Services&nbsp;<PlanInfoPopup content="Extractions & Dental Surgery" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_D1"
						value={ info.field_D1 || '' }
						label={ <label>Relines & Repairs&nbsp;<PlanInfoPopup content="To bridges & dentures" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E1"
						width={ 8 }
						value={ info.field_E1 || '' }
						label={ <label>Other Basic Services&nbsp;<PlanInfoPopup content="Miscellaneous services" /></label> }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Major Services&nbsp;<PlanInfoPopup content="It is advisable to have your provider request a predetermination of benefits from Delta Dental before oral surgery or major services are performed." /></Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_E2"
						value={ info.field_E2 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E3"
						value={ info.field_E3 || '' }
						label={ <label>Periodontic Services&nbsp;<PlanInfoPopup content="To treat gum disease" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_E4"
						value={ info.field_E4 || '' }
						label={ <label>Endodonic Services&nbsp;<PlanInfoPopup content="Root canals" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E5"
						value={ info.field_E5 || '' }
						label={ <label>Major Restorative Services&nbsp;<PlanInfoPopup content="Crowns" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_E6"
						value={ info.field_E6 || '' }
						label={ <label>Prosthodontic Services&nbsp;<PlanInfoPopup content="Bridges & Dentures" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Orthodontic Services (up to age 20)</Header>
				<Divider />
				<Form.Group>
					<Form.Input
						name="field_E7"
						value={ info.field_E7 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E8"
						value={ info.field_E8 || '' }
						label={ <label>Orthodontia&nbsp;<PlanInfoPopup content="Braces" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>
		</Form>
	);
};

export default PlanInfoForm;
