export const maskPhone = value => {
	if ( value ) {
		const arr = value.replace( /[^0-9]/g, '' ).split( '' );
		const maskedValue = arr.map( ( char, i ) => {
			switch ( i ) {
				case 0:
					return `(${ char }`;
				case 3:
					return `) ${ char }`;
				case 6:
					return `-${ char }`;
				default:
					return char;
			}
		} );

		return maskedValue.join( '' );
	}

	return value;
};

export const unmaskPhone = value => {
	if ( value ) {
		return value.replace( /[^0-9]/g, '' );
	}

	return value;
};

export const validatePhone = value => {
	return /^[0-9]{10}$/.test( value );
};

export const validateZip = value => {
	return /^[0-9]{5}$/.test( value );
};

export const validateEmail = value => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
};

export const maskFein = value => {
	if ( value ) {
		const arr = value.replace( /[^0-9]/g, '' ).split( '' );
		const maskedValue = arr.map( ( char, i ) => {
			switch ( i ) {
				case 2:
					return `-${ char }`;
				default:
					return char;
			}
		} );

		return maskedValue.join( '' );
	}

	return value;
};

export const unmaskFein = value => {
	if ( value ) {
		return value.replace( /[^0-9]/g, '' );
	}

	return value;
};

export const validateFein = value => {
	return /^[0-9]{9}$/.test( value );
};

export const maskCurrency = ( value, asCurrency = true, useGrouping = true, fixed = 2 ) => {
	if ( value !== undefined ) {
		if ( value === '' ) {
			return value;
		}

		const str = value.toString().replace( /[$,]/g, '' );

		const num = parseFloat( str );

		return asCurrency
			? num.toLocaleString( 'en-US', { style: 'currency', currency: 'USD' } )
			: num.toLocaleString( undefined, { minimumFractionDigits: fixed, maximumFractionDigits: fixed, useGrouping } );
	}

	return value;
};

export const unmaskCurrency = value => {
	if ( value !== undefined ) {
		const str = value.toString().replace( /[$,]/g, '' );

		return parseFloat( str );
	}

	return value;
};

export const integerKeyDown = e => {
	if (
		!e.ctrlKey
		&& !e.metaKey
		&& e.key !== 'Backspace'
    && e.key !== 'Tab'
    && e.key !== '0'
    && e.key !== '1'
    && e.key !== '2'
    && e.key !== '3'
    && e.key !== '4'
    && e.key !== '5'
    && e.key !== '6'
    && e.key !== '7'
    && e.key !== '8'
    && e.key !== '9'
	) {
		e.preventDefault();
		e.stopPropagation();
	}
}; // backspace, tab, 0-9, numpad 0-9

export const floatKeyDown = e => {
	if (
		!e.ctrlKey
		&& !e.metaKey
		&& e.key !== 'Backspace'
    && e.key !== 'Tab'
    && e.key !== '0'
    && e.key !== '1'
    && e.key !== '2'
    && e.key !== '3'
    && e.key !== '4'
    && e.key !== '5'
    && e.key !== '6'
    && e.key !== '7'
    && e.key !== '8'
    && e.key !== '9'
    && e.key !== '.'
	) {
		e.preventDefault();
		e.stopPropagation();

		if ( e.key === 'q' || e.key === 'Q' ) {
			e.target.select();
		}
	}
}; // backspace, tab, 0-9, numpad 0-9, period, numpad period(s)

export const stateOptions = [
	{ value: '', text: '' },
	{ value: 'AL', text: 'AL' },
	{ value: 'AK', text: 'AK' },
	{ value: 'AZ', text: 'AZ' },
	{ value: 'AR', text: 'AR' },
	{ value: 'CA', text: 'CA' },
	{ value: 'CO', text: 'CO' },
	{ value: 'CT', text: 'CT' },
	{ value: 'DE', text: 'DE' },
	{ value: 'DC', text: 'DC' },
	{ value: 'FL', text: 'FL' },
	{ value: 'GA', text: 'GA' },
	{ value: 'HI', text: 'HI' },
	{ value: 'ID', text: 'ID' },
	{ value: 'IL', text: 'IL' },
	{ value: 'IN', text: 'IN' },
	{ value: 'IA', text: 'IA' },
	{ value: 'KS', text: 'KS' },
	{ value: 'KY', text: 'KY' },
	{ value: 'LA', text: 'LA' },
	{ value: 'ME', text: 'ME' },
	{ value: 'MD', text: 'MD' },
	{ value: 'MA', text: 'MA' },
	{ value: 'MI', text: 'MI' },
	{ value: 'MN', text: 'MN' },
	{ value: 'MS', text: 'MS' },
	{ value: 'MO', text: 'MO' },
	{ value: 'MT', text: 'MT' },
	{ value: 'NE', text: 'NE' },
	{ value: 'NV', text: 'NV' },
	{ value: 'NH', text: 'NH' },
	{ value: 'NJ', text: 'NJ' },
	{ value: 'NM', text: 'NM' },
	{ value: 'NY', text: 'NY' },
	{ value: 'NC', text: 'NC' },
	{ value: 'ND', text: 'ND' },
	{ value: 'OH', text: 'OH' },
	{ value: 'OK', text: 'OK' },
	{ value: 'OR', text: 'OR' },
	{ value: 'PA', text: 'PA' },
	{ value: 'RI', text: 'RI' },
	{ value: 'SC', text: 'SC' },
	{ value: 'SD', text: 'SD' },
	{ value: 'TN', text: 'TN' },
	{ value: 'TX', text: 'TX' },
	{ value: 'UT', text: 'UT' },
	{ value: 'VT', text: 'VT' },
	{ value: 'VA', text: 'VA' },
	{ value: 'WA', text: 'WA' },
	{ value: 'WV', text: 'WV' },
	{ value: 'WI', text: 'WI' },
	{ value: 'WY', text: 'WY' }
];
