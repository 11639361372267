import React, { useContext, useState } from 'react';
import { Button, Form, Icon, Message, Modal, Progress } from 'semantic-ui-react';
import { OperationsContext } from '../OperationsContext';
import { useForm } from '../../../Hooks/useForm';

const FilesModal = () => {
	// context
	const {
		createFile,
		filesModal,
		hideFilesModal,
		fileUploadProgress
	} = useContext( OperationsContext );

	// form handler
	const { formData, updateField, setData } = useForm( { file: null } );

	// state
	const [ isUploading, setIsUploading ] = useState( false );
	const [ validator, setValidator ] = useState( { file: {} } );

	// clear out formData, and validator when modal closes
	const clearData = () => {
		setIsUploading( false );
		setData( { file: null } );
		setValidator( { file: {} } );
	};

	return (
		<Modal
			open={ filesModal }
			size="mini"
		>
			<Modal.Header>
        File
			</Modal.Header>
			<Modal.Content>
				{ !isUploading
					? <Form>
						<input
							type="file"
							name="file"
							label="Upload New File"
							onChange={ e => {
								updateField( { target: { name: 'file', value: e.target.files[ 0 ] } } );
							} }
						/>
						<Message
							hidden={ Boolean( validator.file ) }
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
						>
							<Message.List style={{ listStyle: 'none' }}>
								<Message.Item>
									<Icon name="exclamation circle" />
                Please upload a file.
								</Message.Item>
							</Message.List>
						</Message>
					</Form>
					: <div>
						<p><Icon name="spinner" loading /> Uploading file, please wait...</p>
						<Progress
							size="tiny"
							label={ `${ fileUploadProgress }%` }
							style={ parseInt( fileUploadProgress ) >= 0 ? { display: 'block', margin: '1rem 0' } : { display: 'none' } }
							color={ fileUploadProgress === 100 ? 'green' : null }
							percent={ fileUploadProgress }
						/>
					</div>
				}
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ async () => {
						setIsUploading( true );
						const data = new FormData();

						data.append( 'file', formData.file );

						await createFile( data );
						clearData();
						hideFilesModal();
					} }
					disabled={ isUploading || !formData.file }
				>
				</Button>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Cancel"
					onClick={ () => {
						clearData();
						hideFilesModal();
					}}
					disabled={ isUploading }
				>
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default FilesModal;
