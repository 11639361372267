import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { useForm } from '../../../Hooks/useForm';
import { CustomersContext } from '../CustomersContext';

const NewSubsidiaryGroupModal = ( { closeModal, open } ) => {
	// context
	const { addSubsidiaryGroup } = useContext( CustomersContext );

	// form handler
	const { formData, updateField, setData } = useForm( {
		id: 0,
		name: '',
		parentClientID: null
	} );

	// state
	const [ validator, setValidator ] = useState( { name: 'string' } );

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		setData( {
			id: 0,
			name: '',
			parentClientID: null
		} );
		setValidator( { name: 'string' } );
	};

	const handleCreateNewGroup = useCallback( async () => {
		await addSubsidiaryGroup( formData );
	}, [ addSubsidiaryGroup, formData ] );

	return (
		<Modal
			open={ open }
			size="small"
		>
			<Modal.Header>Create New Subsidiary Group</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group>
						<Form.Input
							required
							name="name"
							value={ formData.name || '' }
							label="Subsidiary Group Name"
							width={ 8 }
							maxLength={ 255 }
							onChange={ updateField }
							error={ validator.name.length === 0 }
							onBlur={ () => {
								setValidator( { ...validator, name: formData.name || '' } );
							}}
							onFocus={ () => {
								setValidator( { ...validator, name: 'string' } );
							}}
						/>
					</Form.Group>
					<Message
						hidden={ validator.name.length > 0 }
						negative
						style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
					>
						<Message.List style={{ listStyle: 'none' }}>
							<Message.Item
								hidden={ validator.name.length > 0 }
							>
								<Icon name="exclamation circle" />
                Please enter a name.
							</Message.Item>
						</Message.List>
					</Message>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content="Save"
					onClick={ () => {
						handleCreateNewGroup();
						clearData();
						closeModal();
					}}
					disabled={
						formData.name.length === 0
					}
				/>
				<Button
					style={{ backgroundColor: '#F58229', color: '#fff' }}
					content="Close"
					onClick={ closeModal }
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default NewSubsidiaryGroupModal;
