/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Checkbox, FormCheckbox, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { CustomersContext } from '../Customers/CustomersContext';
import Table from '../Shared/Table';
import DeleteConfirmModal from '../Shared/DeleteConfirmModal';
import TermInactiveConfirmModal from '../Shared/TermInactiveConfirmModal';
import ClientsModal from '../Customers/Clients/ClientsModal';
import NotesModal from '../Customers/Clients/NotesModal';
import Spinner from '../Shared/Spinner';

function transformDate( str ) {
	return new Date( str ).toLocaleDateString();
}

const Clients = () => {
	// context
	const {
		customer,
		clients,
		uiLoader,
		spinner,
		loadingMessage,
		tableLoader,
		notesModal,
		getClients,
		deleteClient,
		termClient,
		handleClientActivation,
		showClientsModal,
		deleteConfirmModal,
		termInactiveConfirmModal,
		toggleDeleteConfirmModal,
		toggleTermInactiveConfirmModal,
		toggleNotesModal,
		clientBenefitReadonly
	} = useContext( CustomersContext );

	// state
	const [ deleteConfirmModalNameProp, setDeleteConfirmModalNameProp ] = useState( '' );
	const [ deleteId, setDeleteId ] = useState( '' );
	const [ termInactiveConfirmModalNameProp, setTermInactiveConfirmModalNameProp ] = useState( '' );
	const [ termId, setTermId ] = useState( '' );
	const [ showInactive, setShowInactive ] = useState( false );

	useEffect( () => {
		getClients();
	}, [ getClients ] );

	const columns = [
		{
			accessor: 'isActive',
			Header: 'Activate',
			Cell: row => {
				const handleActivation = ( e, { checked } ) => {
					handleClientActivation( row.original.id, checked );
				};

				return (
					<div>
						<Checkbox
							toggle
							checked={ row.original.isActive }
							size="mini"
							onChange={ handleActivation }
						/>
					</div>
				);
			},
			sortable: true,
			width: 100
		},
		{
			Header: 'Prism Client ID',
			accessor: 'prismID',
			filterable: true,
			sortable: true
		},
		{
			id: 'name',
			Header: 'Name',
			accessor: row => `${ row.companyName }`,
			filterable: true,
			sortable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.companyName.toUpperCase(), filter.value.toUpperCase() )
		},
		{
			id: 'subsidiaryGroup',
			Header: <div>Affiliated Group</div>,
			accessor: row => `${ row.subsidiaryGroup }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.subsidiaryGroup.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			// eslint-disable-next-line react/display-name
			Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.subsidiaryPrimary ? <><Icon name="star" />&nbsp;</> : null }{ row.original.subsidiaryGroup }</div>,
			style: { whiteSpace: 'unset' }
		},
		{
			id: 'anthemID',
			Header: 'Anthem Dept ID',
			accessor: 'anthemID',
			filterable: true,
			sortable: true
		},

		// {
		// 	id: 'startDate',
		// 	Header: <div style={{ marginLeft: '4px' }}>Client Start Date</div>,
		// 	accessor: d => d.startDate,
		// 	Cell: d => d.original.startDate === '1900-01-01T00:00:00' ? '' : transformDate( d.original.startDate ),
		// 	filterable: false,
		// 	sortable: true
		// },

		// {
		// 	id: 'contactName',
		// 	Header: <div>Contact Name</div>,
		// 	accessor: row => `${ row.contactName }`,
		// 	filterable: true,
		// 	filterMethod: ( filter, row ) => _.includes( row._original.contactName.toUpperCase(), filter.value.toUpperCase() ),
		// 	sortable: true,
		// 	// eslint-disable-next-line react/display-name
		// 	Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.contactName }</div>,
		// 	style: { whiteSpace: 'unset' }
		// },
		// {
		// 	id: 'email',
		// 	Header: <div style={{ marginLeft: '4px' }}>Email Address</div>,
		// 	accessor: row => row.contactEmail,
		// 	filterMethod: ( filter, row ) => _.includes( row._original.contactEmail.toUpperCase(), filter.value.toUpperCase() ),
		// 	filterable: true,
		// 	sortable: true,
		// 	// eslint-disable-next-line react/display-name
		// 	Cell: row => <a href={ `mailto:${ row.original.contactEmail }` }>{ row.original.contactEmail }</a>,
		// 	style: { whiteSpace: 'unset' },
		// 	width: 250
		// },
		// {
		// 	id: 'source',
		// 	Header: <div>Source</div>,
		// 	accessor: row => `${ row.source }`,
		// 	filterable: true,
		// 	filterMethod: ( filter, row ) => _.includes( row._original.source.toUpperCase(), filter.value.toUpperCase() ),
		// 	sortable: true,
		// 	// eslint-disable-next-line react/display-name
		// 	Cell: row => <div style={{ whiteSpace: 'normal' }}>{ row.original.source }</div>,
		// 	style: { whiteSpace: 'unset' },
		// 	width: 90
		// },
		{
			id: 'status',
			Header: <div>Renewal Status</div>,
			accessor: row => `${ row.status }`,
			filterable: true,
			filterMethod: ( filter, row ) => _.includes( row._original.status.toUpperCase(), filter.value.toUpperCase() ),
			sortable: true,
			Cell:
        // eslint-disable-next-line react/display-name
        row => <div style={{ whiteSpace: 'normal' }}>{ row.original.status }</div>,
			style: { whiteSpace: 'unset' },
			width: 180
		},
		{
			id: 'contactDate',
			Header: <div style={{ marginLeft: '4px' }}>Last Contact</div>,
			accessor: row => `${ row.contactDate } ${ row.contactInitial }`,
			Cell: row => (
				<div style={{ whiteSpace: 'normal', textAlign: 'left' }}>
					<span>{ row.original.contactDate === '1900-01-01T00:00:00' ? '' : transformDate( row.original.contactDate ) }</span>{ row.original.contactInitial && <span> ({ row.original.contactInitial })</span> }
				</div>
			),
			filterMethod: ( filter, row ) => _.includes( row._original.contactInitial.toUpperCase(), filter.value.toUpperCase() )
			  || row._original.contactDate !== '1900-01-01T00:00:00' && _.includes( transformDate( row._original.contactDate ), filter.value ),
			filterable: true,
			sortable: true
		},
		{
			id: 'statusDate',
			Header: <div style={{ marginLeft: '4px' }}>Status Date</div>,
			accessor: d => d.statusDate,
			Cell: d => transformDate( d.original.statusDate ),
			filterable: false,
			sortable: true,
			width: 120
		},
		{
			accessor: 'id',
			Header: 'Actions',
			sortable: false,
			filterable: false,
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<div>
					{ row.original.isActive
						? <>
							{ clientBenefitReadonly
								? <Button
									icon
									primary
									size="tiny"
									onClick={ () => showClientsModal( row.original.id ) }
								>
									<Icon name="search" />
								</Button>
								: <>
									<Button
										icon
										primary
										size="tiny"
										onClick={ () => showClientsModal( row.original.id ) }
									>
										<Icon name="search" />
									</Button>
									<Button
										icon
										negative
										size="tiny"
										onClick={ () => {
											setDeleteConfirmModalNameProp( row.original.companyName );
											setDeleteId( row.original.id );
											toggleDeleteConfirmModal();
										}}
									>
										<Icon name="trash" />
									</Button>
								</>
							}
						</>
						: <>
							<Button
								icon
								primary
								size="tiny"
								onClick={ () => {
									setTermInactiveConfirmModalNameProp( row.original.companyName );
									setTermId( row.original.id );
									toggleTermInactiveConfirmModal();
								}}
							>
								<Icon name="filter" />
							</Button>
						</>
					}
				</div>
			)
		}
	];

	const data = useMemo( () => showInactive ? clients : clients.filter( ( { isActive } ) => isActive ), [ clients, showInactive ] );

	return (
		<>
			<div className="titleHeader">
				{ uiLoader
					? <span>
						<Loader active inverted inline size="tiny" style={{ marginRight: '0.5rem', marginBottom: '2px' }}/>
						{ loadingMessage }
					</span>
					: 'Clients'
				}
			</div>
			<div className="formContainer">
				<Segment basic style={{ padding: '2rem', margin: '0 auto' }}>
					<Header as="h2" style={{ fontWeight: 'normal' }}>Client List</Header>

					<FormCheckbox
						checked={ showInactive }
						label="Show Inactive Clients"
						onChange={ () => setShowInactive( prev => !prev ) }
					/>
					<Segment>
						{/* <CustomersFilter callback={ getClients } /> */}
						<Table
							data={ data }
							columns={ columns }
							loading={ tableLoader }
						/>
						<br />
						<p>records:  { clients ? clients.length : 0 }</p>
					</Segment>
					<ClientsModal />
					<DeleteConfirmModal
						name={ deleteConfirmModalNameProp }
						type="Client"
						open={ deleteConfirmModal }
						closeModal={ () => toggleDeleteConfirmModal() }
						onClick={ () => deleteClient( deleteId ) }
					/>
					<TermInactiveConfirmModal
						name={ termInactiveConfirmModalNameProp }
						type="Client"
						open={ termInactiveConfirmModal }
						closeModal={ () => toggleTermInactiveConfirmModal() }
						onClick={ () => termClient( termId ) }
					/>
				</Segment>
				{ customer
					?	<NotesModal
						open={ notesModal }
						closeModal={ toggleNotesModal }
					/>
					: null
				}
			</div>
			<Spinner active={ spinner } />
		</>
	);
};

export default Clients;
