import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { OperationsContext } from '../OperationsContext';
import FeesFilter from './FeesFilter';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import AdminFeesModal from './AdminFeesModal';
import ImplementationFeesModal from './ImplementationFeesModal';
import { maskCurrency } from '../../../Services/inputMasks';

const Fees = props => {

  // context
  const {
    fees,
    feeType,
    year,
    getFees,
    deleteFee,
    showAdminFeesModal,
    showImplementationFeesModal,
    deleteConfirmModal,
    toggleDeleteConfirmModal,
    tableLoader
  } = useContext( OperationsContext );

  // state
  const [ feeId, setFeeId ] = useState( '' );

  useEffect( () => {
    getFees();
  }, [ getFees, feeType, year ] );

  const columns = [
    {
      id: 'payCycle',
      Header: <div>Payroll Cycle</div>,
      accessor: row => `${ row.payCycle.description }`,
      filterable: true,
      filterMethod: ( filter, row ) =>
        _.includes( row._original.payCycle.description.toUpperCase(), filter.value.toUpperCase() ),
      sortable: true,
      Cell:
        row => {
          return (
            <div style={{ whiteSpace: 'normal' }}>{ row.original.payCycle.description }</div>
          );
        },
      style: {'whiteSpace': 'unset' },
    },
    {
      id: 'employeeRange',
      Header: <div>Number of Employees</div>,
      accessor: row => `${ row.employeeRange.description }`,
      filterMethod: ( filter, row ) =>
        _.includes( row._original.employeeRange.description.toUpperCase(), filter.value.toUpperCase() ),
      filterable: true,
      sortable: true,
      Cell:
        row => {
          return (
            <div style={{ whiteSpace: 'normal' }}>{ row.original.employeeRange.description }</div>
          );
        },
      style: { 'whiteSpace': 'unset' },
    },
    {
      id: 'fee',
      Header: <div>Fee</div>,
      accessor: row => `${ row.feeAmount }`,
      sortable: true,
      Cell:
        row => {
          return (
            <div style={{ whiteSpace: 'normal' }}>{ maskCurrency( row.original.feeAmount ) }</div>
          );
        },
      style: { 'whiteSpace': 'unset' },
    },
    {
      accessor: 'id',
      Header: 'Edit / Remove',
      sortable: false,
      filterable: false,
      Cell: row => (
        <div>
          <Button
            icon
            primary
            size="tiny"
            feeid={ row.original.id }
            onClick={ ( e, { feeid } ) => {
              if ( feeType === 49 ) {
                showAdminFeesModal( feeid );
              } else if ( feeType === 50 ) {
                showImplementationFeesModal( feeid );
              }
            }}
          >
            <Icon name="pencil" />
          </Button>
          <Button
            icon
            negative
            size="tiny"
            onClick={ () => {
              setFeeId( row.original.id );
              toggleDeleteConfirmModal();
            }}
          >
            <Icon name="trash" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header as="h2" style={{ fontWeight: 'normal' }}>Fee Administration</Header>
      <Segment>
        <FeesFilter />
        <Table
          data={ fees }
          columns={ feeType === 49 ? columns : columns.slice( 1 ) }
          loading={ tableLoader }
        />
        <br />
        <p>Records: { fees.length }</p>
      </Segment>
      <AdminFeesModal />
      <ImplementationFeesModal />
      <DeleteConfirmModal
        type="fee"
        open={ deleteConfirmModal }
        closeModal={ () => toggleDeleteConfirmModal() }
        onClick={ () => deleteFee( feeId ) }
      />
    </>
  );
};

export default Fees;
