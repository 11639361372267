import React, { useCallback, useState } from 'react';
import Table from '../../Shared/Table';
import DeleteConfirmModal from '../../Shared/DeleteConfirmModal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import EmployeePlanModal from './EmployeePlanModal';
import { maskCurrency } from '../../../Services/inputMasks';

const EmployeePlanTable = ( { employeePlans, updateField } ) => {
	const [ plan, setPlan ] = useState( '' );
	const [ employeePlanModal, setEmployeePlanModal ] = useState( false );
	const [ employeeToEdit, setEmployeeToEdit ] = useState( '' );
	const [ deleteConfirmModal, setDeleteConfirmModal ] = useState( false );
	const [ planToDelete, setPlanToDelete ] = useState( '' );

	const data = employeePlans.reduce( ( acc, plan ) => plan.active === false ? acc : [ ...acc, plan ], [] );

	const addEmployeePlan = useCallback( newPlan => {
		const updatedEmployeePlans = [ ...employeePlans, newPlan ];

		updateField( { target: { name: 'employees', value: updatedEmployeePlans } } );
	}, [ employeePlans, updateField ] );

	const editEmployeePlan = useCallback( editedPlan => {
		const updatedEmployeePlans = employeePlans.map( ( plan, i ) => {
			if ( editedPlan.id === 0 ) {
				return i === editedPlan.index
					? editedPlan
					: plan;
			}

			return plan.id === editedPlan.id
				? editedPlan
				: plan;
		} );

		updateField( { target: { name: 'employees', value: updatedEmployeePlans } } );
	}, [ employeePlans, updateField ] );

	const deleteEmployeePlan = () => {
		const inactivePlans = employeePlans.reduce( ( acc, plan ) => plan.active === true ? acc : [ ...acc, plan ], [] );
		const updatedEmployeePlans = data.map( ( plan, index ) => index === planToDelete ? { ...plan, active: false } : plan );

		updateField( { target: { name: 'employees', value: [ ...inactivePlans, ...updatedEmployeePlans ] } } );
	};

	const columns = [
		{
			Header: 'Employee Name',
			accessor: 'name',
			filterable: false,
			sortable: true,
			width: 200
		},
		{
			Header: 'EE Amount',
			accessor: 'eE_Amount',
			filterable: false,
			sortable: true,
			Cell:
        row => <div>{ maskCurrency( row.original.eE_Amount || 0 ) }</div>

		},
		{
			Header: 'ES Amount',
			accessor: 'eS_Amount',
			filterable: false,
			sortable: true,
			Cell:
        row => <div>{ maskCurrency( row.original.eS_Amount || 0 ) }</div>
		},
		{
			Header: 'EC Amount',
			accessor: 'eC_Amount',
			filterable: false,
			sortable: true,
			Cell:
        row => <div>{ maskCurrency( row.original.eC_Amount || 0 ) }</div>
		},
		{
			Header: 'EF Amount',
			accessor: 'eF_Amount',
			filterable: false,
			sortable: true,
			Cell:
        row => <div>{ maskCurrency( row.original.eF_Amount || 0 ) }</div>
		},
		{
			Header: 'Edit/Remove',
			accessor: 'id',
			sortable: false,
			Cell: row => (
				<div>
					<Button
						icon
						size="tiny"
						onClick={ () => {
							setEmployeeToEdit( { index: row.index, ...row.original } );
							setEmployeePlanModal( true );
						}}
					>
						<Icon name="pencil" />
					</Button>
					<Button
						icon
						negative
						size="tiny"
						onClick={ () => {
							setPlanToDelete( row.index );
							setDeleteConfirmModal( true );
						}}
					>
						<Icon name="trash" />
					</Button>
				</div>
			)
		}
	];

	// const pageSize = useMemo( () => employeePlans.length > 1 ? employeePlans.length : 1, [ employeePlans ] );

	return (
		<>
			<Segment basic clearing>
				<Header as="span" style={{ lineHeight: '36px', fontSize: '1.5rem', fontWeight: 'normal' }}>
					Employees
				</Header>
				<Button
					formNoValidate
					content="Add Record"
					floated="right"
					onClick={ () => setEmployeePlanModal( true ) }
				/>
			</Segment>
			{ data.length > 0
				? <Table
					key={ data.length }
					data={ data }
					columns={ columns }
					pageSize={ data.length }
					hidePagination
					style={{ marginBottom: '2rem' }}
				/>
				: <p style={{ padding: '0 0 1rem 1rem' }}>No records found.</p>
			}
			<EmployeePlanModal
				employeeToEdit={ employeeToEdit }
				employeePlans={ employeePlans }
				plan={ plan }
				setPlan={ setPlan }
				open={ employeePlanModal }
				closeModal={ () => {
					setEmployeeToEdit( {} );
					setEmployeePlanModal( false );
				}}
				onAdd={ addEmployeePlan }
				onEdit={ editEmployeePlan }
			/>
			<DeleteConfirmModal
				type="Record"
				open={ deleteConfirmModal }
				closeModal={ () => setDeleteConfirmModal( false ) }
				onClick={ deleteEmployeePlan }
			/>
		</>
	);
};

export default EmployeePlanTable;
