/* eslint-disable camelcase */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { maskCurrency, unmaskCurrency } from '../Services/inputMasks';

const UPDATE = 'UPDATE';
const MASK = 'MASK';
const UNMASK = 'UNMASK';
const MULTIPLY = 'MULTIPLY';
const SET_IS_ACTIVE = 'SET_IS_ACTIVE';
const SET_LUMP_SUM_HSA = 'SET_LUMP_SUM_HSA';
const SET_HAS_CHANGED = 'SET_HAS_CHANGED';
const SET_VALIDATOR = 'SET_VALIDATOR';
const APPLY_FLAT_RATE = 'APPLY_FLAT_RATE';
const SET_MINIMUM = 'SET_MINIMUM';

const maskValues = values => Object.entries( values ).reduce( ( acc, [ k, v ] ) => ( { ...acc, [ k ]: maskCurrency( v, false ) } ), {} ); // will return a maskedValues object

const planValuesReducer = ( state, { payload, type } ) => {
	switch ( type ) {
		case UPDATE: { // { type: UPDATE, payload: { name, value } }
			const { name, value, mask } = payload;

			return {
				...state,
				planValues: { ...state.planValues, [ name ]: value || '0' },
				formData: { ...state.formData, [ name ]: mask ? maskCurrency( value, false ) : value },
				hasChanged: state.hasChanged + 1
			};
		}

		case MASK: { // { type: MASK, payload: name } // unmask
			return {
				...state,
				formData: { ...state.formData, [ payload ]: maskCurrency( state.formData[ payload ] || '0', false ) }
			};
		}

		case UNMASK: { // { type: UNMASK, payload: name } // mask
			return {
				...state,
				formData: { ...state.formData, [ payload ]: unmaskCurrency( state.formData[ payload ] || '0' ) }
			};
		}

		case MULTIPLY: { // { type: MULTIPLY, payload: multiplier }
			const multipliedValues = Object.entries( state.planValues ).reduce( ( acc, [ k, v ] ) => {
				const value = k.includes( '_HSA' )
					? state.lumpSumHSA
						? v
						: v / state.frequency.hsa * payload.hsa
					: v / state.frequency.premium * payload.premium;

				return { ...acc, [ k ]: value };
			}, {} ); // will return a planValues object with the multiplier applied to each value

			return {
				...state,
				planValues: multipliedValues,
				formData: maskValues( multipliedValues ),
				validator: multipliedValues,
				frequency: payload
			};
		}

		case SET_IS_ACTIVE: {
			return {
				...state,
				isActive: payload,
				hasChanged: state.hasChanged + 1
			};
		}

		case SET_LUMP_SUM_HSA: {
			return {
				...state,
				lumpSumHSA: payload,
				hasChanged: state.hasChanged + 1
			};
		}

		case SET_HAS_CHANGED: {
			return {
				...state,
				hasChanged: payload
			};
		}

		case SET_VALIDATOR: {
			return {
				...state,
				validator: payload
			};
		}

		case APPLY_FLAT_RATE: {
			const flatValues = {
				eC_Employer: state.planValues.eE_Employer,
				eS_Employer: state.planValues.eE_Employer,
				eF_Employer: state.planValues.eE_Employer,
				eC_HSA: state.planValues.eE_HSA,
				eS_HSA: state.planValues.eE_HSA,
				eF_HSA: state.planValues.eE_HSA
			};

			return {
				...state,
				planValues: { ...state.planValues, ...flatValues },
				formData: { ...state.formData, ...maskValues( flatValues ) },
				validator: { ...state.validator, ...flatValues },
				hasChanged: state.hasChanged + 1
			};
		}

		case SET_MINIMUM: {
			const minValues = {
				eE_Employer: state.planValues.minimumContribution,
				eS_Employer: state.planValues.minimumContribution,
				eC_Employer: state.planValues.minimumContribution,
				eF_Employer: state.planValues.minimumContribution
			};

			return {
				...state,
				planValues: { ...state.planValues, ...minValues },
				formData: { ...state.formData, ...maskValues( minValues ) },
				validator: { ...state.validator, ...minValues },
				hasChanged: state.hasChanged + 1
			};
		}

		default:
			return state;
	}
};

const usePlanValues = ( initialValues, isActive, lumpSumHSA, flatRate ) => {
	const initialState = useRef( {
		isActive,
		lumpSumHSA,
		frequency: { premium: 1, hsa: 1 },
		planValues: initialValues,
		formData: maskValues( initialValues ),
		validator: initialValues,
		hasChanged: 0
	} );

	const [ state, dispatch ] = useReducer( planValuesReducer, initialState.current );

	// const compareData = useCallback( data => {
	// 	// make sure the data is in the same order as the initialState
	// 	const orderedData = {};

	// 	const initialData = { ...initialState.current.planValues, isActive: initialState.current.isActive };

	// for ( let key in initialData ) {
	// 		orderedData[ key ] = data[ key ];
	// 	}

	// 	const areEqual = Object.keys( data ).length === Object.keys( initialData ).length ? JSON.stringify( orderedData ) === JSON.stringify( initialData ) : false;

	// if ( areEqual ) {
	// 		setHasChanged( false );
	// 	} else {
	// 		setHasChanged( true );
	// 	}
	// }, [ setHasChanged ] );

	// set initialData to state and set hasChanged to false

	// useEffect( () => {
	// 	const data = { ...state.planValues, isActive: state.isActive };

	// compareData( data );
	// }, [ compareData, state.planValues, state.isActive ] );

	useEffect( () => { // handle flatRate
		if ( flatRate ) {
			dispatch( { type: APPLY_FLAT_RATE } );
		}
	}, [ flatRate, state.planValues.eE_Employer, state.planValues.eE_HSA ] );

	const updateValue = ( { name, value, mask = false } ) => {
		dispatch( {
			type: UPDATE,
			payload: { name, value, mask }
		} );
	};

	const maskValue = useCallback( name => {
		dispatch( {
			type: MASK,
			payload: name
		} );
	}, [] );

	const unmaskValue = useCallback( name => {
		dispatch( {
			type: UNMASK,
			payload: name
		} );
	}, [] );

	const setFrequency = useCallback( multiplier => {
		let payload;

		switch ( multiplier ) {
			case 'monthly':
				payload = { premium: 1, hsa: 1 };
				break;
			case 'semi-monthly':
				payload = { premium: 12 / 24, hsa: 12 / 24 };
				break;
			case 'bi-weekly':
				payload = { premium: 12 / 24, hsa: 12 / 26 };
				break;
			case 'weekly':
				payload = { premium: 12 / 48, hsa: 12 / 52 };
				break;
			case 'annually':
				payload = { premium: 12, hsa: 12 };
				break;
			default:
				break;
		}

		dispatch( {
			type: MULTIPLY,
			payload
		} );
	}, [] );

	const setIsActive = useCallback( payload => {
		dispatch( {
			type: SET_IS_ACTIVE,
			payload
		} );
	}, [] );

	const setLumpSumHSA = useCallback( payload => {
		dispatch( {
			type: SET_LUMP_SUM_HSA,
			payload
		} );
	}, [] );

	const setHasChanged = useCallback( payload => {
		dispatch( {
			type: SET_HAS_CHANGED,
			payload
		} );
	}, [] );

	const setValidator = useCallback( payload => {
		dispatch( {
			type: SET_VALIDATOR,
			payload
		} );
	}, [] );

	const setMinimum = useCallback( () => {
		dispatch( { type: SET_MINIMUM } );
	}, [] );

	return {
		...state,
		updateValue,
		maskValue,
		unmaskValue,
		setFrequency,
		setIsActive,
		setLumpSumHSA,
		setHasChanged,
		setValidator,
		setMinimum
	};
};

export default usePlanValues;
